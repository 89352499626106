import { colors } from 'themeFlymble';
import styled from 'styled-components';
import { darkBlueLighter2, lightGrey2 } from '../styles/colors';

export const CheckoutPageContent = styled.div`
  width: 100%;
  @media(min-width: 1000px) {
    width: 95%;
    max-width: 1122px;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }
`

export const CheckoutPageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #F2F3F8;


  @media (min-width: 1000px) {
  
    /* padding-top: 70px;
    padding-bottom: 80px; */
    min-height: 1200px;
    /* padding: 2.5%; */

    .checkoutWrapperGrid {
      /* grid-column: 2/4;
      display: grid;
      grid-template-columns: 28fr 13fr; */
      /* grid-column-gap: 80px; */

      .paymentDatesSection {
        /* grid-column: 2/3; */
      }

      .checkoutBody {
        /* grid-row: 3/4; */
          grid-column-start: 1;
      }
    }
    .topSection {
      grid-column-start: 1;
      display: flex;
      flex-direction:column;
    }
    .sideColumnWrapper {
      grid-column: 2 / 3;
      /* grid-row: 3 / 5; */
    }

    .stickyWrapper {
      position: sticky;
      top: 339px;
    }
    .mobileOnly {
      @media(min-width: 1000px) {
        display: none;
      }
    }
  }

  @media (max-width: 999px) {
    /* padding-left: 0px;
    padding-right: 0px; */

    .checkoutBody {
      /* grid-row: 3/4; */
    }

    .stickyWrapper {
      display: none;
    }
  }
`;


export const CheckoutFormsUKWrapper = styled.div`
  padding-top: 45px;
  @media (max-width: 1000px) {
    padding-top: 0px;
  }

  .description {
    font-size: 13px;
    display: block;
    color: black;
    margin-top: 0px;

    @media (min-width: 1000px) {
      /* padding: 16px; */
      padding-bottom: 0px;
      grid-column: 1/3;
      margin-top: 0px;
    }
  }

  h2 {
    font-size: 22px;
    margin-bottom: 0px;
    /* vertical-align: middle; */
    color: black;

    @media (min-width: 1000px) {
      margin-bottom: 20px !important;
    }
  }

  .faIcon {
    font-size: 20px;
    /* vertical-align: middle; */
  }

  @media (min-width: 1000px) {
    padding-top: 0px;

    .description {
      /* display: none; */
    }

    .faIcon {
      font-size: 28px;
    }
  }

  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .proceedWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .authorizeButton {
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

  .proceedButton {
    margin-top: 50px;
    margin-bottom: 20px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

 //ant design customisation
  .ant-input {
    border: 1px solid ${lightGrey2};
    border-radius: 3px;
    height: 40px;
    &::placeholder {
      color: ${darkBlueLighter2};
    }
  }
  .ant-select {
    border: none;
    border-radius: 3px;
    outline: 0;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  .ant-select-auto-complete.ant-select {
    border: none;
    padding-bottom: 0;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
  }
  & .ant-select-auto-complete.ant-select .ant-input {
    border: none;
  }
  .ant-select .ant-select-selector {
    border: 1px solid ${lightGrey2};
    border-radius: 3px;
  }
`;

export const AddPassengerButtonWrapper = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;

  width: 100%;

  span {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: bold;

    width: 220px;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: ${colors.flymbleBlue};
    border-radius: 3px;
  }

  .faIcon {
    padding-right: 3px;
    font-size: 16px;
  }
`;

export const ConfirmationPaymentSection = styled.div`
  padding-bottom: 50px;
  .authorizeButton {
    margin-top: 50px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
      width: 100%;
    }

    line-height: 0 !important;

    display: block;
    width: 50%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }
`;

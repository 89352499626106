
import {dayjs} from 'Services/dayjs';
import { Flight } from 'generated/types';
import { getDurationSeconds } from 'helpers/getDurationSeconds';

 const getConnectionTimes = (route: any) => {
  var connectionTimes: number[] = [];
  var arrivalTimes: number[] = [];
  var departureTimes: number[] = [];

  for (var j = 1; j < route.length; j++) {
    departureTimes.push(dayjs(route[j].departingAt).unix());
  }

  for (var i = 0; i < route.length; i++) {
    arrivalTimes.push(dayjs(route[i].arrivingAt).unix());
  }

  for (var f = 0; f < departureTimes.length; f++) {
    var singleConnection = (departureTimes[f] - arrivalTimes[f] )
    connectionTimes.push(singleConnection);
  }

  return connectionTimes;
}

export const getTotalRouteDurationSeconds = (route: any) => {
  if (route) {
    const routeFlightsDuration = route
      .map((flight: any) => getDurationSeconds(flight.duration))
      .reduce((prev: number, next: number) => prev + next, 0);

    const routeConnectionDurations = getConnectionTimes(route)
      .reduce((prev, next) => prev + next, 0);

    return routeFlightsDuration + routeConnectionDurations;
  } else {
    return 0;
  }
}

export const getTotalTripDurationSeconds = (flight: Flight) => {
  if (!flight) return 0;
  const totalOutboundDuration = getTotalRouteDurationSeconds(flight.outbound)
  const totalReturnDuration = getTotalRouteDurationSeconds(flight.return)
  
  return totalOutboundDuration + totalReturnDuration;
}

export const convertToHourMinutes = (seconds: number)=> {
  const hDiff = Math.trunc(seconds / 60 / 60);
  const minDiff = Math.round((seconds - hDiff * 60 * 60) / 60);
  return `${hDiff}h ${minDiff}min`;
}

export const removeTimeZone = (date: string) => {
  if(date.length > 23) {
    return date.substring(0, 23);
  } else {
    return date;
  }
}

export const FORM_MONTHS = [
  {name: 'January', value: '01'},
  {name: 'February', value: '02'},
  {name: 'March', value: '03'},
  {name: 'April', value: '04'},
  {name: 'May', value: '05'},
  {name: 'June', value: '06'},
  {name: 'July', value: '07'},
  {name: 'August', value: '08'},
  {name: 'September', value: '09'},
  {name: 'October', value: '10'},
  {name: 'November', value: '11'},
  {name: 'December', value: '12'},
]
import styled from 'styled-components';
import { css } from 'emotion/macro';

const MobileOnly = css`
  @media (min-width: 600px) {
    display: none !important;
  }
`;

const DesktopOnly = css`
  @media (max-width: 600px) {
    display: none !important;
  }
`;

const onlyDesktop = css`
  display: none;
  @media (min-width: 1000px) {
    display: grid;
  }
`;

const onlyMobile = css`
  display: none;
  @media (max-width: 999px) {
    display: grid;
  }
`;

const MobilePressWrapper = styled.div`
  @media (min-width: 1000px) {
    display: none;
  }
`;

const Title = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  text-align: center;
  color: #717484;
  background-color: ${props => (props.bgWhite ? 'white' : '#f8f9fe')};
  font-size: 14px;
  font-weight: 400;
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 30)}px;
`;

const Spacer = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  background-color: ${props => (props.bgWhite ? 'white' : '#f8f9fe')};
  min-height: ${props => (props.height ? props.height : 0)}px;
`;

const HorizontalLine = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  line-height: 1px;
  border-top: 2px solid #eee;
  width: 75%;
  margin: 0px auto;
`;

const Sphere = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  width: 50px;
  height: 25px;
  background-color: white;
  border-top-left-radius: 35px;
  border-top-right-radius: 35px;
  bottom: 16px;
  left: calc(50% - 25px);
  position: relative;
`;

export {
  MobileOnly,
  DesktopOnly,
  onlyDesktop,
  onlyMobile,
  MobilePressWrapper,
  Title,
  Spacer,
  HorizontalLine,
  Sphere,
};

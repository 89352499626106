export const REVIEWS = [
  {
    name: 'Lesley',
    review: 'Absolutely fantastic service',
  },
  {
    name: 'Thandie',
    review: 'Very easy website to use',
  },
  {
    name: 'Anne',
    review: 'Quite straightforward to use',
  },
  {
    name: 'Marielle',
    review: 'Easy and quick to book flights',
  },
  {
    name: 'Max',
    review: 'Quick and easy to use!',
  },
  {
    name: 'Keitan',
    review: 'Excellent wouldn’t choose another agent',
  },
]
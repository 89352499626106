import React from 'react';
import styled from 'styled-components';
import { useCurrencyContext } from 'Context/CurrencyProvider';
import { white, darkBlue2, darkBlueLighter2 } from '../../../../styles/colors';

const Wrapper = styled.div`
  order: 1;
  background-color: ${white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 110px;
  @media (max-width: 999px) {
    order: 2;
    padding: 10px 16px 60px 16px;
  }
`;
const Title = styled.div`
  font-family: Public Sans;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 26px;
  width: 90%;
  max-width: 1122px;
  color: ${darkBlue2};
  @media (max-width: 999px) {
    width: 100%;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 16px;
  }
`;
const Subtitle = styled.div`
  width: 90%;
  max-width: 1122px;
  color: ${darkBlueLighter2};
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: left;
  @media (max-width: 999px) {
    width: 100%;
  }
`;

const CreditBrokerInfo = () => {
  const { currencySymbol, currency } = useCurrencyContext();

  return (
    <Wrapper>
      <Title>Flymble acts as a credit broker and not as a lender</Title>
      <Subtitle>
        {currency === 'GBP'
          ? `Flymble Travel Ltd, trading as Flymble, is an Appointed Representative of Fly Now Pay Later
Limited who are authorised and regulated by the Financial Conduct Authority, FRN: 726937.
Credit accounts are provided by Pay Later Financial Services Limited, who are authorised and
regulated by the Financial Conduct Authority under registration number 672306. Pay Later
Financial Services Limited registered office: 4th Floor, 33 Cannon Street, London, United Kingdom.
Registered in England & Wales. Registered No: 09020100.`
          : `Payment options through Fly Now Pay Later are provided by Pay Later Financial Services Inc, 228 Park Ave S, 56453, New York 10003-1502 US, in partnership with Cross River Bank, Member FDIC. Your rate will be 9.99%–29% APR based on credit and is subject to an eligibility check. Options depend on your purchase amount, and a down payment may be required.`}{' '}
        <br />
        <br />
        Representative example: Borrowing {currencySymbol}600 over 9 months, a
        one off transaction fee of 14.99% is payable (fixed), you will repay 9
        monthly payments of {currencySymbol}66.66 per month. Total amount
        payable {currencySymbol}689.94. Representative %APR 49%.
        <br />
        <br />
        Registered office address: Kemp House, 152 -160 City Road London, United
        Kingdom, EC1V 2NX
      </Subtitle>
    </Wrapper>
  );
};

export default CreditBrokerInfo;

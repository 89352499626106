import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { PaymentForm } from './InjectedPaymentForm';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { css, cx } from 'emotion/macro';
import styled from 'styled-components';
import { trackDirectPayment_VisitPaymentPage } from 'Utils';
import { InfoSection } from '../../components/InfoSection';

import { RowPaymentLogos } from './RowPaymentLogos';

const iconSpacing = css`
  margin-right: 10px;
`;

const payment_intent_api =
  `${process.env.REACT_APP_AWS_CLOUD_FUNCTIONS_URL}/createPaymentIntent`;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const PaymentContainer = ({
  handleStripePaymentComplete,
  checkoutPrice,
  customerName,
}) => {
  const [stripeState, setStripeState] = useState(false);

  useEffect(() => {
    createPaymentIntent();
    trackDirectPayment_VisitPaymentPage();
  }, []);

  const createPaymentIntent = async () => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if(clientSecret){
      setStripeState(clientSecret);
      return;
    }

    const payment_amount = Number.parseInt(
      Number.parseFloat(checkoutPrice) * 100
    );
    const body = {
      amount: payment_amount,
      customerName: customerName,
    };
    // call to firebase back-end
    axios
      .post(payment_intent_api, body)
      .then(res => {
        console.log('[Web-app__Create_payment_intent] response', res);
        const csk = res.data.clientSecret;
        console.log('The client secret is...', csk);
        setStripeState(csk);
      })
      .catch(error => console.log(error));
  };

  console.log('[Payment container] the checkou price is...', checkoutPrice);

  // Text to go into the green box.
  const box_items = [
    'SSL secure transaction.',
    'Pay using your debit or credit card.',
    'Receive booking confirmation within 12 hours.',
  ];

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret: stripeState,
    appearance,
  };

  return (
    <>
      <PaymentContainerWrapper className="gridWrapper">
        <h2>
          <FontAwesomeIcon
            icon={faCreditCard}
            className={cx('faIcon', iconSpacing)}
          />
          Payment &nbsp; &nbsp;
          <RowPaymentLogos />
        </h2>
        {stripeState ? (
          <Elements options={options} stripe={stripePromise}>
            <PaymentForm
              handleStripePaymentComplete={handleStripePaymentComplete}
              checkoutPrice={checkoutPrice}
              customerName={customerName}
            />
          </Elements>
        ) : (
            <div>...loading</div>
          )}
      </PaymentContainerWrapper>
    </>
  );
};

PaymentContainer.propTypes = {
  handleStripePaymentComplete: PropTypes.func.isRequired,
  checkoutPrice: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

const PaymentContainerWrapper = styled.div`
  background-color: white;
  padding: 20px;
  padding-bottom: 100px;
  padding-top: 5px;
  margin-bottom: 24px;

  @media (min-width: 1000px) {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;

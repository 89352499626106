import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { CookiesPopupWrapper } from './styles';
import { Link } from 'react-router-dom';

const CookiesPopup = ({ visible, closeHandler }) => {
  const handleCloseCookies = () => {
    setIsCookiesOpen(false);
    localStorage.setItem('cookiePopup', false);
    closeHandler();
  };

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleCloseCookies();
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  const [isCookiesOpen, setIsCookiesOpen] = React.useState(true);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <>
      {visible
        ? isCookiesOpen && (
            <CookiesPopupWrapper>
              <div onClick={handleCloseCookies} ref={wrapperRef}>
                <div className="closeText">
                  <FontAwesomeIcon icon={faTimes} onClick={closeHandler} />
                  &nbsp;&nbsp;Close
                </div>
                <p>
                  We use cookies to offer you a customized browsing experience.
                  &nbsp;
                  <Link to="/terms-conditions" style={{ color: 'white' }}>
                    Click <u>here</u> for details on our privacy policy.
                  </Link>
                </p>
              </div>
            </CookiesPopupWrapper>
          )
        : null}
    </>
  );
};

CookiesPopup.propTypes = {
  visible: PropTypes.bool,
  closeHandler: PropTypes.func.isRequired,
};

CookiesPopup.defaultProps = {
  visible: true,
};

export default CookiesPopup;

import styled from 'styled-components';
import { css } from 'emotion';
import { darkBlue2, white, purple, almostBlack } from '../../styles/colors';

export const faStyle = css`
  font-size: 22px;
`;

export const MobileMenuWrapper = styled.div<{
  open: any;
}>`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: ${almostBlack};
  color: white;
  z-index: 1000;
  display: grid;
  grid-template-rows: 3fr repeat(5, 1fr) 4fr;
  grid-row-gap: 20px;
  left: ${props => (props.open ? '0' : '100vw')};
  transition: all 0.4s ease-in-out;
  padding: 20px 20px;
  text-align: center;

  & div.l1,
  & div.footer {
    a {
      max-width: 70vw;
      color: white;
    }
    span {
      margin: 0 20px;
    }
  }
  & div.footer {
    margin-top: auto;
    margin-bottom: 30%;
  }

  & div.header {
    display: grid;
    grid-template-columns: 32px 1fr 32px;
    grid-column-gap: 20px;
  }
  & .menuitem {
    & a {
      font-size: 20px;
      font-weight: 600;
      color: white;
      text-decoration: none;
    }
  }
`;

export const NavStyled2 = styled.div`
  box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
  background-color: ${white};
  color: ${darkBlue2};

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 5%;
  padding-right: 5%;
  align-content: center;
  position: relative;
  height: 57px;
  & > div.menu-logo,
  & > div.menu-open,
  & > div.menu-language {
    align-items: center;
    display: flex;
    & > span {
      margin-left: 10px;
    }
  }
  img.flymble-logo {
    color: ${purple};
    width: 96px;
    padding-top: 2px;
  }
  @media (max-width: 999px) {
    padding-left: 17px;
    padding-right: 17px;
  }
`;


export const DesktopNavItems = styled.div`
  @media (max-width: 999px) {
    display: none;
  }
  color: ${darkBlue2};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    margin-left: 40px;
  }

`

export const ChatIcon = styled.img`
  width: 15px;
  margin-right: 8px;
`
import React, { useContext, useState, useEffect } from 'react';
import map from 'ramda/src/map';
import prop from 'ramda/src/prop';
import path from 'ramda/src/path';
import equals from 'ramda/src/equals';
import values from 'ramda/src/values';
import length from 'ramda/src/length';
import flatten from 'ramda/src/flatten';
import compose from 'ramda/src/compose';
import groupWith from 'ramda/src/groupWith';
import applySpec from 'ramda/src/applySpec';
import AppContext from 'Context/AppContext';
import { CheckoutFormsUKWrapper } from '../styles';
import CoreView from '../CoreView';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import FnplV2Widget from 'components/FnplWidget/index';
import InstructionsFnpl from '../components/InstructionsFnpl';
import { RedirectElement } from 'components/RedirectElement';
import { extractFlightTicketData } from 'Utils/fnplCalc';
import { v4 as uuidv4 } from 'uuid';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PaymentContainer } from './StripePaymentForm/PaymentContainer';
import { BOOKING_TYPES } from '../../constants/constants';

import { useOrderSummaryContext } from 'Context/OrderSummaryProvider';
import { useBaggagesContext } from 'Context/BaggagesProvider/BaggagesProvider';

import { FlightSource } from 'generated/types';
import { tryProcessBooking } from '../bookingFunctions/tryProcessBooking';
import { processBooking } from '../bookingFunctions/processBooking';
import { useCurrencyContext } from 'Context/CurrencyProvider';

const getServiceId = compose(
  ([serviceId]) => serviceId,
  id => id.split('-')
);

const getBaggagesIds = compose(flatten, values, map(map(prop('id'))));

const handleServices = map(
  applySpec({
    id: path([0]),
    quantity: length,
  })
);

const getServices = compose(
  handleServices,
  groupWith(equals),
  map(getServiceId),
  getBaggagesIds
);

const CreditCheckPageContainerProxy = () => {
  const { baggages } = useBaggagesContext();
  const {
    orderSummaryContext,
    orderSummaryObjectContext,
  } = useOrderSummaryContext();

  const [userIdReferenceState, setUserIdReferenceState] = useState('');
  const [
    redirectToThankYouPageState,
    setRedirectToThankYouPageState,
  ] = useState(false);

  const { currency } = useCurrencyContext();

  const [redirectToErrorPageState, setRedirectToErrorPageState] = useState(
    false
  );

  const context = useContext(AppContext);
  const {
    selectedFlightTicket,
    firstCheckoutPageFormValuesContext,
    setPartnerBookingReferenceContext,
    partnerBookingReferenceContext,
    typeBookingContext,
    isAddedTicketServiceContext,
    isAddedPremiumSupportContext,
    cabinClass

  } = context;

  const services =
    selectedFlightTicket &&
    selectedFlightTicket.source === FlightSource.Duffel &&
    getServices(baggages);

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (clientSecret) {
      //it is redirect from payment, so we don't want to store new try process booking event
      return;
    }

    const referenceUUID = uuidv4();
    setUserIdReferenceState(referenceUUID);
    const try_callback = () => console.log('the try process booking callback');
    tryProcessBooking(
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      try_callback,
      orderSummaryObjectContext,
      typeBookingContext,
      services,
      isAddedPremiumSupportContext,
      isAddedTicketServiceContext,
      cabinClass,
      currency
    );
  }, []);

  const extract = extractFlightTicketData(selectedFlightTicket);

  const orderDataFNPL = {
    ...firstCheckoutPageFormValuesContext,
    tripExtract: extract,
    // flymbleCheckoutPrice: orderSummaryObjectContext.FNPL_Price,
    orderSummaryObject: orderSummaryObjectContext,
  };
  const goThankYouPage = () => setRedirectToThankYouPageState(true);
  const goToErrorPage = () => setRedirectToErrorPageState(true);

  const handleFNPLCallBackComplete = dispersalToken => {
    processBooking(
      dispersalToken,
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      goThankYouPage,
      orderSummaryObjectContext,
      orderSummaryContext,
      typeBookingContext,
      goToErrorPage
    );
  };

  const handleStripePaymentComplete = (type_booking = BOOKING_TYPES.STRIPE_Flights) => {
    const token_string = 'STRIPE-XXXXXXXXX';
    processBooking(
      token_string,
      selectedFlightTicket,
      firstCheckoutPageFormValuesContext,
      partnerBookingReferenceContext,
      extract,
      goThankYouPage,
      orderSummaryObjectContext,
      orderSummaryContext,
      type_booking,
      goToErrorPage
    );
  };

  const is_pay_in_full = typeBookingContext === BOOKING_TYPES.STRIPE_Flights;

  const getPaymentCustomerName = () => {
    const { P0_firstname, P0_lastname } = firstCheckoutPageFormValuesContext;
    const answer = `${P0_firstname} ${P0_lastname}`;

    return answer;
  };

  const stripe_customer_name = getPaymentCustomerName();

  return (
      <CoreView timeOutDelay={0}>
        <CheckoutFormsUKWrapper>
          {is_pay_in_full ? (
            <PaymentContainer
              handleStripePaymentComplete={handleStripePaymentComplete}
              checkoutPrice={orderSummaryObjectContext.FNPL_Price}
              customerName={stripe_customer_name}
            />
          ) : (
            <Form>
              <FnplV2Widget
                orderDataFNPL={orderDataFNPL}
                callbackComplete={handleFNPLCallBackComplete}
                callbackReference={setPartnerBookingReferenceContext}
                referenceUUID={userIdReferenceState}
                flymbleCheckoutPrice={orderSummaryObjectContext.FNPL_Price}
              >
                <InstructionsFnpl isVisible={true} />
              </FnplV2Widget>
            </Form>
          )}
        </CheckoutFormsUKWrapper>
        <RedirectElement
          destination="/thank-you"
          isAllowedRedirect={redirectToThankYouPageState}
        />
        <RedirectElement
          destination="/error"
          isAllowedRedirect={redirectToErrorPageState}
        />
      </CoreView>
  );
};

const CreditCheckPageContainer = () => (
  <ErrorBoundary errorLocation="Pay booking" redirect={true}>
    <CreditCheckPageContainerProxy />
  </ErrorBoundary>
);

export { CreditCheckPageContainer };
export default CreditCheckPageContainer;

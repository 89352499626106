import styled from 'styled-components';
import { css } from 'emotion/macro';
import {
  flymbleBlue,
  flymbleGreen,
  darkBlueLighter,
  lightGrey3,
  blue6,
  darkBlue2,
  blueGrey
} from '../../../../styles/colors'

export const phoneNumber = css`
  a {
    color: ${flymbleBlue};
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
    margin-top: 25px;
  }
`;

export const BottomRowWrapper = styled.div`
font-family: 'Public Sans', sans-serif;
grid-column: 1/3;
display: grid;
color: ${darkBlueLighter};

@media (max-width: 999px) {
  grid-template-columns: 4fr 1fr 3fr;
}

@media (min-width: 1000px) {
  grid-row: 1/4;
  grid-column: 3/ span 2;
  width: 100%;
  align-items: center;
  padding-bottom: 0px;
  border-left: solid 1px ${blue6};
}
.viewDetailsButton {
  padding-left: 15px;
  padding: 0;
  margin-top: 2px;
  @media (min-width: 1000px) {
    padding: 0;
    margin: 0;
    padding-bottom: 15px;
    text-align: center;
  }

  .viewDetails {
    width: 25px;
    height: 25px;
    padding: 8px;
    background-color: ${lightGrey3};
    border-radius: 5px;
    display: inline-block;
    cursor: pointer;
    color: ${darkBlueLighter};
    @media (min-width: 1000px) {
      width: 25px;
      margin-top: 5px;
      height: 25px;
      padding: 6px;
    }
  }
}

.priceTag {
  
  text-align: center;
  @media (min-width: 1000px) {
    grid-row: 1/3;
    .bigprice {
      font-weight:700;
      font-size: 20px;
      padding-top: 20px;
      color: ${darkBlue2};
    }
    .smallprice {
      font-size: 14px;
      font-weight: 400;
      padding-top: 10px;
      line-height: 15px;
      color: ${blueGrey};

    }
    .totalprice {
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 0px;
      padding-top: 0px;
    }
  }
  @media (max-width: 1000px) {
    text-align: left;
    // grid-column: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 7px;
    color: #31393c;
    padding-right: 3px;
    & > .bigprice {
      font-size: 16px;
      // line-height: 34px;
      font-weight: bold;
    }
    & > .smallprice {
      font-size: 13px;
      line-height: 8px;
      font-weight: normal;
    }
  }
}

/* now obsolete, previous formatting with nasty grid */
.priceDisplay {
  background-color: orange;
  @media (min-width: 1000px) {
    grid-row:2;
    display: grid;
    text-align: center;

    .upfrontText {
      display: inline-block;
      grid-row: 2/3;
      font-size: 16px;
      font-weight: 500;
      padding-bottom: 6px;
      padding-top: 8px;
      text-align: center;
    }

    .priceUpfront {
      text-align: center;
      font-size: 36px;
      font-weight: normal;
      display: inline-block;

      grid-row: 1/2;

      .plusSign {
        display: none;
      }
    }
  }

  @media (max-width: 1000px) {
    text-align: center;
    color: #31393c;
    padding-right: 3px;
    span {
      font-size: 15px;
      font-weight: bold;
      :first-child {
        font-weight: bold;
        font-size: 19px;

        @media (max-width: 325px) {
          font-size: 15px;
        }
      }
    }

    .priceUpfront {
      text-align: center;
      font-size: 15px;
      font-weight: normal;
      display: inline-block;

      grid-row: 1/2;
    }

    .upfrontText {
      font-weight: normal;
      padding-left: 5px;
    }

    .plusSign {
      font-weight: normal !important;
    }
  }

  @media (max-width: 325px) {
    padding-top: 5px;
  }

  @media (min-width: 1000px) {
    span {
      :nth-child(2) {
        display: none;
      }
    }
  }
}
.selectFlightTicket {
  padding-top: 10px;
  text-align: right;
  display: block;
  cursor: pointer;

  @media (max-width: 1000px) {
    padding-top: 2px;
    height: 39px;
    width: 111px;
    padding-left: 5px;
  }

  @media (min-width: 1000px) {
    grid-row:3;
    text-align: center;
    padding-top: 0px;  

  }

  a {
    text-decoration: none;
  }

  span {
    padding: 8px;
    background-color: ${flymbleGreen};
    color: white;
    font-size: 16px;
    font-weight: 700;
    display: block;
    text-align: center;
    border-radius: 5px;

    @media (min-width: 1000px) {
      padding: 12px 8px;
      /* background-color: ${flymbleBlue}; */        
      font-size: 16px;
      font-weight: 700;
      border-radius: 5px;
      max-width: 150px;
      display: block;
      margin: 0 auto;
      padding: 10px 8px;
    }
     
  }

  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }
  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .arrowRightIcon{
    margin-left: 10px;
  }
}

.mobileOnly {
  @media (min-width: 1000px) {
    display: none;
  }
}

.priceDisplay {
  .desktopOnly {
    font-size: 12px;
    font-weight: 300;
    line-height: 14px;
    text-align: center;
    padding-bottom: 10px;
    @media (max-width: 1000px) {
      display: none;
    }
  }
  @media (max-width: 1000px) {
    text-align: right;
    padding-right: 14px;
  }

  @media (max-width: 325px) {
    padding-right: 5px;
    font-size: 14px !important;
    text-align: center;
  }
}

.nrInstalments {
  font-size: 15px !important;
}

.desktopOnly {
    grid-row: 4;

  @media(max-width: 1000px) {
    display: none;
  }
}
`;

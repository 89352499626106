import React from 'react';
import styled from 'styled-components';
import { darkBlueLighter2 } from '../../../styles/colors';

interface AirlineRowProps {
  legType: string;
  airlineName: string;
  airlineCodes: string[];
  details: boolean;
}

const AirlineRowWrapper = styled.div<{
  details: any;
}>`
  grid-column: 1/span 2;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    img {
      display: inline-block;
      vertical-align: middle;
      padding-right: 10px;
      width: 35px !important;
      border-radius: 5px;
    }

    .airlineInfo {
      padding-bottom: 10px;
      // grid-column: 1/3;
      // display: grid;
      // grid-template-columns: 2fr 1fr;
      align-items: center;
    }

    .airlineName {
      font-size: 12px;
      font-weight: 300;
      color: ${darkBlueLighter2};
    }
  }

  img {
    width: 30px;
  }

  @media (min-width: 1000px) {
    padding-right: ${(props: any) => props.details ? '5px' : '50px'};
    padding-left: ${(props: any) => props.details ? '0px' : '50px'};
    padding-top: 17px;
    padding-bottom: 16px;
    padding-left: 0;
    grid-column: 1/span 2;
    display: flex;
    padding-left: 50px;
    padding-left: ${(props: any) => props.details ? '0px' : '50px'};

    .airlineInfo {
      /* margin-left: 50px; */
      img {
        padding-right: 5px;
      }
      .details {
        padding-left: -40px;
      }
      .airlineName {
        font-family: 'Heebo';
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: left;
        color: ${darkBlueLighter2};
        padding: 0px;
        padding-left: 5px;
      }
    }

  }

  .airlineCodes {
    display: inline-block;
  }

  .desktopOnly {
    // display: inline-block;
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .shareMobile {
    text-align: right;
    display: none;
    @media (min-width: 1000px) {
      display: none;
    }
  }
`;

// This component displays the airline logo's for the detailled view.
const AirlineRow = (props: AirlineRowProps) => {
  const { legType, airlineName, airlineCodes, details } = props;

  return (
    <AirlineRowWrapper details={details}>
      <div className="legType">{legType}</div>
      <div className="airlineInfo">
        <img
          src={`https://images.kiwi.com/airlines/64/${airlineCodes}.png`}
          alt="airlinelogo"
        />
        <span className="airlineName">{airlineName}</span>
      </div>

      <div className="seatsLeft">
        <span>
          <div />
        </span>
      </div>
    </AirlineRowWrapper>
  )
}

export { AirlineRow, AirlineRowWrapper };

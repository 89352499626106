import React from 'react';
import i18n from 'Utils/i18n.js';
import styled from 'styled-components';
import {dayjs} from 'Services/dayjs';

const backgroundColor = '#f8f9fe';

const departureDate = dayjs()
  .add(150, 'day')
  .format('YYYYMMDD');
const returnDate = dayjs()
  .add(150 + 14, 'day')
  .format('YYYYMMDD');

const DestinationsPL = [
  {
    Destination: 'Zanzibar',
    Price: '74',
    Url: `/search-flight/C_ZNZ/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Hawaje',
    Price: '133',
    Url: `/search-flight/C_HNL/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Malediwy',
    Price: '93',
    Url: `/search-flight/C_MLE/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Kapsztad',
    Price: '88',
    Url: `/search-flight/C_CPT/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Bali',
    Price: '112',
    Url: `/search-flight/C_DPS/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Bangkok',
    Price: '67',
    Url: `/search-flight/C_BKK/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Lanzarote',
    Price: '41',
    Url: `/search-flight/C_ACE/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
];

const DestinationsUK = [
  {
    Destination: 'New York',
    Price: '74',
    Url: `/search-flight/C_ZNZ/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Tenerife',
    Price: '19',
    Url: `/search-flight/C_HNL/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Maledives',
    Price: '93',
    Url: `/search-flight/C_MLE/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Capetown',
    Price: '88',
    Url: `/search-flight/C_CPT/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Bali',
    Price: '112',
    Url: `/search-flight/C_DPS/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Bangkok',
    Price: '67',
    Url: `/search-flight/C_BKK/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
  {
    Destination: 'Lanzarote',
    Price: '41',
    Url: `/search-flight/C_ACE/C_WAW/${departureDate}/${returnDate}/1/0`,
  },
];

const Destinations = i18n.language === 'pl' ? DestinationsPL : DestinationsUK;

const PopularSearches = () => (
  <TrendingWrapper>
    <div className="TrendingGrid">
      <div className="destinationCards">
        <span className="popularTitle">
          {i18n.t('Destinations..POPULAR SEARCHES')}
        </span>
        {Destinations.map(({ Destination, Url }, i) => {
          return (
            <a href={Url} key={i} alt={Destination}>
              <span key={i} className="destination">
                {Destination}
              </span>
            </a>
          );
        })}
      </div>
    </div>
  </TrendingWrapper>
);

const TrendingWrapper = styled.div`
  background-color: ${backgroundColor} !important;
  display: grid;
  grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;

  @media (min-width: 1000px) {
    padding: 60px 0;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  .TrendingGrid {
    grid-column: 2/4;
    text-align: center;

    p {
      padding-bottom: 20px;
    }

    .destinationCards {
      span.popularTitle {
        font-weight: bold;
        font-size: 14px;
        padding: 16px;

        @media (max-width: 1000px) {
          display: block;
        }
      }

      span.destination {
        color: #4860ff;
        background: #fff;
        padding: 10px 16px;
        margin: 5px;
        text-decoration: none;
        line-height: 16px;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        border-radius: 3px;
        display: inline-block;

        @media (min-width: 1000px) {
          font-weight: 500;
        }

        @media (max-width: 1000px) {
          font-size: 12px;
          padding: 9px 10px;
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export default PopularSearches;

import React from 'react';
import styled from 'styled-components';
import faqList from './faqList';
import { white, darkBlueLighter, darkBlueLighter2, darkBlue2 } from '../../styles/colors'
import Accordion from './components/Accordion';

const LandingPageFaqWrapper = styled('div')`
  padding-top: 45px;
  padding-bottom: 90px;
  background-color: ${white};
  display: flex;
  justify-content: center;
  width: 100%;
  @media(max-width: 999px) {
    padding: 63px 16px 10px 16px;
  }
`;
const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1122px;
  display: flex;
  justify-content: space-between;
  @media(max-width: 999px) {
    width: 100%;
    flex-direction: column;
  }
`

const Text = styled.div`
  font-family: Public Sans;
  letter-spacing: 0px;
  text-align: left;
  font-style: normal;
`
const TextWrapper = styled.div`
  margin-right: 20px;
`
const Title = styled.div`
  color: ${darkBlue2};
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 30px;
  @media(max-width: 999px) {
    font-size: 28px;
    line-height: 30px;
  }
`
const Subtitle = styled(Text)`
  color: ${darkBlueLighter};
  max-width: 400px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  @media(max-width: 999px) {
    font-size: 13px;
    line-height: 17px;
    color: ${darkBlueLighter2};
    padding-bottom: 40px;
  }

`

const FAQsLandingPage = () => {

  return (
    <LandingPageFaqWrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>
            Still have some questions? 
          </Title>
          <Subtitle>
            Flymble is an online booking platform for flights which are paid for in monthly instalments.
            <br /><br />
            Flymble was founded in 2016 by two friends, and slowly grew to UKs #1 pay per month travel brand.
          </Subtitle>
        </TextWrapper>
        <Accordion faqList={faqList} />
      </ContentWrapper>
    </LandingPageFaqWrapper>
  );
};


export default FAQsLandingPage;

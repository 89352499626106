import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useCurrencyContext } from 'Context/CurrencyProvider';
import { HOT_DEALS, STAYCATIONS, STAYCATIONS_US } from '../constants/travelDeals';
import TravelDealElement from './TravelDealElement';
import { trackStaycationsRecommendationsClicked } from 'Utils/facebookFunctions';

interface TravelDealsMosaicProps {
  type: string;
  setShowSoonPopup?: Dispatch<SetStateAction<boolean>>;
}

const MosaicWrapper = styled.div`
  width: 90%;
  max-width: 1122px;
  display: grid;
  grid-gap: 10px;
  grid-template-areas: 
    'a b b c d'
    'e e f f d';
  grid-template-columns: repeat(5, 1fr);

  .deal0 {
    grid-area: a;
    width: 100%;
    height: 100%;
    @media(max-width: 999px){
      display: none;
    }
  }
  .deal1 {
    width: 100%;
    height: 100%;
    grid-area: b;
  }
  .deal2 {
    width: 100%;
    height: 100%;
    grid-area: c;
  }
  .deal3 {
    width: 100%;
    height: 100%;
    grid-area: d;
    @media(max-width: 999px){
      display: none;
    }
  }
  .deal4 {
    width: 100%;
    height: 100%;
    grid-area: e;
  }
  .deal5 {
    width: 100%;
    height: 100%;
    grid-area: f;
  }
  @media(max-width: 999px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0px;
  }
`
const StaycationsMosaicWrapper = styled.div`
  width: 90%;
  max-width: 1122px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  grid-template-areas: 
    'a a b c c'
    'd d e e f';

  .staycation0 {
    grid-area: a;
    width: 100%;
    height: 100%;
  }
  .staycation1 {
    grid-area: b;
    width: 100%;
    height: 100%;
    @media(max-width: 999px){
      display: none;
    }
  }
  .staycation2 {
    grid-area: c;
    width: 100%;
    height: 100%;
  }
  .staycation3 {
    grid-area: d;
    width: 100%;
    height: 100%;
  }
  .staycation4 {
    grid-area: e;
    width: 100%;
    height: 100%;
  }
  .staycation5 {
    grid-area: f;
    width: 100%;
    height: 100%;
    @media(max-width: 999px){
      display: none;
    }
  }
  @media(max-width: 999px) {
    display: flex;
    flex-direction: column;
    grid-gap: 0;
  }
`

const TravelDealsMosaic = (props: TravelDealsMosaicProps) => {
  const { type, setShowSoonPopup } = props;
  const { currency, currencySymbol } = useCurrencyContext();

  const handleClick = () => {
    setShowSoonPopup && setShowSoonPopup(true);
    trackStaycationsRecommendationsClicked()
  }
  const staycationList = currency === 'USD' ? STAYCATIONS_US : STAYCATIONS;
  
  if (type === 'staycations') {
    return (
      <StaycationsMosaicWrapper onClick={handleClick}>
        {staycationList.map((place, index) => (

          <div className={`staycation${index}`} key={index}>
            <TravelDealElement
              img={place.img}
              imgMobile={place.img}
              city={place.city}
              country={place.country}
              price={place.price}
              currencySymbol={currencySymbol}
              />
          </div>

        ))}
      </StaycationsMosaicWrapper>
    )
  } else {
    return (
    <MosaicWrapper>
      {HOT_DEALS.map((deal, index) => (
        <div className={`deal${index}`} key={index}>
          <TravelDealElement
            img={deal.img}
            imgMobile={deal.imgMobile || deal.img}
            link={`${deal.link}/${currency}`}
            city={deal.city}
            country={deal.country}
            price={deal.price}
            currencySymbol={currencySymbol}
            />
        </div>

      ))}
    </MosaicWrapper>
    )
  }
}

export default TravelDealsMosaic;
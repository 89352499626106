import styled from 'styled-components';
import { css } from 'emotion/macro';

export const AirportPlaceholder = styled.div`
  padding-top: 10px;
`;

export const highlightStyle = css`
  font-weight: 600;
  background-color: inherit;
  padding: 0;
`;

export const optClassCity = css`
  margin-right: 4px;
`;

export const optClassAirport = css`
  margin-left: 18px;
  margin-right: 4px;
`;

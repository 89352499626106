import { BAGGAGE_OPTIONS } from '../constants/constants';

export function generateLuggageObject(response) {
  const { hand_bag, hold_bag } = response.baggage.definitions;

  const baggageObject = {
    cabinBag: false,
    personalItem: false,
    isRyanAir: false,
    isUnitedAirlines: false,
    priorityBoarding: false,
    checkedLuggage: false,
    checkedBagMode: 'DEFAULT',
    handBagMode: 'DEFAULT',
  };

  // Add HAND bag options to the object.
  if (hand_bag.length === 1) {
    let item = hand_bag[0];
    item.price.amount = item.price.amount * 0.86;
    if (item.category === 'personal_item') {
      baggageObject['personalItem'] = {
        price: item.price.amount,
        dimensions: item.restrictions,
      };
    } else if (item.category === 'cabin_bag') {
      baggageObject['cabinBag'] = {
        price: item.price.amount,
        dimensions: item.restrictions,
      };
    }
  } else if (hand_bag.length > 1) {
    hand_bag.map(item => {
      if (item.category === 'personal_item') {
        baggageObject['personalItem'] = {
          price: item.price.amount,
          dimensions: item.restrictions,
        };
      } else if (item.category === 'cabin_bag' ) {
        const previousPrice = baggageObject['cabinBag'].price;
        const isPreviousPriceHigher = previousPrice > item.price.amount;
        baggageObject['cabinBag'] = {
          price: isPreviousPriceHigher ? previousPrice : item.price.amount,
          dimensions: item.restrictions,
        };
      }
      return true;
    });
  }

  // Add HOLD bag options to the object. <----------- For hold bags, we only allow the default option [0].
  if (hold_bag.length > 0) {
    let item = hold_bag[0];
    let holdBagCombinationItem = response.baggage.combinations.hold_bag.find(el => el.indices.length > 0);
    baggageObject['checkedLuggage'] = {
      price: holdBagCombinationItem.price.amount * 0.86,
      dimensions: item.restrictions,
    };
  }

  // Priority boarding adjustments for Ryan Air.
  baggageObject['priorityBoarding'] = isPriorityBoardingFunc(
    response,
    hand_bag
  );
  baggageObject['isRyanAir'] = isRyanAirFunc(response);
  baggageObject['isUnitedAirlines'] = isUnitedAirlinesFunc(response);

  return determineBaggageView(baggageObject);
}

function determineBaggageView(baggageObject) {
  const {
    CASE_2_FREE_CABIN_AND_PERSONAL,
    CASE_5_PAID_CABIN_NO_PERSONAL,
    // CASE_4_FREE_PRIORITY_BOARDING_ONLY,
    CASE_3_FREE_PERSONAL_PAID_PRIORITY,
    CASE_1_RYAN_AIR,
    CASE_6_CABIN_BAG_ONLY,
    CASE_7_FREE_CHECKED,
    CASE_8_PAID_CHECKED,
    CASE_9_NO_CHECKED_AVAILABLE,
    CASE_10_PERSONAL_ITEM_ONLY,
    UNITED_AIRLINES,
  } = BAGGAGE_OPTIONS;

  const {
    personalItem,
    isRyanAir,
    isUnitedAirlines,
    priorityBoarding,
    cabinBag,
    checkedLuggage,
  } = baggageObject;

  let handBagState;
  // let handBagOneOption = false;
  let holdBagState;
  // let holdBagOneOption = false;

  const setHandBagState = mode => {
    handBagState = mode;
    // handBagOneOption = isOneOption;
  };

  const setHoldBagState = mode => {
    holdBagState = mode;
    // holdBagOneOption = isOneOption;
  };

  // if (priorityBoarding)

  console.log('THE BAGGAGE OBJECT IS...', baggageObject);

  if (personalItem) {
    if (isRyanAir) {
      setHandBagState(CASE_1_RYAN_AIR);
    } else if (!cabinBag) {
      setHandBagState(CASE_10_PERSONAL_ITEM_ONLY);
    } else if (priorityBoarding) {
      setHandBagState(CASE_3_FREE_PERSONAL_PAID_PRIORITY);
    } else if (cabinBag) {
      if ((cabinBag.price === personalItem.price) === 0) {
        setHandBagState(CASE_2_FREE_CABIN_AND_PERSONAL, true);
      }
    }
  } else if (!personalItem) {
    if (priorityBoarding) {
      if (priorityBoarding < 15) {
        setHandBagState(CASE_6_CABIN_BAG_ONLY, false);
      } else if (priorityBoarding > 15) {
        setHandBagState(CASE_5_PAID_CABIN_NO_PERSONAL, false);
      } else if (cabinBag.price === 0) {
        setHandBagState(CASE_3_FREE_PERSONAL_PAID_PRIORITY, false);
      }
    } else if (cabinBag) {
      setHandBagState(CASE_6_CABIN_BAG_ONLY, true);
    }
  }

  if (
    (handBagState === undefined) &
    (personalItem.price === 0) &
    (cabinBag.price === 0)
  ) {
    setHandBagState(CASE_2_FREE_CABIN_AND_PERSONAL, true);
  }

  if(handBagState === undefined && (personalItem.price === 0) && (cabinBag.price !== 0)) {
    setHandBagState(CASE_3_FREE_PERSONAL_PAID_PRIORITY, false);
  }

  if (checkedLuggage) {
    checkedLuggage.price === 0 && setHoldBagState(CASE_7_FREE_CHECKED, true);
    checkedLuggage.price > 0 && setHoldBagState(CASE_8_PAID_CHECKED, false);
  } else {
    isUnitedAirlines && setHoldBagState(UNITED_AIRLINES, true);
    setHoldBagState(CASE_9_NO_CHECKED_AVAILABLE, true);
  }

  baggageObject['checkedBagMode'] = holdBagState;
  baggageObject['handBagMode'] = handBagState;
  console.log('CHECK BAG MODE', holdBagState);
  console.log('HAND BAG MODE', handBagState);

  return baggageObject;
}

function isPriorityBoardingFunc(response) {
  if (response.additional_services.priority_boarding) {
    let prices = [];
    Object.values(
      response.additional_services.priority_boarding.data
    ).forEach(item => prices.push(item.price * 0.86));
    return prices.reduce((a, b) => a + b);
  } else return false;
}

function isRyanAirFunc(response) {
  const RYAN_AIR_IATA_CODE = 'FR';
  const airlinesCodes = response.flights.map(flight => flight.airline.code);
  function checkAirlines(code) {
    return code === RYAN_AIR_IATA_CODE;
  }
  return airlinesCodes.every(checkAirlines);
}

function isUnitedAirlinesFunc(response) {
  const IATA_CODES = ['UA', 'AA'];
  const airlinesCodes = response.flights.map(flight => flight.airline.code);
  function checkAirlines(code) {
    return IATA_CODES.indexOf(code) !== -1;
  }
  return airlinesCodes.every(checkAirlines);
}


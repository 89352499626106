import styled from 'styled-components';
import { css } from 'emotion/macro';
import { SearchFormStyle } from '../../Search/SearchForm/styles/styles';
import { darkBlue2, darkBlueLighter2, purple } from '../../styles/colors';
const flymbleBlue = '#4860ff';

export const PageWrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 0;
  margin: 0;

  grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;

  @media (max-width: 1000px) {
    .desktopOnly {
      display: none;
    }
  }
  hr {
    width: 100%;
    background-color: rgba(225, 227, 238, 0.6);
    height: 0;
    border: 1px solid rgba(225, 227, 238, 0.6);
    margin-bottom: 25px;
  }
`;

export const PageMain = styled.div`
  grid-column: 2/4;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  @media (max-width: 999px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const searchInputSummary = css`
  @media (min-width: 600px) {
    display: none;
  }

  /* This code specifies the new input summary section */
    margin-bottom: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 2px solid #cccedb;
    background-color: white;

    .inputWrapper {
      padding-left: 10px;
      padding-right: 10px;

      display: grid;
      grid-template-columns: 7fr 1fr;

      .summaryRows {
        .fromToRow {
          padding-left: 10px;
          span {
            font-family: 'Public Sans', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 0px;
            text-align: left;
            padding-bottom: 10px;
            padding-left: 0px;
            color: ${darkBlue2};
          }

          .arrowIcon {
            margin-left: 10px;
            margin-right: 10px;
            color: ${darkBlue2};
            font-weight: 300;
          }
        }

        .datesRow {
          color: ${darkBlueLighter2};
          padding-left: 10px;
        
          span {
            font-family: 'Public Sans', sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 0px;
            text-align: left;
            padding-top: 0px;
            padding-left: 0px;
            padding-bottom: 0px;
          }
        }
      }

      .editIcon {
        font-size: 20px;
        color: ${purple};
      }

      .editText {
        font-weight: 400;
        color: ${purple};
      }
    }


  
  span {
    padding: 10px;
    padding-right: 0px;
    font-size: 12px;
    white-space: nowrap;
    @media (max-width: 325px) {
      padding-left: 2px;
      padding-right: 2px;
    }
  }
  .editButton {
    font-size: 14px;
    color: ${flymbleBlue};
    letter-spacing: 0.5px;
    font-weight: 500;

    @media (max-width: 600px) {
      display: block;
      cursor: pointer;

    }
  }
`;


export const SearchBarWrapper = styled.div`
  ${SearchFormStyle};
  @media (max-width: 1000px) {
    display: none;
  }
  padding: 0; margin:0;
`;

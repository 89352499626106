import React from 'react';
import styled from 'styled-components';


const VideoSectionWrapper = styled('div')`
  display: block;
  text-align: center;
  grid-column: 2/4;

  iframe {
    background-color: #f9f9fa !important;
  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

const VideoSection = () => {
  return (
    <VideoSectionWrapper>
      <iframe
        title='daily-motion-flymble'
        frameBorder="0"
        width={window.innerWidth}
        height={(window.innerWidth * 9) / 16}
        src="https://www.dailymotion.com/embed/video/x7f92lc"
        allow="autoplay"
        allowFullScreen={true}
      />
    </VideoSectionWrapper>
  );
};
export default VideoSection;

import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { AirlineRowTop } from './AirlineRowTop';
import { TravelDisplay } from './TravelDisplay';
import { DetailedView } from './DetailedView';
import { DetailsPaymentPlan } from './DetailsPaymentPlan';
import BottomRow from './BottomRow';
import { TotalPriceRow } from './TotalPriceRow';
import { KIND_OF_TRIP } from '../../../constants/constants';
import { FlightTicketWrapper, SearchResult } from '../styles';
import CheckoutRowTop from './CheckoutRowTop';
import CheckoutRowBottom from './CheckoutRowBottom';


const FlightTicket = ({
  checkout,

  typeFlight,
  airlineCodes,
  outboundCodes,
  returnCodes,
  airlineName,
  nrSeatsLeft,
  seatsText,
  departureCity,
  arrivalCity,
  costs,
  theFlightTicket,

  departureTimeOutbound,
  arrivalTimeOutbound,
  departureAirportOutbound,
  arrivalAirportOutbound,
  departureCityOutbound,
  arrivalCityOutbound,
  flightDurationOutbound,
  routeStopsOutbound,
  outboundTextDateDeparture,
  outboundTextDateArrival,

  departureTimeInbound,
  arrivalTimeInbound,
  departureAirportReturn,
  arrivalAirportReturn,
  departureCityReturn,
  arrivalCityReturn,
  returnTextDateDeparture,
  returnTextDateArrival,
  flightDurationInbound,
  routeStopsInbound,

  outboundTotalDuration,
  returnTotalDuration,
  departureAirport,
  arrivalAirport,
  cabin,
  source,
}) => {
  const [viewDetails, toggleDetails] = useState(false);

  return (
    <FlightTicketWrapper onClick={() => toggleDetails(!viewDetails)}>
      <div className="verticalSeperationLine" />
      <div className="searchResultCheckoutGrid">
        <SearchResult isOneWay={typeFlight === KIND_OF_TRIP.ONE_WAY}>
          {checkout ? (
            <CheckoutRowTop
              outbound={true}
              dateText={outboundTextDateDeparture}
              rightText='All times are local'
              airlineCodes={outboundCodes}
            />
          ) : (
            <AirlineRowTop
              airlineCodes={airlineCodes}
              flightLegCodes={outboundCodes}
              airlineName={airlineName}
              nrSeatsLeft={nrSeatsLeft}
              seatsText={seatsText}
              viewDetails={viewDetails}
              departureCity={departureCity}
              arrivalCity={arrivalCity}
              instalment={costs.instalment}
              cabin={cabin}
              source={source}
            />
          )}
          <TravelDisplay
            typeFlight={typeFlight}
            departureTime={departureTimeOutbound}
            arrivalTime={arrivalTimeOutbound}
            departureAirport={departureAirportOutbound}
            arrivalAirport={arrivalAirportOutbound}
            departureRouteCity={departureCityOutbound}
            arrivalRouteCity={arrivalCityOutbound}
            flightDuration={flightDurationOutbound}
            routeStops={routeStopsOutbound}
            departureRouteDay={outboundTextDateDeparture}
            arrivalRouteDay={outboundTextDateArrival}
          />
          {typeFlight !== KIND_OF_TRIP.ONE_WAY && (
            <>
            {checkout && (
              <CheckoutRowTop
                outbound={false}
                dateText={returnTextDateDeparture}
                airlineCodes={returnCodes}
              />
            )}
            <TravelDisplay
              typeFlight={typeFlight}
              departureTime={departureTimeInbound}
              arrivalTime={arrivalTimeInbound}
              departureAirport={departureAirportReturn}
              arrivalAirport={arrivalAirportReturn}
              departureRouteCity={departureCityReturn}
              arrivalRouteCity={arrivalCityReturn}
              departureRouteDay={returnTextDateDeparture}
              arrivalRouteDay={returnTextDateArrival}
              flightDuration={flightDurationInbound}
              routeStops={routeStopsInbound}
            />
            </>
          )}
          <hr />
          {viewDetails ? (
            <React.Fragment>
              <DetailedView
                typeLeg="Outbound"
                textDateDeparture={outboundTextDateDeparture}
                textDateArrival={outboundTextDateArrival}
                totalDuration={outboundTotalDuration}
                airlineCodes={airlineCodes}
                airlineName={airlineName}
                departureTime={departureTimeOutbound}
                departureAirport={departureAirport}
                arrivalTime={arrivalTimeOutbound}
                arrivalAirport={arrivalAirport}
                departureCity={departureCity}
                arrivalCity={arrivalCity}
                connection={true}
                route={theFlightTicket.outbound}
                isOneWay={typeFlight === KIND_OF_TRIP.ONE_WAY}
              />

              {typeFlight !== KIND_OF_TRIP.ONE_WAY && (
                <>
                  <hr />
                  <DetailedView
                    typeLeg="Return"
                    textDateDeparture={returnTextDateDeparture}
                    textDateArrival={returnTextDateArrival}
                    totalDuration={returnTotalDuration}
                    airlineCodes={airlineCodes}
                    airlineName={airlineName}
                    departureTime={departureTimeOutbound}
                    departureAirport={departureAirport}
                    arrivalTime={arrivalTimeOutbound}
                    arrivalAirport={arrivalAirport}
                    departureCity={departureCity}
                    arrivalCity={arrivalCity}
                    connection={true}
                    route={theFlightTicket.return}
                  />
                </>
              )}
              <DetailsPaymentPlan costs={costs} />
              <hr />
              <TotalPriceRow totalPrice={costs} />
              <hr />
            </React.Fragment>
          ) : (
            ''
          )}
          {checkout ? (
            <CheckoutRowBottom isDetailsOpen={viewDetails} />
          ) : (
            <BottomRow
              flymblePrice={costs.flymblePrice}
              theFlightTicket={theFlightTicket}
              viewDetails={viewDetails}
              toggleDetails={() => toggleDetails(!viewDetails)}
            />
          )}
          
        </SearchResult>
      </div>
    </FlightTicketWrapper>
  );
};

const CostsPropType = {
  instalment: PropTypes.number.isRequired,
  upfront: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  noInstalments: PropTypes.number.isRequired,
  flymblePrice: PropTypes.number.isRequired,
};

FlightTicket.propTypes = {
  typeFlight: PropTypes.string.isRequired,
  airlineCodes: PropTypes.array.isRequired,
  outboundCodes: PropTypes.array.isRequired,
  returnCodes: PropTypes.array.isRequired,
  airlineName: PropTypes.string.isRequired,
  nrSeatsLeft: PropTypes.number.isRequired,
  seatsText: PropTypes.string.isRequired,
  departureCity: PropTypes.string,
  arrivalCity: PropTypes.string,

  theFlightTicket: PropTypes.object.isRequired,

  departureTimeOutbound: PropTypes.string.isRequired,
  arrivalTimeOutbound: PropTypes.string.isRequired,
  departureAirportOutbound: PropTypes.string.isRequired,
  arrivalAirportOutbound: PropTypes.string.isRequired,
  departureCityOutbound: PropTypes.string.isRequired,
  arrivalCityOutbound: PropTypes.string.isRequired,
  flightDurationOutbound: PropTypes.string.isRequired,
  routeStopsOutbound: PropTypes.number.isRequired,
  outboundTextDateDeparture: PropTypes.string.isRequired,
  outboundTextDateArrival: PropTypes.string.isRequired,

  departureTimeInbound: PropTypes.string.isRequired,
  arrivalTimeInbound: PropTypes.string.isRequired,
  departureAirportReturn: PropTypes.string.isRequired,
  arrivalAirportReturn: PropTypes.string.isRequired,
  departureCityReturn: PropTypes.string.isRequired,
  arrivalCityReturn: PropTypes.string.isRequired,
  returnTextDateDeparture: PropTypes.string.isRequired,
  returnTextDateArrival: PropTypes.string.isRequired,
  flightDurationInbound: PropTypes.string,
  routeStopsInbound: PropTypes.number.isRequired,

  outboundTotalDuration: PropTypes.string.isRequired,
  returnTotalDuration: PropTypes.string,
  departureAirport: PropTypes.string,
  arrivalAirport: PropTypes.string,

  costs: PropTypes.shape(CostsPropType).isRequired,
  cabin: PropTypes.string,
  source: PropTypes.string.isRequired,
};

export default FlightTicket;

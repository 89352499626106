import 'moment/locale/pl';

import i18n from 'Utils/i18n.js';

import path from 'ramda/src/path'
import uniq from 'ramda/src/uniq'
import concat from 'ramda/src/concat'
import flatten from 'ramda/src/flatten'
import compose from 'ramda/src/compose'

import { getDurationSum } from 'helpers/getDurationSum';
import  {dayjs}  from 'Services/dayjs';

import { TIME_FORMAT } from 'constants/config';

import airlines from './airlines';
import { KIND_OF_TRIP, FLYMBLE_BOOKING_FEE } from '../constants/constants';
import { getTotalRouteDurationSeconds } from './timeUtils';

const ticketDateText = i18n.t('Global..ticketDateText..ddd, DD MMM');
// const ticketTimeFormat = 'HH24:MI';

/**
 * Interests for FNPL particular amount of instalments, starting from 0!
 */

const interestsPL = {
  0: 0,
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0,
  8: 0,
  9: 0,
  10: 0,
  12: 0,
  20: 0,
  24: 0,
  36: 0,
};

const interests = interestsPL;

/**
 * Returns array with calculated paying values
 *
 * @param {number} price Price of tickets nett (KIWI price) for 1 passenger
 * @param {number} noInstalments Number of instalments
 *
 * @todo Can be rewritten into carrying function
 */
const fnplCalc = (price, noInstalments) => {

  var costs = {};
  var bookingFee = parseInt(FLYMBLE_BOOKING_FEE, 10);

  // For full price the reduction factor is 1. To reduce the base price adjust reduction factor downwards.
  // 8% increase in price.
  // const reductionFactor = 1.08;
  const reductionFactor = 1;

  // Adding Flymble booking fee (of X pounds) to the KIWI price.
  var flymblePrice = reductionFactor * price + bookingFee;

  costs = {
    price: price,
    flymblePrice: flymblePrice,
    noInstalments: noInstalments,
    serviceFee: interests[noInstalments],
    // upfront:
    //   Math.round(((flymblePrice * interests[noInstalments]) / 100) * 100) /
    //   100,
    instalment: Math.round((flymblePrice / noInstalments) * 100) / 100,
    total:
      Math.round(flymblePrice * (1 + interests[noInstalments] / 100) * 100) /
      100,
    upfront: 0,
  };
  costs.upfront = costs.total - costs.instalment * costs.noInstalments;
  costs.apr =
    ((((costs.total - costs.flymblePrice) / costs.flymblePrice) * 12) /
      noInstalments) *
    100;

  return costs;
};

const convertToHourMinutes = seconds => {
  const hDiff = Math.trunc(seconds / 60 / 60);
  const minDiff = Math.round((seconds - hDiff * 60 * 60) / 60);
  return `${hDiff}h ${minDiff}min`;
};

const fnplTotalCalc = (totalNettCost, noInstalments) => {
  const costs = {
    instalment: Math.round((totalNettCost / noInstalments) * 100) / 100,
    upfront: Math.round(totalNettCost * interests[noInstalments]) / 100,
    serviceFee: interests[noInstalments],
  };
  costs.apr =
    ((((totalNettCost * (1 + interests[noInstalments] / 100) - totalNettCost) /
      totalNettCost) *
      12) /
      noInstalments) *
    100;

  return costs;
};

// Function that calculates duration between two UTC times.
// Input: dUTC = route departure time
// Input: aUTC = route arrival time
function timeDuration(dUTC, aUTC) {
  var a = dayjs.unix(dUTC);
  var b = dayjs.unix(aUTC);

  var differenceHours = b.diff(a, 'hours', false);
  var differenceMinutes = b.diff(a, 'minutes', false) - differenceHours * 60;

  var finalTime = `${differenceHours}h ${differenceMinutes}m`;

  return finalTime;
}

// Function arrival / departure time calculations
const timeConvert = (date, format = TIME_FORMAT) => {
  // leaves datetime without timeZone part
  const dateWithoutTimeZone = date.substring(0, 23)

  return  dayjs(dateWithoutTimeZone)
    .format(format)
}

// Function to find airline name
function airlineName(iataCode) {
  return (airlines[iataCode] && airlines[iataCode].name) || '';
}

/**
 * Function parses location code into locations understandable by kiwi API
 *
 * @param {string} location
 */
function parseLocation(location) {
  const tokens = location.split('_');
  let loc = {};
  if (tokens[0] === 'A') {
    loc.type = 'airport';
    loc.code = tokens[1];
  } else if (tokens[0] === 'C') {
    loc.type = 'city';
    loc.code = tokens[1];
  } else {
    console.error('Location not recognized: ', location);
  }
  return loc;
}

const getOutboundCodes = flight =>
  flight.outbound.map(path(["carrier", "code"]))

const getReturnCodes = flight =>
  flight.return.map(path(["carrier", "code"]))

const getAirlines = compose(
  uniq,
  flatten,
  flight => ([
    getOutboundCodes(flight),
    getReturnCodes(flight)
  ])
)

// This function is used to parse all data that is display on the flight ticket component
const parsedTicketData = (flightObject) => ({
  // General paramaters.
  source: flightObject.source,
  typeFlight: flightObject.return.length
    ? KIND_OF_TRIP.ROUND
    : KIND_OF_TRIP.ONE_WAY,
  isOneWayDisplay: !(flightObject.return.length > 0),
  route: concat(flightObject.outbound, flightObject.return),

  // Airline codes. [all airline codes, outboundCodes, returnCodes]
  airlineCodes: getAirlines(flightObject),
  outboundCodes: getOutboundCodes(flightObject),
  returnCodes: getReturnCodes(flightObject),
  airlineName: airlineName(getAirlines(flightObject)[0]),
  // ----------> End of Airline codes.

  // Airport codes in travel display view.
  departureAirportOutbound: flightObject.outbound[0].origin.code,
  arrivalAirportOutbound: flightObject.outbound[flightObject.outbound.length - 1].destination.code,
  departureAirportReturn: flightObject.return[0].origin.code,
  arrivalAirportReturn: flightObject.return[flightObject.return.length - 1].destination.code,
  // ----------> End of Airport codes.

  // City names. Unique per route in travel display.
  departureCityOutbound: flightObject.outbound[0].origin.city.name,
  arrivalCityOutbound: flightObject.outbound[flightObject.outbound.length - 1].destination.city.name,
  departureCityReturn: flightObject.return[0].origin.city.name,
  arrivalCityReturn: flightObject.return[flightObject.return.length - 1].destination.city.name,
  // ----------> End of City names.

  // Departure and Arrival times in travel display view.
  departureTimeOutbound: timeConvert(flightObject.outbound[0].departingAt),
  arrivalTimeOutbound: timeConvert(flightObject.outbound[flightObject.outbound.length - 1].arrivingAt),
  departureTimeInbound: timeConvert(flightObject.return[0].departingAt),
  arrivalTimeInbound: timeConvert(flightObject.return[flightObject.return.length - 1].arrivingAt),
  // ----------> End of Departure and Arrival times.

  // Flight duration in travel display view (duration, nr of stops, total duration)
  flightDurationOutbound: convertToHourMinutes(getTotalRouteDurationSeconds(flightObject.outbound)),
  flightDurationInbound: convertToHourMinutes(getTotalRouteDurationSeconds(flightObject.return)),
  routeStopsOutbound: flightObject.outbound.length - 1,
  routeStopsInbound: flightObject.return.length - 1,
  outboundTotalDuration: convertToHourMinutes(getDurationSum(flightObject.outbound)),
  returnTotalDuration: convertToHourMinutes(getDurationSum(flightObject.return)),
  // ----------> End of flight duration parameters.

  // Time and dates.
  outboundTextDateDeparture: timeConvert(flightObject.outbound[0].departingAt, ticketDateText),
  outboundTextDateArrival: timeConvert(flightObject.outbound[flightObject.outbound.length - 1].arrivingAt, ticketDateText),

  returnTextDateDeparture: timeConvert(flightObject.return[0].departingAt, ticketDateText),
  returnTextDateArrival: timeConvert(flightObject.return[flightObject.return.length - 1].arrivingAt, ticketDateText),
  // ----------> End of Time and dates.
  cabin: flightObject.outbound[0].cabin || '',
  
})

const extractFlightTicketData = theFlightTicket => {
  let extract = {};
  try {
    extract = parsedTicketData(theFlightTicket);
  } catch (error) {
    console.log('Extract error', error);
    return {};
  }
  return extract;
};

const fnplV2Calc = (
  price,
  paramNoInstalments = 3,
  dateFrom = dayjs().add(31, 'days')
) => {
  const today = dayjs();
  const daysDiff = dateFrom.diff(today, 'days');

  const depositPercentLow = 10;
  const depositDaysMargin = 30;
  const depositPercentHigh = 15;

  let noInstalments = paramNoInstalments;
  // rates are defined in array, defined for 3, 6 or 12 months only (3*index^2)
  const rates = [11.73, 17.64, 24.45];

  // default to 3 months if out of range amount was passed
  if (![3, 6, 12].includes(paramNoInstalments)) {
    noInstalments = 3;
  }
  // reverted (3*index^2)
  const rateIndex = Math.log2(noInstalments / 3);

  let depositPercent =
    daysDiff > depositDaysMargin ? depositPercentLow : depositPercentHigh;
  let deposit = (depositPercent / 100) * price;

  let creditAmount = price - deposit;
  let instalmentPlan = [0, 1, 2].map(
    i => (creditAmount * (100 + rates[i])) / 100 / noInstalments
  );
  return {
    price: price,
    creditAmount: creditAmount,
    rateIndex: rateIndex,
    instalmentPlan: instalmentPlan[rateIndex],
    deposit: deposit,
    upfront: deposit,
    noInstalments: noInstalments,
    total: instalmentPlan[rateIndex] * noInstalments + deposit,
    interests: rates[rateIndex],
  };
};

export {
  fnplCalc,
  fnplTotalCalc,
  convertToHourMinutes,
  timeDuration,
  timeConvert,
  airlineName,
  parseLocation,
  extractFlightTicketData,
  parsedTicketData,
  fnplV2Calc,
};

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { darkBlue2, purple, white, lightGrey3, lightGrey2 } from '../../styles/colors';

const Wrapper = styled.div`
  padding: 25px;
  background-color: ${lightGrey3};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 999px) {
    flex-direction: column;
  }
`
const Text = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  color: ${darkBlue2};
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  @media(min-width: 1000px) {
    .mobileOnly {
      display: none;
    }
  }
  @media(max-width: 999px) {
    font-size: 16px;
    .desktopOnly {
      display: none;
    }
  }

`
const Button = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  cursor: pointer;
  border-radius: 5px;
  line-height: 50px;
  text-align: center;
  width: 153px;
  height: 50px;
  color: ${white};
  background-color: ${purple};
  font-size: 16px;
  font-weight: 700;
  margin-left: 30px;
  @media(max-width: 999px) {
    margin-left: 0;
    margin-top: 13px;
    line-height: 40px;
    width: 127px;
    height: 40px;
    color: ${darkBlue2};
    background-color: ${lightGrey2};
    font-size: 14px;
  }
`

const GetStarted = () => {

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Wrapper>
      <Text>
        <span className="desktopOnly">Your dream holiday starts here</span>
        <span className="mobileOnly">Travel the smart way</span>
      </Text>
      <Link to='/'>
        <Button onClick={handleClick}>Get started</Button>
      </Link>
    </Wrapper>
  )
}

export default GetStarted;
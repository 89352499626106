
import i18n from 'Utils/i18n.js';


const howItWorksSections = [
  {
    nr: 1,
    title: i18n.t('sections..Search a flight'),
    text: i18n.t(
      'sections..Search over 150 airlines. We work directly with the airlines so you get the best price and can easily amend a booking.'
    ),
  },
  {
    nr: 2,
    title: i18n.t('sections..Choose payment plan'),
    text: i18n.t(
      'sections..Select a flight and choose your preferred payment plan. Spread the cost over 3, 6 or 12 monthly instalments.'
    ),
  },
  {
    nr: 3,
    title: i18n.t('sections..Start packing for your dream holiday!'),
    text: i18n.t(
      'sections..You can expect your tickets and booking confirmation in your inbox within 24 hours. Enjoy your holiday!'
    ),
  },
];

export { howItWorksSections };

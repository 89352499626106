import React, {Dispatch, SetStateAction, useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import PassengersDropdown from './PassengersDropdown';
import {PassengersPickerWrapper, PassengersLabelWrapper} from '../styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PassengersPickerProps {
  dropdownVisible: boolean;
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
}

interface DropDownWrapperProps {
  dropdownVisible: boolean;
}
const DropDownWrapper = styled.div`
  position: absolute;
  display: ${(props: DropDownWrapperProps) => !props.dropdownVisible? 'none' : undefined}
`

const PassengersPicker = (props: PassengersPickerProps) => {
  const { dropdownVisible, setDropdownVisible } = props
  const [label, setLabel] = useState('Passengers');

  return (
    <PassengersPickerWrapper>
      <PassengersLabelWrapper onClick={() => setDropdownVisible(dropdownVisible => !dropdownVisible)}>
        <span>{label}</span>
        <FontAwesomeIcon icon={faChevronDown as IconProp} />
      </PassengersLabelWrapper>
     
      <DropDownWrapper dropdownVisible={dropdownVisible}>
        <PassengersDropdown setDropdownVisible={setDropdownVisible} setLabel={setLabel} />
      </DropDownWrapper>
  </PassengersPickerWrapper>
  )
}

export default PassengersPicker;








import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { white, darkBlueLighter2 } from '../../../styles/colors'

interface EmailFormProps {
  handleSubmit: (e: any) => void;
  setEmail: Dispatch<SetStateAction<boolean>>;
}

const Form = styled.form`
  display: flex;
  justify-content: center;
`
const EmailInput = styled.input`
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 70%;
  padding: 12px;
  &::placeholder {
    font-family: 'Public Sans';
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    color: ${darkBlueLighter2};
  }
  @media(min-width: 1000px) {
    height: 50px;
    padding: 16px 30px;
  }
`
const SubmitButton = styled.button`
  background-color: ${white};
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 75px;
  text-align: center;
  margin-left: 6px;
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  @media(min-width: 1000px) {
    height: 50px;
    width: 90px;
    font-size: 16px;
    line-height: 19px;
  }
`

const EmailForm = (props: EmailFormProps) => {
  const { handleSubmit, setEmail } = props;

  return (
    <Form onSubmit={handleSubmit}>
      <EmailInput type="email" placeholder="E-mail address" onChange={(e: any) => setEmail(e.target.value)}/>
      <SubmitButton type='submit'>Sign up</SubmitButton>
    </Form>
  )
}

export default EmailForm;
import { useEffect, useState } from "react";

import { Flight } from "generated/types";

import add from 'ramda/src/add'
import take from 'ramda/src/take'

import { normalize } from 'helpers/normalize';

const DEFAULT_MAX_ITEMS = 25

export const useFlightsPagination = (flights: Flight[]) => {
  const [maxItems, setMaxItems] = useState(DEFAULT_MAX_ITEMS)
  const [items, setItems] = useState<Flight[]>([])

  useEffect(() => {
    setItems(take(maxItems, flights))
  }, [normalize(flights), maxItems])

  const handleVisibleLastItem = (isLastItemVisible: boolean) => {
    if (!isLastItemVisible || maxItems >= flights.length) {
      return
    }

    setMaxItems(add(DEFAULT_MAX_ITEMS))
  }

  return ({
    items,
    handleVisibleLastItem
  })
}
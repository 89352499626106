import React, { useEffect, useState } from 'react';
import CardSection from './CardSection';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import styled from 'styled-components';
import { trackDirectPayment_PayNowBtn } from 'Utils';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import { BOOKING_TYPES } from 'constants/constants';

const payment_method_api =
  `${process.env.REACT_APP_AWS_CLOUD_FUNCTIONS_URL}/getPaymentMethod`;

const getBookingType = (paymentMethod)  => {
  if(paymentMethod === 'card'){
    return BOOKING_TYPES.STRIPE_Flights
  }

  if(paymentMethod === 'klarna'){
    return BOOKING_TYPES.STRIPE_Klarna
  }

  if(paymentMethod === 'affirm'){
    return BOOKING_TYPES.STRIPE_Affirm
  }

  return BOOKING_TYPES.STRIPE_Unknown
}

export const PaymentForm = (props)=> {
  const stripe = useStripe();
  const elements = useElements();
  const [isButtonLoading, setButtonLoading] = useState(false);
  const [isCardPayment, setIsCardPayment] = useState(false);


  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    setButtonLoading(true);
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent, error }) => {
      if(error){
        console.log(error);
      }

      if(paymentIntent){
        axios
          .post(payment_method_api, { paymentMethod: paymentIntent.payment_method })
          .then(res => {
            if (paymentIntent.status === 'succeeded') {
              props.handleStripePaymentComplete(getBookingType(res.data.paymentMethodType));
            }
          })
      }

      setButtonLoading(false)
    });
  }, [stripe]);

  const handleSubmit = async ev => {
    console.log('[stripePaymentForm] Calling handle submit');
    trackDirectPayment_PayNowBtn();
    setButtonLoading(true)
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();

    const confirmParams = {
      return_url: window.location.href,
      payment_method_data: {
        billing_details: {
          name: props.customerName,
        },
      },
    }

    console.log('Secret',  props.clientSecret)
    // See our confirmCardPayment documentation for more:
    // https://stripe.com/docs/stripe-js/reference#stripe-confirm-card-payment
    await stripe.confirmPayment({elements, confirmParams})
  };

  const handlePaymentChange = (event)=>{
    const type = event.value.type;

    setIsCardPayment(type==='card')
  }
    return (
        <form onSubmit={handleSubmit}>
          <CardSection onPaymentChange={handlePaymentChange}/>
          <PaymentButtonWrapper>
            <Button
              className="paymentButton"
              htmlType="submit"
              loading={isButtonLoading}
            >
              {isCardPayment ? `Pay $${props.checkoutPrice}` : 'Proceed'}
            </Button>
          </PaymentButtonWrapper>
        </form>
    );
}

PaymentForm.propTypes = {
  handleStripePaymentComplete: PropTypes.func.isRequired,
  checkoutPrice: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

const PaymentButtonWrapper = styled.div`
  padding: 0px 0px 0px 0px !important;

  .paymentButton {
    grid-column: 2/3;

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 44px;

    padding-top: 12px;
    padding-bottom: 12px;
    margin-top: 25px;

    font-size: 19px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: #4860ff;
    color: white;
    border-color: #4860ff !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;
    }

    @media (max-width: 400px) {
      .faIconProceed {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      grid-column: 1/3;

      span {
        margin-top: 0px;
      }
    }

    @media (min-width: 1000px) {
      height: 47px;
      font-size: 20px;
      max-width: 50%;
    }
  }

  @media (min-width: 1000px) {
    text-align: center;
    margin-top: 35px;
  }
`;

const airlines = {
  XX: { id: 'XX', lcc: '0', name: 'Greenfly' },
  UA: { id: 'UA', lcc: '0', name: 'United Airlines' },
  NATEXPRESS: { id: 'NATEXPRESS', lcc: '0', name: 'National Express' },
  AG: { id: 'AG', lcc: '0', name: 'Aruba Airlines' },
  EUROLINES: { id: 'EUROLINES', lcc: '0', name: 'Eurolines' },
  M8: { id: 'M8', lcc: '1', name: 'SkyJet Airlines' },
  A0: { id: 'A0', lcc: '1', name: 'Avianca Argentina' },
  OUIGO: { id: 'OUIGO', lcc: '0', name: 'Ouigo' },
  LS: { id: 'LS', lcc: '1', name: 'Jet2' },
  JU: { id: 'JU', lcc: '0', name: 'Air Serbia' },
  ND: { id: 'ND', lcc: '0', name: 'FMI Air' },
  FLIXTRAIN: { id: 'FLIXTRAIN', lcc: '0', name: 'Flixtrain' },
  N4: { id: 'N4', lcc: '0', name: 'Nordwind Airlines' },
  '7H': { id: '7H', lcc: '1', name: 'Ravn Alaska' },
  GA: { id: 'GA', lcc: '0', name: 'Garuda Indonesia' },
  QD: { id: 'QD', lcc: '0', name: 'JC International Airlines' },
  S0: { id: 'S0', lcc: '1', name: 'Aerolinas Sosa' },
  '3O': { id: '3O', lcc: '0', name: 'Air Arabia Maroc' },
  SE: { id: 'SE', lcc: '1', name: 'XL Airways France' },
  BL: { id: 'BL', lcc: '0', name: 'Jetstar Pacific' },
  OA: { id: 'OA', lcc: '0', name: 'Olympic Air' },
  VF: { id: 'VF', lcc: '0', name: 'Valuair' },
  GREYHOUND: { id: 'GREYHOUND', lcc: '0', name: 'Greyhound' },
  P2: { id: 'P2', lcc: '1', name: 'Air Kenya' },
  UU: { id: 'UU', lcc: '0', name: 'Air Austral' },
  JG: { id: 'JG', lcc: '1', name: 'JetGo' },
  M0: { id: 'M0', lcc: '1', name: 'Aero Mongolia' },
  REGIOJET: { id: 'REGIOJET', lcc: '0', name: 'RegioJet' },
  OUIBUS: { id: 'OUIBUS', lcc: '0', name: 'Ouibus' },
  CU: { id: 'CU', lcc: '0', name: 'Cubana de Aviaci\u00f3n' },
  LONGTEST: { id: 'LONGTEST', lcc: '0', name: 'Long iata code test' },
  SR: { id: 'SR', lcc: '0', name: 'Sundair' },
  QF: { id: 'QF', lcc: '0', name: 'Qantas' },
  FLIXBUS: { id: 'FLIXBUS', lcc: '0', name: 'Flixbus' },
  BY: { id: 'BY', lcc: '1', name: 'TUI Airways' },
  RM: { id: 'RM', lcc: '0', name: 'Armenia Aircompany' },
  Y2: { id: 'Y2', lcc: '0', name: 'AirCentury' },
  X4: { id: 'X4', lcc: '0', name: 'Alaska Seaplanes X4' },
  JY: { id: 'JY', lcc: '0', name: 'interCaribbean Airways' },
  V0: { id: 'V0', lcc: '1', name: 'Conviasa' },
  UJ: { id: 'UJ', lcc: '0', name: 'AlMasria Universal Airlines' },
  '2N': { id: '2N', lcc: '1', name: 'NextJet' },
  Y4: { id: 'Y4', lcc: '1', name: 'Volaris' },
  D7: { id: 'D7', lcc: '0', name: 'AirAsia X' },
  PQ: { id: 'PQ', lcc: '0', name: 'Philippines AirAsia' },
  Z2: { id: 'Z2', lcc: '0', name: 'Philippines AirAsia' },
  I5: { id: 'I5', lcc: '0', name: 'AirAsia India' },
  XJ: { id: 'XJ', lcc: '0', name: 'Thai AirAsia X' },
  XT: { id: 'XT', lcc: '0', name: 'Indonesia AirAsia X' },
  VT: { id: 'VT', lcc: '0', name: 'Air Tahiti' },
  XW: { id: 'XW', lcc: '1', name: 'NokScoot' },
  AD: { id: 'AD', lcc: '1', name: 'Azul' },
  OV: { id: 'OV', lcc: '1', name: 'SalamAir' },
  BJ: { id: 'BJ', lcc: '0', name: 'NouvelAir' },
  MV: { id: 'MV', lcc: '0', name: 'Air Mediterranean' },
  NT: { id: 'NT', lcc: '1', name: 'Binter Canarias' },
  '6I': { id: '6I', lcc: '0', name: 'Alsie Express' },
  RT: { id: 'RT', lcc: '0', name: 'JSC UVT Aero' },
  LA: { id: 'LA', lcc: '0', name: 'LATAM Chile' },
  '9J': { id: '9J', lcc: '0', name: 'Dana Airlines Limited' },
  '8G': { id: '8G', lcc: '0', name: 'Mid Africa Aviation' },
  SF: { id: 'SF', lcc: '0', name: 'Tassili Airlines' },
  LP: { id: 'LP', lcc: '0', name: 'LATAM Peru' },
  '9P': { id: '9P', lcc: '1', name: 'Air Arabia Jordan' },
  SX: { id: 'SX', lcc: '0', name: 'SkyWork Airlines' },
  F3: { id: 'F3', lcc: '0', name: 'Flyadeal' },
  CY: { id: 'CY', lcc: '0', name: 'Cyprus Airways' },
  LQ: { id: 'LQ', lcc: '1', name: 'Lanmei Airlines' },
  ET: { id: 'ET', lcc: '0', name: 'Ethiopian Airlines' },
  S9: { id: 'S9', lcc: '0', name: 'Starbow Airlines' },
  EK: { id: 'EK', lcc: '0', name: 'Emirates' },
  Z7: { id: 'Z7', lcc: '1', name: 'Amaszonas Uruguay' },
  DI: { id: 'DI', lcc: '0', name: 'Norwegian Air UK' },
  '6D': { id: '6D', lcc: '0', name: 'Pelita' },
  '99': { id: '99', lcc: '0', name: 'Ciao Air' },
  WE: { id: 'WE', lcc: '1', name: 'Thai Smile' },
  PR: { id: 'PR', lcc: '0', name: 'Philippine Airlines' },
  VE: { id: 'VE', lcc: '0', name: 'EasyFly' },
  BA: { id: 'BA', lcc: '0', name: 'British Airways' },
  J2: { id: 'J2', lcc: '0', name: 'Azerbaijan Airlines' },
  '2P': { id: '2P', lcc: '1', name: 'PAL Express' },
  ZP: { id: 'ZP', lcc: '0', name: 'Amaszonas del Paraguay S.A. Lineas Aereas' },
  JI: { id: 'JI', lcc: '0', name: 'Meraj Air' },
  W3: { id: 'W3', lcc: '0', name: 'Arik Air' },
  KS: { id: 'KS', lcc: '0', name: 'Peninsula Airways' },
  NY: { id: 'NY', lcc: '1', name: 'Air Iceland Connect' },
  PE: { id: 'PE', lcc: '0', name: "People's Viennaline PE" },
  KB: { id: 'KB', lcc: '0', name: 'Druk Air' },
  D4: { id: 'D4', lcc: '0', name: 'Aerodart' },
  LH: { id: 'LH', lcc: '0', name: 'Lufthansa' },
  SV: { id: 'SV', lcc: '0', name: 'Saudi Arabian Airlines' },
  '5T': { id: '5T', lcc: '0', name: 'Canadian North' },
  '2A': { id: '2A', lcc: '0', name: 'Deutsche Bahn' },
  A1: { id: 'A1', lcc: '0', name: 'Atifly' },
  '9I': { id: '9I', lcc: '0', name: 'Alliance Air' },
  WF: { id: 'WF', lcc: '0', name: 'Wider\u00f8e' },
  OO: { id: 'OO', lcc: '0', name: 'SkyWest' },
  HM: { id: 'HM', lcc: '0', name: 'Air Seychelles' },
  TQ: { id: 'TQ', lcc: '0', name: 'Tandem Aero' },
  OE: { id: 'OE', lcc: '1', name: 'LaudaMotion' },
  L7: { id: 'L7', lcc: '0', name: 'Lugansk Airlines' },
  UG: { id: 'UG', lcc: '0', name: 'TunisAir Express' },
  T0: { id: 'T0', lcc: '0', name: 'TACA Peru' },
  J5: { id: 'J5', lcc: '0', name: 'Alaska Seaplane Service' },
  NP: { id: 'NP', lcc: '0', name: 'Nile Air' },
  VL: { id: 'VL', lcc: '0', name: 'Med-View Airline' },
  '3I': { id: '3I', lcc: '0', name: 'Air Comet Chile' },
  HN: { id: 'HN', lcc: '0', name: 'Hankook Airline' },
  II: { id: 'II', lcc: '0', name: 'LSM International ' },
  W7: { id: 'W7', lcc: '0', name: 'Austral Brasil' },
  FL: { id: 'FL', lcc: '0', name: 'AirTran Airways' },
  ZM: { id: 'ZM', lcc: '0', name: 'Air Manas' },
  S1: { id: 'S1', lcc: '0', name: 'Serbian Airlines' },
  B4: { id: 'B4', lcc: '0', name: 'ZanAir' },
  HG: { id: 'HG', lcc: '0', name: 'Niki' },
  M2: { id: 'M2', lcc: '0', name: 'MHS Aviation GmbH' },
  M1: { id: 'M1', lcc: '0', name: 'Maryland Air' },
  LT: { id: 'LT', lcc: '0', name: 'Air Lituanica' },
  TE: { id: 'TE', lcc: '0', name: 'FlyLal' },
  '11': { id: '11', lcc: '0', name: 'TUIfly (X3)' },
  AY: { id: 'AY', lcc: '0', name: 'Finnair' },
  IN: { id: 'IN', lcc: '0', name: 'Nam Air' },
  '7C': { id: '7C', lcc: '1', name: 'Jeju Air' },
  KK: { id: 'KK', lcc: '1', name: 'AtlasGlobal' },
  RJ: { id: 'RJ', lcc: '0', name: 'Royal Jordanian' },
  '6S': { id: '6S', lcc: '0', name: 'SaudiGulf Airlines' },
  CD: { id: 'CD', lcc: '0', name: 'Corendon Dutch Airlines B.V.' },
  LI: { id: 'LI', lcc: '0', name: 'Leeward Islands Air Transport' },
  A7: { id: 'A7', lcc: '0', name: 'Air Plus Comet' },
  '4C': { id: '4C', lcc: '0', name: 'LATAM Colombia' },
  GG: { id: 'GG', lcc: '0', name: 'Air Guyane' },
  '2U': { id: '2U', lcc: '0', name: 'Air Guinee Express' },
  QR: { id: 'QR', lcc: '0', name: 'Qatar Airways' },
  Y7: { id: 'Y7', lcc: '1', name: 'NordStar Airlines' },
  FZ: { id: 'FZ', lcc: '1', name: 'Fly Dubai' },
  FK: { id: 'FK', lcc: '0', name: 'Africa West' },
  AV: { id: 'AV', lcc: '0', name: 'Avianca' },
  '4D': { id: '4D', lcc: '0', name: 'Air Sinai' },
  XM: { id: 'XM', lcc: '0', name: 'Alitalia Express' },
  YW: { id: 'YW', lcc: '0', name: 'Air Nostrum' },
  E2: { id: 'E2', lcc: '0', name: 'Eurowings Europe' },
  XQ: { id: 'XQ', lcc: '1', name: 'SunExpress' },
  '7F': { id: '7F', lcc: '0', name: 'First Air' },
  ZH: { id: 'ZH', lcc: '0', name: 'Shenzhen Airlines' },
  PM: { id: 'PM', lcc: '0', name: 'Canary Fly' },
  MI: { id: 'MI', lcc: '0', name: 'SilkAir' },
  '9U': { id: '9U', lcc: '0', name: 'Air Moldova' },
  '00': { id: '00', lcc: '1', name: 'Anadolujet' },
  DJ: { id: 'DJ', lcc: '1', name: 'AirAsia Japan' },
  QH: { id: 'QH', lcc: '0', name: 'Air Kyrgyzstan' },
  N7: { id: 'N7', lcc: '0', name: 'Nordic Regional Airlines' },
  ZT: { id: 'ZT', lcc: '0', name: 'Titan Airways' },
  QT: { id: 'QT', lcc: '0', name: 'TAMPA' },
  '4Z': { id: '4Z', lcc: '0', name: 'Airlink (SAA)' },
  VD: { id: 'VD', lcc: '0', name: 'Air Libert' },
  E5: { id: 'E5', lcc: '0', name: 'Air Arabia Egypt' },
  ZW: { id: 'ZW', lcc: '0', name: 'Air Wisconsin' },
  HP: { id: 'HP', lcc: '0', name: 'America West Airlines' },
  '4A': { id: '4A', lcc: '0', name: 'Air Kiribati' },
  SQ: { id: 'SQ', lcc: '0', name: 'Singapore Airlines' },
  U6: { id: 'U6', lcc: '1', name: 'Ural Airlines' },
  UI: { id: 'UI', lcc: '0', name: 'Auric Air' },
  NN: { id: 'NN', lcc: '1', name: 'VIM Airlines' },
  GK: { id: 'GK', lcc: '0', name: 'Jetstar Japan' },
  '5D': { id: '5D', lcc: '0', name: 'Aerolitoral' },
  NE: { id: 'NE', lcc: '0', name: 'Nesma Air' },
  '2O': { id: '2O', lcc: '0', name: 'Air Salone' },
  QQ: { id: 'QQ', lcc: '0', name: 'Alliance Airlines' },
  I9: { id: 'I9', lcc: '0', name: 'Air Italy' },
  WY: { id: 'WY', lcc: '0', name: 'Oman Air' },
  K2: { id: 'K2', lcc: '0', name: 'EuroLot' },
  '4T': { id: '4T', lcc: '0', name: 'Belair' },
  AP: { id: 'AP', lcc: '0', name: 'AlbaStar' },
  KO: { id: 'KO', lcc: '0', name: 'Komiaviatrans' },
  WI: { id: 'WI', lcc: '0', name: 'White Airways' },
  LG: { id: 'LG', lcc: '0', name: 'Luxair' },
  KJ: { id: 'KJ', lcc: '0', name: 'British Mediterranean Airways' },
  PX: { id: 'PX', lcc: '0', name: 'Air Niugini' },
  JM: { id: 'JM', lcc: '0', name: 'Air Jamaica' },
  I8: { id: 'I8', lcc: '0', name: 'Izhavia' },
  GL: { id: 'GL', lcc: '0', name: 'Air Greenland' },
  '6E': { id: '6E', lcc: '1', name: 'IndiGo Airlines' },
  SZ: { id: 'SZ', lcc: '0', name: 'Somon Air' },
  WZ: { id: 'WZ', lcc: '1', name: 'Red Wings' },
  H1: { id: 'H1', lcc: '0', name: 'Hahn Air' },
  IQ: { id: 'IQ', lcc: '0', name: 'Qazaq Air' },
  BN: { id: 'BN', lcc: '0', name: 'Horizon Airlines' },
  W8: { id: 'W8', lcc: '0', name: 'Cargojet Airways' },
  PT: { id: 'PT', lcc: '0', name: 'Red Jet Andes' },
  PP: { id: 'PP', lcc: '0', name: 'Air Indus' },
  TC: { id: 'TC', lcc: '0', name: 'Air Tanzania' },
  PJ: { id: 'PJ', lcc: '0', name: 'Air Saint Pierre' },
  Z4: { id: 'Z4', lcc: '0', name: 'Zoom Airlines' },
  '2I': { id: '2I', lcc: '1', name: 'Star Peru' },
  '7P': { id: '7P', lcc: '1', name: 'AirPanama' },
  SB: { id: 'SB', lcc: '0', name: 'Aircalin' },
  EI: { id: 'EI', lcc: '1', name: 'Aer Lingus' },
  A5: { id: 'A5', lcc: '1', name: 'HOP!' },
  HA: { id: 'HA', lcc: '0', name: 'Hawaiian Airlines' },
  GQ: { id: 'GQ', lcc: '0', name: 'Sky Express' },
  DF: { id: 'DF', lcc: '0', name: 'Michael Airlines' },
  U1: { id: 'U1', lcc: '0', name: 'Aviabus' },
  '0X': { id: '0X', lcc: '0', name: 'Copenhagen Express' },
  CT: { id: 'CT', lcc: '0', name: 'Alitalia Cityliner' },
  EH: { id: 'EH', lcc: '0', name: 'ANA Wings' },
  '7G': { id: '7G', lcc: '1', name: 'Star Flyer' },
  FN: { id: 'FN', lcc: '1', name: 'Fastjet' },
  LR: { id: 'LR', lcc: '0', name: 'LACSA' },
  B9: { id: 'B9', lcc: '0', name: 'Air Bangladesh' },
  '7E': { id: '7E', lcc: '0', name: 'Aeroline GmbH' },
  '6G': { id: '6G', lcc: '0', name: 'Air Wales' },
  LK: { id: 'LK', lcc: '0', name: 'Lao Skyway' },
  '5Y': { id: '5Y', lcc: '0', name: 'Atlas Air' },
  R7: { id: 'R7', lcc: '0', name: 'Aserca Airlines' },
  BD: { id: 'BD', lcc: '0', name: 'Cambodia Bayon Airlines' },
  AS: { id: 'AS', lcc: '0', name: 'Alaska Airlines' },
  VU: { id: 'VU', lcc: '0', name: 'Air Ivoire' },
  SY: { id: 'SY', lcc: '1', name: 'Sun Country Airlines' },
  '8Q': { id: '8Q', lcc: '1', name: 'Onur Air' },
  U8: { id: 'U8', lcc: '0', name: 'TUS Airways' },
  XG: { id: 'XG', lcc: '0', name: 'SunExpress' },
  P0: { id: 'P0', lcc: '0', name: 'Proflight Zambia' },
  '3E': { id: '3E', lcc: '0', name: 'Air Choice One' },
  FJ: { id: 'FJ', lcc: '0', name: 'Fiji Airways' },
  CV: { id: 'CV', lcc: '0', name: 'Air Chathams' },
  '0B': { id: '0B', lcc: '1', name: 'Blue Air' },
  MD: { id: 'MD', lcc: '0', name: 'Air Madagascar' },
  LU: { id: 'LU', lcc: '0', name: 'LAN Express' },
  FV: { id: 'FV', lcc: '0', name: 'Rossiya-Russian Airlines' },
  D9: { id: 'D9', lcc: '0', name: 'Aeroflot-Don' },
  NM: { id: 'NM', lcc: '0', name: 'Air Madrid' },
  OF: { id: 'OF', lcc: '0', name: 'Air Finland' },
  W9: { id: 'W9', lcc: '0', name: 'Wizz Air UK' },
  JS: { id: 'JS', lcc: '0', name: 'Air Koryo' },
  MK: { id: 'MK', lcc: '0', name: 'Air Mauritius' },
  '8D': { id: '8D', lcc: '0', name: 'Astair' },
  OT: { id: 'OT', lcc: '0', name: 'Aeropelican Air Services' },
  '6K': { id: '6K', lcc: '0', name: 'Asian Spirit' },
  IT: { id: 'IT', lcc: '0', name: 'Tigerair Taiwan' },
  PS: { id: 'PS', lcc: '0', name: 'Ukraine International' },
  MS: { id: 'MS', lcc: '0', name: 'Egyptair' },
  '5L': { id: '5L', lcc: '0', name: 'Aerosur' },
  J8: { id: 'J8', lcc: '0', name: 'Berjaya Air' },
  Z3: { id: 'Z3', lcc: '0', name: 'Avient Aviation' },
  EC: { id: 'EC', lcc: '0', name: 'Avialeasing Aviation Company' },
  '2K': { id: '2K', lcc: '0', name: 'Aerolineas Galapagos (Aerogal)' },
  TX: { id: 'TX', lcc: '0', name: 'Air Cara\u00efbes' },
  AC: { id: 'AC', lcc: '0', name: 'Air Canada' },
  MU: { id: 'MU', lcc: '0', name: 'China Eastern Airlines' },
  RC: { id: 'RC', lcc: '0', name: 'Atlantic Airways' },
  ZL: { id: 'ZL', lcc: '1', name: 'Regional Express' },
  WX: { id: 'WX', lcc: '0', name: 'CityJet' },
  PD: { id: 'PD', lcc: '1', name: 'Porter Airlines' },
  RE: { id: 'RE', lcc: '0', name: 'Stobart Air' },
  IO: { id: 'IO', lcc: '1', name: 'IrAero' },
  '4R': { id: '4R', lcc: '0', name: 'Renfe' },
  LV: { id: 'LV', lcc: '0', name: 'MEGA Maledives Airline' },
  BS: { id: 'BS', lcc: '0', name: 'British International Helicopters' },
  GS: { id: 'GS', lcc: '0', name: 'Tianjin Airlines' },
  CZ: { id: 'CZ', lcc: '0', name: 'China Southern Airlines' },
  CH: { id: 'CH', lcc: '0', name: 'Bemidji Airlines' },
  '8E': { id: '8E', lcc: '0', name: 'Bering Air' },
  GF: { id: 'GF', lcc: '0', name: 'Gulf Air Bahrain' },
  FB: { id: 'FB', lcc: '0', name: 'Bulgaria Air' },
  CX: { id: 'CX', lcc: '0', name: 'Cathay Pacific' },
  KC: { id: 'KC', lcc: '0', name: 'Air Astana' },
  OI: { id: 'OI', lcc: '0', name: 'Orchid Airlines' },
  AJ: { id: 'AJ', lcc: '0', name: 'Aero Contractors' },
  EN: { id: 'EN', lcc: '0', name: 'Air Dolomiti' },
  YK: { id: 'YK', lcc: '0', name: 'Avia Traffic Airline' },
  NF: { id: 'NF', lcc: '0', name: 'Air Vanuatu' },
  C9: { id: 'C9', lcc: '1', name: 'SkyWise' },
  '8N': { id: '8N', lcc: '0', name: 'Regional Air Services' },
  HR: { id: 'HR', lcc: '0', name: 'China United Airlines' },
  QI: { id: 'QI', lcc: '0', name: 'Cimber Air' },
  DQ: { id: 'DQ', lcc: '0', name: 'Coastal Air' },
  KR: { id: 'KR', lcc: '0', name: 'Comores Airlines' },
  '6A': { id: '6A', lcc: '0', name: 'Consorcio Aviaxsa' },
  OU: { id: 'OU', lcc: '0', name: 'Croatia Airlines' },
  TA: { id: 'TA', lcc: '0', name: 'Grupo TACA' },
  '2L': { id: '2L', lcc: '0', name: 'Helvetic Airways' },
  '2T': { id: '2T', lcc: '0', name: 'TruJet' },
  JJ: { id: 'JJ', lcc: '0', name: 'LATAM Brasil' },
  FP: { id: 'FP', lcc: '0', name: 'Pelican Airlines' },
  KF: { id: 'KF', lcc: '0', name: 'Air Belgium' },
  HU: { id: 'HU', lcc: '0', name: 'Hainan Airlines' },
  VX: { id: 'VX', lcc: '1', name: 'Virgin America' },
  DO: { id: 'DO', lcc: '0', name: 'Dominicana de Aviaci' },
  E3: { id: 'E3', lcc: '0', name: 'Domodedovo Airlines' },
  H7: { id: 'H7', lcc: '0', name: 'Eagle Air' },
  QU: { id: 'QU', lcc: '0', name: 'East African' },
  T3: { id: 'T3', lcc: '0', name: 'Eastern Airways' },
  UZ: { id: 'UZ', lcc: '0', name: 'El-Buraq Air Transport' },
  EU: { id: 'EU', lcc: '0', name: 'Empresa Ecuatoriana De Aviacion' },
  B8: { id: 'B8', lcc: '0', name: 'Eritrean Airlines' },
  EA: { id: 'EA', lcc: '0', name: 'European Air Express' },
  JN: { id: 'JN', lcc: '0', name: 'Excel Airways' },
  EO: { id: 'EO', lcc: '0', name: 'Express One International' },
  ZK: { id: 'ZK', lcc: '0', name: 'Great Lakes Airlines' },
  TY: { id: 'TY', lcc: '0', name: 'Air Caledonie' },
  SS: { id: 'SS', lcc: '0', name: 'Corsair International' },
  P7: { id: 'P7', lcc: '0', name: 'Small Planet Airline' },
  XK: { id: 'XK', lcc: '0', name: 'Air Corsica' },
  B2: { id: 'B2', lcc: '0', name: 'Belavia Belarusian Airlines' },
  BF: { id: 'BF', lcc: '0', name: 'French Bee' },
  ID: { id: 'ID', lcc: '1', name: 'Batik Air' },
  AT: { id: 'AT', lcc: '0', name: 'Royal Air Maroc' },
  IR: { id: 'IR', lcc: '0', name: 'Iran Air' },
  ZE: { id: 'ZE', lcc: '1', name: 'Eastar Jet' },
  YM: { id: 'YM', lcc: '0', name: 'Montenegro Airlines' },
  GP: { id: 'GP', lcc: '0', name: 'APG Airlines' },
  I7: { id: 'I7', lcc: '0', name: 'Paramount Airways' },
  '4G': { id: '4G', lcc: '0', name: 'Gazpromavia' },
  TS: { id: 'TS', lcc: '0', name: 'Air Transat' },
  QB: { id: 'QB', lcc: '0', name: 'Georgian National Airlines' },
  G0: { id: 'G0', lcc: '0', name: 'Ghana International Airlines' },
  G7: { id: 'G7', lcc: '0', name: 'GoJet Airlines' },
  D2: { id: 'D2', lcc: '1', name: 'Severstal Air Company' },
  UD: { id: 'UD', lcc: '0', name: "Hex'Air" },
  FW: { id: 'FW', lcc: '0', name: 'Ibex Airlines' },
  EY: { id: 'EY', lcc: '0', name: 'Etihad Airways' },
  RZ: { id: 'RZ', lcc: '0', name: 'Sansa Air' },
  IC: { id: 'IC', lcc: '0', name: 'Indian Airlines' },
  D6: { id: 'D6', lcc: '0', name: 'Interair South Africa' },
  EP: { id: 'EP', lcc: '0', name: 'Iran Aseman Airlines' },
  A9: { id: 'A9', lcc: '0', name: 'Georgian Airways' },
  WC: { id: 'WC', lcc: '0', name: 'Islena De Inversiones' },
  GI: { id: 'GI', lcc: '0', name: 'Itek Air' },
  JC: { id: 'JC', lcc: '0', name: 'JAL Express' },
  JO: { id: 'JO', lcc: '0', name: 'JALways' },
  '2M': { id: '2M', lcc: '0', name: 'Maya Island Air' },
  RQ: { id: 'RQ', lcc: '0', name: 'Kam Air' },
  KV: { id: 'KV', lcc: '0', name: 'Kavminvodyavia' },
  M5: { id: 'M5', lcc: '0', name: 'Kenmore Air' },
  Y9: { id: 'Y9', lcc: '0', name: 'Kish Air' },
  '7K': { id: '7K', lcc: '0', name: 'Kogalymavia Air Company' },
  GW: { id: 'GW', lcc: '0', name: 'Kuban Airlines' },
  EG: { id: 'EG', lcc: '0', name: 'Ernest Airlines' },
  NG: { id: 'NG', lcc: '0', name: 'Lauda Air' },
  LN: { id: 'LN', lcc: '0', name: 'Libyan Arab Airlines' },
  MP: { id: 'MP', lcc: '0', name: 'Martinair' },
  MZ: { id: 'MZ', lcc: '0', name: 'Merpati Nusantara Airlines' },
  YV: { id: 'YV', lcc: '0', name: 'Mesa Airlines' },
  MX: { id: 'MX', lcc: '0', name: 'Mexicana de Aviaci' },
  YX: { id: 'YX', lcc: '0', name: 'Midwest Airlines' },
  MY: { id: 'MY', lcc: '0', name: 'Midwest Airlines (Egypt)' },
  '8I': { id: '8I', lcc: '0', name: 'Myway Airlines' },
  '3R': { id: '3R', lcc: '0', name: 'Moskovia Airlines' },
  M9: { id: 'M9', lcc: '0', name: 'Motor Sich' },
  '8P': { id: '8P', lcc: '0', name: 'Pacific Coastal Airline' },
  NC: { id: 'NC', lcc: '0', name: 'National Jet Systems' },
  ON: { id: 'ON', lcc: '0', name: 'Nauru Air Corporation' },
  '6H': { id: '6H', lcc: '0', name: 'Israir' },
  LO: { id: 'LO', lcc: '0', name: 'LOT Polish Airlines' },
  PY: { id: 'PY', lcc: '0', name: 'Surinam Airways' },
  H8: { id: 'H8', lcc: '0', name: 'Latin American Wings' },
  '6O': { id: '6O', lcc: '0', name: 'Orbest' },
  FT: { id: 'FT', lcc: '0', name: 'FlyEgypt FT' },
  V7: { id: 'V7', lcc: '1', name: 'Volotea' },
  HK: { id: 'HK', lcc: '0', name: 'Yangon Airways' },
  RA: { id: 'RA', lcc: '0', name: 'Nepal Airlines' },
  EJ: { id: 'EJ', lcc: '0', name: 'New England Airlines' },
  U7: { id: 'U7', lcc: '0', name: 'Northern Dene Airways' },
  J3: { id: 'J3', lcc: '0', name: 'Northwestern Air' },
  '9B': { id: '9B', lcc: '0', name: 'AccesRail' },
  NU: { id: 'NU', lcc: '0', name: 'Japan Transocean Air' },
  GZ: { id: 'GZ', lcc: '0', name: 'Air Rarotonga' },
  O2: { id: 'O2', lcc: '0', name: 'Oceanic Airlines' },
  O7: { id: 'O7', lcc: '0', name: 'Orenburzhye Airline' },
  OL: { id: 'OL', lcc: '0', name: 'Ostfriesische Lufttransport' },
  PV: { id: 'PV', lcc: '0', name: 'PAN Air' },
  PI: { id: 'PI', lcc: '0', name: 'Polar Airlines' },
  O6: { id: 'O6', lcc: '0', name: 'Avianca Brazil' },
  OY: { id: 'OY', lcc: '0', name: 'Andes L\u00edneas A\u00e9reas' },
  S4: { id: 'S4', lcc: '0', name: 'SATA Azores Airlines' },
  LW: { id: 'LW', lcc: '0', name: 'Latin American Wings' },
  '8J': { id: '8J', lcc: '0', name: 'Eco Jet' },
  '9E': { id: '9E', lcc: '0', name: 'Pinnacle Airlines' },
  PH: { id: 'PH', lcc: '0', name: 'Polynesian Airlines' },
  BK: { id: 'BK', lcc: '0', name: 'Potomac Air' },
  IK: { id: 'IK', lcc: '0', name: 'Pegas Fly' },
  U4: { id: 'U4', lcc: '0', name: 'Buddha Air' },
  '7B': { id: '7B', lcc: '0', name: 'Fly Blue Crane' },
  LM: { id: 'LM', lcc: '0', name: 'LoganAir LM' },
  RH: { id: 'RH', lcc: '0', name: 'Republic Express Airlines' },
  QV: { id: 'QV', lcc: '0', name: 'Lao Airlines' },
  FQ: { id: 'FQ', lcc: '0', name: 'Thomas Cook Airlines' },
  KE: { id: 'KE', lcc: '0', name: 'Korean Air' },
  MQ: { id: 'MQ', lcc: '0', name: 'American Eagle Airlines' },
  '5M': { id: '5M', lcc: '0', name: 'Sibaviatrans' },
  ZS: { id: 'ZS', lcc: '0', name: 'Sama Airlines' },
  PU: { id: 'PU', lcc: '0', name: 'Plus Ultra Lineas Aereas' },
  '5G': { id: '5G', lcc: '0', name: 'Skyservice Airlines' },
  FS: { id: 'FS', lcc: '0', name: 'Servicios de Transportes A' },
  SD: { id: 'SD', lcc: '0', name: 'Sudan Airways' },
  RB: { id: 'RB', lcc: '0', name: 'Syrian Arab Airlines' },
  SC: { id: 'SC', lcc: '0', name: 'Shandong Airlines' },
  S5: { id: 'S5', lcc: '0', name: 'Shuttle America' },
  BU: { id: 'BU', lcc: '0', name: 'Baikotovitchestrian Airlines ' },
  '3P': { id: '3P', lcc: '0', name: 'Tiara Air' },
  YB: { id: 'YB', lcc: '1', name: 'BoraJet' },
  Q8: { id: 'Q8', lcc: '0', name: 'Trans Air Congo' },
  GE: { id: 'GE', lcc: '0', name: 'TransAsia Airways' },
  RL: { id: 'RL', lcc: '0', name: 'Royal Falcon' },
  T5: { id: 'T5', lcc: '0', name: 'Turkmenistan Airlines' },
  SJ: { id: 'SJ', lcc: '0', name: 'Sriwijaya Air' },
  CG: { id: 'CG', lcc: '0', name: 'Airlines PNG' },
  VR: { id: 'VR', lcc: '0', name: 'TACV' },
  MT: { id: 'MT', lcc: '1', name: 'Thomas Cook Airlines' },
  UQ: { id: 'UQ', lcc: '0', name: 'Urumqi Airlines' },
  '47': { id: '47', lcc: '0', name: '88' },
  DT: { id: 'DT', lcc: '0', name: 'TAAG Angola Airlines' },
  AX: { id: 'AX', lcc: '0', name: 'Trans States Airlines' },
  MN: { id: 'MN', lcc: '1', name: 'Kulula' },
  NO: { id: 'NO', lcc: '1', name: 'Neos Air' },
  '3T': { id: '3T', lcc: '0', name: 'Turan Air' },
  U5: { id: 'U5', lcc: '0', name: 'USA3000 Airlines' },
  UF: { id: 'UF', lcc: '0', name: 'UM Airlines' },
  US: { id: 'US', lcc: '0', name: 'US Airways' },
  '2W': { id: '2W', lcc: '0', name: 'Welcome Air' },
  '8O': { id: '8O', lcc: '0', name: 'West Coast Air' },
  IV: { id: 'IV', lcc: '0', name: 'Wind Jet' },
  IY: { id: 'IY', lcc: '0', name: 'Yemenia' },
  '9Y': { id: '9Y', lcc: '0', name: 'Air Kazakhstan' },
  JD: { id: 'JD', lcc: '0', name: 'Beijing Capital Airlines' },
  VS: { id: 'VS', lcc: '0', name: 'Virgin Atlantic Airways' },
  S7: { id: 'S7', lcc: '0', name: 'S7 Airlines' },
  KW: { id: 'KW', lcc: '0', name: 'Carnival Air Lines' },
  B7: { id: 'B7', lcc: '0', name: 'Uni Air' },
  YD: { id: 'YD', lcc: '0', name: 'Gomelavia' },
  TM: { id: 'TM', lcc: '0', name: 'Air Mozambique' },
  UR: { id: 'UR', lcc: '0', name: 'Azur Air Germany' },
  VH: { id: 'VH', lcc: '0', name: 'Virgin Pacific' },
  CN: { id: 'CN', lcc: '0', name: 'Canadian National Airways' },
  L8: { id: 'L8', lcc: '0', name: 'Line Blue' },
  DH: { id: 'DH', lcc: '0', name: 'Dennis Sky' },
  ZZ: { id: 'ZZ', lcc: '0', name: 'Zz' },
  GM: { id: 'GM', lcc: '0', name: 'Germania Flug' },
  PZ: { id: 'PZ', lcc: '0', name: 'LATAM Paraguay' },
  MF: { id: 'MF', lcc: '0', name: 'Xiamen Airlines' },
  EQ: { id: 'EQ', lcc: '0', name: 'TAME' },
  J4: { id: 'J4', lcc: '0', name: 'ALAK' },
  G4: { id: 'G4', lcc: '1', name: 'Allegiant Air' },
  WD: { id: 'WD', lcc: '0', name: 'Amsterdam Airlines' },
  '8F': { id: '8F', lcc: '0', name: 'STP Airways' },
  '7Y': { id: '7Y', lcc: '0', name: 'Mann Yadanarpon Airlines' },
  G6: { id: 'G6', lcc: '0', name: 'Air Volga' },
  '4L': { id: '4L', lcc: '0', name: 'Euroline' },
  DZ: { id: 'DZ', lcc: '0', name: 'Starline.kz' },
  '6P': { id: '6P', lcc: '0', name: 'Gryphon Airlines' },
  JR: { id: 'JR', lcc: '0', name: 'Joy Air' },
  '9H': { id: '9H', lcc: '0', name: 'MDLR Airlines' },
  TI: { id: 'TI', lcc: '0', name: 'Tailwind Airlines' },
  JH: { id: 'JH', lcc: '0', name: 'Fuji Dream Airlines' },
  LZ: { id: 'LZ', lcc: '0', name: 'belleair' },
  XZ: { id: 'XZ', lcc: '0', name: 'Congo Express' },
  HY: { id: 'HY', lcc: '0', name: 'Uzbekistan Airways' },
  TV: { id: 'TV', lcc: '0', name: 'Tibet Airlines' },
  A4: { id: 'A4', lcc: '0', name: 'Azimuth' },
  Q2: { id: 'Q2', lcc: '0', name: 'Maldivian' },
  BB: { id: 'BB', lcc: '0', name: 'Seaborne Airlines' },
  ZF: { id: 'ZF', lcc: '0', name: 'Azur Air' },
  S6: { id: 'S6', lcc: '0', name: 'Salmon Air' },
  HZ: { id: 'HZ', lcc: '0', name: 'Aurora Airlines' },
  '5F': { id: '5F', lcc: '1', name: 'Fly One' },
  H5: { id: 'H5', lcc: '0', name: 'I-Fly' },
  R8: { id: 'R8', lcc: '0', name: 'AirRussia' },
  D1: { id: 'D1', lcc: '0', name: 'Domenican Airlines' },
  C4: { id: 'C4', lcc: '0', name: 'LionXpress' },
  '69': { id: '69', lcc: '0', name: 'Royal European Airlines' },
  XO: { id: 'XO', lcc: '0', name: 'LTE International Airways' },
  G5: { id: 'G5', lcc: '0', name: 'Huaxia' },
  P8: { id: 'P8', lcc: '0', name: 'Air Mekong' },
  HH: { id: 'HH', lcc: '0', name: 'Air Hamburg (AHO)' },
  NS: { id: 'NS', lcc: '0', name: 'Caucasus Airlines' },
  VO: { id: 'VO', lcc: '0', name: 'FlyVLM' },
  KT: { id: 'KT', lcc: '0', name: 'VickJet' },
  XV: { id: 'XV', lcc: '0', name: 'BVI Airways' },
  ZJ: { id: 'ZJ', lcc: '0', name: 'Zambezi Airlines (ZMA)' },
  '12': { id: '12', lcc: '0', name: '12 North' },
  L6: { id: 'L6', lcc: '0', name: 'Mauritania Airlines International' },
  CE: { id: 'CE', lcc: '0', name: 'Chalair' },
  '7L': { id: '7L', lcc: '0', name: "Sun D'Or" },
  U3: { id: 'U3', lcc: '0', name: 'Avies' },
  ZA: { id: 'ZA', lcc: '0', name: 'Access Air' },
  TD: { id: 'TD', lcc: '0', name: 'Atlantis European Airways' },
  VC: { id: 'VC', lcc: '0', name: 'Via Air' },
  M4: { id: 'M4', lcc: '0', name: 'Mistral Air' },
  D3: { id: 'D3', lcc: '0', name: 'Daallo Airlines' },
  IA: { id: 'IA', lcc: '0', name: 'Iraqi Airways' },
  FM: { id: 'FM', lcc: '0', name: 'Shanghai Airlines' },
  UH: { id: 'UH', lcc: '0', name: 'AtlasGlobal Ukraine' },
  JK: { id: 'JK', lcc: '0', name: 'Spanair' },
  JZ: { id: 'JZ', lcc: '0', name: 'Skyways Express' },
  NB: { id: 'NB', lcc: '0', name: 'Sterling Airlines' },
  VI: { id: 'VI', lcc: '0', name: 'Volga-Dnepr Airlines' },
  VK: { id: 'VK', lcc: '0', name: 'Level' },
  VG: { id: 'VG', lcc: '0', name: 'VLM Airlines' },
  '2Q': { id: '2Q', lcc: '0', name: 'Air Cargo Carriers' },
  '3B': { id: '3B', lcc: '0', name: 'Binter Cabo Verde' },
  '5P': { id: '5P', lcc: '0', name: 'Small Planet Airlines' },
  S3: { id: 'S3', lcc: '0', name: 'SBA Airlines' },
  '4Q': { id: '4Q', lcc: '0', name: 'Safi Airlines' },
  YQ: { id: 'YQ', lcc: '0', name: 'TAR Aerolineas' },
  V6: { id: 'V6', lcc: '0', name: 'VIP Ecuador' },
  '7Z': { id: '7Z', lcc: '0', name: 'Halcyonair' },
  '4P': { id: '4P', lcc: '0', name: 'Business Aviation' },
  UM: { id: 'UM', lcc: '0', name: 'Air Zimbabwe' },
  '6Y': { id: '6Y', lcc: '0', name: 'SmartLynx Airlines' },
  K5: { id: 'K5', lcc: '0', name: 'SeaPort Airlines' },
  E4: { id: 'E4', lcc: '0', name: 'Elysian Airlines' },
  '7T': { id: '7T', lcc: '0', name: 'Trenitalia' },
  OC: { id: 'OC', lcc: '0', name: 'Oriental Air Bridge' },
  GY: { id: 'GY', lcc: '0', name: 'Colorful Guizhou Airlines' },
  IE: { id: 'IE', lcc: '0', name: 'Solomon Airlines' },
  '6J': { id: '6J', lcc: '0', name: 'Solaseed Air' },
  '1T': { id: '1T', lcc: '0', name: 'Hitit Bilgisayar Hizmetleri' },
  AH: { id: 'AH', lcc: '0', name: 'Air Algerie' },
  OK: { id: 'OK', lcc: '0', name: 'Czech Airlines' },
  '7Q': { id: '7Q', lcc: '0', name: 'Elite Airways' },
  DX: { id: 'DX', lcc: '0', name: 'DAT Danish Air Transport' },
  NH: { id: 'NH', lcc: '0', name: 'All Nippon Airways' },
  ZN: { id: 'ZN', lcc: '0', name: 'Zenith International Airline' },
  HT: { id: 'HT', lcc: '0', name: 'Hellenic Imperial Airways' },
  WO: { id: 'WO', lcc: '0', name: 'Swoop' },
  YO: { id: 'YO', lcc: '0', name: 'TransHolding System' },
  '3F': { id: '3F', lcc: '0', name: 'Fly Colombia ( Interliging Flights )' },
  HC: { id: 'HC', lcc: '0', name: 'Himalayan Airlines' },
  V9: { id: 'V9', lcc: '0', name: 'Star1 Airlines' },
  ZG: { id: 'ZG', lcc: '0', name: 'Grozny Avia' },
  AU: { id: 'AU', lcc: '0', name: 'Austral Lineas Aereas' },
  EV: { id: 'EV', lcc: '0', name: 'ExpressJet' },
  SP: { id: 'SP', lcc: '0', name: 'SATA Air Acores' },
  '2F': { id: '2F', lcc: '0', name: 'Frontier Flying Service' },
  '9S': { id: '9S', lcc: '0', name: 'Spring Airlines' },
  K1: { id: 'K1', lcc: '0', name: 'Kostromskie avialinii' },
  B1: { id: 'B1', lcc: '0', name: 'Baltic Air lines' },
  PL: { id: 'PL', lcc: '0', name: 'Southern Air Charter' },
  FU: { id: 'FU', lcc: '0', name: 'Felix Airways' },
  DS: { id: 'DS', lcc: '0', name: 'EasyJet (DS)' },
  YL: { id: 'YL', lcc: '0', name: 'Yamal Airlines' },
  R5: { id: 'R5', lcc: '0', name: 'Jordan Aviation' },
  '8Z': { id: '8Z', lcc: '0', name: 'Wizz Air Hungary' },
  HF: { id: 'HF', lcc: '0', name: "Air Cote d'Ivoire" },
  '6B': { id: '6B', lcc: '0', name: 'TUIfly Nordic' },
  '9T': { id: '9T', lcc: '0', name: 'Transwest Air' },
  AL: { id: 'AL', lcc: '0', name: 'Skywalk Airlines' },
  QX: { id: 'QX', lcc: '0', name: 'Horizon Air' },
  RD: { id: 'RD', lcc: '0', name: 'Ryan International Airlines' },
  R4: { id: 'R4', lcc: '0', name: 'Rossiya' },
  NI: { id: 'NI', lcc: '0', name: 'Portugalia' },
  '9Q': { id: '9Q', lcc: '0', name: 'PB Air' },
  QO: { id: 'QO', lcc: '0', name: 'Origin Pacific Airways' },
  NW: { id: 'NW', lcc: '0', name: 'Northwest Airlines' },
  '1I': { id: '1I', lcc: '0', name: 'NetJets' },
  DM: { id: 'DM', lcc: '0', name: 'Maersk' },
  MB: { id: 'MB', lcc: '0', name: 'MNG Airlines' },
  '4N': { id: '4N', lcc: '0', name: 'Air North' },
  HE: { id: 'HE', lcc: '0', name: 'Luftfahrtgesellschaft Walter' },
  L3: { id: 'L3', lcc: '0', name: 'LTU Austria' },
  WJ: { id: 'WJ', lcc: '0', name: 'Air Labrador' },
  CL: { id: 'CL', lcc: '0', name: 'Lufthansa CityLine' },
  '1B': { id: '1B', lcc: '0', name: 'Abacus International' },
  H6: { id: 'H6', lcc: '0', name: 'Bulgarian Air Charter' },
  WA: { id: 'WA', lcc: '0', name: 'KLM Cityhopper' },
  JF: { id: 'JF', lcc: '0', name: 'Jetairfly' },
  QJ: { id: 'QJ', lcc: '0', name: 'Jet Airways' },
  VV: { id: 'VV', lcc: '0', name: 'Viva Air' },
  YS: { id: 'YS', lcc: '0', name: 'R\u00e9gional' },
  GO: { id: 'GO', lcc: '0', name: 'Kuzu Airlines Cargo' },
  GH: { id: 'GH', lcc: '0', name: 'Globus' },
  '8A': { id: '8A', lcc: '0', name: 'Atlas Blue' },
  O8: { id: 'O8', lcc: '0', name: 'Siam Air' },
  NQ: { id: 'NQ', lcc: '0', name: 'Air Japan' },
  U9: { id: 'U9', lcc: '0', name: 'Tatarstan Airlines' },
  QM: { id: 'QM', lcc: '0', name: 'Air Malawi' },
  NX: { id: 'NX', lcc: '0', name: 'Air Macau' },
  XR: { id: 'XR', lcc: '0', name: 'Corendon Airlines Europe' },
  '3G': { id: '3G', lcc: '0', name: 'Atlant-Soyuz Airlines' },
  V3: { id: 'V3', lcc: '0', name: 'Carpatair' },
  FI: { id: 'FI', lcc: '0', name: 'Icelandair' },
  '2B': { id: '2B', lcc: '0', name: 'AlbaWings' },
  Q9: { id: 'Q9', lcc: '0', name: 'Wataniya Airways' },
  OJ: { id: 'OJ', lcc: '0', name: 'Overland Airways' },
  I2: { id: 'I2', lcc: '0', name: 'Iberia Express' },
  BW: { id: 'BW', lcc: '0', name: 'Caribbean Airlines' },
  QC: { id: 'QC', lcc: '0', name: 'Camair-co' },
  '3U': { id: '3U', lcc: '0', name: 'Sichuan Airlines' },
  WU: { id: 'WU', lcc: '0', name: 'Wizz Air Ukraine' },
  BQ: { id: 'BQ', lcc: '0', name: 'Buquebus L\u00edneas A\u00e9reas' },
  YY: { id: 'YY', lcc: '0', name: 'Virginwings' },
  MR: { id: 'MR', lcc: '0', name: 'Homer Air' },
  '8U': { id: '8U', lcc: '0', name: 'Afriqiyah Airways' },
  LF: { id: 'LF', lcc: '0', name: 'FlyNordic' },
  BH: { id: 'BH', lcc: '0', name: 'Hawkair' },
  '8H': { id: '8H', lcc: '0', name: 'Heli France' },
  JB: { id: 'JB', lcc: '0', name: 'Helijet' },
  T4: { id: 'T4', lcc: '0', name: 'Hellas Jet' },
  HW: { id: 'HW', lcc: '0', name: 'Hello' },
  M7: { id: 'M7', lcc: '0', name: 'MasAir' },
  OM: { id: 'OM', lcc: '0', name: 'MIAT Mongolian Airlines' },
  W5: { id: 'W5', lcc: '0', name: 'Mahan Air' },
  PO: { id: 'PO', lcc: '0', name: 'Polar Airlines' },
  '78': { id: '78', lcc: '0', name: 'Southjet cargo' },
  '4H': { id: '4H', lcc: '0', name: 'United Airways' },
  W2: { id: 'W2', lcc: '0', name: 'Flexflight' },
  MA: { id: 'MA', lcc: '0', name: 'Mal\u00e9v' },
  AE: { id: 'AE', lcc: '0', name: 'Mandarin Airlines' },
  GV: { id: 'GV', lcc: '0', name: 'Grant Aviation' },
  N5: { id: 'N5', lcc: '0', name: 'Skagway Air Service' },
  '5E': { id: '5E', lcc: '0', name: 'SGA Airlines' },
  AO: { id: 'AO', lcc: '0', name: 'Avianova (Russia)' },
  L4: { id: 'L4', lcc: '0', name: 'LASA Argentina' },
  WQ: { id: 'WQ', lcc: '0', name: 'PanAm World Airways' },
  KY: { id: 'KY', lcc: '0', name: 'KSY' },
  DN: { id: 'DN', lcc: '0', name: 'Senegal Airlines' },
  YR: { id: 'YR', lcc: '0', name: 'SENIC AIRLINES' },
  '4M': { id: '4M', lcc: '0', name: 'LATAM Argentina' },
  OG: { id: 'OG', lcc: '0', name: 'AirOnix' },
  WH: { id: 'WH', lcc: '0', name: 'China Northwest Airlines (WH)' },
  '5Q': { id: '5Q', lcc: '0', name: 'BQB Lineas Aereas' },
  KG: { id: 'KG', lcc: '0', name: 'Royal Airways' },
  YH: { id: 'YH', lcc: '0', name: 'Yangon Airways Ltd.' },
  FG: { id: 'FG', lcc: '0', name: 'Ariana Afghan Airlines' },
  LB: { id: 'LB', lcc: '1', name: 'Air Costa' },
  '8B': { id: '8B', lcc: '0', name: 'TransNusa' },
  L5: { id: 'L5', lcc: '0', name: 'Atlas Atlantique Airlines' },
  ZC: { id: 'ZC', lcc: '0', name: 'Korongo Airlines' },
  Q3: { id: 'Q3', lcc: '0', name: 'SOCHI AIR CHATER' },
  AN: { id: 'AN', lcc: '0', name: 'Ansett Australia' },
  '4K': { id: '4K', lcc: '0', name: 'Askari Aviation' },
  ZX: { id: 'ZX', lcc: '0', name: 'Japan Regio' },
  P4: { id: 'P4', lcc: '0', name: 'Air Peace Limited' },
  HI: { id: 'HI', lcc: '0', name: 'Papillon Grand Canyon Helicopters' },
  SO: { id: 'SO', lcc: '0', name: "Salsa d\\\\'Haiti" },
  VP: { id: 'VP', lcc: '0', name: 'VASP' },
  B5: { id: 'B5', lcc: '0', name: 'Fly-SAX' },
  TJ: { id: 'TJ', lcc: '0', name: 'Tradewind Aviation' },
  JA: { id: 'JA', lcc: '0', name: 'JetSmart' },
  G8: { id: 'G8', lcc: '1', name: 'Go Air' },
  SW: { id: 'SW', lcc: '0', name: 'Air Namibia' },
  CQ: { id: 'CQ', lcc: '0', name: 'SOCHI AIR' },
  VA: { id: 'VA', lcc: '1', name: 'Virgin Australia Airlines' },
  PA: { id: 'PA', lcc: '1', name: 'Airblue' },
  WK: { id: 'WK', lcc: '0', name: 'Edelweiss Air' },
  NA: { id: 'NA', lcc: '0', name: 'Nesma Air' },
  T6: { id: 'T6', lcc: '0', name: 'Airswift Transport' },
  '3J': { id: '3J', lcc: '0', name: 'Jubba Airways' },
  GB: { id: 'GB', lcc: '0', name: 'BRAZIL AIR' },
  ES: { id: 'ES', lcc: '0', name: 'EuropeSky' },
  '1C': { id: '1C', lcc: '0', name: 'OneChina' },
  OQ: { id: 'OQ', lcc: '0', name: 'Chongqing Airlines' },
  '1X': { id: '1X', lcc: '0', name: 'Branson Air Express' },
  ZY: { id: 'ZY', lcc: '0', name: 'Ada Air' },
  C5: { id: 'C5', lcc: '0', name: 'CommutAir' },
  C3: { id: 'C3', lcc: '0', name: 'Contact Air' },
  CS: { id: 'CS', lcc: '0', name: 'Continental Micronesia' },
  '0D': { id: '0D', lcc: '0', name: 'Darwin Airline' },
  SU: { id: 'SU', lcc: '0', name: 'Aeroflot Russian Airlines' },
  DK: { id: 'DK', lcc: '0', name: 'Eastland Air' },
  GJ: { id: 'GJ', lcc: '0', name: 'Eurofly Service' },
  EZ: { id: 'EZ', lcc: '0', name: 'Evergreen International Airlines' },
  XE: { id: 'XE', lcc: '0', name: 'ExpressJet' },
  M3: { id: 'M3', lcc: '0', name: 'Air Norway' },
  RF: { id: 'RF', lcc: '0', name: 'Florida West International Airways' },
  FH: { id: 'FH', lcc: '0', name: 'Freebird Airlines' },
  C1: { id: 'C1', lcc: '0', name: 'CanXpress' },
  QA: { id: 'QA', lcc: '0', name: 'Click (Mexicana)' },
  W1: { id: 'W1', lcc: '0', name: 'World Experience Airline' },
  ZQ: { id: 'ZQ', lcc: '0', name: 'Locair' },
  N1: { id: 'N1', lcc: '0', name: 'N1' },
  T7: { id: 'T7', lcc: '0', name: 'Twin Jet' },
  H9: { id: 'H9', lcc: '0', name: 'Himalaya Airlines' },
  EM: { id: 'EM', lcc: '0', name: 'Empire Airlines' },
  Y8: { id: 'Y8', lcc: '0', name: 'Marusya Airways' },
  '4X': { id: '4X', lcc: '0', name: 'Red Jet Mexico' },
  QY: { id: 'QY', lcc: '0', name: 'Red Jet Canada' },
  E1: { id: 'E1', lcc: '0', name: 'Usa Sky Cargo' },
  GN: { id: 'GN', lcc: '0', name: 'GNB Linhas Aereas' },
  XB: { id: 'XB', lcc: '0', name: 'NEXT Brasil' },
  '6U': { id: '6U', lcc: '0', name: 'Air Cargo Germany' },
  N0: { id: 'N0', lcc: '0', name: 'Norte Lineas Aereas' },
  G1: { id: 'G1', lcc: '0', name: 'Indya Airline Group' },
  '1F': { id: '1F', lcc: '0', name: 'CB Airways UK ( Interliging Flights )' },
  F1: { id: 'F1', lcc: '0', name: 'Fly Brasil' },
  CB: { id: 'CB', lcc: '0', name: 'CCML Airlines' },
  '24': { id: '24', lcc: '0', name: 'Euro Jet' },
  '2D': { id: '2D', lcc: '0', name: 'Aero VIP (2D)' },
  Y1: { id: 'Y1', lcc: '0', name: 'Yellowstone Club Private Shuttle' },
  H3: { id: 'H3', lcc: '0', name: 'Harbour Air (Priv)' },
  TH: { id: 'TH', lcc: '0', name: 'TransBrasil Airlines' },
  RG: { id: 'RG', lcc: '0', name: 'Rotana Jet' },
  A6: { id: 'A6', lcc: '0', name: 'Air Alps Aviation (A6)' },
  BZ: { id: 'BZ', lcc: '0', name: 'Black Stallion Airways' },
  RI: { id: 'RI', lcc: '0', name: 'Mandala Airlines' },
  HQ: { id: 'HQ', lcc: '0', name: 'Thomas Cook Belgium' },
  KN: { id: 'KN', lcc: '0', name: 'China United' },
  '4B': { id: '4B', lcc: '0', name: 'BoutiqueAir' },
  I6: { id: 'I6', lcc: '0', name: 'Air indus' },
  __: { id: '__', lcc: '0', name: 'FakeAirline' },
  TU: { id: 'TU', lcc: '0', name: 'Tunisair' },
  KA: { id: 'KA', lcc: '0', name: 'Cathay Dragon' },
  QG: { id: 'QG', lcc: '1', name: 'Citilink' },
  HD: { id: 'HD', lcc: '1', name: 'Air Do' },
  IZ: { id: 'IZ', lcc: '1', name: 'Arkia' },
  RS: { id: 'RS', lcc: '0', name: 'Air Seoul' },
  E9: { id: 'E9', lcc: '0', name: 'Evelop Airlines' },
  '5H': { id: '5H', lcc: '1', name: 'Fly540' },
  K6: { id: 'K6', lcc: '0', name: 'Cambodia Angkor Air' },
  DG: { id: 'DG', lcc: '0', name: 'CebGo' },
  F8: { id: 'F8', lcc: '0', name: 'Flair Airlines' },
  IW: { id: 'IW', lcc: '0', name: 'Wings Air' },
  Q6: { id: 'Q6', lcc: '0', name: 'Volaris Costa Rica' },
  CI: { id: 'CI', lcc: '0', name: 'China Airlines' },
  VB: { id: 'VB', lcc: '1', name: 'VivaAerobus' },
  DA: { id: 'DA', lcc: '0', name: 'Dana Air' },
  RO: { id: 'RO', lcc: '0', name: 'Tarom' },
  GR: { id: 'GR', lcc: '0', name: 'Aurigny Air Services' },
  JE: { id: 'JE', lcc: '1', name: 'Mango' },
  WW: { id: 'WW', lcc: '1', name: 'WOW air' },
  TK: { id: 'TK', lcc: '0', name: 'Turkish Airlines' },
  YT: { id: 'YT', lcc: '0', name: 'Yeti Airways' },
  QL: { id: 'QL', lcc: '1', name: 'Laser Air' },
  SA: { id: 'SA', lcc: '0', name: 'South African Airways' },
  UX: { id: 'UX', lcc: '0', name: 'Air Europa' },
  OS: { id: 'OS', lcc: '0', name: 'Austrian Airlines' },
  K8: { id: 'K8', lcc: '0', name: 'Kan Air' },
  TG: { id: 'TG', lcc: '0', name: 'Thai Airways International' },
  VN: { id: 'VN', lcc: '0', name: 'Vietnam Airlines' },
  WN: { id: 'WN', lcc: '1', name: 'Southwest Airlines' },
  YE: { id: 'YE', lcc: '0', name: 'Yan Air' },
  '77': { id: '77', lcc: '0', name: 'Southjet connect' },
  CC: { id: 'CC', lcc: '0', name: 'CM Airlines' },
  OH: { id: 'OH', lcc: '0', name: 'Comair' },
  '9L': { id: '9L', lcc: '0', name: 'Colgan Air' },
  CJ: { id: 'CJ', lcc: '0', name: 'BA CityFlyer' },
  O1: { id: 'O1', lcc: '0', name: 'Orbit Airlines Azerbaijan' },
  '5K': { id: '5K', lcc: '0', name: 'Hi Fly (5K)' },
  NJ: { id: 'NJ', lcc: '0', name: 'Nordic Global Airlines' },
  '20': { id: '20', lcc: '0', name: 'Air Salone' },
  EE: { id: 'EE', lcc: '0', name: 'RegionalJet' },
  FR: { id: 'FR', lcc: '1', name: 'Ryanair' },
  RK: { id: 'RK', lcc: '0', name: 'Air Afrique' },
  '76': { id: '76', lcc: '0', name: 'Southjet' },
  Z9: { id: 'Z9', lcc: '0', name: 'Bek Air' },
  '9K': { id: '9K', lcc: '1', name: 'Cape Air' },
  OB: { id: 'OB', lcc: '1', name: 'Boliviana de Aviaci\u00f3n' },
  KM: { id: 'KM', lcc: '0', name: 'Air Malta' },
  XC: { id: 'XC', lcc: '1', name: 'Corendon' },
  P6: { id: 'P6', lcc: '0', name: 'Pascan Aviation' },
  YU: { id: 'YU', lcc: '0', name: 'EuroAtlantic Airways' },
  '9M': { id: '9M', lcc: '0', name: 'Central Mountain Air' },
  AA: { id: 'AA', lcc: '0', name: 'American Airlines' },
  '9X': { id: '9X', lcc: '1', name: 'Southern Airways Express' },
  '7N': { id: '7N', lcc: '0', name: 'Pawa Dominicana' },
  '6T': { id: '6T', lcc: '1', name: 'Air Mandalay' },
  KI: { id: 'KI', lcc: '1', name: 'KrasAvia' },
  SM: { id: 'SM', lcc: '1', name: 'Air Cairo' },
  EF: { id: 'EF', lcc: '1', name: 'EasyFly' },
  XL: { id: 'XL', lcc: '0', name: 'LATAM Ecuador' },
  NK: { id: 'NK', lcc: '1', name: 'Spirit Airlines' },
  P5: { id: 'P5', lcc: '0', name: 'Wingo airlines' },
  A3: { id: 'A3', lcc: '1', name: 'Aegean' },
  '2J': { id: '2J', lcc: '0', name: 'Air Burkina' },
  '2C': { id: '2C', lcc: '0', name: 'SNCF' },
  '6F': { id: '6F', lcc: '1', name: 'Primera Air Nordic' },
  IG: { id: 'IG', lcc: '1', name: 'Air Italy' },
  MW: { id: 'MW', lcc: '0', name: 'Mokulele Flight Service' },
  BP: { id: 'BP', lcc: '0', name: 'Air Botswana' },
  V8: { id: 'V8', lcc: '0', name: 'ATRAN Cargo Airlines' },
  CW: { id: 'CW', lcc: '0', name: 'Air Marshall Islands' },
  DL: { id: 'DL', lcc: '0', name: 'Delta Air Lines' },
  RV: { id: 'RV', lcc: '0', name: 'Caspian Airlines' },
  C0: { id: 'C0', lcc: '0', name: 'Centralwings' },
  VZ: { id: 'VZ', lcc: '0', name: 'Thai Vietjet' },
  IF: { id: 'IF', lcc: '0', name: 'Fly Baghdad Airlines' },
  IS: { id: 'IS', lcc: '0', name: 'Fly Ais Airlines' },
  R6: { id: 'R6', lcc: '0', name: 'RACSA' },
  RW: { id: 'RW', lcc: '0', name: 'Republic Airlines' },
  C2: { id: 'C2', lcc: '0', name: 'CanXplorer' },
  T2: { id: 'T2', lcc: '0', name: 'Thai Air Cargo' },
  Q5: { id: 'Q5', lcc: '0', name: '40-Mile Air' },
  V5: { id: 'V5', lcc: '0', name: 'Aerov\u00edas DAP' },
  SH: { id: 'SH', lcc: '0', name: 'Sharp Airlines' },
  '7W': { id: '7W', lcc: '0', name: 'Windrose Airlines' },
  LC: { id: 'LC', lcc: '0', name: 'Varig Log' },
  XF: { id: 'XF', lcc: '0', name: 'Vladivostok Air' },
  F7: { id: 'F7', lcc: '0', name: 'Etihad Regional' },
  S2: { id: 'S2', lcc: '0', name: 'Air Sahara' },
  V2: { id: 'V2', lcc: '0', name: 'Vision Airlines' },
  '8V': { id: '8V', lcc: '0', name: 'Astral Aviation' },
  '8T': { id: '8T', lcc: '0', name: 'Air Tindi' },
  UB: { id: 'UB', lcc: '0', name: 'Myanmar National Airlines' },
  YN: { id: 'YN', lcc: '0', name: 'Air Creebec' },
  P1: { id: 'P1', lcc: '0', name: 'Regional Sky' },
  PB: { id: 'PB', lcc: '0', name: 'Provincial Airlines' },
  '7V': { id: '7V', lcc: '0', name: 'Federai Airlines' },
  V4: { id: 'V4', lcc: '0', name: 'Vieques Air Link' },
  '0V': { id: '0V', lcc: '0', name: 'VASCO' },
  F2: { id: 'F2', lcc: '0', name: 'Safarilink Aviation' },
  '7J': { id: '7J', lcc: '1', name: 'Tajik Air' },
  ZD: { id: 'ZD', lcc: '0', name: 'EWA Air' },
  '4W': { id: '4W', lcc: '0', name: 'Allied Air' },
  N6: { id: 'N6', lcc: '0', name: 'Nomad Aviation' },
  ST: { id: 'ST', lcc: '1', name: 'Germania' },
  JT: { id: 'JT', lcc: '1', name: 'Lion Air' },
  UL: { id: 'UL', lcc: '0', name: 'SriLankan Airlines' },
  WG: { id: 'WG', lcc: '1', name: 'Sunwing' },
  WV: { id: 'WV', lcc: '0', name: 'Aero VIP' },
  J1: { id: 'J1', lcc: '0', name: 'One Jet' },
  ML: { id: 'ML', lcc: '1', name: 'Air Mediterranee' },
  DB: { id: 'DB', lcc: '0', name: 'Brit Air' },
  R3: { id: 'R3', lcc: '1', name: 'Yakutia Airlines' },
  LX: { id: 'LX', lcc: '0', name: 'Swiss International Air Lines' },
  J7: { id: 'J7', lcc: '1', name: 'Greenland Express' },
  '6R': { id: '6R', lcc: '1', name: 'Alrosa' },
  XY: { id: 'XY', lcc: '1', name: 'flynas' },
  CA: { id: 'CA', lcc: '0', name: 'Air China' },
  K9: { id: 'K9', lcc: '1', name: 'KrasAvia (old iata)' },
  '5U': { id: '5U', lcc: '0', name: 'Transportes A\u00e9reos Guatemaltecos' },
  EL: { id: 'EL', lcc: '1', name: 'Ellinair' },
  VJ: { id: 'VJ', lcc: '1', name: 'VietJet Air' },
  DV: { id: 'DV', lcc: '1', name: 'Scat Air' },
  '5W': { id: '5W', lcc: '0', name: 'WESTBahn' },
  NL: { id: 'NL', lcc: '1', name: 'Shaheen Air International' },
  Z6: { id: 'Z6', lcc: '1', name: 'Dniproavia' },
  MJ: { id: 'MJ', lcc: '1', name: 'Mihin Lanka' },
  '9N': { id: '9N', lcc: '0', name: 'Tropic Air Limited' },
  G9: { id: 'G9', lcc: '1', name: 'Air Arabia' },
  '3K': { id: '3K', lcc: '0', name: 'Jetstar Asia Airways' },
  SG: { id: 'SG', lcc: '1', name: 'Spicejet' },
  YZ: { id: 'YZ', lcc: '0', name: 'Alas Uruguay' },
  '8L': { id: '8L', lcc: '1', name: 'Lucky air' },
  BR: { id: 'BR', lcc: '1', name: 'EVA Air' },
  ZB: { id: 'ZB', lcc: '1', name: 'Monarch' },
  '9V': { id: '9V', lcc: '0', name: 'Avior Airlines' },
  AB: { id: 'AB', lcc: '1', name: 'Air Berlin' },
  '5Z': { id: '5Z', lcc: '1', name: 'Cem Air' },
  '5O': { id: '5O', lcc: '0', name: 'ASL Airlines France' },
  EB: { id: 'EB', lcc: '1', name: 'Wamos Air' },
  WS: { id: 'WS', lcc: '1', name: 'WestJet' },
  '5C': { id: '5C', lcc: '1', name: 'Nature Air' },
  OR: { id: 'OR', lcc: '1', name: 'TUI Airlines Netherlands' },
  AW: { id: 'AW', lcc: '1', name: 'Africa World Airlines' },
  GT: { id: 'GT', lcc: '1', name: 'Flyvista' },
  HX: { id: 'HX', lcc: '1', name: 'Hong Kong Airlines' },
  '7I': { id: '7I', lcc: '1', name: 'Insel Air' },
  PN: { id: 'PN', lcc: '1', name: 'West Air China' },
  JX: { id: 'JX', lcc: '1', name: 'Jambojet' },
  '9C': { id: '9C', lcc: '1', name: 'Spring Airlines' },
  DR: { id: 'DR', lcc: '1', name: 'Ruili Airlines' },
  FA: { id: 'FA', lcc: '1', name: 'Fly Safair' },
  DP: { id: 'DP', lcc: '1', name: 'Pobeda' },
  NZ: { id: 'NZ', lcc: '1', name: 'Air New Zealand' },
  CM: { id: 'CM', lcc: '0', name: 'Copa Airlines' },
  Z8: { id: 'Z8', lcc: '1', name: 'Amaszonas' },
  S8: { id: 'S8', lcc: '1', name: 'SkyWise' },
  JW: { id: 'JW', lcc: '1', name: 'Vanilla Air' },
  WB: { id: 'WB', lcc: '0', name: 'Rwandair Express' },
  PG: { id: 'PG', lcc: '1', name: 'Bangkok Airways' },
  DC: { id: 'DC', lcc: '1', name: 'Braathens Regional Airways' },
  W4: { id: 'W4', lcc: '1', name: 'LC Per\u00fa' },
  '2G': { id: '2G', lcc: '1', name: 'Angara airlines' },
  OD: { id: 'OD', lcc: '1', name: 'Malindo Air' },
  BT: { id: 'BT', lcc: '1', name: 'airBaltic' },
  MM: { id: 'MM', lcc: '1', name: 'Peach Aviation' },
  '8R': { id: '8R', lcc: '1', name: 'Sol L\u00edneas A\u00e9reas' },
  I4: { id: 'I4', lcc: '0', name: 'I-Fly' },
  '8M': { id: '8M', lcc: '0', name: 'Myanmar Airways' },
  RX: { id: 'RX', lcc: '1', name: 'Regent Airways' },
  BI: { id: 'BI', lcc: '0', name: 'Royal Brunei Airlines' },
  OX: { id: 'OX', lcc: '1', name: 'Orient Thai Airlines' },
  Y5: { id: 'Y5', lcc: '1', name: 'Golden Myanmar Airlines' },
  X9: { id: 'X9', lcc: '0', name: 'Fake Airline' },
  '5N': { id: '5N', lcc: '1', name: 'NordAvia' },
  LJ: { id: 'LJ', lcc: '1', name: 'Jin Air' },
  BX: { id: 'BX', lcc: '1', name: 'Air Busan' },
  BE: { id: 'BE', lcc: '1', name: 'flybe' },
  C7: { id: 'C7', lcc: '1', name: 'Cinnamon Air' },
  SI: { id: 'SI', lcc: '0', name: 'Blue Islands' },
  KL: { id: 'KL', lcc: '0', name: 'KLM Royal Dutch Airlines' },
  OZ: { id: 'OZ', lcc: '0', name: 'Asiana Airlines' },
  ED: { id: 'ED', lcc: '1', name: 'Airblue' },
  KX: { id: 'KX', lcc: '0', name: 'Cayman Airways' },
  MO: { id: 'MO', lcc: '1', name: 'Calm Air' },
  CO: { id: 'CO', lcc: '0', name: 'Cobalt Air' },
  KQ: { id: 'KQ', lcc: '0', name: 'Kenya Airways' },
  ME: { id: 'ME', lcc: '0', name: 'Middle East Airlines' },
  VQ: { id: 'VQ', lcc: '1', name: 'Novoair' },
  '9W': { id: '9W', lcc: '0', name: 'Jet Airways' },
  K7: { id: 'K7', lcc: '0', name: 'Air KBZ' },
  YJ: { id: 'YJ', lcc: '1', name: 'Asian Wings Air' },
  G3: { id: 'G3', lcc: '1', name: 'Gol Transportes A\u00e9reos' },
  '7R': { id: '7R', lcc: '1', name: 'Rusline' },
  IX: { id: 'IX', lcc: '1', name: 'Air India Express' },
  BC: { id: 'BC', lcc: '1', name: 'Skymark Airlines' },
  XN: { id: 'XN', lcc: '0', name: 'Xpressair' },
  KU: { id: 'KU', lcc: '0', name: 'Kuwait Airways' },
  BG: { id: 'BG', lcc: '1', name: 'Biman Bangladesh Airlines' },
  TP: { id: 'TP', lcc: '0', name: 'TAP Portugal' },
  ZV: { id: 'ZV', lcc: '1', name: 'V Air' },
  TN: { id: 'TN', lcc: '0', name: 'Air Tahiti Nui' },
  HO: { id: 'HO', lcc: '1', name: 'Juneyao Airlines' },
  PF: { id: 'PF', lcc: '1', name: 'Primera Air' },
  A2: { id: 'A2', lcc: '0', name: 'Astra Airlines' },
  DD: { id: 'DD', lcc: '1', name: 'Nok Air' },
  B0: { id: 'B0', lcc: '0', name: 'Aws express' },
  TR: { id: 'TR', lcc: '1', name: 'Scoot' },
  TL: { id: 'TL', lcc: '1', name: 'Airnorth' },
  P9: { id: 'P9', lcc: '1', name: 'Peruvian Airlines' },
  QS: { id: 'QS', lcc: '1', name: 'SmartWings' },
  ZI: { id: 'ZI', lcc: '0', name: 'Aigle Azur' },
  '3M': { id: '3M', lcc: '1', name: 'Silver Airways' },
  JP: { id: 'JP', lcc: '0', name: 'Adria Airways' },
  BM: { id: 'BM', lcc: '0', name: 'BMI regional' },
  YC: { id: 'YC', lcc: '1', name: 'Yamal Air' },
  D8: { id: 'D8', lcc: '0', name: 'Norwegian International' },
  SN: { id: 'SN', lcc: '0', name: 'Brussels Airlines' },
  '6W': { id: '6W', lcc: '1', name: 'Saratov Aviation Division' },
  B6: { id: 'B6', lcc: '1', name: 'JetBlue Airways' },
  WP: { id: 'WP', lcc: '0', name: 'Island Air' },
  KD: { id: 'KD', lcc: '0', name: 'Kalstar Aviation' },
  CF: { id: 'CF', lcc: '0', name: 'City Airline' },
  '3Q': { id: '3Q', lcc: '0', name: 'Yunnan Airlines' },
  CP: { id: 'CP', lcc: '0', name: 'Canadian Airlines' },
  MH: { id: 'MH', lcc: '1', name: 'Malaysia Airlines' },
  '7M': { id: '7M', lcc: '0', name: 'MAYAir' },
  AI: { id: 'AI', lcc: '0', name: 'Air India Limited' },
  SK: { id: 'SK', lcc: '0', name: 'SAS' },
  QW: { id: 'QW', lcc: '0', name: 'Blue Wings' },
  B3: { id: 'B3', lcc: '0', name: 'Bellview Airlines' },
  TF: { id: 'TF', lcc: '0', name: 'Braathens Regional Aviation' },
  IB: { id: 'IB', lcc: '0', name: 'Iberia Airlines' },
  JL: { id: 'JL', lcc: '0', name: 'Japan Airlines' },
  JV: { id: 'JV', lcc: '0', name: 'Bearskin Lake Air Service' },
  KP: { id: 'KP', lcc: '0', name: 'ASKY Airlines' },
  QK: { id: 'QK', lcc: '0', name: 'Air Canada Jazz' },
  AF: { id: 'AF', lcc: '0', name: 'Air France' },
  AQ: { id: 'AQ', lcc: '1', name: '9 Air' },
  VW: { id: 'VW', lcc: '0', name: 'Aeromar' },
  PW: { id: 'PW', lcc: '0', name: 'Precision Air' },
  PK: { id: 'PK', lcc: '0', name: 'Pakistan International Airlines' },
  '3S': { id: '3S', lcc: '0', name: 'Air Antilles Express' },
  TO: { id: 'TO', lcc: '0', name: 'Transavia France' },
  '9R': { id: '9R', lcc: '1', name: 'SATENA' },
  Q7: { id: 'Q7', lcc: '0', name: 'SkyBahamas Air' },
  HV: { id: 'HV', lcc: '1', name: 'Transavia' },
  UE: { id: 'UE', lcc: '0', name: 'Nasair' },
  AM: { id: 'AM', lcc: '0', name: 'AeroM\u00e9xico' },
  '4U': { id: '4U', lcc: '1', name: 'germanwings' },
  RP: { id: 'RP', lcc: '0', name: 'Chautauqua Airlines' },
  '5J': { id: '5J', lcc: '1', name: 'Cebu Pacific' },
  TB: { id: 'TB', lcc: '1', name: 'tuifly.be' },
  IP: { id: 'IP', lcc: '0', name: 'Island Spirit' },
  R2: { id: 'R2', lcc: '1', name: 'Orenburg Airlines' },
  FC: { id: 'FC', lcc: '1', name: 'Viva Air Colombia' },
  '3L': { id: '3L', lcc: '1', name: 'Intersky' },
  '2Z': { id: '2Z', lcc: '1', name: 'Passaredo' },
  UT: { id: 'UT', lcc: '1', name: 'UTair' },
  X3: { id: 'X3', lcc: '1', name: 'TUIfly' },
  TT: { id: 'TT', lcc: '1', name: 'Tiger Airways Australia' },
  DY: { id: 'DY', lcc: '1', name: 'Norwegian' },
  UO: { id: 'UO', lcc: '1', name: 'Hong Kong Express Airways' },
  UN: { id: 'UN', lcc: '1', name: 'Transaero Airlines' },
  '1D': { id: '1D', lcc: '1', name: 'Aerolinea de Antioquia' },
  H2: { id: 'H2', lcc: '1', name: 'Sky Airline' },
  J9: { id: 'J9', lcc: '1', name: 'Jazeera Airways' },
  JQ: { id: 'JQ', lcc: '1', name: 'Jetstar Airways' },
  FY: { id: 'FY', lcc: '1', name: 'Firefly' },
  AZ: { id: 'AZ', lcc: '0', name: 'Alitalia' },
  WM: { id: 'WM', lcc: '0', name: 'Windward Islands Airways' },
  '3H': { id: '3H', lcc: '1', name: 'AirInuit' },
  PC: { id: 'PC', lcc: '1', name: 'Pegasus' },
  LY: { id: 'LY', lcc: '1', name: 'El Al Israel Airlines' },
  TW: { id: 'TW', lcc: '1', name: 'Tway Airlines' },
  F9: { id: 'F9', lcc: '1', name: 'Frontier Airlines' },
  TZ: { id: 'TZ', lcc: '1', name: 'Scoot - old' },
  AR: { id: 'AR', lcc: '0', name: 'Aerolineas Argentinas' },
  BV: { id: 'BV', lcc: '1', name: 'Blue Panorama' },
  '4O': { id: '4O', lcc: '1', name: 'Interjet' },
  EW: { id: 'EW', lcc: '1', name: 'Eurowings' },
  FO: { id: 'FO', lcc: '1', name: 'Flybondi' },
  SL: { id: 'SL', lcc: '1', name: 'Thai Lion Air' },
  UK: { id: 'UK', lcc: '0', name: 'Air Vistara' },
  UP: { id: 'UP', lcc: '1', name: 'Bahamasair' },
  VY: { id: 'VY', lcc: '1', name: 'Vueling' },
  U2: { id: 'U2', lcc: '1', name: 'easyJet' },
  DE: { id: 'DE', lcc: '1', name: 'Condor' },
  AK: { id: 'AK', lcc: '1', name: 'AirAsia' },
  FD: { id: 'FD', lcc: '0', name: 'Thai AirAsia' },
  QZ: { id: 'QZ', lcc: '0', name: 'Indonesia AirAsia' },
  W6: { id: 'W6', lcc: '1', name: 'Wizzair' },
};

export default airlines;

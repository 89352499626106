import React from 'react';
import styled from 'styled-components';
import CheckCircleSolid from 'assets/images/check-circle-solid.svg';
import { MobileOnly, DesktopOnly } from '../';
import i18n from 'Utils/i18n.js';

const KeyPointsStyled = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  text-align: center;
  ul {
    padding: 0;
    padding-top: 13px;
    text-align: left;
    margin: 20px auto;
    display: inline-block;
  }
  li {
    padding: 0.15em 0 0.7em 0em;
    margin-bottom: 0.2em;
    text-indent: 2.2em;
    list-style: none;
    background-repeat: no-repeat;
    background-image: url(${CheckCircleSolid});
    background-size: 24px;
    font-size: 18px;
    font-weight: 400;
    @media (max-width: 320px) {
      font-size: 16px;
      background-size: 22px;
    }
  }
`;

const KeyPoints = props => {
  return (
    <KeyPointsStyled {...props}>
      <ul>
        <li>{i18n.t('KeyPoints..Fly now, pay later')}</li>
        <li>{i18n.t('KeyPoints..Spread the cost up to 10 months')}</li>
        <li>{i18n.t('KeyPoints..Personal travel assistant')}</li>
      </ul>
    </KeyPointsStyled>
  );
};

export { KeyPoints };

import { gql } from '@apollo/client';

export const FlightScalarFieldsFragment = gql`
  fragment FlightScalarFieldsFragment on Flight {
    id
    price
    currency
    source
    token
    passengerIdentityDocuments
    deepLink
  }
`;

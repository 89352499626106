import 'antd/lib/auto-complete/style/index.css';
import 'antd/lib/select/style/index.css';
import 'antd/lib/tooltip/style/index.css';

import React, { Component } from 'react';
import { AutoComplete } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Highlighter from 'react-highlight-words';
import PropTypes from 'prop-types';
import { css } from 'emotion/macro';
import {
  faPlaneDeparture,
  faGlobe,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons';
import { listUKAirports, listUKCities } from './cities_UK_only_GB.autogen.js';
import { listAirports, listCities } from './cities_UK.autogen.js';
import {
  AirportPlaceholder,
  highlightStyle,
  optClassCity,
  optClassAirport,
} from './styles';
import {
  MIN_SEARCH_CHARS,
  LOCATION_PICKER_MAX_RESULTS,
} from '../../constants/constants';

class LocationPicker extends Component {
  constructor(props) {
    super(props);
    const value = props.value || '';

    this.state = {
      fromOptions: props.initialOptions || [],
      searchString: '',
      value,
    };

    this.icons = {
      faPlaneDeparture,
      faBuilding,
      faGlobe,
    };
    const { isOnlyUK } = this.props;
    this.listAirports = (isOnlyUK && listUKAirports) || listAirports;
    this.listCities = (isOnlyUK && listUKCities) || listCities;
  }

  getFromOptions = (list, searchString = []) => {
    const { highlightClassName } = this.props;

    return list.map(airport => {
      let optClass =
        airport.icon === 'faPlaneDeparture' ? optClassAirport : optClassCity;
      return (
        <AutoComplete.Option key={airport.key} label={airport.label}>
          <FontAwesomeIcon
            icon={this.icons[airport.icon]}
            size="sm"
            fixedWidth
            className={optClass}
          />
          <span>
            <Highlighter
              highlightClassName={highlightClassName}
              searchWords={searchString}
              textToHighlight={airport.label}
            />
          </span>
        </AutoComplete.Option>
      );
    });
  };

  airportSort = (a, b, field = 'cityBase') => {
    return (
      1024 * (parseInt(a[field], 10) - parseInt(b[field], 10)) +
      a.label.localeCompare(b.label)
    );
  };

  airportFilter = value => {
    let _value = value.toUpperCase();
    const list = this.listAirports.filter(
      a => a.label.toUpperCase().indexOf(_value) >= 0
    );

    return list.sort(this.airportSort);
  };

  handleOnChange = value => {
    const name = this.props.name;
    const { onChange } = this.props;

    if (typeof value === 'undefined') {
      this.setState({ propsValue: '' });
      this.props.setFieldsValue(name, '');
    }

    if (onChange) {
      onChange(value);
    }
  };

  componentWillReceiveProps = nextProps => {
    // Should be a controlled component.
    if ('value' in nextProps) {
      const value = nextProps.value;
      this.setState({ value });
      if (value) {
        if (value.key) {
          this.setState({ propsValue: value.key });
        } else {
          this.setState({ propsValue: value });
          let opt = this.listAirports.filter(loc => loc.key === value);
          if (opt.length > 0) {
            this.setState({ fromOptions: this.getFromOptions(opt) });
          } else if (this.listCities.has(value)) {
            this.setState({
              fromOptions: this.getFromOptions([this.listCities.get(value)]),
            });
          }
        }
      } else if (value === '') {
        this.setState({ propsValue: '' });
      }
    }
  };

  componentDidMount() {
    this.setState({ value: this.getInitialLabel(this.state.value) });
  }

  handleOnSearch = value => {
    if (value.length >= MIN_SEARCH_CHARS) {
      let normalizedValue = value.toUpperCase();
      let list = this.listAirports.filter(
        a => a.label.toUpperCase().indexOf(normalizedValue) >= 0
      );
      list = list.sort(this.airportSort).slice(0, LOCATION_PICKER_MAX_RESULTS);
      let scities = list.map(c => c.city);
      let c2 = new Set();
      scities.forEach(c => {
        c2.add(c);
      });
      var fin = [];
      c2.forEach(c => {
        this.listCities.has(`C_${c}`) &&
          fin.push(this.listCities.get(`C_${c}`));

        let allCityAirports = list.filter(airport => airport.city === c);
        fin.push(...allCityAirports);
      });
      let fromOptions = this.getFromOptions(fin, value.split(' '));

      this.setState(() => ({
        fromOptions: fromOptions,
      }));
    }
  };

  handleOnBlur = () => {
    if (this.state.value) {
      if (this.state.fromOptions.length > 0) {
        this.props.setFieldsValue(
          this.props.name,
          this.state.fromOptions[0].key
        );
      } else {
        this.props.setFieldsValue(this.props.name, '');
      }
    }
  };

  getInitialLabel = value => {
    if (value) {
      let opt = this.listAirports.find(el => el.key === value);
      if (!opt) {
        opt = this.listCities.get(value);
      }
      if (opt && opt.label) {
        return opt.label;
      }
    } else {
      return '';
    }
  };

  render() {
    const { disabled } = this.props;
    const { fromOptions, value } = this.state;
    const label = this.getInitialLabel(value);
    
    return (
      <AutoComplete
        style={{cursor: 'pointer'}}
        disabled={disabled}
        showSearch={true}
        onClick={(e) => e.target.setSelectionRange(0,999)}
        onSearch={this.handleOnSearch}
        onChange={this.handleOnChange}
        onSelect={this.handleOnChange}
        // onClick={(e) => e.target.select()}
        // onFocus={(e) => setTimeout(e.target.select.bind(e.target), 20)}
        onFocus={(e) => e.target.setSelectionRange(0,999)}
        notFoundContent={<span>please type at least 3 letters</span>}
        allowClear={false}
        placeholder={
          <AirportPlaceholder>{'Airport or city'}</AirportPlaceholder>
        }
        // eslint-disable-next-line react/no-children-prop
        children={fromOptions}
        value={label || value}
        onBlur={this.handleOnBlur}
        popupClassName={css`
          @media (min-width: 1000px) {
            min-width: 500px;
          }
        `}
      />
    );
  }
}

LocationPicker.propTypes = {
  airportIcon: PropTypes.object,
  cityIcon: PropTypes.object,
  highlightClassName: PropTypes.string,
  setFieldsValue: PropTypes.func.isRequired,
  'data-__meta': PropTypes.object,
  isOnlyUK: PropTypes.bool,
  disabled: PropTypes.bool,
};

LocationPicker.defaultProps = {
  airportIcon: faPlaneDeparture,
  cityIcon: faBuilding,
  disabled: false,
  highlightClassName: highlightStyle,
  isOnlyUK: false,
};

export default LocationPicker;

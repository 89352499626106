import React from 'react';
import { Flight } from 'generated/types';
import { normalize } from 'helpers/normalize';
import { useFlightsHandler } from './useFlightsHandler';

interface IFlightsContext {
  resetResults: () => void;
  priceFlights: Flight[];
  qualityFlights: Flight[];
  durationFlights: Flight[];
  isPriceLoading: boolean;
  isQualityLoading: boolean;
  isDurationLoading: boolean;
  isLoading: boolean;
}

const FlightsContext = React.createContext<IFlightsContext | null>(null);

export const FlightsProvider: React.FC = props => {
  const {
    resetResults,
    priceFlights,
    qualityFlights,
    durationFlights,
    isPriceLoading,
    isQualityLoading,
    isDurationLoading,
    isLoading,
  } = useFlightsHandler()

  const value = React.useMemo(() => ({
    resetResults,
    priceFlights,
    qualityFlights,
    durationFlights,
    isPriceLoading,
    isQualityLoading,
    isDurationLoading,
    isLoading,
  }), [
    normalize(priceFlights),
    normalize(qualityFlights),
    normalize(durationFlights),
    isPriceLoading,
    isQualityLoading,
    isDurationLoading
  ])

  return (
    <FlightsContext.Provider value={value} {...props} />
  );
};

export const useFlightsContext = () => {
  const context = React.useContext(FlightsContext);

  if (!context) {
    throw new Error("useFlightsContext must be inside FlightsProvider");
  }

  return context;
}

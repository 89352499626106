export const KIWI = {
  URL: 'https://api.skypicker.com/flights',
  DATE_FORMAT: 'DD/MM/YYYY',
  CHECK_FLIGHTS_URL: 'https://booking-api.skypicker.com/api/v0.1/check_flights',
};

// Pricing factors
export const FLYMBLE_PRICE_MARGIN = 0.98;
export const VIRTUAL_INTERLINING_MARGIN = 0.1;
export const TICKET_SERVICE_PRICE = 19.5;
export const PREMIUM_SUPPORT_PRICE = 8.5
// Flight ticket search parameters and limits.
export const REACT_APP_TICKET_DATE_LIMIT_DEPARTURE = 3;
export const REACT_APP_TICKET_LOWER_LIMIT = 100;
export const REACT_APP_TICKET_HIGHER_LIMIT = 3000;

// The amount of price margin that can be higher or lower.
export const PRICE_TEST_BANDWITH = 0.05;

export const PRICE_VARIANTS = {
  VARIANT_A: 'VARIANT_A',
  VARIANT_B: 'VARIANT_B',
  VARIANT_C: 'VARIANT_C',
  VARIANT_D: 'VARIANT_D',
  VARIANT_E: 'VARIANT_E',
  VARIANT_F: 'VARIANT_F',
  VARIANT_G: 'VARIANT_G',
  VARIANT_H: 'VARIANT_H',
  VARIANT_I: 'VARIANT_I',
};

export const DUFFEL_PRICE_MARGIN = 1.15;

export const PRICE_VARIANT_MARGINS = {
  // BATCH 1 --- since December 9th 2019
  VARIANT_A: 1.03,
  VARIANT_B: 1.08,
  VARIANT_C: 0.98,
  // BATCH 2 --- since January 6th 2020
  VARIANT_D: 0.98,
  VARIANT_E: 1.03,
  VARIANT_F: 0.93,
  // BATCH 3 --- since January 24th 2020
  VARIANT_G: 0.97,
  VARIANT_H: 1.03,
  VARIANT_I: 0.98,
};

export const PAYMENT_PLAN_GROUPS = {
  GROUP_REGULAR: 'GROUP_REGULAR',
  GROUP_EMAIL: 'GROUP_EMAIL',
};

export const PARTNERS = {
  KLARNA: 'klarna',
  FNPL: 'flynowpaylater',
  STRIPE: 'stripe',
};

export const BOOKING_TYPES = {
  FNPL_Flights: 'FNPL_Flights',
  STRIPE_Flights: 'STRIPE_Flights',
  STRIPE_Klarna: 'STRIPE_Klarna',
  STRIPE_Affirm: 'STRIPE_Affirm',
  STRIPE_Unknown: 'STRIPE_Unknown',
};

export const DISCOUNT_CODES = {
  FLYMBLE20: 20,
  REVIEW40: 40,
  FRIENDS20: 20,
  VIP20: 20,
  HAPPY15: 15,
  KAS40: 40,
  COMEBACK25: 25,
  FLYAGAIN25: 25,
  NOVEMBER20: 20,
  SALAMI40: 40,
  SMS20: 20,
  GIFT20: 20,
  LUCKY33: 33,
  START2020: 20,
  WELCOME25: 25,
  WELCOME20: 20,
  WELCOME15: 15,
  MAIL20: 20,
  PAYLATER20: 20,
};

export const KIND_OF_TRIP = {
  ROUND: 'round',
  ONE_WAY: 'oneway',
};

export const SEARCH_MOBILE_MODE = {
  DARK: 'dark',
  LIGHT: 'light',
};

export const BAGGAGE_OPTIONS = {
  CASE_2_FREE_CABIN_AND_PERSONAL: 'CASE_2_FREE_CABIN_AND_PERSONAL',
  CASE_5_PAID_CABIN_NO_PERSONAL: 'CASE_5_PAID_CABIN_NO_PERSONAL',
  CASE_4_FREE_PRIORITY_BOARDING_ONLY: 'CASE_4_FREE_PRIORITY_BOARDING_ONLY',
  CASE_3_FREE_PERSONAL_PAID_PRIORITY: 'CASE_3_FREE_PERSONAL_PAID_PRIORITY',
  CASE_1_RYAN_AIR: 'CASE_1_RYAN_AIR',
  CASE_6_CABIN_BAG_ONLY: 'CASE_6_CABIN_BAG_ONLY',
  CASE_7_FREE_CHECKED: 'FREE_CHECKED',
  CASE_8_PAID_CHECKED: 'PAID_CHECKED',
  CASE_9_NO_CHECKED_AVAILABLE: 'NO_CHECKED_AVAILABLE',
  CASE_10_PERSONAL_ITEM_ONLY: 'CASE_10_PERSONAL_ITEM_ONLY',
  UNITED_AIRLINES: 'UNITED_AIRLINES',
};

export const BAGGAGE_INITIAL_SELECTED = {
  CASE_2_FREE_CABIN_AND_PERSONAL: { isPersonalFree: true, isCabinFree: true },
  CASE_5_PAID_CABIN_NO_PERSONAL: { isPersonalFree: false, isCabinFree: false },
  CASE_4_FREE_PRIORITY_BOARDING_ONLY: {
    isPersonalFree: true,
    isCabinFree: true,
  },
  CASE_3_FREE_PERSONAL_PAID_PRIORITY: {
    isPersonalFree: false,
    isCabinFree: false,
  },
  CASE_1_RYAN_AIR: { isPersonalFree: false, isCabinFree: false },
  CASE_6_CABIN_BAG_ONLY: { isPersonalFree: false, isCabinFree: true },
  CASE_7_FREE_CHECKED: true,
  CASE_8_PAID_CHECKED: false,
  CASE_9_NO_CHECKED_AVAILABLE: false,
  CASE_10_PERSONAL_ITEM_ONLY: true,
  UNITED_AIRLINES: false,
};

export const MIN_SEARCH_CHARS = 3;
export const LOCATION_PICKER_MAX_RESULTS = 30;

export const PURCHASED_BAGGAGE_TYPES = {
  personal_Item_And_Cabin: 'Personal Item And Cabin',
  cabin_Bag: 'Cabin bag',
  checked_Bag: 'Checked bag',
};

export const ROOM_CAPACITY = {
  ADULTS_ONLY: 'ADULTS_ONLY',
  ONE_CHILD: 'ONE_CHILD',
  INFANT_AND_CHILD: 'INFANT_AND_CHILD',
};

export const MINIMUM_BOOKING_PERIOD = 7;

// (old) pricing parameters
export const FLYMBLE_BOOKING_FEE = 0;


export const REQUIRED_COUNTRY = 'UK_PL';

export const CABIN_CLASS =  {
  ECONOMY: "Economy",
  PREMIUM_ECONOMY: "EconomyPremium",
  BUSINESS:  "Business",
  FIRST_CLASS: "FirstClass"
}

export const CURRENCY = {
  GBP: 'GBP',
  USD: 'USD'
}
import React, { useContext, useState, useEffect } from 'react';
import ConfirmationPage from './SummarySectionConfirmation';
import AppContext from 'Context/AppContext';
import PropTypes from 'prop-types';
import ConfirmationButtons from './SummarySectionConfirmation/ConfirmationButtons';
import { trackConfirmationPageButton } from 'Utils';
import FlyNowPayLaterBenefitsBanner from '../components/ConfirmationPartnerSection/ConfirmationPartnerSectionUK';
import { CheckoutFormsUKWrapper } from '../CheckoutForms/CheckoutFormsUK/styles';
import CoreView from '../CoreView';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { RedirectElement } from 'components/RedirectElement';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { trackDirectPayment_VisitConfirmationPage } from 'Utils';
import { BOOKING_TYPES } from '../../constants/constants';

const ConfirmationPageContainerProxy = ({ isPaymentPage }) => {
  const context = useContext(AppContext);
  const { paymentPlanPartnerSelected, typeBookingContext } = context;

  useEffect(() => {
    typeBookingContext === BOOKING_TYPES.STRIPE_Flights &&
      trackDirectPayment_VisitConfirmationPage();
  }, []);

  const [isVisibleState, setIsVisibleState] = useState(true);
  const [redirectToConfirmationPageState, setRedirectToConfirmationPageState] =
    useState(false);
  const [redirectToCheckoutPageState, setRedirectToCheckoutPageState] =
    useState(false);
  const [redirectToThankYouPageState, setRedirectToThankYouPageState] =
    useState(false);

  // That means that this function needs to be called on the confirmation page
  function handleOnSubmitConfirmation() {
    trackConfirmationPageButton();
    if (window.location.pathname.indexOf('confirmation/packages') !== -1) {
      setRedirectToThankYouPageState(true);
    } else {
      setIsVisibleState(!isVisibleState);
      setRedirectToConfirmationPageState(!redirectToConfirmationPageState);
    }
  }

  function handleEditFormDetails() {
    setRedirectToCheckoutPageState(!redirectToCheckoutPageState);
  }

  return (
    <>
      {!isPaymentPage ? (
        <CoreView>
          <CheckoutFormsUKWrapper>
            <Form>
              <ConfirmationPage
                isVisible={isVisibleState}
                data={context.firstCheckoutPageFormValuesContext}
                isPayInFull={
                  typeBookingContext === BOOKING_TYPES.STRIPE_Flights
                }
              >
                <FlyNowPayLaterBenefitsBanner
                  // isVisible={paymentPlanPartnerSelected === PARTNERS.FNPL}
                  isVisible={false}
                />
                <ConfirmationButtons
                  handleEditFormDetails={() => handleEditFormDetails()}
                  onConfirmButtonClick={() => handleOnSubmitConfirmation()}
                  isLoading={false}
                  paymentPlanPartnerSelected={paymentPlanPartnerSelected}
                  isConfirmationButtonsVisible={true}
                />
              </ConfirmationPage>
            </Form>
          </CheckoutFormsUKWrapper>
          <RedirectElement
            destination="/pay-booking"
            isAllowedRedirect={redirectToConfirmationPageState}
          />
          <RedirectElement
            destination="/checkout"
            isAllowedRedirect={redirectToCheckoutPageState}
          />
          <RedirectElement
            destination="/packages-pay-booking"
            isAllowedRedirect={redirectToThankYouPageState}
          />
        </CoreView>
      ) : (
        <ConfirmationPage
          isVisible={isVisibleState}
          data={context.firstCheckoutPageFormValuesContext}
          isPayInFull={typeBookingContext === BOOKING_TYPES.STRIPE_Flights}
        />
      )}
    </>
  );
};

ConfirmationPageContainerProxy.propTypes = {
  isPaymentPage: PropTypes.bool.isRequired,
};

const ConfirmationPageContainer = () => (
  <ErrorBoundary errorLocation="Confirmation" redirect={true}>
    <ConfirmationPageContainerProxy />
  </ErrorBoundary>
);

export { ConfirmationPageContainer };
export default ConfirmationPageContainer;

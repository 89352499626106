import React from 'react';
import styled from 'styled-components';
// import { CovidBanner } from '../../CovidBanner/index.js';
import backgroundImageBeach from './beach-midsize.jpeg';

const grayBackgroundColor = '#f6f6f6';
const subTitleColor = '#696969';

export const HeroSectionMobile = () => {
  return (
    <HeroSectionMobileWrapper>
      <div className="backgroundImage">
        <div className="heroTitles">
          <h1>Fly now, pay later</h1>
          <h4>Spread the cost from 0% APR</h4>
          {/* <CovidBanner /> */}
        </div>
      </div>
      {/* <TravelCategories /> */}
    </HeroSectionMobileWrapper>
  );
};

const HeroSectionMobileWrapper = styled.div`
  // background-color: ${grayBackgroundColor};
  padding-top: 0px;

  .backgroundImage {
    /* background-image: url('assets/beachOptimized.jpg'); */
    // background-image: url('${backgroundImageBeach}');
    /* min-height: 180px;
    background-size: 100% 123%; */

    min-height: 160px;
    background-size: 115% 150%;
    background-position-y: -10px;

  .heroTitles {
    text-align: left;
    padding-bottom: 28px;
    padding-top: 52px;
    padding-left: 18px;
    padding-right: 20px;
    font-family: 'Public Sans' sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0.5px;
    text-align: left;

    h1 {
      color: white;
      margin: 0px;
      font-family: 'Public Sans' sans-serif;
      font-size: 38px;
      font-style: normal;
      font-weight: 700;
      line-height: 45px;
      letter-spacing: 0.5px;
      text-align: left;


    }

    h2 {
      color: ${subTitleColor};
      font-size: 17px;
      font-weight: 400;
      margin-top: 17px;
      margin-bottom: 5px;
    }

    h4 {
     
      margin-top: 12px;
      font-family: 'Public Sans' sans-serif;
      font-size: 17px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0px;
      text-align: left;
      color: white;

    }
  }

  }

  @media (min-width: 1000px) {
    display: none;
  }
`;

import axios from 'axios';
import firebase from 'config/firebaseConfig.js';
import { metaConversionApi } from 'Services/analytics/metaConversionApi';
import { trackThankYouPage } from 'Utils/facebookFunctions';
import { makeAcceptedAWS } from '../../Services/CheckoutServices/addToAcceptedAWS';

const newDispersal = firebase.functions().httpsCallable('addMessage');

export const processBooking = async (
  dispersalToken,
  selectedFlightTicket,
  firstCheckoutPageFormValuesContext,
  partnerBookingReferenceContext,
  extract,
  goThankYouPage,
  orderSummaryObject,
  orderSummary,
  typeBookingContext,
  goToErrorPage
) => {
  var checkoutEventName = process.env.REACT_APP_BOOKING_SOURCE;
  // AWS
  axios
    .post('https://flymble.com/rest/book', {
      payload: {
        deepLink: selectedFlightTicket.deep_link,
        orderSummary,
        ...firstCheckoutPageFormValuesContext,
        tripExtract: extract,
        orderSummaryObject: orderSummaryObject,
      },
      source: checkoutEventName,
    })
    .then((_response) => {
      // for now 2019-03-12 response do not return correct bookingId
    });

  const flymbleUserId = localStorage.getItem('flymbleUserId') || '';
  const flymbleApplicationId =
    window.localStorage.getItem('applicationId') || '';

  try {
    const result = await makeAcceptedAWS({
      applicationId: flymbleApplicationId,
      userId: flymbleUserId,
      typeBooking: typeBookingContext
    });

    console.log({RESULTING_RESP: result});

    trackThankYouPage(orderSummaryObject.Flymble_Price);
    window.mixpanel.people.track_charge(orderSummaryObject.Flymble_Price);

    // Function that calls the addMessage function on the back-end resulting in new database entry in the OLD (Canaveral) firebase.
    newDispersal({
      booking: firstCheckoutPageFormValuesContext,
      tripExtract: extract,
      deepLink: selectedFlightTicket.deep_link,
      orderSummaryObject: orderSummaryObject,
      source: checkoutEventName,
      token: dispersalToken,
      reference: partnerBookingReferenceContext,
    })
      .then((result) => {
        console.debug('[processBooking] NewBooking Successful', result);
      })
      .catch((error) => {
        console.debug('[processBooking] NewBooking Error', error);
      });
      
      console.log({stat: result});

      if (result && (result.status === 200 || result.status === 204)) {
        metaConversionApi({ eventName: 'Purchase' });
        goThankYouPage();
      } else {
        goToErrorPage();
      }
  } catch (e) {
    console.log(e);
  }
};

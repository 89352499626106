import React, { useState } from 'react';
import { signUpUserEmailCollect } from '../../../../Utils';
import Popup from '../../../../components/Popup';

interface SoonPopupProps {
  closePopup: () => void;
  selectedTab: string;
}

const SoonPopup = (props: SoonPopupProps) => {
  const { closePopup, selectedTab} = props;
  const [email, setEmail] = useState(null);
  const soonType = selectedTab === 'Hotels' ? "hotels" : 'staycations';

  const subscribeOrSaveEmail = (lastSearch: any, email: any) => {
    const flymbleUserId = localStorage.getItem('flymbleUserId');
    const source = `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`;
    signUpUserEmailCollect(lastSearch, flymbleUserId, email, source);
  }

  return (
    <Popup
      title={`We are launching ${soonType} soon!`}
      subtitle="Be the first to hear when we launch. Sign up for our newsletter!"
      thankYouText={`You will get notified when we launch ${soonType}!`}
      emailSignup={true}
      handleSignup={() => subscribeOrSaveEmail(`${soonType} interrest form`, email)}
      closePopup={closePopup}
      email={email as any}
      setEmail={setEmail as any}
      />
  );
};

export default SoonPopup;

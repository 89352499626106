import React, {useContext} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { darkBlue2, white, lightGrey2, purple, darkBlueLighter2} from '../../../styles/colors';
import AppContext from 'Context/AppContext';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const DesktopProgressWrapper = styled.div`
  position: relative;
  background-color: ${white};
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  letter-spacing: 0px;
  margin-bottom: 40px;
  padding: 21px;
  box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
  border-radius: 5px;
  @media(max-width: 999px) {
    display: none;
  }
`
const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  position: relative;
`
const Circle = styled.div<{
  inactive?: any;
}>`
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  color: ${(props: any) => props.inactive ? darkBlueLighter2 : white};
  background-color: ${(props: any) => props.inactive ? lightGrey2 : purple};
  margin-bottom: 9px;
  z-index: 10;
`

const Title = styled.div`
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  color: ${darkBlue2};
`
const Line = styled.div<{
  inactive?: any;
}>`
  position: relative;
  left: 85px;
  top: -22px;
  background-color: ${(props : any) => props.inactive ? lightGrey2 : purple};
  height: 2px;
  width: 170px;
  z-index: 9;
  @media(min-width: 1100px) {
    width: 195px;
  }

`

const DesktopProgress = () => {

  const {checkoutProgress} = useContext(AppContext);
  const appContext = useContext(AppContext);
  const {typeBookingContext} = appContext;
  let isPayInFull = false;
  if(typeBookingContext === "STRIPE_Flights") {
    isPayInFull = true;
  }

  return (
    <DesktopProgressWrapper>
      <StepWrapper>
        <Circle><FontAwesomeIcon icon={faCheck as IconProp} className="faIcon" /></Circle>     <Line />
        <Title>Flight results</Title>
      </StepWrapper>
 
      <StepWrapper>
        <Circle>2</Circle><Line inactive={!(checkoutProgress.stepNumber > 2)} />
        <Title>Details</Title>
      </StepWrapper>
      
      <StepWrapper>
        <Circle inactive={!(checkoutProgress.stepNumber == 3)}>3</Circle><Line inactive={!(checkoutProgress.stepNumber == 3)} />
        <Title>{isPayInFull ? "Confirm Details" : "Credit check"}</Title>
      </StepWrapper>
      
      <StepWrapper>
        <Circle inactive={!(checkoutProgress.stepNumber == 4)}>4</Circle>
        <Title>Booking complete</Title>
      </StepWrapper>
    </DesktopProgressWrapper>
  )
}

export default DesktopProgress;
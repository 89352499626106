import React, { useContext, useEffect, useState } from 'react';

import { PARTNERS } from 'Utils';
import DisplayPaymentInformation from './Display';
import { BOOKING_TYPES } from '../../../constants/constants';
import { TICKET_SERVICE_PRICE, PREMIUM_SUPPORT_PRICE } from '../../../constants/constants';

import { normalize } from 'helpers/normalize';
import { getTotalBaggagesSum } from 'helpers/getTotalBaggagesSum';

import AppContext from 'Context/AppContext';
import { useBaggagesContext } from 'Context/BaggagesProvider/BaggagesProvider';

export const PaymentInformation = () => {
  const { baggages, clearFreeBaggages } = useBaggagesContext();
  const context = useContext(AppContext);
  useEffect(() => {
    clearFreeBaggages();
  }, []);

  const {
    paymentPlanPartnerSelected,
    lastSearch: { noMonths, noAdults, noChildren, noInfants, cabin },
    selectedFlightTicket,
    discountCode: { code, value },
    getBaggagePiecesAndPrices,
    purchasedBaggage,
    typeBookingContext,
    isAddedTicketServiceContext,
    isAddedPremiumSupportContext
  } = context;
  console.log(isAddedTicketServiceContext);
  // Unpack added baggage pieces.

  // The flymble price what we received from KIWI and then with our adjustment.
  // This adjustment is done elsewhere in the application.
  const flymblePrice = selectedFlightTicket.price;

  //
  const [totalBaggagePrice, setTotalBaggagePrice] = useState(0);
  const [baggagePricesByType, setBaggagePricesByType] = useState({});
  const [baggagesCount, setBaggagesCount] = useState({});
  // Calculating the total number of passengers
  const noPassengers = noAdults + noChildren + noInfants;

  // Apply discount code to the final price ().
  const discountValue = value || 0;

  /*
  The total booking price charged by flymble. This means: 
  (1) Price for all passengers, 
  (2) Including margin [not calculated here], 
  (3) Optional baggage prices, 
  (4) Optional discount code.
  */
  const costAddedTicketService = isAddedTicketServiceContext
    ? TICKET_SERVICE_PRICE * noPassengers
    : 0;

  const costAddedPremiumSupport = isAddedPremiumSupportContext ? PREMIUM_SUPPORT_PRICE : 0;
  const totalFlymbleIncludingBaggage =
    flymblePrice.toFixed(2) -
    discountValue +
    totalBaggagePrice +
    costAddedTicketService + costAddedPremiumSupport;

  // The Monthly price, for the whole booking.
  // August 5th 2019: We currently display the price per month as "from".
  let monthlyPriceIncludingBaggage = totalFlymbleIncludingBaggage / noMonths;

  useEffect(() => {
    if (purchasedBaggage) {
      setTimeout(() => {
        clearTimeout();
        setTotalBaggagePrice(getBaggagePiecesAndPrices().totalBaggagePrice);
      }, 700);
      setBaggagePricesByType(getBaggagePiecesAndPrices().pricesByType[0]);
      setBaggagesCount(getBaggagePiecesAndPrices().baggagePiecesCount);
    }
  }, [normalize(purchasedBaggage)]);

  useEffect(() => {
    if (!Object.keys(baggages).length) {
      return;
    }

    console.log(purchasedBaggage);

    const totalBaggagesSum = getTotalBaggagesSum(baggages);

    setTotalBaggagePrice(totalBaggagesSum);
  }, [normalize(baggages)]);

  const isPayInFull = typeBookingContext === BOOKING_TYPES.STRIPE_Flights;
  // const isPayInFull = true;
  // const isVisibleDisplayPaymentInformation = window.location.pathname.indexOf('pay-booking') === -1 ? true : isPayInFull;
  const isVisibleDisplayPaymentInformation = true;

  return (
    isVisibleDisplayPaymentInformation && (
      <>
        <DisplayPaymentInformation
          isAddedTicketServiceContext={isAddedTicketServiceContext}
          isAddedPremiumSupportContext={isAddedPremiumSupportContext}
          costAddedPremiumSupport={PREMIUM_SUPPORT_PRICE}
          costAddedTicketService={costAddedTicketService}
          isPayInFull={isPayInFull}
          sumBaggagePrices={totalBaggagePrice}
          monthlyPriceIncludingBaggage={monthlyPriceIncludingBaggage}
          totalFlymbleIncludingBaggage={totalFlymbleIncludingBaggage}
          discountValue={discountValue}
          discountCode={code}
          noPassengers={noPassengers}
          noMonths={noMonths}
          purchasedBaggage={baggagePricesByType}
          airfareAllPassengers={flymblePrice}
          baggagesCount={baggagesCount}
          isVisibleInformationCircle={
            paymentPlanPartnerSelected !== PARTNERS.KLARNA
          }
          cabinClass={cabin}
        />
      </>
    )
  );
};

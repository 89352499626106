import React from 'react';
import PropTypes from 'prop-types';
import LogoFNPL from 'assets/images/fnpl-logo.svg';
import styled from 'styled-components';

const PaymentBannerWrapper = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;
  padding-top: 10px;
  background-color: white;
  border: 1px solid #e4eaf0;
  margin-top: 25px;
  margin-bottom: 45px;
  color: black;
  @media (min-width: 700px) {
    padding: 12px 38px;
    margin-top: 40px;
  }

  h3 {
    font-size: 22px;
  }

  & > ul {
    padding-left: 0;
    list-style-type: none;
  }

  & > ul > li {
    margin: 6px 0;
    list-style: none;
  }

  li::before {
    display: inline-block;
    height: 1.8em;
    width: 1em;
    background-size: inherit;
    background-repeat: no-repeat;
    padding-left: 2em;
    vertical-align: -8px;
    color: green;
    list-style-type: none;
  }

  .banner {
    text-align: left;
    img {
      margin-top: 5px;
      max-height: 45px;
      max-width: 190px;
    }
  }
`;

const PaymentBanner = ({ isVisible }) => {
  return (
    isVisible && (
      <PaymentBannerWrapper>
        <h3>The booking is almost yours! One more step to go.</h3>
        <p>
          <b>Important</b> - to complete your booking you will now review the
          agreement provided by our partner FlyNowPayLater.
        </p>
        <div className="banner">
          <img src={LogoFNPL} alt='UK partner logo' />
        </div>
        <ul>
          {/* <li>Spread the cost over 3, 6 or 12 months</li> */}

          <li>✓ Secure and instant checkout</li>
          <li>✓ Over 200.000 happy customers</li>
        </ul>
      </PaymentBannerWrapper>
    )
  );
};

PaymentBanner.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default PaymentBanner;

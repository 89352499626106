import React from 'react';
import styled from 'styled-components';

interface IconProps {
  icon: string;
  size?: number;
  marginRight?: number;
}

const IconImg = styled.img<{
  size: any;
  marginRight: any;
}>`
  width: ${(props: any) => props.size ? `${props.size}px`: '30px'};
  height: ${(props: any) => props.size ? `${props.size}px`: '30px'};
  margin-right: 14px;
  @media(min-width: 1000px) {
    margin-right: ${(props: any) => props.marginRight ? `${props.marginRight}px`: '22px'};
  }
`

const Icon = (props: IconProps) => {
  const { icon, size, marginRight } = props;

  return <IconImg src={icon} size={size} marginRight={marginRight} />
}

export default Icon;
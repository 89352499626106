import React from 'react';
import { useHistory } from 'react-router-dom';
import { trackSelectFlightTicket } from '../../../../Utils';
import { useAppContext } from '../../../../Context/AppContext';
import { useCurrencyContext } from '../../../../Context/CurrencyProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { VARIANT_OPTIMAL_MONTHS } from '../../../../constants/env';
import { BottomRowWrapper } from './styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface BottomRowProps {
  viewDetails: boolean;
  toggleDetails: () => void;
  theFlightTicket: any;
  flymblePrice: string;
}
const BottomRow = (props:  BottomRowProps) => {
  const { flymblePrice, theFlightTicket, toggleDetails, viewDetails } = props;
  const { lastSearch: { noAdults, noChildren, noInfants }, setSelectedFlightTicket } = useAppContext();
  const { currencySymbol } = useCurrencyContext();
  const history = useHistory();
  const currentBookingValue = parseFloat(flymblePrice) * (noAdults + noChildren + noInfants);

  const selectTicket = (ticket: any) => {
    setSelectedFlightTicket(ticket);
    trackSelectFlightTicket();
    window.localStorage.setItem('SelectedFlightResult', JSON.stringify(ticket));
    history.push({
      state: { theFlightTicket: ticket },
      pathname: '/payment-plan',
    });
  };
 
  return (
    <BottomRowWrapper className="bottomRowWrapperDisplay">  
      <div className="priceTag">
        <div className="bigprice">
          <span>From {currencySymbol}{(currentBookingValue / VARIANT_OPTIMAL_MONTHS).toFixed(2)}/mo</span>
        </div>
        <div className="smallprice">
          <span>Total: {currencySymbol}{currentBookingValue.toFixed(2)}</span>
        </div>
      </div>

      <div
        className="viewDetailsButton"
        onClick={() => toggleDetails()}
      >
        <FontAwesomeIcon className="viewDetails" icon={(viewDetails ? faChevronUp : faChevronDown) as IconProp}/>
      </div>
    
      <div className="selectFlightTicket">
        <span
          onClick={() => {
              selectTicket(theFlightTicket)
            
          }}
          id="selectFlightTicketButton"
        >
          Select
          <FontAwesomeIcon className="arrowRightIcon" icon={faArrowRight as IconProp} />
        </span>
      </div>
     
    </BottomRowWrapper>
    )
  }



export default BottomRow;

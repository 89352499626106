import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { darkBlueLighter, bgLightBlue2, darkBlue2, white, lightGrey3 } from '../../../../styles/colors';
import TravelDealsMosaic from './components/TravelDealsMosaic';


interface TravelDealsProps {
  setShowSoonPopup: Dispatch<SetStateAction<boolean>>;
}

const TravelDealsWrapper = styled.div`
  background-color: ${lightGrey3};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  width: 100%;
  padding-bottom: 140px;
  position: relative;
  @media(max-width: 999px) {
    padding-bottom: 63px;
  }
`
const WhiteTriangle = styled.div`
  background-color: ${white};
  width: 90px;
  height: 90px;
  position: absolute;
  top: -70px;
  transform: rotate(-45deg);
  border-radius: 8px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  @media(max-width: 999px){
    display: none;
  }
`

const Text = styled.div`
  margin-left: 5px;
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  width: 90%;
  max-width: 1122px;
  text-align: left;
`

const Title = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  color: ${darkBlue2};
  margin-top: 55px;
  margin-bottom: 5px;
`
const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${darkBlueLighter};
  margin-bottom: 20px; 
`
const TravelDeals = (props: TravelDealsProps) => {
  const { setShowSoonPopup } = props;
  return (
    <TravelDealsWrapper>
      <WhiteTriangle />
      <Title>Hot Deals</Title>
      <Subtitle>Escape the cold. Seek the sun.</Subtitle>
      <TravelDealsMosaic type='flights' />

      <Title>Staycations</Title>
      <Subtitle>Take a break. No need to fly.</Subtitle>
      <TravelDealsMosaic type='staycations' setShowSoonPopup={setShowSoonPopup} />
    </TravelDealsWrapper>
  )
}

export default TravelDeals;
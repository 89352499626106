import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import PaymentInformation from './components/PaymentInformation';
import { CheckoutPageWrapper, CheckoutPageContent } from './styles';
import {
  SideCollumnDesktop,
  InfoSection,
  Header,
  DesktopProgress,
  MobileProgress,
  FlightTicket
} from './components';
import AppContext from 'Context/AppContext';
import { withRouter } from 'react-router-dom';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { LoadingScreen } from '../components/LoadingScreen';
import { PackageInformationCheckout } from './PackagesCheckoutPage/PackageInformationCheckout/index.js';

const CoreView = ({ children, timeOutDelay = 0 }) => {
  const context = useContext(AppContext);
  const [isVisible, setIsVisible] = useState(false);
  const { selectedFlightTicket: selectedFlightTicketContext } = context;

  const initializeFlightTicket = () => {
    const { localStorage } = window;
    const localStorageFlightTicket = JSON.parse(
      localStorage.getItem('SelectedFlightResult')
    );

    if (selectedFlightTicketContext !== null) {
      localStorage.setItem(
        'SelectedFlightResult',
        JSON.stringify(selectedFlightTicketContext)
      );
      return selectedFlightTicketContext;
    } else if (localStorageFlightTicket) {
      context.setSelectedFlightTicket(localStorageFlightTicket);
      return localStorageFlightTicket;
    }
  };

  useEffect(() => {
    initializeFlightTicket();
    setTimeout(() => {
      clearTimeout();
      setIsVisible(true);
    }, timeOutDelay);
  }, []);

  if (
    window.location.pathname.indexOf('packages') !== -1 ||
    selectedFlightTicketContext
  ) {
    return (
      <ErrorBoundary>
        <CheckoutPageWrapper>
          <CheckoutPageContent>
            <FlightsView
              isVisible={window.location.pathname.indexOf('packages') === -1}
            />
           
            <PackagesView
              isVisible={window.location.pathname.indexOf('packages') !== -1}
            />
            <div className="checkoutBody">{isVisible && children}</div>
          </CheckoutPageContent>
        </CheckoutPageWrapper>
      </ErrorBoundary>
    );
  } else return <LoadingScreen />;
};

const FlightsView = ({ isVisible }) =>
  isVisible && (
    <>
    <div className="topSection">
      <Header />
      <MobileProgress />
      <DesktopProgress />
      <InfoSection />
      <FlightTicket />
      <div className="mobileOnly">
        <PaymentInformation />
      </div>
    </div>
      
      <SideCollumnDesktop>
        <PaymentInformation />
      </SideCollumnDesktop>
    </>
  );

const PackagesView = ({ isVisible }) =>
  isVisible && (
    <>
      <PackageInformationCheckout />
      <SideCollumnDesktop>
        <div />
      </SideCollumnDesktop>
    </>
  );

CoreView.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  location: PropTypes.object.isRequired,
  timeOutDelay: PropTypes.number,
};

const RouteredCoreView = withRouter(CoreView);

export default RouteredCoreView;

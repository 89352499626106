import styled from 'styled-components';
import { maxContainerWidth } from '../../../../styles/constants';

export const ComponentStyled = styled('div')`
  max-width: ${maxContainerWidth};
  margin: 0 auto;
  padding: 20px 40px;

  .blog-article-header {
    border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 20px 0;
    margin: 15px 0;

    .blog-article-header_author {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      & > img {
        height: 50px;
        width: 50px;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    & > p {
      font-size: 20px;
      font-weight: 300;
      line-height: 1.6 !important;
      opacity: 0.9;
      text-align: left;
      @media (max-width: 1000px) {
        font-size: 18px;
      }

      @media (max-width: 600px) {
        font-size: 16px;
      }
    }

    & > h1 {
      font-size: 25px;
      line-height: 1.2;
      color: #000;
      margin-bottom: 10px;
      font-weight: 500;
      @media (max-width: 1000px) {
        font-size: 23px;
      }

      @media (max-width: 600px) {
        font-size: 21px;
      }
    }
  }

  & img {
    height: auto;
    width: 100%;
  }
`;

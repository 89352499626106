import React from 'react';
import styled from 'styled-components';
import { blue6, darkBlue, purple } from '../../../styles/colors';
import { useCurrencyContext } from '../../../Context/CurrencyProvider';

interface PriceDetailsFooterProps {
  minInstalments: number;
  maxInstalments: number;
  price: number;
  instalmentsPrice: number;
}

const FooterWrapper = styled.div`
  font-family: 'Public Sans';
  font-style: normal;
  letter-spacing: 0px;
  padding-top: 17px;
  border-top: 1px solid ${blue6};
  margin-bottom: 15px;
`
const Instalments = styled.div`
  color: ${purple};
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  margin-bottom: 15px;
`
const Price = styled.div`
  color: ${darkBlue};
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  display: flex;
  justify-content: space-between;
`


const PriceDetailsFooter = ({ minInstalments, maxInstalments, price, instalmentsPrice }: PriceDetailsFooterProps) => {
  const { currencySymbol } = useCurrencyContext();
  return (
    <>
      <FooterWrapper>
        <Instalments>Pay in {minInstalments} to {maxInstalments} instalments</Instalments>
        <Price>
          <span>From </span>
          <span>{currencySymbol}{instalmentsPrice}/mo</span>
        </Price>
      </FooterWrapper>
      <span>You can select your payment plan at the end of the checkout. T&Cs apply.</span>
    </>
    )
}

export default PriceDetailsFooter;
import { gql } from "@apollo/client";

import { PlaceFragment } from "apollo/client/place/PlaceFragment";
import { SegmentScalarFieldsFragment } from "apollo/client/segment/SegmentScalarFieldsFragment";
import { CarrierScalarFieldsFragment } from "apollo/client/carrier/CarrierScalarFieldsFragment";

export const SegmentFragment = gql`
  fragment SegmentFragment on Segment {
    ...SegmentScalarFieldsFragment
    origin {
      ...PlaceFragment
    }
    destination {
      ...PlaceFragment
    }
    carrier {
      ...CarrierScalarFieldsFragment
    }
  }
  ${PlaceFragment}
  ${CarrierScalarFieldsFragment}
  ${SegmentScalarFieldsFragment}
`;

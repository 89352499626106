import styled, {css} from 'styled-components';
// import { css } from 'emotion/macro';
import {
  darkBlue2,
  darkBlueLighter2,
  white
} from '../../../styles/colors';

const inputSize = 54;
const inputFontSize = 14;
const inputMarginLeft = 18;

export const DateRangeSpan = styled.span`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  @media (min-width: 700px) {
    .ant-input {
      padding: 4px 175px;
    }
  }
`;

export const controlHeight = css`
  height: ${inputSize}px;
`;

// This code styles the Searchform. It is exported to FrontSearch.js top keep
// the <form> as the outer element of the component.  
export const SearchFormStyle = css`
  font-family: 'Public Sans', sans-serif;
  width: 100%;

  @media (min-width: 1000px) {
    padding-bottom: 10px;
    padding-top: 10px;
    .searchFormGrid {
      display: grid;
      grid-template-areas:
        'flyingFrom flyingFrom flyingTo flyingTo dateFrom dateFrom searchButton';
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 8px;
    }
  }

  .datePickerGridMobile {
    grid-area: dateFrom;
  }
  .adultsNONE {
    grid-area: noAdults;
  }
  .childrenNONE {
    grid-area: noChildren;
  }
  .flyingFrom {
    grid-area: flyingFrom;
  }
  .flyingTo {
    grid-area: flyingTo;
  }

  .datePickerGridMobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 8px;
  }
  p {
    font-size: 14px;
    font-weight: 500;
  }

  /* antd form validation message */
  & div.ant-form-explain {
    display: none;
    cursor: pointer;
  }
  /* antd adjust form labels */
  & div.ant-select {
    font-size: 10px;
    cursor: pointer;
  }
  /* adjust placeholder position */
  & div.ant-select-selection__placeholder {
    top: 66%;
    font-size: ${inputFontSize}px;
    border: none;
    cursor: pointer;
  }
  & .ant-select-auto-complete.ant-select .ant-input {
    padding-top: 18px;
    height: ${inputSize}px;
    font-size: ${inputFontSize}px;
    background-color: ${white};
    border: none;
    cursor: pointer;
  }
  & .ant-select-auto-complete.ant-select .ant-select-selection__rendered,
  & .ant-select-selection__rendered {
    line-height: ${inputSize}px;
    border: none;
    cursor: pointer;
  }
  /* datepicker customization */
  & .ant-input {
    padding-left: ${inputMarginLeft - 2}px;
    height: ${inputSize}px;
    padding-top: 14px;
    border: none;
    background-color: ${white};
    border: none;
    color: ${darkBlueLighter2};
    font-variant-numeric: normal;
    font-variant: normal;
    font-feature-settings: normal;
    cursor: pointer;
  }
  & .ant-calendar-picker-input.ant-input {
    padding-top: 20px;
    border: none;
    cursor: pointer;
  }
  & .ant-select-selection--single {
    height: ${inputSize}px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  &
    .ant-select-auto-complete.ant-select
    .ant-select-selection__placeholder {
    margin-left: ${inputMarginLeft}px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 400;
    border: none;
    cursor: pointer;
  }

  & input {
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  & input::placeholder {
    color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
  }
  & .ant-select-combobox .ant-select-search__field__wrap,
  & .ant-select-auto-complete.ant-select .ant-select-search--inline {
    height: ${inputSize}px;
    border: none;
    cursor: pointer;
  }
  & div.ant-select-selection__rendered {
    top: 12%;
    font-size: 14px;
    span {
      padding-left: 26px;
      border: none;
      cursor: pointer;
    }
    cursor: pointer;
  }
  /* passengers customization */
  & .ant-legacy-form-item-control {
    line-height: unset;
    border: none;
    cursor: pointer;
  }
  .ant-picker {
    border: none;
    border-radius: 5px;
    font-variant-numeric: normal;
    font-variant: normal;
    font-feature-settings: normal;
    cursor: pointer;
  }
  .ant-picker-input {
    cursor: pointer;
    > input {
      color: ${darkBlueLighter2};
      font-family: 'Public Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 7px;
      height: 30px;
    }
  }
  input.ant-select-selection-search-input {
    cursor: pointer !important;
  }

  .ant-select-selection-search {
    // padding-top: 5px;
    cursor: pointer !important;
  }
 
  .ant-select {
    border: none;
    border-radius: 5px;
    > .ant-select-selector {
      border: none;
      border-radius: 5px;
      color: ${darkBlueLighter2}; 
      font-family: 'Public Sans', sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0px;
      text-align: left;
      .ant-select-selection-search-input {
        padding-top: 7px;
      }
    }
    
  }

`;

export const SearchFormWrapper = styled.div`
  ${SearchFormStyle}
`;

export const FormLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: ${darkBlue2};
  position: relative;
  top: 16px;
  margin-left: ${inputMarginLeft}px;
  z-index: 10;
  line-height: 0;
`;

export const FormIconWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: inline-block;
  top: ${props => props.top}px;
  left: 12px;
`;

export const mobileOnly = css`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const desktopOnly = css`
  @media (max-width: 1000px) {
    display: none;
  }
`;

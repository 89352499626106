import React, { useState } from 'react';
import styled from 'styled-components';
import { FlightTicketFunction } from '../../MappedFlightTickets/MappedFlightTickets';
import { MobileOnly, DesktopOnly } from 'routes/LandingPage/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FlightInformation = ({ flightTicket }) => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <>
      <FlightInformationWrapper
        isOneWay={flightTicket.duration.return === 0}
        mobileOnly
      >
        {window.location.pathname.indexOf('confirmation') === -1 && (
          <p
            className="viewDetailsToggle"
            onClick={() => setIsVisible(!isVisible)}
          >
            View trip details
            <FontAwesomeIcon
              className="viewDetailsIcon"
              icon={isVisible ? faChevronUp : faChevronDown}
            />
          </p>
        )}

        {isVisible && (
          <>
            <h2>Your flight details</h2>
            {FlightTicketFunction(flightTicket, 10, 1)}
          </>
        )}
      </FlightInformationWrapper>
      <FlightInformationWrapper
        isOneWay={flightTicket.duration.return === 0}
        desktopOnly
      >
        <h2>Your flight details</h2>
        {FlightTicketFunction(flightTicket, 10, 1)}
      </FlightInformationWrapper>
    </>
  );
};

export { FlightInformation };

const FlightInformationWrapper = styled.div`
  ${props => (props.mobileOnly ? MobileOnly : null)};
  ${props => (props.desktopOnly ? DesktopOnly : null)};
  h1 {
    font-size: 18px;
    font-weight: 300;
  }

  hr,
  .viewDetailsWrapper,
  .priceDisplay,
  .priceTag,
  .selectFlightTicket {
    display: none !important;
  }

  .showDetailsDesktop {
    display: inline-block !important;
  }

  @media (min-width: 1000px) {
    text-align: left;

    h1 {
      font-size: 38px;
    }

    /* 
  This code is used to modify the Flight Ticket on the Desktop view, on the checkout page. 
  There needs to be a small adjustment depending on wether the ticket is oneway or not one way. 
  */
    .searchResultCheckoutGrid {
      > div {
        grid-template-columns: ${props =>
          props.isOneWay ? '1fr' : '1fr 1fr'} !important;
        max-width: 100%;
      }
    }

    .bottomRowWrapperDisplay {
      display: none !important;
    }

    .detailsPaymentPlanDisplay {
      display: none !important;
    }
  }

  .viewDetailsToggle {
    text-align: center;
    font-weight: 500;
    color: black;
  }

  .viewDetailsIcon {
    margin-left: 10px;
    padding: 4px;
    width: 20px;
    height: 20px;
    background-color: #f0f0f0;
    display: inline-block;
    cursor: pointer;
    border: 1px #80808091 solid;
    border-radius: 50%;
    vertical-align: middle;
  }

  @media (max-width: 1000px) {
    .totalFlightDuration {
      display: inline-block !important;
    }
    h2 {
      display: none;
    }

    .searchResultCheckoutGrid {
      p,
      span,
      b {
        text-align: left !important;
      }
      .viewDetailsMobileButton {
        grid-column: 2/3;
        text-align: center !important;
      }
    }
  }

  .priceUnit {
    display: none;
  }
`;

import map from 'ramda/src/map'
import sum from 'ramda/src/sum'
import reduce from 'ramda/src/reduce'
import values from 'ramda/src/values'
import compose from 'ramda/src/compose'

import { Baggage } from 'generated/types'

const handlePassenger = reduce<Baggage, number>((acc, { price }) => acc += price, 0)

export const getTotalBaggagesSum = compose(
  sum,
  values,
  map<Record<string, Baggage[]>, Record<string, number>>(handlePassenger)
)

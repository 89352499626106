export const DEFAULT_FILTERS = {
  stopovers: { zero: true, one: true, twoPlus: true },
  rstopovers: 'twoPlus',
  maxStopovers: 100,
  departureHours: { min: 0, max: 96 },
  returnHours: { min: 0, max: 96 },
  flightDuration: {outbound: 48, return: 48},
  excludeAirlines: [],
  source: ['kiwi', 'duffel']
};

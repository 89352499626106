import styled from 'styled-components';
import {
  darkBlue,
  darkBlue2,
  white,
  lightGrey2,
  purple
} from '../../../../styles/colors';

export const CabinClassPickerWrapper = styled.div`
  display: inline-block;
  margin-right: 21px;
  
`

export const PassengersPickerWrapper = styled.div`
  display: inline-block;
  padding-bottom: 12px;
`

export const CabinClassLabelWrapper = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: ${darkBlue2};
  cursor: pointer;
  span {
    padding: 10px 6px 12px 12px;
  }
`;

export const PassengersLabelWrapper = CabinClassLabelWrapper;

export const CabinClassDropdownWrapper = styled.div`
  z-index: 200;
  background-color: white;
  color: ${darkBlue};
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-weight: 400px;
  width: 287px;
  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  padding: 22px 15px 16px 15px;
  @media (min-width: 1000px) {
    position: relative;
    top: 10px;
  }
  @media (max-width: 999px) {
    width: 100%;
    position: fixed;
    bottom: 0%;
    left: 0;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
  }

  // Radio buttons
  .ant-radio-group {
    padding-bottom: 10px;

  }
  .ant-radio {
    &-checked {
      &-inner {
        border: none;
      }
    }
    &-checked {
      border: none;
      $-inner {
        background-color: ${purple} ;
        border: none;
        background-color: black;

      }
    }

    &-inner {
      @media (max-width: 999px) {
        width: 20px;
        height: 20px;

      }
      background-color: ${white};
      border: 3px ${lightGrey2} solid;

      &-checked {
        background-color: ${purple} ;
      }
      &:hover {
        border-color: ${lightGrey2};
      }
      &::after {
        background-color: ${white};
        @media (max-width: 999px) {
          width: 12px;
          height: 12px;
        }
        border: none;
      }
    }
    &-wrapper {
      font-family: 'Public Sans', sans-serif;
      font-size: 14px;
      font-weight: 400px;
      color: ${darkBlue};
      &:hover {
        border-color: ${lightGrey2};
      }
    }
    .ant-radio-wrapper:hover &,
    &:hover .ant-radio-inner,
    &-input:focus + .ant-radio-inner {
      border: 1px ${purple} solid;
      background-color: ${purple};
    }
  &-input:focus {
    border-color: ${purple};
  }
  &-checked::after {
    border-color: ${lightGrey2};

  }


  }
  .ant-radio-wrapper:hover &,
  &:hover .ant-radio-inner,
  &-input:focus + .ant-radio-inner {
  border-color: ${lightGrey2};
}

.ant-radio-checked {
  .ant-radio-inner {
    border: 1px ${purple} solid;
    background-color: ${purple};
  }
}

`;

export const PassengersDropdownWrapper = styled(CabinClassDropdownWrapper)`
`;

export const DropdownButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 999px) {
    justify-content: left;
  }
`;
const DropdownButton = styled.div`
  margin-top: 5px;
  margin-right: 3%;
  width: 105px;
  height: 32px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  span {
    font-family: 'Public Sans', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0px;
    text-align: center;
  }
`;
export const DropdownCancelButton = styled(DropdownButton)`
  background-color: ${lightGrey2};
  span {
    color: ${darkBlue2};
  }
`;
export const DropdownDoneButton = styled(DropdownButton)`
  background-color: ${purple};
  span {
    color: white;
  }
`;

export const MobileOpacity = styled.div`
  z-index: 200;
  position: fixed;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;
  background-color: black;
  opacity: 0.6;
  @media (min-width: 1000px) {
    display: none;
  }
`

import React from 'react';
import BaggagePassengersSection from '../BaggagePassengersSection';
import PersonSection from '../PersonSection';
import styled from 'styled-components';
import { white } from '../../../../styles/colors';
import UserIcon from '../../../components/assets/icons/user.png';
import SectionTitle from '../../../components/SectionTitle';
import { WarningBannerWrapper } from '../../../components/WarningBanner';

interface PassengersInfoProps {
  passengers: any[];
  checkedBaggages: any[];
}

const PassengersInfoWrapper = styled.div`
  background-color: ${white};
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 25px;
  margin-bottom: 10px;
   @media(min-width: 1000px) {
    padding-left: 35px;
    padding-right: 35px;
    box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
    border-radius: 5px;
    margin-bottom: 20px;
  }
`

const PassengersInfo = (props: PassengersInfoProps) => {
  const {passengers, checkedBaggages } = props;

  return (
    <PassengersInfoWrapper>
      <div>
      <SectionTitle title="Passengers" icon={UserIcon}/>
      <WarningBannerWrapper isOrange={true}>
        <span>
          Please make sure that your details match the <b>details on your passport/ID</b>.
        </span>
      </WarningBannerWrapper>

        {passengers.map((person, index) => (
          <PersonSection
            key={`P${index}`}
            passengerData={person}
          />
        ))}
      </div>
    {checkedBaggages.length > 0 && (
      <div>
        <p className="overviewSubtitle">
          Baggage
        </p>
        {checkedBaggages.map((person, index) => (
          <BaggagePassengersSection
            key={`P${index}`}
            passengerData={person}
          />
        ))}
      </div>
    )}
    </PassengersInfoWrapper>

  )
}

export default PassengersInfo;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { blue6, darkBlueLighter2, darkBlue2 } from '../../../styles/colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AccordionItemProps {
  question: string;
  answer: string;
}

const Item = styled.div`
  border-bottom: 1px solid ${blue6};
`
const Text = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
`
const QuestionWrapper = styled.div`
  cursor: pointer;
  padding: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media(max-width: 999px) {
    padding: 15px 0 15px 0;
  }
`
const Question = styled(Text)`
  color: ${darkBlue2};
  font-size: 14px;
  max-width: 90%; 
  @media(max-width: 999px) {
    font-size: 13px;
  }
`
const IconWrapper = styled.div`
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid ${darkBlue2};
  text-align: center;
  line-height: 20px;
  margin-right: 10px;
  .icon {
    font-size: 12px;
  }
`
const Answer = styled(Text)<{
  open: any;
}>`
  color: ${darkBlueLighter2};
  font-size: 14px;
  display: ${(props: any) => props.open ? 'block' : 'none'};
  padding-bottom: 30px;
  max-width: 500px;
  @media(max-width: 999px) {
    font-size: 13px;
    padding-bottom: 15px;
  }
`

const AccordionItem =(props: AccordionItemProps) => {
  const { question, answer } = props;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  }

  return (
    <Item>
      <QuestionWrapper onClick={handleClick}>
        <Question>{question}</Question>
        <IconWrapper>
          <FontAwesomeIcon
            icon={(open ? faChevronUp : faChevronDown) as IconProp}
            className="icon"
            />
        </IconWrapper>
      </QuestionWrapper>
      <Answer open={open}>{answer}</Answer>
    </Item>
  )
}

export default AccordionItem;
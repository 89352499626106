import { axiosConfig } from '../../../config/axiosConfig';

export const makeAcceptedAWS = async applicationObject => {
  try {
    const response = await axiosConfig.post(
      '/processAplication',
      // 'http://localhost:4000/dev/processAplication',
      applicationObject
    );
    return response;
  } catch (e) {
    console.log(e);
  }
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { css, cx } from 'emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import TrustPilot from 'assets/images/trustpilot-vector-logo.svg';
import PressBar from 'routes/LandingPage/components/PressBar';
// import { RepresentativeExampleToggle } from 'components/RepresentativeExample';
import { Button, Modal } from 'antd';
import Icon3 from './assets/Bitmap1-min.png';
import Icon2 from './assets/Bitmap2-min.png';
import Icon1 from './assets/Bitmap3-min.png';
import Icon4 from './assets/Bitmap4-min.png';
import i18n from 'Utils/i18n.js';
import { Trans, useTranslation } from 'react-i18next';

const TrustSectionWrapper = styled.div`
  padding: 25px 15px;
  border-radius: 4px;
  text-align: center;

  .whyBook {
    background-color: #14c29b;
    color: white;
    font-size: 17px;
    outline: none;
    border: none;
    width: 100%;
    height: 52px;
    box-shadow: inset 0 -5px 0 0 rgba(0, 80, 53, 0.91),
      -1px 0 0 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 2px;

    @media (max-width: 325px) {
      font-size: 14px;
    }
  }

  .needHelpBanner {
    /* background-color: #14c29b; */
    border: solid black 2px;
    padding: 10px 10px;
    margin-bottom: 20px;
    border-radius: 3px;
    text-align: center;
    margin-top: 20px;

    p {
      /* color: white; */
      color: black;
      font-weight: 300;
      font-size: 18px;
      margin: 0px;
      :nth-child(2) {
        font-size: 14px;
        padding-top: 5px;
      }
    }

    br {
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }
  .trustPilotLogo {
    margin-top: 2px;
    height: 30px !important;
    margin-bottom: 15px;
  }

  .logoDescribtion {
    font-size: 12px;
    color: #3d3e48;
  }

  .asSeenIn {
    margin-bottom: -2px;
    margin-top: 0px;
  }

  .pressBar {
    padding-top: 0px;
    display: none;

    /* margin-left: -1.3em;
    margin-right: -1.3em; */
    @media (max-width: 1000px) {
      display: none;
    }

    @media (min-width: 1000px) {
      img {
        max-width: 85px;
      }
    }
  }
`;

const ModalContentWrapper = styled.div`
  .modalRow {
    display: grid;
    grid-template-columns: 1fr 7fr;
    align-items: center;
    grid-column-gap: 10px;

    p {
      font-size: 16px;
      font-weight: 600;
      padding-right: 25px;
      color: black;
    }

    img {
      height: 35px;
      margin: auto;
    }
  }

  .handleOk {
    margin-top: 20px;
    background-color: #14c29b;
    color: white;
    font-size: 19px;
    outline: none;
    border: none;
    width: 100%;
    height: 50px;
    box-shadow: inset 0 -5px 0 0 rgba(0, 80, 53, 0.91),
      -1px 0 0 0 rgba(0, 0, 0, 0.1);
    padding-bottom: 2px;
  }
`;

const iconSpacing = css`
  margin-right: 10px;
`;

const ModalContent = ({ onOk }) => {
  const { t } = useTranslation();
  return (
    <ModalContentWrapper>
      <div className="modalRow">
        <img src={Icon1} alt="/" className="modalIcon" />
        <p>
          {t('ModalContent..ModalContent..On-demand British customer service')}
        </p>
      </div>
      <div className="modalRow">
        <img src={Icon4} alt="/" className="modalIcon" />
        <p>
          {t(
            'ModalContent..Trustpilot top 20 customer service in Travel globally'
          )}
        </p>
      </div>
      <div className="modalRow">
        <img src={Icon2} alt="/" className="modalIcon" />
        <p>{t('ModalContent..Lock in fares while they’re still cheap')}</p>
      </div>
      <div className="modalRow">
        <img src={Icon3} alt="/" className="modalIcon" />
        <p>{t('ModalContent..Travel experts in pay per month travel')}</p>
      </div>
      <Button className="handleOk" onClick={onOk}>
        {t('ModalContent..Got it')}
      </Button>
    </ModalContentWrapper>
  );
};

ModalContent.propTypes = {
  onOk: PropTypes.func.isRequired,
};

export default class TrustSection extends Component {
  constructor(props) {
    super(props);
    this.state = { visible: false };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({
      visible: false,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <TrustSectionWrapper>
        <button className="whyBook" onClick={this.showModal}>
          <FontAwesomeIcon
            icon={faArrowRight}
            alt="/"
            className={cx('faIcon', iconSpacing)}
          />
          {i18n.t('TrustSection..Why book with us?')}
        </button>
        <Modal
          open={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <ModalContent onOk={this.handleOk} />
        </Modal>
        <Trans
          i18nKey="TrustSection..Need help?"
          className="needHelpBanner"
          parent="div"
        >
          <p>
            Need help? <br />
            Call us on <b>phone</b>
          </p>
          <p>
            {i18n.t('Help..Monday - Friday')} {i18n.t('Help..open 9am - 6pm')}
          </p>
        </Trans>
        <div className="logoSection">
          <p className="logoDescribtion">
            {i18n.t('TrustSection..RATED TOP 20 IN TRAVEL GLOBALLY')}
          </p>
          <a href="https://www.trustpilot.com/review/flymble.com">
            <img
              src={TrustPilot}
              alt="trust pilot logo"
              className="trustPilotLogo"
            />
          </a>

          <div className="pressBar">
            <p className="logoDescribtion asSeenIn">
              {i18n.t('TrustSection..AS SEEN IN')}
            </p>
            {/*
              We received recommendation to remove pressbar from checkout page as it is getting a bit messy sometimes. 
            */}
            <PressBar />
          </div>
        </div>
      </TrustSectionWrapper>
    );
  }
}

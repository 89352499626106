import firebase from 'config/firebaseConfig.js';

import { BOOKING_TYPES } from '../../constants/constants';
import { getPassengers } from '../../Services/CheckoutServices/addToAWS/getPassangers';
import { createApplicationAWS } from 'Services/CheckoutServices/addToAWS';

const newBooking = firebase.functions().httpsCallable('addMessage');

export const tryProcessBooking = async (
  selectedFlightTicket,
  firstCheckoutPageFormValuesContext,
  partnerBookingReferenceContext,
  extract,
  callBack,
  orderSummaryObject,
  typeBookingContext,
  services,
  premiumSupport,
  airHelp,
  cabinClass,
  currency
) => {
  let booking_values = firstCheckoutPageFormValuesContext;

  if (typeBookingContext === BOOKING_TYPES.STRIPE_Flights) {
    const { P0_firstname, P0_lastname } = firstCheckoutPageFormValuesContext;

    booking_values.paying_firstname = P0_firstname;
    booking_values.paying_lastname = P0_lastname;
  }

  const tryBookingValuesAWS = {
    selectedOffersIds: [selectedFlightTicket.id],
    typeBooking: typeBookingContext || BOOKING_TYPES.FNPL_Flights,
    creditCheckAcceptance: booking_values.acceptance,
    payment: {
      paymentInformation: {
        employment: booking_values.employment,
        residentialStatus: booking_values.residentialstatus,
        salary: booking_values.salary,
        moreThanYear: booking_values.moreThanYear,
        title: booking_values.paying_title,
        firstName: booking_values.paying_firstname,
        lastName: booking_values.paying_lastname,
        dateOfBirth: `${booking_values.paying_dobYear}-${booking_values.paying_dobMonth}-${booking_values.paying_dobYear}`,
        nationality: booking_values.paying_nationality,
      },
    },
    contactInformation: {
      city: booking_values.city,
      county: booking_values.county,
      street: booking_values.street,
      houseNr: booking_values.houseNr,
      postcode: booking_values.postcode,
      mobileNr: booking_values.mobileNr,
      email: booking_values.email,
      previousHouseNr: booking_values.previous_houseNr,
      previousPostcode: booking_values.previous_postcode,
      previous_street: booking_values.previous_street,
    },
    passangers: getPassengers(booking_values),
    orderSummary: orderSummaryObject,
    itinerary: {
      flightTickets: extract,
    },
    premiumSupport: premiumSupport,
    airHelp: airHelp,
    bookingSystem: selectedFlightTicket.source,
    passengerIdentityDocuments: selectedFlightTicket.passengerIdentityDocuments,
    deepLink: selectedFlightTicket.deepLink,
    reference: partnerBookingReferenceContext,
    source: `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`,
    userId: localStorage.getItem('flymbleUserId') || '',
    services,
    cabinClass: cabinClass,
    currency: currency
  };

  const applicationId = await createApplicationAWS(tryBookingValuesAWS);
  localStorage.setItem('applicationId', applicationId);

  newBooking({
    orderSummaryObject,
    booking: booking_values,
    tripExtract: extract,
    deepLink: selectedFlightTicket.deepLink,
    //***********************************************
    // source has to be TRY!!! Do not change it here
    //***********************************************
    source: 'TRY-booking-by-FNPL',
    reference: partnerBookingReferenceContext,
  })
    .then(result => {
      console.debug(
        'Try NewBooking Successful, in newBooking API call',
        result
      );
      this.setState({ orderId: result.data.bookingId });
    })
    .catch(error => {
      console.debug('Try not passed correctly: ', error);
    });

  if (callBack) {
    callBack();
  }
};

import styled from 'styled-components';

const flymbleBlue = '#4860ff';
const searchPageBg = '#f9f9fa';

export const ThankYouPageWrapper = styled.div`
  padding-top: 20px;
  min-height: 1200px;
  background-color: ${searchPageBg};

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 510px)) 1fr;
    padding-top: 40px;

    .gridThankYou {
      grid-column: 2/4;
    }
  }

  h2 {
    font-size: 16px;
    text-align: center;
    color: black;
  }
`;

export const AlmostDoneWrapper = styled.div`
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;

  h2 {
    font-size: 24px;
  }

  h4 {
    font-weight: 500;
    margin-top: 25px;
    padding-bottom: 10px;
    font-size: 20px;
  }

  .timeBox {
    border: ${flymbleBlue} 2px solid;
    padding: 7px 16px;
    font-size: 22px;
    border-radius: 2px;

    span {
      margin-left: 7px;
    }
  }
`;

export const NextStepsWrapper = styled.div`
  margin-top: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;

  background-color: white;

  .nextStepsDescribtion {
    padding-bottom: 30px;
    font-size: 15px;
    display: block;
  }

  h2 {
    font-size: 18px;
    text-align: left;
    margin-bottom: 5px;
  }

  .describtion {
    font-size: 15px;
    padding-bottom: 10px;
    display: block;
  }

  .singleStep {
    display: grid;
    grid-template-columns: 1fr 7fr;
    grid-gap: 5px;
    margin-bottom: 20px;

    @media (min-width: 1000px) {
      grid-template-columns: 1fr 17fr;
    }

    p {
      font-size: 15px;
      margin: 0px;
    }

    a {
      text-decoration: none;
      color: black;
      font-weight: bold;
    }

    .numberStep {
      grid-column: 1/2;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      border: 2px solid ${flymbleBlue};
      text-align: center;
      vertical-align: middle;

      margin-top: 3px;

      p {
        margin-top: 2px;
        color: ${flymbleBlue};
        font-size: 24px;
        font-weight: 500;
      }
    }

    .stepBodyText {
      padding-top: 3px;
    }
  }

  .fnplLogo {
    max-height: 42px;
    padding-top: 10px;
  }
`;

export const RateUsWrapper = styled.div`
  margin-top: 35px;
  padding-top: 35px;
  padding-bottom: 70px;
  background-color: white;
  padding-left: 20px;
  padding-right: 20px;

  h2 {
    font-size: 16px;
    text-align: left;
    margin-bottom: 5px;
  }

  .describtion {
    font-size: 15px;
    padding-bottom: 10px;
    display: block;
  }

  img {
    max-width: 220px;
    margin-left: -6px;
    padding-bottom: 25px;
    padding-top: 10px;
  }

  input {
    width: 100%;
  }

  p {
    font-size: 12px;
  }

  .feedbackButton {
    display: block;
    width: 100%;

    margin-top: 15px;
    height: 51px;

    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    border-radius: 2px;
    background-color: ${flymbleBlue};
    color: white;
  }
`;

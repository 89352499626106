import React from 'react';
import { dayjs } from 'Services/dayjs';
import PropTypes from 'prop-types';
import { CustomerInformationSectionWrapper } from './styles';
import styled from 'styled-components';
import { darkBlue2 } from '../../../styles/colors';
import PassengersInfo from './components/PassengersInfo';
import PersonApplyingInfo from './components/PersonApplyingInfo';

const Title = styled.div`
  color: ${darkBlue2};
  font-family: 'Public Sans';
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (min-width: 1000px) {
    font-size: 24px;
    line-height: 28px;
    margin-left: 0;
  }
`;

class CustomerInformationSection extends React.Component {
  render() {
    const {
      passengers,
      data: {
        paying_title,
        paying_firstname,
        paying_lastname,
        paying_dobDay,
        paying_dobMonth,
        paying_dobYear,
        email,
        mobileNr,
        street,
        houseNr,
        postcode,
        residentialstatus,
        salary,
        employment,
        city,
        county,
        apartmentNr,
        floorNr,
      },
    } = this.props;

    const leadBookerDob = dayjs()
      .utc()
      .year(parseInt(paying_dobYear, 10))
      .month(parseInt(paying_dobMonth, 10) - 1)
      .date(parseInt(paying_dobDay, 10));

    const checkedBaggages = passengers.filter(
      (p) => p.additionalBaggage === true
    );

    const hideApplyingPersonAddress = typeof postcode == 'undefined';

    console.log(
      '[CustomerInformationSection] this.props.isPayInFull ',
      this.props.isPayInFull
    );
    console.log('[CustomerInformationSection.js] - passengers', passengers);

    return (
      <CustomerInformationSectionWrapper>
        <Title>Check your details</Title>
        <PassengersInfo
          passengers={passengers}
          checkedBaggages={checkedBaggages}
        />
        <PersonApplyingInfo
          paying_title={paying_title}
          paying_firstname={paying_firstname}
          paying_lastname={paying_lastname}
          paying_dobDay={paying_dobDay}
          paying_dobMonth={paying_dobMonth}
          paying_dobYear={paying_dobYear}
          email={email}
          mobileNr={mobileNr}
          street={street}
          houseNr={houseNr}
          postcode={postcode}
          residentialstatus={residentialstatus}
          salary={salary}
          employment={employment}
          city={city}
          county={county}
          apartmentNr={apartmentNr}
          floorNr={floorNr}
          leadBookerDob={leadBookerDob}
          hideApplyingPersonAddress={hideApplyingPersonAddress}
        />
      </CustomerInformationSectionWrapper>
    );
  }
}

CustomerInformationSection.propTypes = PropTypes.shape({
  data: PropTypes.object.isRequired,
  passengers: PropTypes.array,
  isShortVersion: PropTypes.bool.isRequired,
}).isRequired;

export default CustomerInformationSection;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const flymbleBlue = '#4860ff';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorInfo: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({ errorInfo: errorInfo });
    console.log('The error info from the error boundary is:...', errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <ErrorMessageContent
            errorLocation={this.props.errorLocation}
            errorInfo={this.state.errorInfo}
          />
        </>
      );
    } else return this.props.children;
  }
}

const ErrorMessageContent = ({
  errorLocation = window.location.pathname.split('/'),
  errorInfo,
}) => {
  const [isVisibleError, setIsVisibleError] = useState(false);
  return (
    <ErrorMessageWrapper>
      <div className="gridPosition">
        <h1>Oops!</h1>
        <h2>We can't seem to find the page you're looking for.</h2>
        <p>Here are some helpful links instead:</p>
        <Link to="/checkout">
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;Checkout
        </Link>
        <Link to="/">
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;Home
        </Link>
        <Link to="/search-flight/round/C_ACE/C_LON/20200622/20200705/1/0/0">
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;Search
        </Link>
        <Link to="/search">
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;Help
        </Link>
        <br />
        <p
          style={{ color: `${flymbleBlue}` }}
          onClick={() => setIsVisibleError(!isVisibleError)}
        >
          <FontAwesomeIcon icon={faArrowRight} />
          &nbsp;Error log (Code 404&nbsp;{errorLocation})
        </p>

        {isVisibleError && (
          <>
            {JSON.stringify(errorInfo.componentStack)}
            <br />
          </>
        )}
        <br />
        <br />
        <img
          src="https://a0.muscache.com/airbnb/static/error_pages/404-Airbnb_final-d652ff855b1335dd3eedc3baa8dc8b69.gif"
          width="320"
          height="432"
          alt="an error message"
        />
      </div>
    </ErrorMessageWrapper>
  );
};

const ErrorMessageWrapper = styled.div`
  padding: 20px;
  @media (min-width: 1000px) {
    padding-top: 40px;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    .gridPosition {
      grid-column: 2/4;
    }
  }

  a {
    display: block;
  }

  p {
    margin-bottom: 5px;
  }
`;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch as faSpinner, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { purple, white } from '../../../styles/colors';
import { submitSearchButton } from '../../../Utils';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SearchButtonProps {
  isLoading: boolean;
}

const Button = styled.button`
  color: ${white};
  border: none;
  background-color: ${purple};
  box-shadow: none;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 700;
  font-size: 18px;
  border-radius: 5px;
  span {
    padding-right: 10px; 
    padding-left: 10px;
  }
  height:  54px;
  width: 149px;
  line-height: 54px;
  font-family: 'Public Sans', sans-serif;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  margin-bottom: 0;
  @media(max-width: 999px) {
    width: 100%;
    hehight: 45px;
  }
  outline: none;
  cursor: pointer;
`

const SearchButton = (props: SearchButtonProps) => {
  const { isLoading } = props;
  return (
    <div className='searchButton'>
      <Button type='submit' onClick={() => submitSearchButton()}>
        {isLoading && <FontAwesomeIcon icon={faSpinner as IconProp} spin={isLoading}/>}
        <span className="searchButtonText">Search</span>
        <FontAwesomeIcon icon={faArrowRight as IconProp} />
      </Button>
    </div>
  )
}

export default SearchButton;
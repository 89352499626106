import styled from 'styled-components';

export const FlightTicketWrapper = styled.div`
  padding: 6px 0;
  grid-column: 2/4;
  font-family: -apple-system-, BlinkMacSystemFont, sans-serif;
  
  @media (min-width: 1000px) {
    .borderlineTravelDisplay {
      border-left: 1px #dfdfdf solid;
    }
  }
`;

export const SearchResult = styled.div`
  min-height: 100px;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  margin-bottom: 0px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 1000px) {
    padding: 20px;
    padding-bottom: 10px;
  }

  hr {
    text-align: right;
    margin-right: -1px;
    width: 97%;
    grid-column: 1/3;
    opacity: 0.75;
    border-top: dashed 1px #f0f0f0;

    @media (min-width: 1000px) {
      display: none;
    }

    @media (max-width: 1000px) {
      width: 100%;
      margin-right: 0px;
    }
  }

  .v1 {
    border-left: 6px solid green;

    height: 500px;
    position: absolute;
    left: 50%;

    @media (min-width: 1000px) {
      width: 100%;
      margin-right: 20px;
    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

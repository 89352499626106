import React, { useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import AppContext from 'Context/AppContext';
import { trackPaymentPlanPage, createTripTextConstants } from '../../Utils';
import ViewComponent from './View';
import { CheckFlightsContainer } from '../../Checkout/CheckFlightsContainer';
import { LoadingScreen } from '../../components/LoadingScreen';
import { metaConversionApi } from 'Services/analytics/metaConversionApi';
import { BOOKING_TYPES } from '../../constants/constants';
import { VARIANT_OPTIMAL_MONTHS } from '../../constants/env';

interface PaymentsPlanPageProps {
  history: any;
  location: any;

}
const PaymentPlanPage = (props: PaymentsPlanPageProps) => {
  const {history} = props;
  const context = useContext(AppContext);
  const {
    setAmount,
    setPaymentPlanPartner,
    isOneWayFlight,
    paymentPlanPartnerSelected,
    selectedFlightTicket: selectedFlightTicketContext,
    selectedFlightTicket: theFlightTicket,
    lastSearch,
    lastSearch: { noMonths },
    changeTypeBookingContext,
    setCheckoutProgress
  } = context;

  const initializeFlightTicket = () => {
    const { localStorage } = window;
    const localStorageFlightTicket = JSON.parse(
      localStorage.getItem('SelectedFlightResult') as any
    );

    if (selectedFlightTicketContext !== null) {
      localStorage.setItem(
        'SelectedFlightResult',
        JSON.stringify(selectedFlightTicketContext)
      );
      return selectedFlightTicketContext;
    } else if (localStorageFlightTicket) {
      context.setSelectedFlightTicket(localStorageFlightTicket);
      return localStorageFlightTicket;
    }
  };

  useEffect(() => {
    setCheckoutProgress({
      stepNumber: 2,
      stepNameDesktop: 'Details',
      stepNameMobile: 'Provide personal details',
      stepNext: 'credit check',
    });
    initializeFlightTicket();
    setAmount(VARIANT_OPTIMAL_MONTHS);
    changeTypeBookingContext(BOOKING_TYPES.STRIPE_Flights);
    goToCheckout();
    // metaConversionApi();
  }, []);

  function goToCheckout() {
    // Construct url and push.
    // Be carefull with removing the history state object, legacy code relies / relied on it.
    history.push({
      pathname: `/checkout/${theFlightTicket.outbound[theFlightTicket.outbound.length - 1].destination.code}`,
      state: { theFlightTicket },
    });
  }

  return <LoadingScreen />;
};

const RouteredPaymentPlanPage = withRouter(PaymentPlanPage);
export default RouteredPaymentPlanPage;

import React from 'react';
import styled from 'styled-components';
import { white, darkBlueLighter, darkBlue2} from '../../styles/colors'
import HowItWorksSection from './components/HowItWorksSection';
import calendarIconMobile from './icons/calendarIcon2.png';
import earthIconMobile from './icons/earthIcon2.png';
import shoppingBagIconMobile from './icons/shoppingBagIcon2.png';
import shoppingBagIconDesktop from './icons/shoppingBagIcon4.png';
import calendarIconDesktop from './icons/calendarIcon.svg';
import eartIconDesktop from './icons/earthIcon4.png';
import { useCurrencyContext } from 'Context/CurrencyProvider';


const Wrapper = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 95px;
  @media(max-width: 999px) {
    padding-left: 17px;
    padding-right: 17px;
    padding-top: 56px;
  }
`
const Text = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  padding-bottom: 10px;
  width: 90%;
  max-width: 1122px;
  @media(max-width: 999px) {
    width: 100%;
  }
`
const Title = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  color: ${darkBlue2};
  @media(max-width: 999px) {
    font-size: 28px;
    line-height: 33px;
  }
`

const Subtitle = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: ${darkBlueLighter};
  padding-bottom: 35px;
  @media(max-width: 999px) {
    font-size: 14px;
    line-height: 17px;
    padding-bottom: 38px;
  }
`
const SectionsWrapper = styled.div`
  width: 90%;
  max-width: 1122px;
  display: flex;
  justify-content: space-between;
  @media(max-width: 999px) {
    width:100%;
    flex-direction: column;
  }
`

const HowItWorks = () => {

  const {currencySymbol, currency} = useCurrencyContext();

  const sections = [
    {
      title: 'Search a flight',
      text: 'Search over 150 airlines. We work directly with the airlines so you get the best price and can easily amend a booking.',
      mobileIcon: earthIconMobile,
      desktopIcon: eartIconDesktop,
    },
    {
      title: 'Choose a plan that suits you',
      text: `Choose between 0% APR interest-free plans and pay off before your departure, or choose to pay off afterwards in 3 to 12 monthly payments.`,
      mobileIcon: shoppingBagIconMobile,
      desktopIcon: shoppingBagIconDesktop,
    },
    {
      title: 'Pay monthly',
      text: 'Once approved, we will send your booking confirmation and tickets within 24 hours. You will receive monthly reminders to pay off the balance. You can always pay off the remaining amount at once.',
      mobileIcon: calendarIconMobile,
      desktopIcon: calendarIconDesktop,
    },
  ];
  

  return (
    <Wrapper>
      <Title>How it works</Title>
      <Subtitle>Easy as 1, 2, 3.</Subtitle>
      <SectionsWrapper>
        {sections.map((section, index) => (
          <HowItWorksSection 
            key={index}
            title={section.title}
            text={section.text}
            mobileIcon={section.mobileIcon}
            desktopIcon={section.desktopIcon}
            />
        ))}
      </SectionsWrapper>
    </Wrapper>
  );
};
export default HowItWorks;
export { HowItWorks };

export const topAirports = [
  {
    icon: 'faBuilding',
    label: 'Warszawa (all airports), Polska',
    key: 'C_WAW',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'London (all airports) United Kingdom',
    key: 'C_LON',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Manchester (all airports), United Kingdom',
    key: 'C_MAN',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Edinburgh (all airports), United Kingdom',
    key: 'C_EDI',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Birmingham (all airports), United Kingdom',
    key: 'C_BHX',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Glasgow (all airports), United Kingdom',
    key: 'C_GLA',
    type: 'city',
  },
];

export const topDestinations = [
  {
    icon: 'faBuilding',
    label: 'Bangkok (all airports), Thailand',
    key: 'C_BKK',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Lanzarote (all airports), Spain',
    key: 'C_ACE',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'New York City (all airports), United States',
    key: 'C_NYC',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Bangkok (all airports), Thailand',
    key: 'C_BKK',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Ibiza (all airports), Spain',
    key: 'C_IBZ',
    type: 'city',
  },
  {
    icon: 'faBuilding',
    label: 'Paris (all airports), France',
    key: 'C_PAR',
    type: 'city',
  },
];

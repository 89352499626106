import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { darkBlue2, lightGrey3  } from '../../../styles/colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CheckoutRowBottomProps {
  isDetailsOpen: boolean;
}

const RowWrapper = styled.div`
  color: ${darkBlue2};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1/3;
  padding-top: 11px;
  padding-bottom: 4px;
  @media(min-width: 1000px) {
    padding-bottom: 13px;
  }
`
const Button = styled.div`
  background-color: ${lightGrey3};
  display: flex;
  align-items: center;
  justify-content: center;
`
const ViewDetailsButtonMobile = styled(Button)`
  width: 125px;
  height: 35px;
  border-radius: 5px;
  font-family: 'Public Sans';
  font-size: 13px;
  font-weight: 500;
  line-height: 15px;
  span {
    margin-right: 10px;
  }
  @media(min-width: 1000px) {
    display: none;
  }
`
const ViewDetailsButtonDesktop = styled(Button)`
  background-color: ${lightGrey3};
  width: 25px;
  height: 25px;
  border-radius: 50%;
  @media(max-width: 999px) {
    display: none;
  }
`
const CheckoutRowBottom = (props: CheckoutRowBottomProps) => {
  const { isDetailsOpen } = props;

  return (
    <RowWrapper>
      <ViewDetailsButtonDesktop>
        <FontAwesomeIcon icon={(isDetailsOpen ? faChevronUp : faChevronDown) as IconProp} />
      </ViewDetailsButtonDesktop>
      <ViewDetailsButtonMobile>
        <span>View details</span>
        <FontAwesomeIcon icon={(isDetailsOpen ? faChevronUp : faChevronDown) as IconProp} />
      </ViewDetailsButtonMobile>
    </RowWrapper>
  )
}

export default CheckoutRowBottom;
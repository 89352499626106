import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';
import mastercard from './mastercard.png';
import visa from './visa.png';

export const RowPaymentLogos = () => {
  return (
    <RowPaymentLogosWrapper>
      <VisaLogo />
      <MasterCardLogo />
    </RowPaymentLogosWrapper>
  );
};

RowPaymentLogos.propTypes = {
  //   isVisible: PropTypes.bool.isRequired,
};

const RowPaymentLogosWrapper = styled.div`
  float: right;

  @media (min-width: 1000px) {
    float: none;
    display: inline-block;
  }
`;

const VisaLogo = styled.div`
  margin-left: 0px;
  display: inline-block;
  background-image: url(${visa});
  width: 50px;
  height: 17px;
  background-size: contain;
    background-repeat: no-repeat;
`;

const MasterCardLogo = styled.div`
  margin-left: 5px;
  display: inline-block;
  background-image: url(${mastercard});
  width: 38px;
  height: 22px;
  background-size: contain;
    background-repeat: no-repeat;
`;

import {dayjs} from 'Services/dayjs';

/**
 * Class providing various utilities for DatePicker, e.g. setting disabled dates range.
 * @constructor
 * @param getValues - reference to form's getValues function
 * @param setValue - reference to form's setValue function
 */

const MONTHS_AHEAD = 12;
const DEFAULT_DAYS_AHEAD = 15;
const DEFAULT_DATE_TO_AHEAD = 2;

export default class DatePickerUtils {
  constructor(getValues, setValue) {
    this.getValues = getValues;
    this.setValue = setValue;
  }

  dateToDisabledDate = d => {
    const minDate = dayjs();
    const maxDate = dayjs().add(MONTHS_AHEAD, 'month');
    let startDate = this.getValues('dateFrom');

    if (startDate) {
      return d && !d.isBetween(startDate, maxDate, 'day', '(]');
    }

    return d && !d.isBetween(minDate, maxDate, 'day', '[]');
  };

  handleDateFromOpenChange = stateOpen => {
    const { dateFrom: me } = this.getValues();

    if (!me) {
      this.setValue('dateFrom', dayjs().add(DEFAULT_DAYS_AHEAD, 'day'));
    }

    // panel is closing and date is selected and no end date, set end date to next day
    if (!stateOpen) {
      setTimeout(() => {
        //this.setState({ endOpen: true });
        const { dateFrom, dateTo } = this.getValues(['dateFrom', 'dateTo']);
        if (dateFrom && (!dateTo || dateTo.diff(dateFrom) < 0)) {
          this.setValue(
            'dateTo',
            dayjs(dateFrom).add(DEFAULT_DATE_TO_AHEAD, 'day')
          );
        }
      }, 400);
    }
  };

  handleDateToOpenChange = endOpen => {
    const { dateTo: me, dateFrom: other } = this.getValues([
      'dateTo',
      'dateFrom',
    ]);
    if (!me) {
      if (other) {
        this.setValue('dateTo', dayjs(other).add(3, 'day'));
      } else {
        this.setValue('dateTo', dayjs().add(DEFAULT_DAYS_AHEAD, 'day'));
      }
    }
  };

  dateFromDisabledDate = d => {
    const minDate = dayjs().add(7, 'day');
    const maxDate = dayjs().add(MONTHS_AHEAD, 'month');

    return d && !d.isBetween(minDate, maxDate, 'day', '[]');
  };

  onDateToChange2 = () => {
    this.setState({ endOpen: false });
  };

  dateFromFooterRender = () => 'Book flights at least 7 days in advance';

  dateToFooterRender = () =>
    `Book flights up to ${MONTHS_AHEAD} months in advance`;
}

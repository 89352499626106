import styled from 'styled-components';
import {
  flymbleGreen,
  darkBlueLighter,
  darkBlue2,
  almostBlack2,
  red,
  lightGrey2,
} from '../../../../styles/colors'

export const TravelDisplayStyle = styled.div`
  color: ${darkBlue2};
  grid-column: 1/3;
  @media (min-width: 1000px) {
    grid-column: 1/span 2;
    
  }
    .onlyCheckout {
      display: none;
    }
  .travelDisplayHolder {
    /* padding-left: 18px;
    padding-right: 18px; */
  
    display: inline-block;
    padding-bottom: 12px;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    text-align: left;
    font-weight: 300;
    font-size: 14px;
    @media (min-width: 1000px) {
      padding-top:0;
      padding-left: 20px;
      padding-right: 20px;
    }

    .routeStopsWrapper__lineAndDots {
      position: relative;
    }

    .airportCodeFrom {
      color: ${darkBlueLighter};
      padding-left: 0px;
      grid-row: 2/3;
      @media (min-width: 1000px) {
        font-size: 14px;
        font-weight: 400;
        padding-bottom: 20px;
        text-align: left;
        padding-left: 30px;  
      }
    }

    .airportCodeTo {
      color: ${darkBlueLighter};
      padding-right: 0px;
      text-align: right;
      grid-column: 3/4;
      grid-row: 2/3;
      @media (min-width: 1000px) {
        font-size: 14px;
        font-weight: 400;
        text-align: right;
        padding-right: 30px;
      }
    }

    .departureTime {
      font-size: 16px;
      font-weight: bold;
      
      grid-column: 1/2;
      @media (min-width: 1000px) {
        text-align: left;
        padding-left: 30px;
        padding-bottom: 0px;
        font-size: 18px;
        font-weight: 700;
        padding-left: 30px;
      }
    }

    .arrivalTime {
      font-size: 16px;
      font-weight: bold;
      text-align: right;
      grid-column: 3/4;
      grid-row: 1/2;

      @media (min-width: 1000px) {
        text-align: right;
        padding-right: 30px;
        font-size: 18px;
        font-weight: 700;
        padding-left: 17px;
      }
    }

    /* Information only for desktop */
    .departureRouteDay {
      grid-column: 1/2;
      padding-left: 17px;

      @media (min-width: 1000px) {
        font-size: 14px;
        color: #1b1b1b;
        font-weight: 200;
      }

      @media (max-width: 1000px) {
        display: none;
        padding-bottom: 5px;
      }
    }

    .departureRouteCity {
      grid-column: 1/2;
      padding-left: 17px;

      @media (min-width: 1000px) {
        font-size: 14px;
        color: #1b1b1b;
        font-weight: 200;
      }

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .arrivalRouteDay {
      grid-column: 3/4;
      grid-row: 3/4;
      text-align: right;
      padding-right: 30px;
      color: #1b1b1b;
      font-weight: 200;
      @media (max-width: 1000px) {
        display: none;
      }
    }

    .arrivalRouteCity {
      grid-column: 3/4;
      text-align: right;
      padding-right: 30px;
      color: #1b1b1b;
      font-weight: 200;

      @media (max-width: 1000px) {
        display: none;
      }
    }

    .routeStopsWrapper {
      text-align: center;
      color: b${almostBlack2};
      font-size: 12px;

      .directFlight {
        color: ${flymbleGreen};
        font-size: 11px;
        font-weight: 600;
        @media (min-width: 1000px) {
          font-size: 12px;
          font-weight: 600;
        }
      }
      @media (max-width: 1000px) {
        grid-column: 2/3;
        grid-row: 1/3;
      }
      @media (min-width: 1000px) {
        grid-row: 1/3;
        grid-column: 2/3;
        font-size: 12px;
        font-weight: 400px;
      }
    }

    .totalFlightDuration {
      display: block;
      text-align: center;
      color: ${darkBlueLighter};
      font-size: 12px;
      font-weight: 400;

      @media (min-width: 1000px) {
        font-size: 12px;
      }
    }
  }
`;

export const CircleStop = styled.div`
  width: 7px;
  text-align: left;
  height: 7px;
  border-radius: 100%;
  background-color: ${lightGrey2};
  z-index: 10;
  vertical-align: middle;
  display: inline-block;
`;

export const RedCircleStop = styled.div`
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: ${red};
  z-index: 10;
  text-align: left;
  position: absolute;
  // this code calculates placement of red dots base of the number of stops
  left: ${props => (
    props.index === 0
    ? 70/(props.stops + 1) * (props.index + 1) + 13
    : 70/(props.stops + 1) * (props.index + 1) + 14
  )}%;
  top: 6px;
  vertical-align: middle;
  display: inline-block;
`;

export const LineStopHorizontal = styled.div`
  text-align: center;
  width: 70%;
  height: 1px;
  background-color: ${lightGrey2};
  display: inline-block;
  vertical-align: middle;
`;

export const LineStopVertical = styled.div`
  text-align: center;
  height: 30px;
  width: 1px;
  z-index: 2;
  background-color: ${lightGrey2};
  display: inline-block;
  vertical-align: middle;
  margin-top: -5px;
  margin-bottom: -6px;
  margin-left: -0.04vw;
`;

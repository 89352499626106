import styled from 'styled-components';
import { colors } from 'themeFlymble';

export const CheckoutFormsUKWrapper = styled.div`
  padding-top: 45px;
  @media (max-width: 1000px) {
    padding-top: 0px;
  }

  .description {
    font-size: 13px;
    display: block;
    padding-bottom: 30px;
    color: black;
    margin-top: 11px;

    @media (min-width: 1000px) {
      padding-bottom: 0px;
      grid-column: 1/3;
      margin-top: 0px;
    }
  }

  h2 {
    font-size: 22px;
    margin-bottom: 0px;
    color: black;

    @media (min-width: 1000px) {
      margin-bottom: 20px !important;
    }
  }

  .faIcon {
    font-size: 20px;
  }

  @media (min-width: 1000px) {
    padding-top: 0px;

    .description {
      /* display: none; */
    }

    .faIcon {
      font-size: 28px;
    }
  }

  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }

  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .proceedWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .authorizeButton {
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

  .proceedButton {
    margin-top: 50px;
    margin-bottom: 50px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }
`;

export const AddPassengerButtonWrapper = styled.div`
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  cursor: pointer;

  width: 100%;

  span {
    display: inline-block;
    text-align: center;
    font-size: 14px;
    color: white;
    font-weight: bold;

    width: 220px;
    padding-top: 14px;
    padding-bottom: 14px;
    background-color: ${colors.flymbleBlue};
    border-radius: 3px;
  }

  .faIcon {
    padding-right: 3px;
    font-size: 16px;
  }
`;

export const ConfirmationPaymentSection = styled.div`
  padding-bottom: 50px;
  .authorizeButton {
    margin-top: 50px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
      width: 100%;
    }

    line-height: 0 !important;

    display: block;
    width: 50%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${colors.flymbleBlue};
    color: white;
    border-color: ${colors.flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-bottom: 150px;
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }
`;

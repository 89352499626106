/* eslint-disable max-len */
import React from 'react';
import { Switch } from 'react-router-dom';

import STTRoute from './components/ScrollToTopRoute';

// Seperate pages are imported below here.
import LandingPage from './routes/LandingPage/index.js';
import SearchResult from './Page/SearchResult/index';
import ThankYouPage from './routes/ThankYouPage';
import ErrorPage from './routes/ErrorPage';
import pages from './pages.js';
import { KIND_OF_TRIP, CABIN_CLASS, CURRENCY } from './constants/constants';

import ConfirmationPage from './Checkout/ConfirmationPageContainer/index.js';
import CreditCheckPage from './Checkout/CreditCheckPageContainer/index.js';
import PaymentPlanPage from './routes/PaymentPlanPage';
import { BlogArticle } from './routes/BlogPage/components/BlogArticle';

/**
 * Loader - should be used only for big chunks of page, main views
 *
 * @param isLoading boolean indicates component is loading
 * @param pastDelay boolean indicates loading last longer than delay set
 *
 * Smaller elements do not require Loading placeholder at all.
 */

const Search = React.lazy(() => import('./Search'));

const SearchResultMobile = React.lazy(() => import('./Search'));

const QuerySearchPage = React.lazy(() => import('./Search/QuerySearch'));

const CheckoutPage = React.lazy(() =>
  import('Checkout/FirstCheckoutPageContainer')
);

const ApplicationResult = React.lazy(() =>
  import('routes/ApplicationResultPage')
);

const SearchFilters = React.lazy(() => import('./Search/SearchFilters'));

const AboutUs = React.lazy(() => import('./routes/AboutUsPage'));

const Help = React.lazy(() => import('./routes/HelpPage'));

const Sitemap = React.lazy(() => import('./routes/SitemapPage'));

const Transparency = React.lazy(() => import('./routes/TransparencyPage'));

const ContactUs = React.lazy(() => import('./routes/HelpPage'));

const Careers = React.lazy(() => import('./routes/CareersPage'));

const ReviewPage = React.lazy(() => import('./routes/ReviewPage'));

const TermsConditions = React.lazy(() =>
  import('./routes/LegalPages/TermsConditions')
);

const CookiesPolicy = React.lazy(() =>
  import('./routes/LegalPages/CookiesPrivacy')
);

const RepresentativeExample = React.lazy(() =>
  import('./routes/LegalPages/RepresentativeExample/index.js')
);

const SEODashboard = React.lazy(() => import('./routes/BlogPage'));

const LinkGenerator = React.lazy(() => import('./components/LinkGenerator'));

const airportCityReg = '[acAC]_[a-zA-Z]{3}';
const dateReg = '\\d{8}';
const passengerReg = '[0-7]{1}';
const kinfOfTrip = `${KIND_OF_TRIP.ROUND}|${KIND_OF_TRIP.ONE_WAY}`;
const cabinClass = `${CABIN_CLASS.ECONOMY}|${CABIN_CLASS.PREMIUM_ECONOMY}|${CABIN_CLASS.BUSINESS}|${CABIN_CLASS.FIRST_CLASS}`;
const currency = `${CURRENCY.GBP}|${CURRENCY.USD}`;

/******************************/

const AppRouter = () => {
  return (
    <React.Suspense>
      <Switch>
        <STTRoute path="/guides/:id/:title/" component={BlogArticle} />
        <STTRoute exact path="/" component={LandingPage} />
        <STTRoute exact path="/fly-now-pay-later" component={LandingPage} />
        <STTRoute
          exact
          path="/v1"
          component={LandingPage}
          compProps={{ variant: 'v1' }}
        />
        <STTRoute
          exact
          path="/v2"
          component={LandingPage}
          compProps={{ variant: 'v2' }}
        />
        <STTRoute
          exact
          path="/v3"
          component={LandingPage}
          compProps={{ variant: 'v3' }}
        />
        <STTRoute
          exact
          path="/v4"
          component={LandingPage}
          compProps={{ variant: 'v4' }}
        />
        <STTRoute
          exact
          path="/v5"
          component={LandingPage}
          compProps={{ variant: 'v5' }}
        />
        <STTRoute path={pages.aboutUs} component={AboutUs} />
        <STTRoute path="/help" component={Help} />
        <STTRoute
          path={`${pages.querySearchPage}/:type(${kinfOfTrip})?/:to(${airportCityReg})/:from(${airportCityReg})?/:fromdate(${dateReg})?/:todate(${dateReg})?/:nadults(${passengerReg})?/:nchildren(${passengerReg})?/:ninfants(${passengerReg})/:cabin(${cabinClass})?/:currency(${currency})`}
          component={QuerySearchPage}
        />
        <STTRoute
          path={`${pages.querySearchPage}`}
          component={QuerySearchPage}
        />
        <STTRoute path="/sf" component={SearchFilters} />
        <STTRoute path="/transparency" component={Transparency} />
        <STTRoute path="/contact-us" component={ContactUs} />
        <STTRoute path="/terms-conditions" component={TermsConditions} />
        <STTRoute path="/cookies-policy" component={CookiesPolicy} />
        <STTRoute
          path="/representative-example"
          component={RepresentativeExample}
        />
        <STTRoute path="/sitemap" component={Sitemap} />
        <STTRoute path="/guides" component={SEODashboard} />
        <STTRoute path="/searchform" component={Search} />
        <STTRoute path="/searchresult" component={SearchResult} />
        <STTRoute path="/checkout" component={CheckoutPage} />
        <STTRoute path="/payment-plan" component={PaymentPlanPage} />
        <STTRoute path="/thank-you" component={ThankYouPage} />
        <STTRoute path="/error" component={ErrorPage} />
        <STTRoute path="/application-result" component={ApplicationResult} />
        <STTRoute path="/linkgen" component={LinkGenerator} />
        <STTRoute path="/review-page" component={ReviewPage} />
        <STTRoute path="/join-our-team" component={Careers} />
        <STTRoute path={pages.flightResult} component={SearchResultMobile} />

        <STTRoute path="/confirmation" component={ConfirmationPage} />
        <STTRoute path="/pay-booking" component={CreditCheckPage} />
        <STTRoute component={LandingPage} />
      </Switch>
    </React.Suspense>
  );
};

export default AppRouter;

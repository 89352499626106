import React from 'react';
import PropTypes from 'prop-types';
import LogoFNPL from '../components/assets/FNPLLogo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { flymbleGreen, white } from '../../styles/colors';

const InstructionsWrapper = styled.div`
  background-color: ${white};
  box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
  border-radius: 5px;
  padding: 30px 35px 30px;
  padding-left: 35px;
  padding-right: 35px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  @media(max-width: 999px) {
    display: none;
  }
`
const Text = styled.div`
  .faIcon {
    margin-right: 15px;
    font-size: 18px;
    color: ${flymbleGreen};
  }
`
const Row = styled.div`
  padding-bottom: 7px;
`
const FNPLLogo = styled.img`
  width: 150px;
  height: 60px;
`
const instructions = [
  'Credit check will be performed by our partner Fly Now Pay Later',
  'It can take up to 2 minutes to load.',
  'Double check if all your details are correct'
]
const InstructionsFnpl = ({ isVisible }) => {
  return (
    isVisible && (
      <InstructionsWrapper>
         <Text>
          {instructions.map((instruction, index) => (
            <Row key={index}>
              <FontAwesomeIcon icon={faCheck} className="faIcon" />
              {instruction}
            </Row>
          ))}
        </Text>
        <FNPLLogo src={LogoFNPL} alt='Fly now pay later logo' />
      </InstructionsWrapper>
    )
  );
};

InstructionsFnpl.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};

export default InstructionsFnpl;


import React from 'react';
import styled from 'styled-components';
import { useCurrencyContext } from 'Context/CurrencyProvider';
import { white, darkBlue2, blue6 } from '../../../../styles/colors'

const Wrapper = styled.div`
  background-color: ${white};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 120px;
  padding-bottom: 95px;
  @media(max-width: 999px) {
    padding: 48px 0 0 0;
    display: none;
  }
`
const Card = styled.div`
  background-color: ${darkBlue2};
  width: 90%;
  max-width: 1122px;
  height: 135px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 46px;
  @media(max-width: 999px) {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    padding: 33px 30px;
    height: 206px;
    border-radius: 0;
  }
  @media(max-width: 600px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`
const Text = styled.div`
  color: ${white};
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
`

const Title = styled(Text)`
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  padding-bottom: 9px;
  @media(max-width: 999px) {
    font-size: 20px;
    line-height: 23px;
    padding-bottom: 12px;
  }
`
const Subtitle = styled(Text)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`
const Button = styled.div`
  cursor: pointer;
  color: ${darkBlue2};
  background-color: ${blue6};
  width: 150px;
  height: 50px;
  border-radius: 5px;
  font-family: Public Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 999px) {
    width: 104px;
    height: 40px;
    font-size: 14px;
    line-height: 17px;
  }
`


const ReferFriend = () => {
  const {currencySymbol} = useCurrencyContext();
  
  const handleButtonClick = () => {
    console.log('Refer a friend')
  }

  return (
    <Wrapper>
      <Card>
        <Text>
          <Title>Refer a friend and get a discount!</Title>
          <Subtitle>Refer a friend, family member or collegue and both get {currencySymbol}20 off!</Subtitle>
        </Text>
        <Button onClick={handleButtonClick}>Refer a friend</Button>
      </Card>
    </Wrapper>
  )
}

export default ReferFriend;
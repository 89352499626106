import { gql } from "@apollo/client";

import { CityScalarFieldsFragment } from "apollo/client/city/CityScalarFieldsFragment";
import { PlaceScalarFieldsFragment } from "apollo/client/place/PlaceScalarFieldsFragment";
import { AirportScalarFieldsFragment } from "apollo/client/airport/AirportScalarFieldsFragment";

export const PlaceFragment = gql`
  fragment PlaceFragment on Place {
    ...PlaceScalarFieldsFragment
    city {
      ...CityScalarFieldsFragment
    }
    airports {
      ...AirportScalarFieldsFragment
    }
  }
  ${CityScalarFieldsFragment}
  ${PlaceScalarFieldsFragment}
  ${AirportScalarFieldsFragment}
`;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import LogoBlueWhite from 'assets/images/flymble_logo_blue_white.svg';
import StarsBar from './assets/trustpilotStarsBar.png';
import trustpilotLogo from 'assets/images/trustpilot-vector-logo.svg';
import { NavStyled2, DesktopNavItems, ChatIcon } from './styles';
import chatIcon from './assets/chatIcon2.png';
import CurrencySelector from './components/CurrencySelector';
import MobileMenu from './components/MobileMenu';
import MobileMenuButton from './components/MobileMenuButton';

const StartsAndLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoWrapper = styled.img<{
  mobileOnly: any;
}>`
  display: block;
  margin-left: auto;
  margin-right: auto;
  height: 23px;
  padding-bottom: 3px;
  image-rendering: crisp-edges;
`;
const StarsBarImage = styled.img`
  height: 23px;
  margin-right: 10px;
`;
const Element = styled.span`
  cursor: pointer;
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  text-align: left;
`;
const MobileNavItems = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  @media (min-width: 1000px) {
    display: none;
  }
`;
const TrustPilotUrl =
  'https://uk.trustpilot.com/review/flymble.com?utm_medium=Trustbox&utm_source=MicroCombo';

export interface MenuItem {
  link: string;
  title: string;
}

interface NavigationProps {
  menuItems: MenuItem[];
}

const Navigation = (props: NavigationProps) => {
  const { menuItems } = props;
  const [open, setOpen] = useState(false);
  const isQA = localStorage.getItem('isQA') === 'true';

  const handleOnToggle = () => {
    setOpen(false);
  };

  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const openChat = () => (window as any)['Intercom']('show');

  return (
    <NavStyled2>
      <div className="menu-logo">
        <Link to="/">
          <img
            className="flymble-logo"
            src={LogoBlueWhite}
            alt="Flymble logo"
          />
        </Link>
      </div>

      <DesktopNavItems>
        <a
          href={TrustPilotUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <StartsAndLogoWrapper>
            <StarsBarImage src={StarsBar} alt="trust pilot stars bar" />
            <LogoWrapper src={trustpilotLogo} mobileOnly />
          </StartsAndLogoWrapper>
        </a>
        <Element
          onClick={() =>
            window.open('https://intercom.help/flymblesupport/en/')
          }
        >
          Help Center
        </Element>
        <Element onClick={openChat}>
          <ChatIcon src={chatIcon} />
          Chat with us
        </Element>
      </DesktopNavItems>
      <MobileMenu menuItems={menuItems} open={open} onToggle={handleOnToggle} />
    </NavStyled2>
  );
};

export default Navigation;

import React from 'react';
import styled from 'styled-components';
import { css } from 'emotion/macro';
import { Link } from 'react-router-dom';

import FlymbleLogo from './assets/flymble_logo_white.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

import paymentsImage from './assets/paymentsList.png';
import { ReactComponent as DuffelImage } from './assets/duffel.svg';
import partnersImage from './assets/bottom_bar_logos.png';
import { white, darkBlue2 } from '../../styles/colors';
import { useCurrencyContext } from 'Context/CurrencyProvider/CurrencyProvider';

const faStyle = css`
  font-size: 22px;
`;
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${darkBlue2};
`;

const FooterWrapper = styled.div`
  background-color: ${darkBlue2};
  color: ${white};
  width: 90%;
  max-width: 1122px;
  padding-top: 50px;
  padding-bottom: 40px;
  @media (max-width: 999px) {
    width: 100%;
    padding: 25px 17px 60px 17px;
  }

  font-family: Public Sans;
  font-style: normal;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 35px 60px 60px 30px 200px 40px;

  .socialList {
    grid-column: 2/3;
    grid-row: 1;
  }
  .linkList1 {
    grid-column: 1/2;
    grid-row: 2;
  }
  .linkList2 {
    grid-column: 2/3;
    grid-row: 2;
  }

  .legalLinks {
    grid-column: 1/3;
    grid-row: 4;
  }
  .legalText {
    grid-column: 1/3;
    grid-row: 5;
  }
  .paymentLogos {
    grid-row: 6/7;
    display: flex;
    align-items: center;
  }
  .partnerLogos {
    grid-row: 6/7;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .duffel-partner-logo {
      margin-left: 7px;
      height: 16px;
      @media (max-width: 620px) {
        margin-top: 5px;
      }
    }
  }

  @media (min-width: 1000px) {
    grid-template-rows: 70px 60px 130px;
    grid-template-columns: 2fr 1fr 1fr 2fr;
    .linkList1,
    .linkList2 {
      grid-row: span 2;
    }
    .socialList {
      grid-column: 1/2;
      grid-row: 2/3;
      text-align: left !important;
    }
    .linkList1 {
      grid-column: 2/3;
      padding: 0px !important;
      margin: 0px;
      font-weight: 500;
    }
    .linkList2 {
      grid-column: 3/4;
      padding: 0px !important;
      margin: 0px;
      font-weight: 500;
    }

    .legalLinks {
      grid-column: 4/5;
      grid-row: 2/3;
      margint-top: -40px;
      p {
        display: none;
      }
    }
    .legalText {
      grid-column: 1/6;
      grid-row: 3/4;
    }
    .partnerLogos {
      grid-row: 5/6;
      grid-column: 1/2;
    }
    .paymentLogos {
      grid-row: 5/6;
      grid-column: 2/4;
    }
    .addressDesktop {
      grid-row: 5/6;
      color: white;
      grid-column: 4/5;
    }
  }

  .logoWrap {
    @media (min-width: 1000px) {
      img {
        height: 50px;
      }
    }
  }

  .socialList {
    margin: 0px;
    list-style: none;
    padding-left: 0px;
    text-align: right;

    li {
      display: inline-block;
      &:not(:last-child) {
        margin-right: 20px;

        @media (max-width: 325px) {
          margin-right: 10px;
        }
      }

      a {
        color: white;
      }
    }
  }

  .linkList1 {
    list-style: none;
    text-align: left;

    padding-left: 28px;

    li a {
      color: white;
      text-decoration: none;
      line-height: 2.5;
    }
  }

  .linkList2 {
    list-style: none;
    text-align: left;

    li a {
      color: white;
      text-decoration: none;
      line-height: 2.5;
    }
  }

  .legalLinks {
    list-style: none;
    color: white;
    text-align: left;
    padding-left: 0px;
    font-size: 10px;
    color: #ffffff;

    p {
      font-size: 12px;
    }

    @media (min-width: 1000px) {
      font-size: 12px;
    }

    li {
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }

  .legalText {
    p {
      font-family: Public Sans;
      font-size: 10px;
      font-style: normal;
      // font-weight: 400;
      line-height: 12px;
      letter-spacing: 0px;
      text-align: left;
      opacity: 0.5;
      @media (min-width: 1000px) {
        font-size: 12px;
      }
    }
  }
  .partnerLogos,
  .paymentLogos {
    span {
      /* opacity: 0.5; */
      font-size: 12px;
    }
    img {
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      padding-left: 6px;
    }
  }

  .addressDesktop {
    display: none;
    @media (min-width: 1000px) {
      display: block;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      // font-weight: 300;
      line-height: 14px;
      letter-spacing: 0px;
      text-align: left;
    }
  }

  .klarnaLogoText {
    @media (min-width: 1000px) {
      display: inline-block;
      vertical-align: text-top;
    }

    @media (max-width: 1000px) {
      padding-top: 3px;
      display: block;
      padding-bottom: 10px;
    }
  }

  .klarnaLogoFooter {
    display: block;
    margin-top: 0 !important;
    @media (min-width: 1000px) {
      display: inline-block !important;
      vertical-align: middle;
    }
    @media (max-width: 1000px) {
      display: block !important;
      margin-bottom: 8px !important;
    }
  }

  padding-bottom: 50px;
`;

const MenuLinks = styled.ul`
  list-style: none;
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;

  padding-left: 28px;

  li a {
    color: white;
    text-decoration: none;
    line-height: 2.5;
  }
  @media (max-width: 999px) {
    .right {
      text-align: right;
      padding-right: 28px;
    }
  }
`;

const Footer = () => {
  const { currency } = useCurrencyContext();

  const isUnitedStates = currency === 'USD';
  return (
    <div className="wrapper">
      <Wrapper>
        <FooterWrapper>
          <div className="logoWrap">
            <img src={FlymbleLogo} alt="Flymble logo" />
          </div>
          <ul className="socialList">
            <li>
              <a
                href="https://www.facebook.com/Flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebookF} className={faStyle} />
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/flymble/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedinIn} className={faStyle} />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/flymbletravel/?hl=en"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faInstagram} className={faStyle} />
              </a>
            </li>
          </ul>
          <MenuLinks style={{ paddingLeft: '50px' }}>
            <li>
              <Link to="/about-us" alt="/">
                About us
              </Link>
            </li>
            <li>
              <Link to="/transparency">Transparency</Link>
            </li>
            <li>
              <Link to="/sitemap" alt="/">
                Sitemap
              </Link>
            </li>
          </MenuLinks>
          <MenuLinks className="right">
            <li>
              <Link to="/help" alt="/">
                Faqs
              </Link>
            </li>
            <li>
              <Link to="/guides" alt="/">
                Blog
              </Link>
            </li>
            <li>
              <Link to="/join-our-team">Careers</Link>
            </li>
          </MenuLinks>
          <ul className="legalLinks">
            <li>
              <a href="/terms-conditions">
                <u>Terms & Conditions, Cookie Policy and Privacy Policy</u>{' '}
              </a>
            </li>
            {/* <li>
              <a href="https://drive.google.com/a/flymble.com/file/d/1SbPk4pT_SlgdVsVhZJgXNXkQAtReT7rp/view?usp=sharing">
                <u>Complaints Policy</u>
              </a>
            </li> */}
          </ul>
          <div className="legalText">
            <p>
              Flymble Travel Limited is an appointed representative of Fly Now
              Pay Later Limited. Fly Now Pay Later acts as a credit intermediary
              and not a lender, and is authorised and regulated by the Financial
              Conduct Authority under registration number 778540. Flymble.com is
              a registered trademark under Flymble Travel LTD, Flymble Travel
              LTD is a subsidiary of Vliegwel BV
            </p>
            <p style={{ marginBottom: '20px' }}>
              Rates are between 10–30% APR. Not all customers will be approved
              for the full purchase amount. Customers are responsible for any
              difference between the approved financed amount and the purchase
              price, if applicable. Subject to eligibility, credit check and
              approval. Payment options depend on your purchase amount.
              Estimated payment amount excludes taxes and shipping fees. Actual
              terms may vary.
              {isUnitedStates && (
                <>
                  Payment options through Fly Now Pay Later are provided by Pay
                  Later Financial Services Inc, 228 Park Ave S, 56453, New York,
                  New York 10003-1502 US, NMLS #2233088 in partnership with
                  Cross River Bank, Member FDIC. Please visit
                  https://flynowpaylater.com/us for more information.
                </>
              )}
            </p>
          </div>
          <div className="partnerLogos">
            <span>Partnered with:</span>
            <img src={partnersImage} alt="partners logos" />
            <DuffelImage className="duffel-partner-logo" />
          </div>
          <div className="paymentLogos">
            <span>Payments accepted:</span>
            <img src={paymentsImage} alt="accepted payments logos" />
          </div>
          {/* <div className="partnerLogos" style={{ gridRow: '6/7' }}>
            <span className="klarnaLogoText">Pay later in 3 with:</span>
            <img
              className="klarnaLogoFooter"
              src={klarnaLogoPink}
              alt="partner logo Klarna"
              style={{ height: '22px', marginBottom: '7px' }}
            />
          </div> */}

          <div className="addressDesktop">
            34b York Way, King's Cross, London, N1 9AB
          </div>
        </FooterWrapper>
      </Wrapper>
    </div>
  );
};

export default Footer;

import { css } from 'emotion/macro';

export const mainStyle = css`
  min-height: 400px;
  @media (min-width: 1000px) {
    min-height: 1000px;
  }
  /* TP widget */
  & > div > div > div.trustpilot-widget {
    @media (min-width: 600px) {
      display: none;
    }
    border-bottom: none;
    margin-top: 10px;
    padding-bottom: 0px;
    margin-bottom: 5px;
  }
`;

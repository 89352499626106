import React, { useEffect } from 'react';

import { IOrderSummaryContext } from './types'

import { useContextValue } from './useContextValue';

const OrderSummaryContext = React.createContext<IOrderSummaryContext | null>(null);

const ORDER_SUMMARY_CONTEXT_KEY = 'orderSummaryContext';
const ORDER_SUMMARY_OBJECT_CONTEXT_KEY = 'orderSummaryObjectContext';

const getInitialValues = ()=>{
  const orderSummaryContext = localStorage.getItem(ORDER_SUMMARY_CONTEXT_KEY)
  const orderSummaryObjectContext = localStorage.getItem(ORDER_SUMMARY_OBJECT_CONTEXT_KEY)

  return {
    initialOrderSummaryContext: orderSummaryContext ? JSON.parse(orderSummaryContext) : '',
    initialOrderSummaryObjectContext: orderSummaryObjectContext ? JSON.parse(orderSummaryObjectContext) : {}
  }
}

export const OrderSummaryProvider: React.FC = props => {
  const { value } = useContextValue(getInitialValues())

  useEffect(()=>{
    localStorage.setItem(ORDER_SUMMARY_CONTEXT_KEY, JSON.stringify(value.orderSummaryContext))
    localStorage.setItem(ORDER_SUMMARY_OBJECT_CONTEXT_KEY, JSON.stringify(value.orderSummaryObjectContext))
  },[value])
  return (
    <OrderSummaryContext.Provider value={value} {...props} />
  );
};

export const useOrderSummaryContext = () => {
  const context = React.useContext(OrderSummaryContext);

  if (!context) {
    throw new Error("useOrderSummaryContext must be inside OrderSummaryProvider");
  }

  return context;
}

import React, { useState } from 'react';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import DatePicker  from './DatePicker/DatePicker';
import { FormLabel, DateRangeSpan } from '../styles/styles';
import '../styles/datepicker.css';
import { useFormContext, Controller } from 'react-hook-form';
import DatePickerUtils from '../utils/DatePickerUtils';

const FormItem = Form.Item;

export default function SearchDatePicker() {
  const { register, getValues, setValue, control } = useFormContext();
  const dateUtils = new DatePickerUtils(getValues, setValue);
  const [dateToOpen, setDateToOpen] = useState(false);
  const [dateFromOpen, setDateFromOpen] = useState(false);
  const dateFormat = 'MMM DD';

  return (
    <div className="datePickerGridMobile">
      <div>
        <FormLabel>Depart</FormLabel>
        <FormItem>
          <Controller
            control={control}
            as={
              <DatePicker
                open={dateFromOpen}
                format={dateFormat}
                showToday={false}
                disabledDate={dateUtils.dateFromDisabledDate}
                renderExtraFooter={() =>
                  'Book flights at least 7 days in advance'
                }
                onOpenChange={dateUtils.handleDateFromOpenChange}
                onSelect={()=> {setDateToOpen(true); setDateFromOpen(false)}}
                onClick={() => {setDateFromOpen(true); setDateToOpen(false);}}
                onBlur={() => setDateFromOpen(false)}
                allowClear={false}
                popupClassName="date-from-style"
                placeholder="Depart"
                style={{
                  width: '100%',
                }}
              />
            }
            name="dateFrom"
          />
        </FormItem>
        <DateRangeSpan  />
      </div>

      <div>
        <FormLabel>Return</FormLabel>
        <FormItem>
          <Controller
            as={
              <DatePicker
                // onChange={null}
                open={dateToOpen}
                format={dateFormat}
                showToday={false}
                disabledDate={dateUtils.dateToDisabledDate}
                renderExtraFooter={() =>
                  'Book flights up to 10 months in advance'
                }
                onOpenChange={dateUtils.handleDateToOpenChange}
                onClick={() => {setDateToOpen(true); setDateFromOpen(false);}}
                onSelect={() => setDateToOpen(false)}
                onBlur={() => setDateToOpen(false)}
                allowClear={false}
                popupClassName="date-to-style"
                placeholder="Return"
                style={{
                  width: '100%',
                }}
              />
            }
            name="dateTo"
          />
        </FormItem>
        <DateRangeSpan />
      </div>
    </div>
  );
}

import styled from 'styled-components';
import background from './HeroSectionMobile/beachDesktop.jpeg';

const flymbleBlue = '#4860ff';
const heroHeight = '570';

export const MainStyled = styled.div`
  color: #fff;
  background-color: white;
  background-size: 1920px 1000px;
  background-attachment: scroll;
  background-position-x: center;
  background-position-y: -145px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1000px) {
    min-height: 470px;
    background-position-y: -220px;
    min-height: ${heroHeight}px;
    background-image: url(${background});
    padding-top: 50px;
    .wrapper {
      margin-left: 1.3em;
      max-height: ${heroHeight}px;
    }
  }

  span.phone-number {
    @media (max-width: 1000px) {
      display: none;
    }
    float: right;
    position: absolute;
    right: 90px;
    top: 90px;

    a {
      font-size: 40px;
      font-weight: 400px;
      color: #fffd;
      text-shadow: 2px 2px 20px rgb(255, 255, 255);

      .faIconPhone {
        transform: scale(-1, 1);
        font-size: 35px;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .titles {
    @media (min-width: 1000px) {
      float: right;
      display: none;
    }
  }

  h1 {
    font-family: 'Montserrat Alternates', sans-serif;
    margin: 0px;
    padding-top: 0px;
    padding-right: 20px;
    font-size: 37px;
    font-weight: 600;
    color: ${flymbleBlue};

    @media (min-width: 1000px) {
      font-size: 45px;
      padding-top: 0px;
      margin-top: 30px;
    }
  }

  h3 {
    padding-top: 11px;
  }

  .desktopOnly {
    @media (max-width: 1000px) {
      display: none;
    }
  }
  .mobileOnly {
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .iataLogo {
    position: absolute;
    right: 30px;
    top: 85px;
    height: 50px;
    padding-right: 20px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  /* This changes are to transfer mobile searchform to desktop */
  @media (min-width: 1000px) {
    .mainGrid {
      grid-template-columns: 1fr repeat(2, minmax(auto, 550px)) 1fr;
      padding-top: 60px;
    }
  }

  .desktopTitles {
    padding-bottom: 30px;
    text-align: left;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    width: 90%;
    max-width: 1122px;
    color: white;

    h2 {
      font-size: 26px;
      margin-block-start: -5px;
      font-weight: 400;
      margin-top: 10px;
    }

    .title {
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 48px;
      font-weight: 700;
      line-height: 57px;
      letter-spacing: 0.5px;
      text-align: left;
      padding-top: 70px;
      margin-bottom: 5px;
    }

    .subtitle {
      padding-top: 5px;
      color: white;
      font-family: -apple-system, BlinkMacSystemFont, sans-serif;
      font-size: 20px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;

    }

    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

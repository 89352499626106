import dayjs from 'dayjs';

//Hot Deals
import Dublin from '../assets/HotDeals/Dublin.webp'
import Dubai from '../assets/HotDeals/Dubai.webp'
import Tenerife from '../assets/HotDeals/Tenerife.webp'
import TenerifeMobile from '../assets/HotDeals/TenerifeBig.webp'
import NewYork from '../assets/HotDeals/NewYork.webp'
import Cancun from '../assets/HotDeals/Cancun.webp'
import CapeTown from '../assets/HotDeals/CapeTown.webp'

//Staycations
import Cornwall from '../assets/Staycations/Cornwall.webp'
import Cotswolds from '../assets/Staycations/Cotswolds.webp'
import LakeDistrict from '../assets/Staycations/LakeDistrict.webp'
import NorthDevon from '../assets/Staycations/NorthDevon.webp'
import Wales from '../assets/Staycations/Wales.webp'
import Whitby from '../assets/Staycations/Whitby.webp'

//Staycations US
import Yosemite from '../assets/StaycationsUS/Yosemite1.webp'
import Anchorage from '../assets/StaycationsUS/Anchorage1.webp'
import SF from '../assets/StaycationsUS/SF1.webp'
import NewOrleans from '../assets/StaycationsUS/NewOrleans.webp'
import DisneyWorld from '../assets/StaycationsUS/DisneyWorld1.webp'
import LasVegas from '../assets/StaycationsUS/LasVegas1.webp'


const departureDate = dayjs().add(150, 'day').format('YYYYMMDD');
const returnDate = dayjs().add(150 + 14, 'day').format('YYYYMMDD');
const startAirport = 'C_LON'

export const HOT_DEALS = [
  {  
    city: 'Dubai',
    country: 'UAE',
    img: Dubai,
    link: `/search-flight/C_DXB/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 14
  },
  {  
    city: 'Dublin',
    country: 'Ireland',
    img: Dublin,
    link: `/search-flight/C_DUB/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 7
  },
  {  
    city: 'Tenerife',
    country: 'Spain',
    img: Tenerife,
    imgMobile: TenerifeMobile,
    link: `/search-flight/C_TCI/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 8
  },
  {  
    city: 'New York',
    country: 'USA',
    img: NewYork,
    link: `/search-flight/C_NYC/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 22
  },
  {  
    city: 'Cancun',
    country: 'Mexico',
    img: Cancun,
    link: `/search-flight/C_CUN/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 29
  },
  {  
    city: 'Cape Town',
    country: 'South Africa',
    img: CapeTown,
    link: `/search-flight/C_CPT/${startAirport}/${departureDate}/${returnDate}/1/0/0/Economy`,
    price: 27
  },
]

export const STAYCATIONS = [
  {  
    city: 'Whitby',
    country: 'United Kingdom',
    img: Whitby,
    price: 7
  },
  {  
    city: 'Cotswolds',
    country: 'United Kingdom',
    img: Cotswolds,
    price: 5
  },
  {  
    city: 'Wales',
    country: 'United Kingdom',
    img: Wales,
    price: 6
  },
  {  
    city: 'Lake District',
    country: 'United Kingdom',
    img: LakeDistrict,
    price: 5
  },
  {  
    city: 'North Devon',
    country: 'United Kingdom',
    img: NorthDevon,
    price: 6
  },
 
  {  
    city: 'Cornwall',
    country: 'United Kingdom',
    img: Cornwall,
    price: 6
  },
]

export const STAYCATIONS_US = [
 
  {  
    city: 'Disney World',
    country: 'Florida',
    img: DisneyWorld,
    price: 6
  }, {  
    city: 'Las Vegas',
    country: 'Nevada',
    img: LasVegas,
    price: 6
  },
  {  
    city: 'Anchorage',
    country: 'Alaska',
    img: Anchorage,
    price: 5
  },

  {  
    city: 'New Orleans',
    country: 'Louisiana',
    img: NewOrleans,
    price: 5
  },
  {  
    city: 'Yosemite National Park',
    country: 'California',
    img: Yosemite,
    price: 6
  },
 
  {  
    city: 'San Francisco',
    country: 'California',
    img: SF,
    price: 7
  }, 
]
const listUKCities = new Map([
 ['C_ABZ', { icon: 'faBuilding', label: 'Aberdeen (all airports), United Kingdom', key: 'C_ABZ', type: 'city', code: 'aberdeen_gb' } ],
 ['C_QDH', { icon: 'faBuilding', label: 'Ashford (all airports), United Kingdom', key: 'C_QDH', type: 'city', code: 'ashford_gb' } ],
 ['C_BRR', { icon: 'faBuilding', label: 'Barra (all airports), United Kingdom', key: 'C_BRR', type: 'city', code: 'barra_gb' } ],
 ['C_BFS', { icon: 'faBuilding', label: 'Belfast (all airports), United Kingdom', key: 'C_BFS', type: 'city', code: 'belfast_gb' } ],
 ['C_BEB', { icon: 'faBuilding', label: 'Benbecula (all airports), United Kingdom', key: 'C_BEB', type: 'city', code: 'benbecula_gb' } ],
 ['C_BDA', { icon: 'faBuilding', label: 'Bermuda (all airports), United Kingdom', key: 'C_BDA', type: 'city', code: 'bermuda_bm' } ],
 ['C_BHX', { icon: 'faBuilding', label: 'Birmingham (all airports), United Kingdom', key: 'C_BHX', type: 'city', code: 'birmingham_gb' } ],
 ['C_BLK', { icon: 'faBuilding', label: 'Blackpool (all airports), United Kingdom', key: 'C_BLK', type: 'city', code: 'blackpool_gb' } ],
 ['C_BOH', { icon: 'faBuilding', label: 'Bournemouth (all airports), United Kingdom', key: 'C_BOH', type: 'city', code: 'bournemouth_gb' } ],
 ['C_MNI', { icon: 'faBuilding', label: 'Brades (all airports), United Kingdom', key: 'C_MNI', type: 'city', code: 'brades_ms' } ],
 ['C_BRS', { icon: 'faBuilding', label: 'Bristol (all airports), United Kingdom', key: 'C_BRS', type: 'city', code: 'bristol_gb' } ],
 ['C_CBG', { icon: 'faBuilding', label: 'Cambridge (all airports), United Kingdom', key: 'C_CBG', type: 'city', code: 'cambridge_gb' } ],
 ['C_CAL', { icon: 'faBuilding', label: 'Campbeltown (all airports), United Kingdom', key: 'C_CAL', type: 'city', code: 'campbeltown_gb' } ],
 ['C_CWL', { icon: 'faBuilding', label: 'Cardiff (all airports), United Kingdom', key: 'C_CWL', type: 'city', code: 'cardiff_gb' } ],
 ['C_ZFI', { icon: 'faBuilding', label: 'Chesterfield (all airports), United Kingdom', key: 'C_ZFI', type: 'city', code: 'chesterfield_gb' } ],
 ['C_CVT', { icon: 'faBuilding', label: 'Coventry (all airports), United Kingdom', key: 'C_CVT', type: 'city', code: 'coventry_gb' } ],
 ['C_LDY', { icon: 'faBuilding', label: 'Derry (all airports), United Kingdom', key: 'C_LDY', type: 'city', code: 'derry_gb' } ],
 ['C_ZEQ', { icon: 'faBuilding', label: 'Dewsbury (all airports), United Kingdom', key: 'C_ZEQ', type: 'city', code: 'dewsbury_gb' } ],
 ['C_XPW', { icon: 'faBuilding', label: 'Didcot (all airports), United Kingdom', key: 'C_XPW', type: 'city', code: 'didcot_gb' } ],
 ['C_DSA', { icon: 'faBuilding', label: 'Doncaster (all airports), United Kingdom', key: 'C_DSA', type: 'city', code: 'doncaster_gb' } ],
 ['C_QQD', { icon: 'faBuilding', label: 'Dover (all airports), United Kingdom', key: 'C_QQD', type: 'city', code: 'dover_gb' } ],
 ['C_DND', { icon: 'faBuilding', label: 'Dundee (all airports), United Kingdom', key: 'C_DND', type: 'city', code: 'dundee_gb' } ],
 ['C_MME', { icon: 'faBuilding', label: 'Durham, England (all airports), United Kingdom', key: 'C_MME', type: 'city', code: 'durham_gb' } ],
 ['C_EOI', { icon: 'faBuilding', label: 'Eday (all airports), United Kingdom', key: 'C_EOI', type: 'city', code: 'eday_gb' } ],
 ['C_EDI', { icon: 'faBuilding', label: 'Edinburgh (all airports), United Kingdom', key: 'C_EDI', type: 'city', code: 'edinburgh_gb' } ],
 ['C_EXT', { icon: 'faBuilding', label: 'Exeter (all airports), United Kingdom', key: 'C_EXT', type: 'city', code: 'exeter_gb' } ],
 ['C_GLA', { icon: 'faBuilding', label: 'Glasgow (all airports), United Kingdom', key: 'C_GLA', type: 'city', code: 'glasgow_gb' } ],
 ['C_INV', { icon: 'faBuilding', label: 'Inverness (all airports), United Kingdom', key: 'C_INV', type: 'city', code: 'inverness_gb' } ],
 ['C_ILY', { icon: 'faBuilding', label: 'Islay (all airports), United Kingdom', key: 'C_ILY', type: 'city', code: 'islay_gb' } ],
 ['C_KOI', { icon: 'faBuilding', label: 'Kirkwall (all airports), United Kingdom', key: 'C_KOI', type: 'city', code: 'kirkwall_gb' } ],
 ['C_HUY', { icon: 'faBuilding', label: 'Kirmington (all airports), United Kingdom', key: 'C_HUY', type: 'city', code: 'humberside_gb' } ],
 ['C_LBA', { icon: 'faBuilding', label: 'Leeds (all airports), United Kingdom', key: 'C_LBA', type: 'city', code: 'leeds_gb' } ],
 ['C_XQT', { icon: 'faBuilding', label: 'Lichfield (all airports), United Kingdom', key: 'C_XQT', type: 'city', code: 'lichfield_gb' } ],
 ['C_LPL', { icon: 'faBuilding', label: 'Liverpool (all airports), United Kingdom', key: 'C_LPL', type: 'city', code: 'liverpool_gb' } ],
 ['C_LON', { icon: 'faBuilding', label: 'London (all airports), United Kingdom', key: 'C_LON', type: 'city', code: 'london_gb' } ],
 ['C_MAN', { icon: 'faBuilding', label: 'Manchester (all airports), United Kingdom', key: 'C_MAN', type: 'city', code: 'manchester_gb' } ],
 ['C_ZMA', { icon: 'faBuilding', label: 'Mansfield (all airports), United Kingdom', key: 'C_ZMA', type: 'city', code: 'mansfield_gb' } ],
 ['C_KYN', { icon: 'faBuilding', label: 'Milton Keynes (all airports), United Kingdom', key: 'C_KYN', type: 'city', code: 'milton-keynes_gb' } ],
 ['C_NCL', { icon: 'faBuilding', label: 'Newcastle upon Tyne (all airports), United Kingdom', key: 'C_NCL', type: 'city', code: 'newcastle-upon-tyne_gb' } ],
 ['C_NQY', { icon: 'faBuilding', label: 'Newquay (all airports), United Kingdom', key: 'C_NQY', type: 'city', code: 'newquay_gb' } ],
 ['C_NRL', { icon: 'faBuilding', label: 'North Ronaldsay (all airports), United Kingdom', key: 'C_NRL', type: 'city', code: 'north-ronaldsay_gb' } ],
 ['C_NWI', { icon: 'faBuilding', label: 'Norwich (all airports), United Kingdom', key: 'C_NWI', type: 'city', code: 'norwich_gb' } ],
 ['C_NQT', { icon: 'faBuilding', label: 'Nottingham (all airports), United Kingdom', key: 'C_NQT', type: 'city', code: 'nottingham_gb' } ],
 ['C_OXF', { icon: 'faBuilding', label: 'Oxford (all airports), United Kingdom', key: 'C_OXF', type: 'city', code: 'oxford_gb' } ],
 ['C_PPW', { icon: 'faBuilding', label: 'Papa Westray (all airports), United Kingdom', key: 'C_PPW', type: 'city', code: 'papa-westray_gb' } ],
 ['C_PLH', { icon: 'faBuilding', label: 'Plymouth (all airports), United Kingdom', key: 'C_PLH', type: 'city', code: 'plymouth_gb' } ],
 ['C_XRE', { icon: 'faBuilding', label: 'Reading (all airports), United Kingdom', key: 'C_XRE', type: 'city', code: 'reading_gb' } ],
 ['C_XRG', { icon: 'faBuilding', label: 'Rugeley (all airports), United Kingdom', key: 'C_XRG', type: 'city', code: 'rugeley_gb' } ],
 ['C_NDY', { icon: 'faBuilding', label: 'Sanday, Orkney (all airports), United Kingdom', key: 'C_NDY', type: 'city', code: 'sanday_gb' } ],
 ['C_SZD', { icon: 'faBuilding', label: 'Sheffield (all airports), United Kingdom', key: 'C_SZD', type: 'city', code: 'sheffield_gb' } ],
 ['C_SDZ', { icon: 'faBuilding', label: 'Shetland Islands (all airports), United Kingdom', key: 'C_SDZ', type: 'city', code: 'shetland_gb' } ],
 ['C_SOU', { icon: 'faBuilding', label: 'Southampton (all airports), United Kingdom', key: 'C_SOU', type: 'city', code: 'southampton_gb' } ],
 ['C_SYY', { icon: 'faBuilding', label: 'Stornoway (all airports), United Kingdom', key: 'C_SYY', type: 'city', code: 'stornoway_gb' } ],
 ['C_SOY', { icon: 'faBuilding', label: 'Stronsay (all airports), United Kingdom', key: 'C_SOY', type: 'city', code: 'stronsay_gb' } ],
 ['C_TRE', { icon: 'faBuilding', label: 'Tiree (all airports), United Kingdom', key: 'C_TRE', type: 'city', code: 'tiree_gb' } ],
 ['C_TTK', { icon: 'faBuilding', label: 'Tottenham (all airports), United Kingdom', key: 'C_TTK', type: 'city', code: 'tottenham_gb' } ],
 ['C_XWD', { icon: 'faBuilding', label: 'Wakefield (all airports), United Kingdom', key: 'C_XWD', type: 'city', code: 'wakefield_gb' } ],
 ['C_XWN', { icon: 'faBuilding', label: 'Warrington (all airports), United Kingdom', key: 'C_XWN', type: 'city', code: 'warrington_gb' } ],
 ['C_WRY', { icon: 'faBuilding', label: 'Westray (all airports), United Kingdom', key: 'C_WRY', type: 'city', code: 'westray_gb' } ],
 ['C_WIC', { icon: 'faBuilding', label: 'Wick (all airports), United Kingdom', key: 'C_WIC', type: 'city', code: 'wick_gb' } ],
 ['C_XWI', { icon: 'faBuilding', label: 'Wigan (all airports), United Kingdom', key: 'C_XWI', type: 'city', code: 'wigan_gb' } ],
 ['C_VLY', { icon: 'faBuilding', label: 'Ynys Môn (all airports), United Kingdom', key: 'C_VLY', type: 'city', code: 'ynys-mon_gb' } ],
]);
const listUKAirports = [
  { icon: 'faPlaneDeparture', label: 'ABZ Aberdeen, United Kingdom, Aberdeen, ABZ, aberdeen aberdeen united kingdom', key:'A_ABZ', cityBase: -987, city: 'ABZ', country: 'GB', type: 'airport', nlabel: 'aberdeen aberdeen united kingdom'},
  { icon: 'faPlaneDeparture', label: 'VLY Ynys Môn, United Kingdom, Anglesey, VLY, anglesey ynys mon united kingdom', key:'A_VLY', cityBase: -873, city: 'VLY', country: 'GB', type: 'airport', nlabel: 'anglesey ynys mon united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BRR Barra, United Kingdom, Barra, BRR, barra barra united kingdom', key:'A_BRR', cityBase: -873, city: 'BRR', country: 'GB', type: 'airport', nlabel: 'barra barra united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BFS Belfast, United Kingdom, Belfast International, BFS, belfast international belfast united kingdom', key:'A_BFS', cityBase: -989, city: 'BFS', country: 'GB', type: 'airport', nlabel: 'belfast international belfast united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BEB Benbecula, United Kingdom, Benbecula, BEB, benbecula benbecula united kingdom', key:'A_BEB', cityBase: -873, city: 'BEB', country: 'GB', type: 'airport', nlabel: 'benbecula benbecula united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BHX Birmingham, United Kingdom, Birmingham, BHX, birmingham birmingham united kingdom', key:'A_BHX', cityBase: -995, city: 'BHX', country: 'GB', type: 'airport', nlabel: 'birmingham birmingham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BLK Blackpool, United Kingdom, Blackpool, BLK, blackpool blackpool united kingdom', key:'A_BLK', cityBase: -891, city: 'BLK', country: 'GB', type: 'airport', nlabel: 'blackpool blackpool united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BOH Bournemouth, United Kingdom, Bournemouth, BOH, bournemouth bournemouth united kingdom', key:'A_BOH', cityBase: -939, city: 'BOH', country: 'GB', type: 'airport', nlabel: 'bournemouth bournemouth united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BRS Bristol, United Kingdom, Bristol, BRS, bristol bristol united kingdom', key:'A_BRS', cityBase: -994, city: 'BRS', country: 'GB', type: 'airport', nlabel: 'bristol bristol united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CBG Cambridge, United Kingdom, Cambridge, CBG, cambridge cambridge united kingdom', key:'A_CBG', cityBase: -873, city: 'CBG', country: 'GB', type: 'airport', nlabel: 'cambridge cambridge united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CAL Campbeltown, United Kingdom, Campbeltown, CAL, campbeltown campbeltown united kingdom', key:'A_CAL', cityBase: -891, city: 'CAL', country: 'GB', type: 'airport', nlabel: 'campbeltown campbeltown united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CWL Cardiff, United Kingdom, Cardiff, CWL, cardiff cardiff united kingdom', key:'A_CWL', cityBase: -989, city: 'CWL', country: 'GB', type: 'airport', nlabel: 'cardiff cardiff united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LDY Derry, United Kingdom, City of Derry, LDY, city of derry derry united kingdom', key:'A_LDY', cityBase: -900, city: 'LDY', country: 'GB', type: 'airport', nlabel: 'city of derry derry united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NQY Newquay, United Kingdom, Cornwall Airport Newquay, NQY, cornwall airport newquay newquay united kingdom', key:'A_NQY', cityBase: -919, city: 'NQY', country: 'GB', type: 'airport', nlabel: 'cornwall airport newquay newquay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'CVT Coventry, United Kingdom, Coventry, CVT, coventry coventry united kingdom', key:'A_CVT', cityBase: -873, city: 'CVT', country: 'GB', type: 'airport', nlabel: 'coventry coventry united kingdom'},
  { icon: 'faPlaneDeparture', label: 'DSA Doncaster, United Kingdom, Doncaster Sheffield, DSA, doncaster sheffield doncaster united kingdom', key:'A_DSA', cityBase: -946, city: 'DSA', country: 'GB', type: 'airport', nlabel: 'doncaster sheffield doncaster united kingdom'},
  { icon: 'faPlaneDeparture', label: 'DND Dundee, United Kingdom, Dundee, DND, dundee dundee united kingdom', key:'A_DND', cityBase: -898, city: 'DND', country: 'GB', type: 'airport', nlabel: 'dundee dundee united kingdom'},
  { icon: 'faPlaneDeparture', label: 'MME Durham, England, United Kingdom, Durham Tees Valley, MME, durham tees valley durham united kingdom', key:'A_MME', cityBase: -902, city: 'MME', country: 'GB', type: 'airport', nlabel: 'durham tees valley durham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'EMA Nottingham, United Kingdom, East Midlands, EMA, east midlands nottingham united kingdom', key:'A_EMA', cityBase: -987, city: 'NQT', country: 'GB', type: 'airport', nlabel: 'east midlands nottingham united kingdom'},
  { icon: 'faPlaneDeparture', label: 'EOI Eday, United Kingdom, Eday, EOI, eday eday united kingdom', key:'A_EOI', cityBase: -873, city: 'EOI', country: 'GB', type: 'airport', nlabel: 'eday eday united kingdom'},
  { icon: 'faPlaneDeparture', label: 'EDI Edinburgh, United Kingdom, Edinburgh, EDI, edinburgh edinburgh united kingdom', key:'A_EDI', cityBase: -996, city: 'EDI', country: 'GB', type: 'airport', nlabel: 'edinburgh edinburgh united kingdom'},
  { icon: 'faPlaneDeparture', label: 'EXT Exeter, United Kingdom, Exeter, EXT, exeter exeter united kingdom', key:'A_EXT', cityBase: -983, city: 'EXT', country: 'GB', type: 'airport', nlabel: 'exeter exeter united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LGW London, United Kingdom, Gatwick, LGW, gatwick london united kingdom', key:'A_LGW', cityBase: -998, city: 'LON', country: 'GB', type: 'airport', nlabel: 'gatwick london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BHD Belfast, United Kingdom, George Best Belfast City, BHD, george best belfast city belfast united kingdom', key:'A_BHD', cityBase: -926, city: 'BFS', country: 'GB', type: 'airport', nlabel: 'george best belfast city belfast united kingdom'},
  { icon: 'faPlaneDeparture', label: 'GLA Glasgow, United Kingdom, Glasgow, GLA, glasgow glasgow united kingdom', key:'A_GLA', cityBase: -995, city: 'GLA', country: 'GB', type: 'airport', nlabel: 'glasgow glasgow united kingdom'},
  { icon: 'faPlaneDeparture', label: 'PIK Glasgow, United Kingdom, Glasgow Prestwick, PIK, glasgow prestwick glasgow united kingdom', key:'A_PIK', cityBase: -884, city: 'GLA', country: 'GB', type: 'airport', nlabel: 'glasgow prestwick glasgow united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LHR London, United Kingdom, Heathrow, LHR, heathrow london united kingdom', key:'A_LHR', cityBase: -999, city: 'LON', country: 'GB', type: 'airport', nlabel: 'heathrow london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'HUY Kirmington, United Kingdom, Humberside, HUY, humberside kirmington united kingdom', key:'A_HUY', cityBase: -900, city: 'HUY', country: 'GB', type: 'airport', nlabel: 'humberside kirmington united kingdom'},
  { icon: 'faPlaneDeparture', label: 'INV Inverness, United Kingdom, Inverness, INV, inverness inverness united kingdom', key:'A_INV', cityBase: -943, city: 'INV', country: 'GB', type: 'airport', nlabel: 'inverness inverness united kingdom'},
  { icon: 'faPlaneDeparture', label: 'ILY Islay, United Kingdom, Islay, ILY, islay islay united kingdom', key:'A_ILY', cityBase: -903, city: 'ILY', country: 'GB', type: 'airport', nlabel: 'islay islay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'MNI Brades, United Kingdom, John A. Osborne, MNI, john a osborne brades montserrat', key:'A_MNI', cityBase: -873, city: 'MNI', country: 'GB', type: 'airport', nlabel: 'john a osborne brades montserrat'},
  { icon: 'faPlaneDeparture', label: 'KOI Kirkwall, United Kingdom, Kirkwall, KOI, kirkwall kirkwall united kingdom', key:'A_KOI', cityBase: -883, city: 'KOI', country: 'GB', type: 'airport', nlabel: 'kirkwall kirkwall united kingdom'},
  { icon: 'faPlaneDeparture', label: 'BDA Bermuda, United Kingdom, L.F. Wade International, BDA, l f wade international bermuda bermuda united kingdom', key:'A_BDA', cityBase: -976, city: 'BDA', country: 'GB', type: 'airport', nlabel: 'l f wade international bermuda bermuda united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LBA Leeds, United Kingdom, Leeds Bradford, LBA, leeds bradford leeds united kingdom', key:'A_LBA', cityBase: -990, city: 'LBA', country: 'GB', type: 'airport', nlabel: 'leeds bradford leeds united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LPL Liverpool, United Kingdom, Liverpool John Lennon, LPL, liverpool john lennon liverpool united kingdom', key:'A_LPL', cityBase: -987, city: 'LPL', country: 'GB', type: 'airport', nlabel: 'liverpool john lennon liverpool united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LCY London, United Kingdom, London City, LCY, london city london united kingdom', key:'A_LCY', cityBase: -991, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london city london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'OXF Oxford, United Kingdom, London Oxford Airport, OXF, london oxford airport oxford united kingdom', key:'A_OXF', cityBase: -873, city: 'OXF', country: 'GB', type: 'airport', nlabel: 'london oxford airport oxford united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SEN London, United Kingdom, London Southend, SEN, london southend london united kingdom', key:'A_SEN', cityBase: -984, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london southend london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'STN London, United Kingdom, London Stansted, STN, london stansted london united kingdom', key:'A_STN', cityBase: -994, city: 'LON', country: 'GB', type: 'airport', nlabel: 'london stansted london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LTN London, United Kingdom, Luton, LTN, luton london united kingdom', key:'A_LTN', cityBase: -993, city: 'LON', country: 'GB', type: 'airport', nlabel: 'luton london united kingdom'},
  { icon: 'faPlaneDeparture', label: 'MAN Manchester, United Kingdom, Manchester, MAN, manchester manchester united kingdom', key:'A_MAN', cityBase: -997, city: 'MAN', country: 'GB', type: 'airport', nlabel: 'manchester manchester united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NCL Newcastle upon Tyne, United Kingdom, Newcastle, NCL, newcastle newcastle upon tyne united kingdom', key:'A_NCL', cityBase: -992, city: 'NCL', country: 'GB', type: 'airport', nlabel: 'newcastle newcastle upon tyne united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NRL North Ronaldsay, United Kingdom, North Ronaldsay, NRL, north ronaldsay north ronaldsay united kingdom', key:'A_NRL', cityBase: -873, city: 'NRL', country: 'GB', type: 'airport', nlabel: 'north ronaldsay north ronaldsay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NWI Norwich, United Kingdom, Norwich, NWI, norwich norwich united kingdom', key:'A_NWI', cityBase: -958, city: 'NWI', country: 'GB', type: 'airport', nlabel: 'norwich norwich united kingdom'},
  { icon: 'faPlaneDeparture', label: 'PPW Papa Westray, United Kingdom, Papa Westray, PPW, papa westray papa westray united kingdom', key:'A_PPW', cityBase: -873, city: 'PPW', country: 'GB', type: 'airport', nlabel: 'papa westray papa westray united kingdom'},
  { icon: 'faPlaneDeparture', label: 'PLH Plymouth, United Kingdom, Plymouth City, PLH, plymouth city plymouth united kingdom', key:'A_PLH', cityBase: -873, city: 'PLH', country: 'GB', type: 'airport', nlabel: 'plymouth city plymouth united kingdom'},
  { icon: 'faPlaneDeparture', label: 'NDY Sanday, Orkney, United Kingdom, Sanday, NDY, sanday sanday united kingdom', key:'A_NDY', cityBase: -873, city: 'NDY', country: 'GB', type: 'airport', nlabel: 'sanday sanday united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SOU Southampton, United Kingdom, Southampton, SOU, southampton southampton united kingdom', key:'A_SOU', cityBase: -985, city: 'SOU', country: 'GB', type: 'airport', nlabel: 'southampton southampton united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SYY Stornoway, United Kingdom, Stornoway, SYY, stornoway stornoway united kingdom', key:'A_SYY', cityBase: -889, city: 'SYY', country: 'GB', type: 'airport', nlabel: 'stornoway stornoway united kingdom'},
  { icon: 'faPlaneDeparture', label: 'SOY Stronsay, United Kingdom, Stronsay, SOY, stronsay stronsay united kingdom', key:'A_SOY', cityBase: -873, city: 'SOY', country: 'GB', type: 'airport', nlabel: 'stronsay stronsay united kingdom'},
  { icon: 'faPlaneDeparture', label: 'LSI Shetland Islands, United Kingdom, Sumburgh, LSI, sumburgh shetland islands united kingdom', key:'A_LSI', cityBase: -891, city: 'SDZ', country: 'GB', type: 'airport', nlabel: 'sumburgh shetland islands united kingdom'},
  { icon: 'faPlaneDeparture', label: 'TRE Tiree, United Kingdom, Tiree, TRE, tiree tiree united kingdom', key:'A_TRE', cityBase: -873, city: 'TRE', country: 'GB', type: 'airport', nlabel: 'tiree tiree united kingdom'},
  { icon: 'faPlaneDeparture', label: 'WRY Westray, United Kingdom, Westray, WRY, westray westray united kingdom', key:'A_WRY', cityBase: -873, city: 'WRY', country: 'GB', type: 'airport', nlabel: 'westray westray united kingdom'},
  { icon: 'faPlaneDeparture', label: 'WIC Wick, United Kingdom, Wick, WIC, wick wick united kingdom', key:'A_WIC', cityBase: -878, city: 'WIC', country: 'GB', type: 'airport', nlabel: 'wick wick united kingdom'},
];
export { listUKAirports, listUKCities };

import React from 'react';
import styled from 'styled-components';
import { darkBlue2, darkBlueLighter, darkBlueLighter2  } from '../../../styles/colors';
import { AirlineRowTop } from './AirlineRowTop';

interface CheckoutRowTopProps {
  outbound: boolean;
  dateText: string
  rightText?: string;
  airlineCodes: string[];
}

const CheckoutRowTopWrapper = styled.div<{
  outbound: any;
}>`
  color: ${darkBlue2};
  grid-column: 1/3;
  font-family: 'Public Sans';
  .airlineRow {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .airlineInfo {
    padding-bottom: 7px !important;
  }
  padding-top: ${(props: any) => props.outbound ? '10px' : '18px'};
  @media(min-width: 1000px) {
    .airlineRow {
      padding-left: 30px !important;
      padding-right: 30px !important;
      padding-bottom: 12px;
      padding-top: 15px;
    }
    .airlineInfo {
    padding-bottom: 0px !important;
  }
    padding-top: ${(props: any) => props.outbound ? '32px' : 0};
  }
`
const Row = styled.div` 
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  @media(min-width: 1000px) {
    padding-bottom: 0px;
  }
`
const DirectionInfo = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding-left: 0;
  @media(min-width: 1000px) {
    font-size: 16px;
    padding-left: 30px;
  }
`
const DateInfo = styled.span`
  color: ${darkBlueLighter};
  margin-left: 10px;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  @media(min-width: 1000px) {
    font-size: 14px;  
  }
`
const RightText = styled.span`
  color: ${darkBlueLighter2};
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  padding-right: 0;
  @media(min-width: 1000px) {
    padding-right: 30px;
  }
`

const CheckoutRowTop = (props: CheckoutRowTopProps) => {
  const { outbound, dateText, rightText, airlineCodes } = props;

  return (
    <CheckoutRowTopWrapper outbound={outbound}>
      <Row>
        <div>
          <DirectionInfo>{outbound ? 'Outbound' : 'Return'}</DirectionInfo>
          <DateInfo>{dateText}</DateInfo>
        </div>
        <div>
          <RightText>{rightText}</RightText>
        </div>
      </Row>
      <AirlineRowTop airlineCodes={airlineCodes} cabin="Economy" flightLegCodes={airlineCodes}/>
  
    </CheckoutRowTopWrapper>
  )
}

export default CheckoutRowTop
import { ApolloClient, ApolloLink, HttpLink } from '@apollo/client';

import { cache } from './cache';

const searchFlightsLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  // uri: process.env.REACT_APP_GRAPHQL_URL_LOCAL,
});

const blogLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_BLOG_URL + '/graphql',
  // uri: 'http://localhost:1337/graphql',
});

export const client = new ApolloClient({
  cache,
  link: ApolloLink.split(
    operation => operation.getContext().clientName === 'search',
    searchFlightsLink,
    blogLink
  ),
});

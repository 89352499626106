import { NetworkStatus, useLazyQuery } from '@apollo/client';

import {
  IQueryCitiesData,
  QueryFlights,
} from 'apollo/client/flight/QueryFlights';

export const useLazyQueryFlights = () => {
  const [
    queryFlights,
    { data, loading, refetch, networkStatus },
  ] = useLazyQuery<IQueryCitiesData>(QueryFlights, {
    onError: handleError,
    notifyOnNetworkStatusChange: true,
    context: {
      clientName: 'search',
    },
  });

  function handleError() {
    // TODO: handler error message if we need it
  }

  const isLoading = loading || networkStatus === NetworkStatus.refetch;

  return {
    data,
    refetch,
    isLoading,
    queryFlights,
  };
};

import styled from 'styled-components';

export const PressArticlesWrapper = styled.div`
  padding-left: 1.3em;
  padding-right: 1.3em;
  background-color: #4860ff;

  @media (min-width: 1000px) {
    padding: 100px 1.3em;
  }

  @media (max-width: 1000px) {
    display: none;
  }

  @media (max-width: 599px) {
    padding-top: 20px;
    padding-bottom: 45px;
    margin-bottom: 0px;
  }

  .articleCard {
    background-color: white;
    margin: 10px;
    padding-top: 12px;
    padding-left: 15px;
    padding-right: 10px;
    padding-bottom: 0px;
    border-radius: 5px;
    text-align: left;

    :last-child {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    img {
      max-height: 18px;
      @media (max-width: 1000px) {
        width: 96px;
      }
    }

    a {
      float: right;
      text-decoration: none;
      color: #0079ff;
      font-size: 14px;
      font-weight: 400;
    }

    p {
      color: black;
      padding: 8px 0 14px;
      font-size: 14px;
    }
  }

  .pressArticlesGrid {
    text-align: center;
    line-height: 1.42857;

    @media (min-width: 1000px) {
      display: grid;
      grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    }

    h2 {
      color: white !important;
      text-align: center;
      padding-bottom: 56px;
      font-size: 36px;
      margin-top: 0px;
      margin-bottom: 0px;

      @media (min-width: 1000px) {
        grid-column: 2/4;
        grid-row: 1/2;
      }
    }
  }

  .viewMoreArticles {
    padding-top: 35px;
    text-align: center;
    font-size: 14px;
    text-decoration: none;
    color: white;
    line-height: 1.42857;
    @media (min-width: 1000px) {
      grid-row: 4/5;
      grid-column: 2/4;
    }
  }

  @media (min-width: 1000px) {
    .article1 {
      grid-row: 2/3;
      grid-column: 2/3;
    }

    .article2 {
      grid-row: 2/3;
      grid-column: 3/4;
    }

    .article3 {
      grid-row: 3/4;
      grid-column: 2/3;
    }

    .article4 {
      grid-row: 3/4;
      grid-column: 3/4;
    }
  }
`;

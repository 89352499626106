export const getPassengers = obj => {
  let passengers = [];
  const passengerNrs = Object.keys(obj)
    .map(key => key.includes('P') && key.includes('_') && key[1])
    .filter((v, i, a) => v && a.indexOf(v) === i);

  passengerNrs.forEach((passenger, index) => {
    return passengers.push({
      passengerNr: passenger,

      [`${index}_firstName`]: obj[`P${passenger}_firstname`],

      [`${index}_lastName`]: obj[`P${passenger}_lastname`],

      [`${index}_gender`]: obj[`P${passenger}_gender`],

      [`${index}_dateOfBirth`]: `${obj[`P${passenger}_dobYear`]}-${
        obj[`P${passenger}_dobMonth`]
      }-${obj[`P${passenger}_dobDay`]}`,

      [`${index}_nationality`]: obj[`P${passenger}_nationality`],

      [`${index}_idNumber`]: obj[`P${passenger}_idNumber`],

      [`${index}_idExpiryDate`]: `${obj[`P${passenger}_idExpYear`]}-${
        obj[`P${passenger}_idExpMonth`]
      }-${obj[`P${passenger}_idExpDay`]}`,
      [`${index}_duffelId`]: obj[`P${passenger}_id`],
    });
  });
  console.log(passengers);
  return passengers;
};

import React, { Component } from 'react';
import { dayjs } from '../../Services/dayjs';
import {
  KIND_OF_TRIP,
  BOOKING_TYPES,
  MINIMUM_BOOKING_PERIOD,
} from '../../constants/constants';
import { PropTypes } from 'prop-types';
import { PARTNERS } from 'Utils/partnerConstants';
import { FORMSTEPS } from 'Utils/index';
import { VARIANT_LOCALE, VARIANT_OPTIMAL_MONTHS } from '../../constants/env';

import { testCheckoutFormValues } from 'Utils/__tests__/testCheckoutFormValues';

const AppContext = React.createContext();
const AppConsumer = AppContext.Consumer;
dayjs.locale(VARIANT_LOCALE);

const STATE_KEY = 'state';
const action = {
  SET_PAYMENT_PROVIDER: 'SET_PAYMENT_PROVIDER',
};
/**
 * Main Context Provider for the App, at least for now (July 2018)
 *
 */
class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchKey: null, // We use this key to know if user maked a new search request with the same search params
      typeBookingContext: BOOKING_TYPES.FNPL_Flights,
      lastSearch: {
        noMonths: VARIANT_OPTIMAL_MONTHS,
        noAdults: 1,
        noChildren: 0,
        noInfants: 0,
        typeFlight: KIND_OF_TRIP.ROUND,
      },
      resultTabs: {
        t1: 5,
        t2: 5,
        t3: 5,
      },
      showResults: true,
      selectedFlightTicket:
        // process.env.NODE_ENV === 'development' ? testFlightTicket : null,
        process.env.NODE_ENV === 'development' ? null : null,
      discountCode: {
        code: null,
        value: 0,
      },
      paymentProvider: '',
      paymentPlanPartnerSelected: PARTNERS.FNPL,
      currentFormStepContext: FORMSTEPS.STEP1,
      setSearchKey: this.setSearchKey,
      setCurrentFormStepContext: this.setCurrentFormStepContext,
      setPaymentPlanPartner: this.setPaymentPlanPartner,
      get: this.getContext,
      setLastSearch: this.setLastSearch,
      setAmount: this.setAmount,
      toggleShowResults: this.toggleShowResults,
      setSelectedFlightTicket: this.setSelectedFlightTicket,
      setDiscountCode: this.setDiscountCode,
      isOneWayFlight: this.isOneWayFlight,
      setPaymentProvider: this.setPaymentProvider,
      setCheckoutProgress: this.setCheckoutProgress,
      // setOrderSummaryContext: this.setOrderSummaryContext,
      orderSummaryContext: null,
      orderSummaryObjectContext: {},
      changeTypeBookingContext: this.changeTypeBookingContext,
      // START --- THIS NEEDS TO BE AMMENDED WITH CHECKFLIGHTS.
      getBaggagePiecesAndPrices: this.getBaggagePiecesAndPrices,
      totalBaggagePriceContext: 0,
      addedBaggagePiecesContext: 0,
      purchasedBaggageTypes: null,
      // END --- THIS NEEDS TO BE AMMENDED WITH CHECKFLIGHTS.
      // START NEW CHECK FLIGHTS
      removePurchasedBaggage: this.removePurchasedBaggage,
      setPurchasedBaggage: this.setPurchasedBaggage,
      purchasedBaggage: null,
      setCheckFlightsBaggageContext: this.setCheckFlightsBaggageContext,
      checkFlightsBaggageContext: null,
      duffelPrice: 0,
      setDuffelPrice: this.setDuffelPrice,
      // END NEW CHECK FLIGHTS
      // START CHECKOUT VALUES
      firstCheckoutPageFormValuesContext:
        process.env.NODE_ENV === 'development' ||
        process.env.NODE_ENV === 'staging'
          ? testCheckoutFormValues
          : null,
      // process.env.NODE_ENV === 'development' ? null : null,
      setFirstCheckoutPageFormValuesContext:
        this.setFirstCheckoutPageFormValuesContext,
      setPartnerBookingReferenceContext: this.setPartnerBookingReferenceContext,
      partnerBookingReferenceContext: false,
      // END CHECKOUT VALUES
      isAddedTicketServiceContext: false,
      isAddedPremiumSupportContext: false,
      addTicketServiceContext: this.addTicketServiceContext,
      addPremiumSupport: this.addPremiumSupport,
      showMaintenancePopup: false,
      setShowMaintenancePopup: this.setShowMaintenancePopup,
      showUSASoonPopup: false,
      setShowUSASoonPopup: this.setShowUSASoonPopup,
      setCabinClass: this.setCabinClass,
      checkoutProgress: {
        stepNumber: 2,
        stepNameDesktop: "Details",
        stepNameMobile: "Provide personal details",
        stepNext: "credit check"
      },
      cabinClass: "Economy"
    };

    const savedState = localStorage.getItem(STATE_KEY)
    if(savedState){
      this.state = {
        ...this.state,
        ...(JSON.parse(savedState)),
      }
    }

    const lastSearch = JSON.parse(
      localStorage.getItem('AppContext.lastSearch')
    );
    if (lastSearch) {
      let tomorrow = dayjs().add(MINIMUM_BOOKING_PERIOD, 'days').startOf('day');
      let daysDifference = dayjs(lastSearch.dateTo).diff(
        dayjs(lastSearch.dateFrom),
        'days'
      );
      let dateFrom = dayjs.max(dayjs(lastSearch.dateFrom), tomorrow);
      let dateTo = dayjs.max(
        dayjs(lastSearch.dateTo),
        tomorrow.clone().add(daysDifference, 'days')
      );
      this.state.lastSearch = {
        noInfants: 0,
        ...this.state.lastSearch,
        ...lastSearch,
        dateFrom: dayjs(dateFrom),
        dateTo: dayjs(dateTo),
      };
    }
  }

  componentDidUpdate(props, state ){
    localStorage.setItem(STATE_KEY, JSON.stringify(state))
  }

  toggleShowResults = (show) => {
    this.setState({ showResults: show });
  };

  setShowMaintenancePopup = (show) => {
    this.setState({ showMaintenancePopup: show });
  };

  setShowUSASoonPopup = (show) => {
    this.setState({ showUSASoonPopup: show });
  };
  /**
   * Returns copy of state as main Context object
   *
   * To change the state of the Context, you must use {@link setLastSearch}
   */
  getContext = () => {
    return Object.assign({}, this.state);
  };

  /**
   * Calculate optimal payback time before today and date of departure
   * If lower than minimum, take 3 months as default
   */

  getOptimalPaybackTime = (_dateFrom) => {
    // let dateDiff = Math.floor(_dateFrom.diff(moment(), 'months'));
    // ******************* OPTIMAL MONTHS FUNCTIONALITY *******************
    // uncomment to turn the functionality ON
    // return Math.max(3, Math.min(12, dateDiff));
    // uncomment to turn the functionality OFF
    return parseInt(VARIANT_OPTIMAL_MONTHS, 10);
  };

  setDuffelPrice = (price) => {
    this.setState({ duffelPrice: price });
  }

  /**
   * Set the main Context object - state
   *
   * @param {object} state
   * @param {function} callback function
   *
   * Also after succesfull async change localstore is updated
   * And call @param callback after state is changed
   */
  setLastSearch = (value, afterCallback = () => {}) => {
    this.setState(
      (prevState) => ({
        lastSearch: {
          ...prevState.lastSearch,
          ...value,
        },
      }),
      () => {
        localStorage.setItem(
          'AppContext.lastSearch',
          JSON.stringify(this.state.lastSearch)
        );
        afterCallback();
      }
    );
  };

  setResultBrowser = (values) => {
    this.setState(({ resultTabs }) => ({
      resultTabs: {
        ...resultTabs,
        ...values,
      },
    }));
  };

  setAmount = (value) => {
    this.setLastSearch({ noMonths: value });
  };

  actionCreator = ({ type, payload }) => {
    switch (type) {
      case action.SET_PAYMENT_PROVIDER:
        this.setState((state) => ({
          ...state,
          paymentProvider: payload.paymentProvider,
        }));
        break;
      default:
        break;
    }
  };

  setPaymentProvider = (value) => {
    this.setState({ paymentProvider: value });
  };

  setSelectedFlightTicket = (ticket) => {
    this.setState({
      selectedFlightTicket: ticket,
      // When a new ticket is selected we also reset the purchased and included baggage:
      purchasedBaggage: null,
    });
  };

  setDiscountCode = (discountCode) => {
    this.setState({ discountCode });
  };

  setCabinClass = (cabinClass) => {
    this.setState({cabinClass: cabinClass});
  }

  setSearchKey = (searchKey) => {
    this.setState({ searchKey });
  };

  setDiscountCode = (discountCode) => {
    this.setState({ discountCode });
  };

  isOneWayFlight = (kindOfTrip) =>
    kindOfTrip
      ? kindOfTrip === KIND_OF_TRIP.ONE_WAY
      : this.state.lastSearch.typeFlight === KIND_OF_TRIP.ONE_WAY;

  setPaymentPlanPartner = (partnerName) => {
    this.setState({
      paymentPlanPartnerSelected: partnerName,
    });
  };

  setCurrentFormStepContext = step => {
    const { STEP1, STEP2, STEP3, STEP4, STEP5, STEP0 } = FORMSTEPS;

    switch (step) {
      case STEP0:
        this.setState({ currentFormStepContext: STEP0 });
        break;
      case STEP1:
        this.setState({ currentFormStepContext: STEP1 });
        break;
      case STEP2:
        this.setState({ currentFormStepContext: STEP2 });
        break;
      case STEP3:
        this.setState({ currentFormStepContext: STEP3 });
        break;
      case STEP4:
        this.setState({ currentFormStepContext: STEP4 });
        break;
      case STEP5:
        this.setState({ currentFormStepContext: STEP5 });
        break;
      default:
        this.setState({ currentFormStepContext: STEP1 });
        break;
    }
  };

  changeTypeBookingContext = (booking_type) => {
    this.setState({ typeBookingContext: booking_type });
  };

  setFirstCheckoutPageFormValuesContext = (checkoutValues) => {
    this.setState({ firstCheckoutPageFormValuesContext: checkoutValues });
  };

  // -------> Credit check page
  setPartnerBookingReferenceContext = (value) => {
    this.setState({ partnerBookingReferenceContext: value });
  };

  // <-------------

  // --------------> New Check Flight Functions.
  setCheckFlightsBaggageContext = (object) => {
    this.setState({
      checkFlightsBaggageContext: object,
    });
  };

  // <--------------

  getBaggagePiecesAndPrices = () => {
    let passengers = Object.keys(this.state.purchasedBaggage).map(
      (key) => this.state.purchasedBaggage[key]
    );


    let purchasedBaggageTypes = [].concat.apply(
      [],
      passengers.map((passenger) =>
        Object.keys(passenger).map((key) => {
          let dummyObject = {};
          return Object.assign(dummyObject, {
            [key]: passenger[key],
          });
        })
      )
    );

    let pricesArray = [].concat.apply(
      [],
      passengers.map((passenger) =>
        Object.keys(passenger).map((key) => passenger[key])
      )
    );

    let addedBaggagePieces = pricesArray.length;
    let totalBaggagePrice = 0;
    if (pricesArray.length !== 0) {
      totalBaggagePrice = pricesArray.reduce((a, b) => a + b);
    }

    this.setState({
      totalBaggagePriceContext: totalBaggagePrice,
      addedBaggagePiecesContext: addedBaggagePieces,
      purchasedBaggageTypesContext: purchasedBaggageTypes,
    });

    let baggagePiecesCount = {};
    const pricesByType = [
      purchasedBaggageTypes.reduce((acc, n) => {
        for (let prop in n) {
          if (acc.hasOwnProperty(prop)) {
            acc[prop] += n[prop];
          } else acc[prop] = n[prop];
          baggagePiecesCount[prop]
            ? (baggagePiecesCount[prop] += 1)
            : (baggagePiecesCount[prop] = 1);
        }
        return acc;
      }, {}),
    ];
    console.log(baggagePiecesCount);
    return {
      addedBaggagePieces,
      totalBaggagePrice,
      pricesByType,
      baggagePiecesCount,
    };
  };

  setPurchasedBaggage = (passengerId, baggageType, price) => {
    let isExistingPassenger = false;
    if (this.state.purchasedBaggage) {
      isExistingPassenger =
        Object.keys(this.state.purchasedBaggage).indexOf(passengerId) !== -1;
    }

    if (isExistingPassenger) {
      this.setState((prevState) => ({
        purchasedBaggage: {
          ...prevState.purchasedBaggage,
          [passengerId]: {
            ...prevState.purchasedBaggage[passengerId],
            [baggageType]: price,
          },
        },
      }));
    } else {
      this.setState({
        purchasedBaggage: {
          ...this.state.purchasedBaggage,
          [passengerId]: {
            [baggageType]: price,
          },
        },
      });
    }
  };

  removePurchasedBaggage = (passengerId, baggageType) => {
    let isExistingPassenger = false;
    if (this.state.purchasedBaggage) {
      isExistingPassenger =
        Object.keys(this.state.purchasedBaggage).indexOf(passengerId) !== -1;
    }

    if (isExistingPassenger) {
      if (this.state.purchasedBaggage[passengerId][baggageType]) {
        let oldState = Object.assign({}, this.state.purchasedBaggage);
        delete oldState[passengerId][baggageType];
        this.setState((prevState) => ({
          purchasedBaggage: {
            ...prevState.purchasedBaggage,
            [passengerId]: {
              ...oldState[passengerId],
            },
          },
        }));
      }
    }
  };

  setCheckoutProgress = (progress) => {
    this.setState({checkoutProgress: progress});
  }

  addTicketServiceContext = initialize => {
    if (initialize === 1) {
      this.setState({ isAddedTicketServiceContext: false });
    } else {
      this.setState((prevState) => ({
        isAddedTicketServiceContext: !prevState.isAddedTicketServiceContext,
      }));
    }
  };

  addPremiumSupport = initialize => {
    if (initialize === 1) {
      this.setState({ isAddedPremiumSupportContext: false });
    } else {
      this.setState((prevState) => ({
        isAddedPremiumSupportContext: !prevState.isAddedPremiumSupportContext,
      }));
    }
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

const useAppContext = () => {
  const context = React.useContext(AppContext);

  if (!context) {
    throw new Error('useAppContext must be inside AppProvider');
  }

  return context;
};

export default AppContext;
export { AppProvider, AppConsumer, useAppContext };

import { gql } from '@apollo/client';

import { Flight } from 'generated/types';

import { PlaceFragment } from 'apollo/client/place/PlaceFragment';
import { SegmentFragment } from 'apollo/client/segment/SegmentFragment';
import { FlightScalarFieldsFragment } from 'apollo/client/flight/FlightScalarFieldsFragment';

export interface IQueryCitiesData {
  flights: Flight[];
}

export const QueryFlights = gql`
  query QueryFlights(
    $where: FlightsWhereInput!
    $sortBy: FlightSort!
    $filters: FlightsFiltersInput
    $priceVariant: PriceVariant
    $source: FlightSource
    $cabin: CabinClass
  ) {
    flights(
      where: $where
      sortBy: $sortBy
      filters: $filters
      priceVariant: $priceVariant
      source: $source
      cabin: $cabin
    ) {
      ...FlightScalarFieldsFragment
      outbound {
        ...SegmentFragment
      }
      return {
        ...SegmentFragment
      }
    }
  }
  ${PlaceFragment}
  ${SegmentFragment}
  ${FlightScalarFieldsFragment}
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { darkBlue2, purple, white, grey3 } from '../../../../styles/colors';
import { signUpUserEmailCollect } from '../../../../Utils';
import { useAppContext } from 'Context/AppContext';

const Wrapper = styled.div`
  order: 2;
  background-color: ${white};
  display: flex;
  justify-content: center;
  padding-bottom: 120px;
  @media (max-width: 999px) {
    order: 1;
    padding: 50px 16px;
  }
`;
const SignupCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1122px;
  background-color: ${purple};
  width: 90%;
  padding: 40px 40px 53px 40px;
  border-radius: 8px;
  @media (max-width: 999px) {
    width: 100%;
    padding: 35px 19px 35px 19px;
    border-radius: 5px;
  }
`;
const WhiteEllipse = styled.div`
  background-color: ${white};
  width: 163px;
  height: 135px;
  border-radius: 50%;
  position: absolute;
  top: -80px;
  right: 50px;
  z-index: 10;
  @media (max-width: 999px) {
    display: none;
  }
`;
const Text = styled.div`
  color: ${white};
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
`;
const Title = styled(Text)`
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 15px;
  @media (max-width: 999px) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 11px;
  }
`;
const Subtitle = styled(Text)`
  width: 60%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 47px;
  @media (max-width: 999px) {
    .desktop {
      display: none;
    }
    width: 90%;
    font-size: 13px;
    line-height: 17px;
    margin-bottom: 25px;
  }
`;
const Form = styled.form`
  display: flex;
  width: 100%;
  justify-content: center;
`;
const Input = styled.input`
  width: 70%;
  border: none;
  border-radius: 5px;
  background-color: ${white};
  padding: 16px 30px;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  &::placeholder {
    color: ${grey3};
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
  }
  @media (max-width: 999px) {
    padding: 12px 13px;
    font-size: 14px;
    line-height: 17px;
  }
`;
const Button = styled.button`
  border: none;
  cursor: pointer;
  color: ${darkBlue2};
  background-color: ${white};
  font-size: 16px;
  font-weight: 700;
  width: 116px;
  height: 50px;
  border-radius: 5px;
  text-align: center;
  line-height: 50px;
  margin-left: 10px;
  @media (max-width: 999px) {
    margin-left: 7px;
    height: 40px;
    width: 77px;
    line-height: 40px;
    font-size: 14px;
  }
  outline: none;
`;
const ThankYou = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${white};
`;

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [signedUp, setSignedUp] = useState(false);
  const { lastSearch } = useAppContext();

  const subscribeOrSaveEmail = (lastSearch: any, email: any) => {
    const flymbleUserId = localStorage.getItem('flymbleUserId');
    const source = `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`;
    signUpUserEmailCollect(
      lastSearch,
      flymbleUserId,
      email,
      source,
      'UK',
      'NEWSLETTER'
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!email) return;
    // signUpToMailchimp(email, 'newsletter');
    subscribeOrSaveEmail(lastSearch, email);
    setSignedUp(true);
  };

  return (
    <Wrapper>
      <SignupCard>
        <WhiteEllipse />
        <Title>Access to exclusive deals</Title>
        <Subtitle>
          Never miss out on ticket giveaways and other mindblowing deals. What
          are you waiting for?{' '}
          <span className="desktop">Sign up for our newsletter!</span>
        </Subtitle>
        {signedUp ? (
          <ThankYou>Thank you!</ThankYou>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Input
              type="email"
              placeholder="E-mail address"
              onChange={(e: any) => setEmail(e.target.value)}
            />
            <Button type="submit">Sign up</Button>
          </Form>
        )}
      </SignupCard>
    </Wrapper>
  );
};

export default NewsletterSignup;

import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useAppContext } from 'Context/AppContext';
import { blue6, darkBlue2 } from '../../styles/colors'
import { signUpUserEmailCollect } from '../../Utils';
import EmailForm from './components/EmailForm';
import { useCurrencyContext } from 'Context/CurrencyProvider';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PopupProps {
  emailSignup: boolean;
  handleSignup?: () =>void;
  title: string;
  subtitle: string;
  subtitle2?: string;
  thankYouText?: string;
  email?: string;
  setEmail?: Dispatch<SetStateAction<boolean>>;
  closePopup: () => void;
}

const PopupWrapper = styled.div`
  z-index: 100;
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color:rgba(0,0,0,0.6);
  display: flex;
  align-items: center;
  justify-content: center;
`
const PopupCard = styled.div`
  padding: 20px;
  padding-bottom: 20px;
  border-radius: 3px;
  text-align: center;
  z-index: 1000;
  width: 96%;
  max-width: 500px;
  min-height: 200px;
  background-color: ${blue6};
  color: ${darkBlue2};
  font-family: 'Public Sans';
  font-style: normal;
  letter-spacing: 0px;
  text-align: center;
  @media(min-width: 1000px) {
    max-width: 570px;
  }
`
const IconWrapper = styled.div`
  width: 26px;
  padding: 6px;
  margin-left: auto;
  text-align: center;
  color: ${darkBlue2};
  cursor: pointer;
`
const SubcribedMessage = styled.div`
  line-height: 100px;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
`
const Title = styled.div`
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 16px;
  @media(max-width: 999px) {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
  }
`
const Subtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 36px;
  @media(max-width: 999px) {
    margin-bottom: 25px;
    font-size: 13px;
    font-weight: 400;
    line-height: 17px;
  }
`

const Popup = (props: PopupProps) => {
  const { closePopup, emailSignup, title, subtitle, handleSignup, thankYouText, email, setEmail, subtitle2} = props;
  const [showSubscribed, setShowSubscribed] = useState(false);
  const { lastSearch } = useAppContext();
  const {currency} = useCurrencyContext(); 

  const subscribeOrSaveEmail = (lastSearch: any, email: any) => {
    const flymbleUserId = localStorage.getItem('flymbleUserId');
    const source = `${process.env.NODE_ENV.toUpperCase()}-WEB-APP`;
    const country = currency === 'USD' ? 'US' : 'UK';
    signUpUserEmailCollect(lastSearch, flymbleUserId, email, source, country);
  }

  const handleClick= (e: any) => {
    if (e.target.id === 'close_wrapper') {
      closePopup()
    } else if (e.target.id === 'popup') {
      return
    } else if (e.target.id === 'popup_wrapper') {
      closePopup()
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!email) return;
    handleSignup ? handleSignup() : subscribeOrSaveEmail(lastSearch, email);
    setShowSubscribed(true);
  }

  return (
    <PopupWrapper id="popup_wrapper" onClick={handleClick}>
      <PopupCard id="popup">
        <IconWrapper id='close_wrapper'>
          <FontAwesomeIcon icon={faTimes as IconProp} onClick={closePopup}/>
        </IconWrapper>
        {showSubscribed ? (
          <SubcribedMessage>Great! <br /> <br /> {thankYouText}!</SubcribedMessage>
        ) : (
          <>
            <Title>{title}</Title>
            <Subtitle>
              {subtitle}
              {subtitle2 && (
                <>
                  <br /><br />{subtitle2}
                </>
              )}
            </Subtitle>
            {emailSignup && (
              <EmailForm handleSubmit={handleSubmit} setEmail={setEmail as any} />
            )}
          </>
        )}
      </PopupCard>
    </PopupWrapper>


  );
};

export default Popup;

import React from 'react';
import styled from 'styled-components';
import StarsBar from './assets/trustpilotStarsBar.png';
import { white, darkBlueLighter, darkBlue} from '../../styles/colors'

interface ReviewCardProps {
  name: string;
  review: string;
}

const Card = styled.div`
  background-color: ${white};
  padding-top: 21px;
  padding: 21px 14px 19px 14px;
  width: 190px;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 3px;
`
const Name = styled.div`
  color: ${darkBlue};
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
`
const Review = styled.div`
  color: ${darkBlueLighter};
  font-family: Public Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  width: 100%;
`
const Stars = styled.div`
  text-align: center;
  width: 100%;
  height: auto;
  img {
    // height: 17px;
    width: 50%;
    height: auto;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges crisp-edges;
    image-rendering: -moz-crisp-edges crisp-edges;
     -moz-crisp-edges image-rendering:crisp-edges;
  } 
  img.starsBar {
    width: 7em;
    image-rendering: crisp-edges;
  }
`

const ReviewCard = (props: ReviewCardProps) => {
  const { name, review } = props;

  return (
    <Card>
      <Name>{name}</Name>
      <Review>{review}</Review>
      <Stars><img src={StarsBar} alt="trustpilot five stars" className="starsBar"/></Stars>
    </Card>
  )
}

export default ReviewCard;

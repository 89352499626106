import map from 'ramda/src/map'
import prop from 'ramda/src/prop'
import reduce from 'ramda/src/reduce'
import compose from 'ramda/src/compose'

import { getDurationSeconds } from 'helpers/getDurationSeconds';

export const getDurationSum = compose(
  reduce((acc, x) => acc + getDurationSeconds(x), 0),
  map(prop("duration"))
)

import React, { useState, useEffect } from 'react';
import { dayjs } from 'Services/dayjs';

// Larger landing page sections.
import {
  Main,
  HowItWorks,
  FeedbackSection,
  FAQsLandingPage,
  PressBar,
  SupportSection,
  VideoSection,
  UspDropdowns,
} from './components';
import TravelDeals from './components/TravelDeals';
import InstantSupportCard from '../../components/InstantSupport';
import NewsletterSignup from './components/NewsletterSignup';
import GetStarted from '../../components/GetStarted';
import ReferFriend from './components/ReferFriend';
import CreditBrokerInfo from './components/CreditBrokerInfo';

// Small landing page components and styling components.
import { Spacer, TrustPilotSection, MainWrapper } from './components';

// constants.
import { howItWorksSections } from './constants';

const LandingPage = () => {
  const [showSoonPopup, setShowSoonPopup] = useState(false);

  useEffect(() => {
    window.Intercom('trackEvent', 'landing-check', {
      time: dayjs().format(),
    });
  }, []);

  return (
    <div>
      <Main
        className="mainHolder"
        isPhoneVisible={false}
        showSoonPopup={showSoonPopup}
        setShowSoonPopup={setShowSoonPopup}
      />
      <div style={{ backgroundColor: 'white' }}>
        <TrustPilotSection />
        <UspDropdowns />
        <PressBar />
      </div>

      <Spacer height={35} bgWhite mobileOnly />
      <React.Suspense fallback={<div style={{ height: '400px' }} />}>
        <TravelDeals setShowSoonPopup={setShowSoonPopup} />
        <HowItWorks sections={howItWorksSections} />
        <InstantSupportCard />
        <MainWrapper>{false && <VideoSection />}</MainWrapper>
        <FeedbackSection />
        <ReferFriend />
        <FAQsLandingPage />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <NewsletterSignup />
          <CreditBrokerInfo />
        </div>
        <GetStarted />
        {false && <SupportSection />}
      </React.Suspense>
    </div>
  );
};

export default LandingPage;

import React, { useContext } from 'react';
import BaggagePassengersSection from '../BaggagePassengersSection';
import PersonSection from '../PersonSection';
import { ContrastStyle, CustomerInformationSectionWrapper } from '../styles';
import styled from 'styled-components';
import { darkBlue2, flymbleBlue, white } from '../../../../styles/colors';
import UserIcon from '../../../components/assets/icons/user.png';
import SectionTitle from '../../../components/SectionTitle';
import { WarningBannerWrapper } from '../../../components/WarningBanner';
import { CurrencyContext } from 'Context/CurrencyProvider';
import AppContext from 'Context/AppContext';

interface PersonApplyingInfoProps {
  isPayInFull: boolean;
  paying_title: string;
  paying_firstname: string;
  paying_lastname: string;
  leadBookerDob: any;
  email: string;
  mobileNr: string;
  hideApplyingPersonAddress: boolean;
  houseNr: string;
  apartmentNr: string;
  street: string;
  city: string;
  floorNr: string;
  postcode: string;
  county: string;
  moreThanYear: string;
  residentialstatus: string;
  employment: string;
  salary: string;
}

const PersonApplyingInfoWrapper = styled.div`
  background-color: ${white};
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 25px;
   @media(min-width: 1000px) {
    padding-left: 35px;
    padding-right: 35px;
    box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
    border-radius: 5px;
  }
`

const Line = styled.div`
  color: ${darkBlue2};
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
`
const Name = styled(Line)`
  font-weight: 700;
`

const dateText = 'YYYY-MM-DD';

const PersonApplyingInfo = (props: PersonApplyingInfoProps) => {
  const {
    paying_title,
    paying_firstname,
    paying_lastname,
    leadBookerDob,
    email,
    mobileNr,
    hideApplyingPersonAddress,
    houseNr,
    apartmentNr,
    street,
    floorNr,
    city,
    postcode,
    county,
    moreThanYear,
    residentialstatus,
    employment,
    salary

  } = props;

  const currencyContext = useContext(CurrencyContext);
  const { currencySymbol } = currencyContext;
  const appContext = useContext(AppContext);
  const {typeBookingContext} = appContext;
  let isPayInFull = false;
  if(typeBookingContext == "STRIPE_Flights") {
    isPayInFull = true;
  }
  return (
    <PersonApplyingInfoWrapper>
      <div>
      <SectionTitle title={isPayInFull ? 'Contact details' : 'Person applying for credit'} icon={UserIcon}/>
      <WarningBannerWrapper isOrange={true}>
        <span>
          Please make sure that your details match the <b>details on your passport/ID</b>.
        </span>
      </WarningBannerWrapper>

          {!isPayInFull && (
            <>
            <Name>
              {`${paying_title} ${paying_firstname} ${paying_lastname}`}
            </Name>
            <Line>
                {leadBookerDob.utc().format(dateText)}
            </Line>
            </>
          )}

          <Line>{email}</Line>
          <Line>{mobileNr}</Line>

          {!hideApplyingPersonAddress && (
            <Line>
              <Line>
                {`${houseNr || ''} ${street}`}
                {apartmentNr && `, ${apartmentNr}`}
                {floorNr && `, floor ${floorNr}`}
              </Line>
              <Line>
                {`${city}, ${postcode}, ${county} `}
              </Line>
              {typeof residentialstatus !== 'undefined' && (
                <Line>
                  {`${residentialstatus} | ${employment} | ${currencySymbol}${salary}`}
                </Line>
              )}
            </Line>
          )}

       </div>
    </PersonApplyingInfoWrapper>

  )
}

export default PersonApplyingInfo;

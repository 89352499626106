import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlane, faBed, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { white, darkBlue2 } from '../../../../styles/colors'
import { trackStaycationsTabClicked, trackHotelsTabClicked } from 'Utils/facebookFunctions';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const TRIP_TYPES = {
  FLIGHTS: 'Flights',
  HOTELS: 'Hotels',
  STAYCATIONS: 'Staycations'
}

interface TripTypeTabsProps {
  selectedTab: string;
  setSelectedTab: Dispatch<SetStateAction<string>>;
  setShowPopup: Dispatch<SetStateAction<boolean>>;
}

const TripTabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 47px;
  @media (min-width: 1000px) {
    width: 430px;
    margin-left: 18px;
    margin-right: auto;
  }
  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
`

const TripTab = styled.div<{
  type: any;
  flights: any;
  selected: any;
}>`
  background-color: ${(props: any) => props.type === TRIP_TYPES.FLIGHTS ? white : darkBlue2};
  color: ${(props: any) => props.type === TRIP_TYPES.FLIGHTS ? darkBlue2 : white};
  height: 100%;
  text-align: center;
  line-height: 50px;
  align-items: center;
  width: ${(props: any) => props.type === TRIP_TYPES.STAYCATIONS ? '37%' : '29%'};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  cursor: pointer;
  span {
    margin-left: 7px;
  }
  .planeIcon {
    transform: rotate(-40deg);
  }
`

const TripTypeTabs = (props: TripTypeTabsProps) => {
  const { selectedTab, setShowPopup, setSelectedTab} = props;

  return (
    <TripTabs>
      <TripTab
        type={TRIP_TYPES.FLIGHTS}
        flights={true}
        onClick={() => setSelectedTab(TRIP_TYPES.FLIGHTS)}
        selected={selectedTab === TRIP_TYPES.FLIGHTS}
      >
        <FontAwesomeIcon icon={faPlane as IconProp} className="planeIcon" />
        <span>Flights</span>
      </TripTab>
      <TripTab
        type={TRIP_TYPES.HOTELS}
        flights={false}
        // onClick={() => setSelectedTab(TRIP_TYPES.HOTELS)}
        onClick={() => { 
          setSelectedTab(TRIP_TYPES.HOTELS);
          setShowPopup(true);
          trackHotelsTabClicked();
        }}
        selected={selectedTab === TRIP_TYPES.HOTELS}
      >
        <FontAwesomeIcon icon={faBed as IconProp} />
        <span>Hotels</span>
      </TripTab>
      <TripTab 
        type={TRIP_TYPES.STAYCATIONS}
        flights={false}
        // onClick={() => setSelectedTab(TRIP_TYPES.STAYCATIONS)}
        onClick={() => { 
          setSelectedTab(TRIP_TYPES.STAYCATIONS)
          setShowPopup(true);
          trackStaycationsTabClicked();
        }}
        selected={selectedTab === TRIP_TYPES.STAYCATIONS}
      >
        <FontAwesomeIcon icon={faMapMarkerAlt as IconProp} />
        <span>Staycations</span>
      </TripTab>
    </TripTabs>
  );
};

export default TripTypeTabs;

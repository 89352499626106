import React, { useContext } from 'react';
import styled from 'styled-components';
import { darkBlue2, white, flymbleGreen, blue6, darkBlueLighter} from '../../../styles/colors';
import AppContext from 'Context/AppContext';

const MobileProgressWrapper = styled.div`
  padding: 17px;
  color: ${darkBlue2};
  background-color: ${white};
  display: flex;
  align-items: center;
  /* justify-content: center; */
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  margin-bottom: 15px;
  @media(min-width: 1000px) {
    display: none;
  }
`
const Circle = styled.div`
  width: 65px;
  min-width:65px;
  max-width: 65px;
  height: 65px;
  min-height: 65px;
  max-height: 65px;
  border-radius: 50%;
  transform: rotate(45deg);
  border: 5px solid ${flymbleGreen};
  border-bottom-color: ${blue6};
  border-left-color: ${blue6};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  span {
    transform: rotate(-45deg);
  }
`
const TextWrapper = styled.div`
  margin-left: 24px;
`
const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 8px;
`
const Subtitle =styled.div`
  color: ${darkBlueLighter};
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
`

const MobileProgress = () => {

  const {checkoutProgress} = useContext(AppContext);
  const appContext = useContext(AppContext);
  const {typeBookingContext} = appContext;
  let isPayInFull = false;
  if(typeBookingContext === "STRIPE_Flights") {
    isPayInFull = true;
  }

  return (
    <MobileProgressWrapper>
      <Circle>
        <span>{checkoutProgress.stepNumber} of 4</span>
      </Circle>
      <TextWrapper>
        <Title>{isPayInFull && checkoutProgress.stepNameMobile === "Credit check" ? "Confirm Details" : checkoutProgress.stepNameMobile}</Title>
        <Subtitle>{`Next: ${checkoutProgress.stepNext}`}</Subtitle>
      </TextWrapper>
    </MobileProgressWrapper>
  )
}

export default MobileProgress;
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type Airport = {
  __typename?: 'Airport';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type AvailableServices = {
  __typename?: 'AvailableServices';
  baggages: Array<Baggage>;
};

export type Baggage = {
  __typename?: 'Baggage';
  id: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  dimensions?: Maybe<Dimensions>;
  type: BaggageType;
  passengerIds?: Maybe<Array<Scalars['String']>>;
};

export enum BaggageType {
  Checked = 'checked',
  CarryOn = 'carry_on'
}

export enum CabinClass {
  Economy = 'Economy',
  Business = 'Business',
  FirstClass = 'FirstClass',
  EconomyPremium = 'EconomyPremium'
}

export type Carrier = {
  __typename?: 'Carrier';
  code: Scalars['String'];
};

export type CheckFlightDataInput = {
  duffelOfferId?: Maybe<Scalars['String']>;
};

export type CheckFlightPayload = {
  __typename?: 'CheckFlightPayload';
  id: Scalars['String'];
  price: Scalars['Float'];
  carriers: Array<Carrier>;
  passengers: Array<Passenger>;
  availableServices?: Maybe<AvailableServices>;
};

export type City = {
  __typename?: 'City';
  id: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
};

export type Dimensions = {
  __typename?: 'Dimensions';
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
};

export type Flight = {
  __typename?: 'Flight';
  id: Scalars['String'];
  price: Scalars['Float'];
  currency: Scalars['String'];
  deepLink?: Maybe<Scalars['String']>;
  passengerIdentityDocuments?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['String']>;
  source: FlightSource;
  outbound: Array<Segment>;
  return: Array<Segment>;
};

export enum FlightSort {
  Price = 'price',
  Duration = 'duration',
  Quality = 'quality'
}

export enum FlightSource {
  Duffel = 'duffel',
  Kiwi = 'kiwi'
}

export type FlightsFiltersInput = {
  /** Eg: 18:00 */
  departureTimeFrom: Scalars['String'];
  /** Eg: 18:00 */
  departureTimeTo: Scalars['String'];
  /** Eg: 18:00 */
  returnTimeFrom: Scalars['String'];
  /** Eg: 18:00 */
  returnTimeTo: Scalars['String'];
  /** Eg: 'AA,BA' */
  excludeAirlines: Scalars['String'];
  /** Eg: 1 */
  maxStopovers?: Maybe<Scalars['Int']>;
  /** Eg: 22 */
  maxFlightDuration?: Maybe<Scalars['Int']>;
};

export type FlightsWhereInput = {
  flightFrom: Scalars['String'];
  flightTo: Scalars['String'];
  departureAt: Scalars['String'];
  returnAt: Scalars['String'];
  adults: Scalars['Int'];
  childrens?: Maybe<Scalars['Int']>;
  infants?: Maybe<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  checkFlight: CheckFlightPayload;
};


export type MutationCheckFlightArgs = {
  data: CheckFlightDataInput;
};

export type Passenger = {
  __typename?: 'Passenger';
  id: Scalars['String'];
  baggages: Array<Baggage>;
};

export type Place = {
  __typename?: 'Place';
  id: Scalars['String'];
  code: Scalars['String'];
  city: City;
  airports?: Maybe<Array<Airport>>;
};

export enum PriceVariant {
  VariantA = 'VARIANT_A',
  VariantB = 'VARIANT_B',
  VariantC = 'VARIANT_C',
  VariantD = 'VARIANT_D',
  VariantE = 'VARIANT_E',
  VariantF = 'VARIANT_F',
  VariantG = 'VARIANT_G',
  VariantH = 'VARIANT_H',
  VariantI = 'VARIANT_I'
}

export type Query = {
  __typename?: 'Query';
  flights: Array<Flight>;
};


export type QueryFlightsArgs = {
  where: FlightsWhereInput;
  sortBy: FlightSort;
  priceVariant?: Maybe<PriceVariant>;
  filters?: Maybe<FlightsFiltersInput>;
  source?: Maybe<FlightSource>;
  cabin?: Maybe<CabinClass>;
};

export type Segment = {
  __typename?: 'Segment';
  id: Scalars['String'];
  duration: Scalars['String'];
  arrivingAt: Scalars['String'];
  departingAt: Scalars['String'];
  flightNumber: Scalars['String'];
  carrier: Carrier;
  origin: Place;
  destination: Place;
};

const faqList = [
  {
    question: 'When will I receive my flight tickets if I book Fly Now Pay Later flights?',
    answer: `You will get your flight ticket instantly after completing the booking
        process and you can choose to pay off the balance before or after your
        departure date. Get ready to start packing!`
  },
  {
    question: 'Can somebody else pay for my booking?',
    answer: `Yes. Please fill in the personal details of this person below the
        passenger form. Please don't hesitate to contact us via chat should you
        have any questions.`
  },
  {
    question: 'Is there a credit check?',
    answer: `A Fly Now Pay Later credit check is performed to determine your eligibility for financing. You must have fully settled any previous flights that have been financed with Fly Now Pay Later before applying`
  
  },
  {
    question: 'Are there any fees or charges if my application is declined?',
    answer: `No, you will not be charged if we are unable to offer you a Fly Now
          Pay Later payment plan.`
  },
  {
    question: 'Is it possible to depart from one city and return to another? Can I book multi-city trips?',
    answer: `It is currently not possible to search multi city trips on our
          website, but we expect to have this functionality within the coming
          months.`
  }
];

export default faqList;

import 'antd/lib/progress/style/index.css'; // for css
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { RedirectElement } from 'components/RedirectElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { darkBlue2, lightGrey2, purple, white } from '../../../styles/colors';
import AppContext from 'Context/AppContext';

class ConfirmationButtons extends React.Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      progress: 0,
      isVisible: true,
      redirectToCheckout: false,
      isLoadingConfirmButton: false,
    };
  }

  componentDidMount = () => {
    const {setCheckoutProgress, typeBookingContext} = this.context;
  
    setCheckoutProgress({
      stepNumber: 3,
      stepNameDesktop: typeBookingContext ===  "STRIPE_Flights" ? "Confirm Details" : "Credit check",
      stepNameMobile: typeBookingContext ===  "STRIPE_Flights" ? "Confirm Details" : "Credit check",
      stepNext: "Booking complete"

    })
    window.scrollTo(0, 0);
    this.setState({ redirectToCheckout: false });
  };

  handleConfirmButtonClick = () => {

    this.setState({
      isLoadingConfirmButton: true,
    });
    setTimeout(() => {
      clearTimeout();
      this.setState({
        isLoadingConfirmButton: false,
      });
      this.props.onConfirmButtonClick();
    }, 2000);
  };

  render() {
    const {
      isLoading,
      handleEditFormDetails,
      paymentPlanPartnerSelected,
      isConfirmationButtonsVisible,
    } = this.props;

    return (
      isConfirmationButtonsVisible && (
        <ConfirmationButtonsWrapper>
          <Button
            onClick={this.handleConfirmButtonClick}
            className="proceedWithKlarna"
            id={`confirmButtonWith${paymentPlanPartnerSelected}`}
            loading={this.state.isLoadingConfirmButton}
          >
            Proceed to payment
            <FontAwesomeIcon icon={faArrowRight} className="arrowIcon" />
          </Button>

          {window.location.pathname.indexOf('confirmation/packages') === -1 && (
            <Button
              onClick={handleEditFormDetails}
              className="backButton"
              id="editDetailsButton"
              disabled={isLoading}
            >
              Back
            </Button>
          )}
          {this.state.redirectToCheckout && (
            <RedirectElement destination="/checkout" />
          )}
        </ConfirmationButtonsWrapper>
      )
    );
  }
}

ConfirmationButtons.propTypes = {
  handleEditFormDetails: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onConfirmButtonClick: PropTypes.func.isRequired,
  paymentPlanPartnerSelected: PropTypes.string,
  isConfirmationButtonsVisible: PropTypes.bool,
};

export default ConfirmationButtons;

const ConfirmationButtonsWrapper = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .arrowIcon {
    margin-left: 10px;
    @media (max-width: 999px) {
      display: none;
    }
  }
  padding-left: 18px;
  padding-right: 18px;
  padding-bottom: 20px;

  @media (min-width: 1000px) {
    padding-left: 0;
    padding-right: 0;
  }

  .proceedWithKlarna {
    background-color: ${purple};
    color: ${white};
    width: 100%;
    max-width: 218px;
    @media(min-width: 1000px) {
      width: 255px;
      max-width: 255px;

    }
  }
  .backButton {
    margin-right: 10px;
    background-color: ${lightGrey2};
    color: ${darkBlue2};
    width: 92px;
    @media(min-width: 1000px) {
      width: 101px;
    }
  }

  .backButton,
  .confirmationButton,
  .proceedWithFNPL,
  .proceedWithKlarna {
    display: flex;
    align-items: center;
    justify-content: center;
    /* line-height: 0 !important; */

    font-family: Public Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: center;

    cursor: pointer;
    border-radius: 5px;
    border: none;
    outline: none;
    height: 42px;
    @media(min-width: 1000px) {
      height: 54px;
    }
  }


  .proceedWithKlarna {
    grid-column: 2/3;
  }
`;
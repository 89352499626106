import React from 'react';
import PropTypes from 'prop-types';

import {dayjs} from 'Services/dayjs';
import { fnplDictionary } from 'Utils/FnplDictionaries';
import { listAirports } from 'components/LocationPicker/cities_UK.autogen.js';
import { parseSalary } from './parseSalary';
import { CurrencyContext } from 'Context/CurrencyProvider'; 
import { css, cx } from 'emotion/macro';

const hidden = css`
  display: block;

  iframe {
    display: block !important;
  }
`;

class FnplV2Widget extends React.Component {
  static contextType = CurrencyContext
  constructor(props) {
    super(props);
    this.fnplDivRef = React.createRef();
    this.state = { isFnplBuild: false };
    this.FNPL = {};
  }

  scrollToFnpl = () => {
    window.scrollTo(0, this.fnplDivRef.current.offsetTop - 300);
  };

  componentDidMount = () => {
    this.setState({ isVisibleChildrenChildren: true });
    // START ------> loading the FNPL integration
    const currency = this.context.currency
    const integrationKey = currency === "GBP" ? process.env.REACT_APP_FNPL_WEB_INTEGRATION_KEY : process.env.REACT_APP_FNPL_WEB_INTEGRATION_KEY_US;
    const baseWidgetUrl = this.context.country === "US" ? "https://widget.us.flynowpaylater.com/cdn/integration.min.js" : "https://widget.flynowpaylater.com/cdn/integration.min.js";
    console.log(integrationKey);
    const script = document.createElement('script');
    const mode = '';
    const usWidgetParams = currency === "GBP" ? '' : "&currency=USD&jurisdiction=US";
    // const referenceUUID = uuidv4();
    const ref = `&reference=${this.props.referenceUUID}`;
    script.src = `${baseWidgetUrl}?callback=fnplCallback&key=${integrationKey}${mode}${ref}${usWidgetParams}`;
    script.async = true;
    document.body.appendChild(script);
    // END <------- loading the FNPL integration
    const { callbackReference } = this.props;
    callbackReference(`${this.props.referenceUUID}`);
    this.startFNPL();
  };

  getPassengersData = () => {
    let next = 0;
    let list = [];
    let { orderData } = this.state;
    while (
      typeof orderData[`P${next}_firstname`] !== 'undefined' &&
      next < 10
    ) {
      list.push({
        name:
          orderData[`P${next}_firstname`] +
          ' ' +
          orderData[`P${next}_lastname`],
        dob: {
          day: parseInt(orderData[`P${next}_dobDay`], 10),
          month: parseInt(orderData[`P${next}_dobMonth`], 10),
          year: parseInt(orderData[`P${next}_dobYear`], 10),
        },
      });
      next += 1;
    }
    return list;
  };

  /**
   * Updates FNPL integration values according to customer entries
   */
  updateFNPL = () => {
    const FNPL = window.receivedFNPL;
    const {
      orderData: {
        paying_title,
        paying_firstname,
        paying_lastname,
        paying_dobMonth,
        paying_dobDay,
        paying_dobYear,
        email,
        mobileNr,
        postcode,
        street,
        houseNr,
        apartmentNr = '',
        floorNr = '',
        city,
        county,
        salary,
        residentialstatus,
        employment,
        tripExtract,
        orderSummaryObject: { FNPL_Price },
        // flymbleCheckoutPrice,
      },
    } = this.state;

    const { callbackComplete } = this.props;
    console.log({fnpl: FNPL_Price});

    // Set the information about the applicant (Might be same as the primary passenger)
    FNPL.applicant.fullName.set(`${paying_firstname} ${paying_lastname}`);
    FNPL.applicant.dateOfBirth.set(
      parseInt(paying_dobDay, 10),
      parseInt(paying_dobMonth, 10),
      parseInt(paying_dobYear, 10)
    );
    FNPL.applicant.residentialType.set(
      fnplDictionary.residential(residentialstatus)
    );
    FNPL.applicant.employmentType.set(fnplDictionary.employment(employment));
    FNPL.applicant.phone.country.set(this.context.country === "UK" ? "GB" : "US");

    // //gender is important
    FNPL.applicant.gender.set(fnplDictionary.gender(paying_title));

    FNPL.applicant.phone.number.set(mobileNr.trim().replace(/(^0|\s)/gi, ''));
    // // country
    FNPL.applicant.email.set(email);
    FNPL.applicant.salary.currency.set(this.context.currency);

    // Salary needs to be above 6000.
    const parsedSalary = parseSalary(salary);
    FNPL.applicant.salary.annualAmount.set(parsedSalary);
      console.log(this.context);
    FNPL.applicant.address.floor.set(floorNr || '');
    FNPL.applicant.address.apartment.set(apartmentNr || '');
    FNPL.applicant.address.house.set(houseNr || '');
    FNPL.applicant.address.street.set(street || '');
    FNPL.applicant.address.city.set(city || '');
    FNPL.applicant.address.county.set(county || '');
    FNPL.applicant.address.postcode.set(postcode || ''); // j
    FNPL.applicant.address.country.set(this.context.country === "UK" ? "GB" : "US");
    // FNPL.trigger.error();

    // Set the checkout information -------------------------------------------------------> @Check this for packages.
    // FNPL.checkout.amount.set(this.props.flymbleCheckoutPrice);
    // FNPL.checkout.amount.set(FNPL_Price);

    let passengers = this.getPassengersData();
    // console.log('PASSENGERS:', this.getPassengersData());

    // Set the information about your flight itinerary
    FNPL.itinerary.clearFlights();
    FNPL.itinerary.type.setFlightType();

    // Set the information about the primary passenger on your booking
    // FNPL.itinerary.person.name.set('Mike Vincent Tarnowski von Wynaendts');
    FNPL.itinerary.person.name.set(passengers[0].name);
    FNPL.itinerary.person.dob.set(
      passengers[0].dob.day,
      passengers[0].dob.month,
      passengers[0].dob.year
    );

    // You may register all of the connected, returning, or individual flights on the ticket, there is no limit.
    // Set the first flight on the ticket, with all of its passengers on this flight.

    if (this.props.isPackagesCheckout) {
      const packageTrip = this.props.packagesTripObject;
      const {
        itinerary_outbound,
        itinerary_return,
        hotel_itinerary,
        checkout_packages_price,
      } = packageTrip;

      FNPL.itinerary.addFlight(itinerary_outbound);
      FNPL.itinerary.addFlight(itinerary_return);
      FNPL.itinerary.addHotel(hotel_itinerary);
      FNPL.checkout.amount.set(checkout_packages_price);
    } else {
      tripExtract.route.map(flight => {
        let dTimeUTC = dayjs(flight.departingAt).utc();
        let aTimeUTC = dayjs(flight.arrivingAt).utc();
        let countryFrom = listAirports.filter(
          code => code.key === `A_${flight.origin.code}`
        );

        // There is an error here.
        countryFrom =
          Array.isArray(countryFrom) && countryFrom[0] && countryFrom[0].country
            ? countryFrom[0].country
            : '';
        let countryTo = listAirports.filter(
          code => code.key === `A_${flight.destination.code}`
        );
        countryTo =
          Array.isArray(countryTo) && countryTo[0] && countryTo[0].country
            ? countryTo[0].country
            : '';
        let itinerary = {
          flightNumber: `${flight.carrier.code}${flight.flightNumber}`,
          passengers: passengers,
          from: {
            date: {
              exday: 1,
              exmonth: 12,
              exyear: 2019,
              day: dTimeUTC.date(),
              month: 1 + dTimeUTC.month(),
              year: dTimeUTC.year(),
            },
            country: countryFrom,
            airport: flight.origin.code,
          },
          to: {
            date: {
              day: aTimeUTC.date(),
              month: 1 + aTimeUTC.month(),
              year: aTimeUTC.year(),
            },
            country: countryTo,
            airport: flight.destination.code,
          },
        };

        console.log({countryFrom: countryFrom, countryTo: countryTo});

        return FNPL.itinerary.addFlight(itinerary);
      });
      // FNPL.checkout.amount.set(this.props.flymbleCheckoutPrice);
      FNPL.checkout.amount.set(FNPL_Price);
    }

    // Set the checkout information -------------------------------------------------------> @Check this for packages.

    // Set the callback function we will call once a successful authorisation has commenced.
    FNPL.setCallback.complete(dispersalToken => {
      callbackComplete(dispersalToken);
    });

    // Trigger the save function, to send the changes to our service.
    // FNPL.save();
    this.saveFNPL();
  };

  /**
   * Function to confirm changes into data for FNPL integration and
   * start/restart the plugin/application process
   *
   */
  saveFNPL = () => {
    const FNPL = window.receivedFNPL;
    FNPL.save();
  };

  handleFnplUpdate = () => {
    this.updateFNPL();
  };

  /**
   * Function that setup default values of integration
   */
  startFNPL = () => {
    const FNPL = window.receivedFNPL;
    const isObjectEmpty =
      Object.entries(FNPL).length === 0 && FNPL.constructor === Object;

    if (isObjectEmpty) {
      setTimeout(() => this.startFNPL(), 1000);
    } else {
      setTimeout(
        () => {
          window.receivedFNPL.build('fnplDiv');
          this.setState({ isFnplBuild: true });
          this.handleCreditCheckStart();
        },

        1000
      );
    }
  };

  handleCreditCheckStart = () => {
    this.setState({ orderData: this.props.orderDataFNPL }, () => {
      this.handleFnplUpdate();
      this.scrollToFnpl();
    });
  };

  state = { orderData: {} };

  han;

  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
        <div id="fnplDiv" ref={this.fnplDivRef} className={cx(hidden)} />
      </div>
    );
  }
}

FnplV2Widget.propTypes = {
  callbackReference: PropTypes.func.isRequired,
  callbackComplete: PropTypes.func.isRequired,
  orderDataFNPL: PropTypes.object.isRequired,
  children: PropTypes.any,
  referenceUUID: PropTypes.string.isRequired,
  isPackagesCheckout: PropTypes.bool,
  packagesTripObject: PropTypes.shape({
    itinerary_outbound: PropTypes.object.isRequired,
    itinerary_return: PropTypes.object.isRequired,
    hotel_itinerary: PropTypes.object.isRequired,
    checkout_packages_price: PropTypes.number.isRequired,
  }),
};

export default FnplV2Widget;

import React from 'react';
import styled from 'styled-components';
import { darkBlue2 } from '../../styles/colors';

const HeaderWrapper = styled.div`
  color: ${darkBlue2};
  width: 95%;
  max-width: 1122px;
  font-family: 'Public Sans';
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0px;
  text-align: left;
  margin-top: 65px;
  margin-bottom: 40px;
  @media(max-width: 999px) {
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 30px;
    padding-left: 17px; 
    margin-top: 51px;
  }
`

const Header = () => {

  return (
    <HeaderWrapper>
      Confirm your booking
    </HeaderWrapper>
  )
}

export default Header;
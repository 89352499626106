import styled from 'styled-components';

const flymbleBlue = '#4860ff';

const ContrastStyle = styled.span`
  margin-left: 4px;
  margin-right: 4px;
`;

const ConfirmationButtonsWrapper = styled.div`
  margin-top: 80px;

  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
    grid-column-gap: 18px;
  }

  .confirmationButton,
  .proceedWithFNPL,
  .proceedWithKlarna,
  .backButton {
    margin-top: 10px;
    margin-bottom: 10px;
    grid-column: 2/3;

    @media (max-width: 1000px) {
      grid-column: 1/3;
    }

    line-height: 0 !important;

    display: block;
    width: 100%;
    height: 52px;

    padding-top: 12px;
    padding-bottom: 12px;

    font-size: 20px;
    text-align: center;
    cursor: pointer;
    border-radius: 3px;
    background-color: ${flymbleBlue};
    color: white;
    border-color: ${flymbleBlue} !important;

    .faIconProceed {
      margin-left: 20px;
      font-size: 20px;

      @media (max-width: 400px) {
        margin-left: 10px;
        font-size: 19px;
      }
    }

    @media (max-width: 1000px) {
      margin-top: 10px;

      span {
        margin-top: 0px;
      }
    }
  }

  .backButton {
    background-color: #e9eef2;
    border: none;
    color: black;
    grid-column: 1/2;
  }

  .proceedWithKlarna {
    grid-column: 2/3;
  }
`;

const CustomerInformationSectionWrapper = styled.div`

`;
export {
  ContrastStyle,
  ConfirmationButtonsWrapper,
  CustomerInformationSectionWrapper,
};

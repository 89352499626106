import React from 'react';
import styled from 'styled-components';
import { blue10, darkBlue2, blue6 } from '../../../styles/colors'

interface SectionProps {
  title: string;
  text: string;
  desktopIcon: string;
  mobileIcon: string;
}

const SectionWrapper = styled.div`
  width: 270px;
  @media(max-width: 999px) {
    width: 100%;
  }
`
const IconWrapper = styled.div`
  width: 70px;
  height: 70px;
  background-color: ${blue6};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  img {
    color: ${darkBlue2};
    width: 30px;
    height: 30px;
  }
  @media(min-width: 1000px) {
    .mobileIcon {
      display: none;
    }
  }
  @media(max-width: 999px) {
    .desktopIcon {
      display: none;
    }
    width: 50px;
    height: 50px;
    margin-bottom: 15px;
    img {
      width: 22px;
      height: 22px;
      image-rendering: high-quality;
    }
  }
`
const Title = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: ${darkBlue2};
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  padding-bottom: 17px;
  @media(max-width: 999px) {
    padding-bottom: 10px;
  }
`
const Text = styled.div`
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  color: ${blue10};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-bottom: 30px;
  @media(max-width: 999px) {
    font-size: 13px;
    line-height: 19px;
  }
`

const HowItWorksSection = (props: SectionProps) => {
  const { title, text, desktopIcon, mobileIcon } = props;

  return (
    <SectionWrapper>
      <IconWrapper>
        <img src={desktopIcon} className='desktopIcon'/>
        <img src={mobileIcon} className='mobileIcon'/>
      </IconWrapper>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </SectionWrapper>
  )
}

export default HowItWorksSection;
import React, { useMemo } from 'react';

import propEq from 'ramda/src/propEq'
import append from 'ramda/src/append'
import reject from 'ramda/src/reject'
import includes from 'ramda/src/includes'

import { Baggage } from 'generated/types';

import { normalize } from 'helpers/normalize';

import { IBaggagesContext } from './types';

const BaggagesContext = React.createContext<IBaggagesContext | null>(null);

export const BaggagesProvider: React.FC = props => {
  const [baggages, setBaggages] = React.useState<Record<string, Baggage[]> | {}>({});
  const [freeBaggages, setFreeBaggages] = React.useState({});
  const togglePassangerBaggage = (passengerId: string, baggage: Baggage) => {
    setBaggages(prevBaggages => {
      const isBaggageExist = includes(baggage, (prevBaggages as any)[passengerId] || []);

      if (isBaggageExist) {
        return ({
          ...prevBaggages,
          [passengerId]: reject(propEq("id", baggage.id), (prevBaggages as any)[passengerId])
        })
      }

      return ({
        ...prevBaggages,
        [passengerId]: append(baggage, (prevBaggages as any)[passengerId])
      })
    })
  }

  const addFreeBaggages = (baggageTitle: string) => {
    (freeBaggages as any)[baggageTitle] ? setFreeBaggages({...freeBaggages, [baggageTitle]: (freeBaggages as any)[baggageTitle] += 1}) : setFreeBaggages({...freeBaggages, [baggageTitle]: (freeBaggages as any)[baggageTitle] = 1})
  }

  const removeFreeBaggages = (baggageTitle: string) => {
    (freeBaggages as any)[baggageTitle] && (freeBaggages as any)[baggageTitle] != 0 ? setFreeBaggages({...freeBaggages, [baggageTitle]: (freeBaggages as any)[baggageTitle] -= 1}) : setFreeBaggages({...freeBaggages, [baggageTitle]: (freeBaggages as any)[baggageTitle] = 0})
  }

  const clearFreeBaggages = () => {
    setFreeBaggages({});
  }

  const value = useMemo(() => ({
    baggages,
    freeBaggages,
    togglePassangerBaggage,
    addFreeBaggages,
    removeFreeBaggages,
    clearFreeBaggages
  }), [normalize(baggages), normalize(freeBaggages)])



  return (
    <BaggagesContext.Provider {...props} value={value} />
  );
};

export const useBaggagesContext = () => {
  const context = React.useContext(BaggagesContext);

  if (!context) {
    throw new Error("useBaggagesContext must be inside BaggagesProvider");
  }

  return context;
}

import { useContext, useEffect, createContext } from 'react';
import { withRouter } from 'react-router-dom';
import { message } from 'antd';
import AppContext from '../../Context/AppContext/index';
import SearchForm from '../../Search/SearchForm/SearchForm';
import { PageMain, PageWrapper, SearchBarWrapper } from './styles';
import { metaConversionApi } from 'Services/analytics/metaConversionApi';

// @TODO move this into available flights?

const SearchResultContext = createContext({});
const SearchResultConsumer = SearchResultContext.Consumer;

message.config({
  top: 100,
  duration: 2,
  maxCount: 1,
});

const SearchResult = (props: any) => {
  const { children } = props;

  useEffect(() => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    metaConversionApi({ eventName: 'Search' });
  }, []);

  const appContext = useContext(AppContext);
  const {
    lastSearch: { flyingFrom },
  } = appContext;

  if (typeof flyingFrom === 'undefined') {
    return (
      <PageWrapper>
        <PageMain id="summary">
          <h5>no data</h5>
        </PageMain>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper>
        <PageMain id="summary">
          <>
            <SearchBarWrapper>
              <SearchForm />
            </SearchBarWrapper>
          </>
          {children}
        </PageMain>
      </PageWrapper>
    );
  }
};

const RoutedSearchResult = withRouter(SearchResult);

export { SearchResultConsumer };
export default RoutedSearchResult;

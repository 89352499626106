import firebase from 'firebase/app';
import 'firebase/functions';

const isLocal = window.location.href.includes('localhost');
const apiKey = isLocal
  ? process.env.REACT_APP_FIREBASE_CLOUD_FUNCTIONS_URL_DEV
  : process.env.REACT_APP_FIREBASE_API_KEY;

const config = {
  apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABAE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
};

firebase.initializeApp(config);

export default firebase;

import React from 'react';
import styled from 'styled-components';
import AccordionItem from './AccordionItem';

interface FAQ {
  question: string;
  answer: string;
}

interface AccordionProps {
  faqList: FAQ[];
}

const Wrapper = styled.div`
  margin-top: -20px;
  @media(max-width: 999px) {
    margin-top: 0;
  }
`

const Accordion = (props: AccordionProps) => {
  const { faqList } = props;

  return (
    <Wrapper>
      {faqList.map((set, index) => (
        <AccordionItem key={index} question={set.question} answer={set.answer} />
      ))}
    </Wrapper>
  )
}
export default Accordion;
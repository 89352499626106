import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { lightGrey2 } from '../../../../../styles/colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface PassengersDropdownRowProps {
  title: string;
  subtitle?: string;
  passengers: number;
  setPassengers: Dispatch<SetStateAction<number>>;
  adults?: number;
}

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  @media (max-width: 999px) {
    padding-left: 10px;
  }
`
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Subtitle = styled.span`
  font-size: 10px;
  line-height: 8px;
  opacity: 0.7;
`
const ControlsWrapper= styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  @media (max-width: 999px) {
    width: 105px;
  }
`
const Minus = styled.div`
  font-size: 7px;
  background-color: ${lightGrey2};
  border-radius: 3px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: 999px) {
    width: 35px;
    height: 35px;
  }
`
const Plus = Minus;

const PassengersDropdownRow = (props: PassengersDropdownRowProps) => {
  const {title, subtitle, passengers, setPassengers, adults} = props;

  const handleMinusClick= () => {
    if (passengers !== 0) {
      setPassengers(noPassengers => noPassengers - 1);
    }
  }

  const handlePlusClick= () => {
    if (title === 'Infants' && adults && adults <= passengers) {
      return
    }
    if (passengers !== 7) {
      setPassengers(noPassengers => noPassengers + 1);
    }
  }

  return (
    <Row>
      <TitleWrapper>
        <span>{title}</span>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleWrapper>
      
      <ControlsWrapper>
        <Minus onClick={handleMinusClick}>
          <FontAwesomeIcon icon={faMinus as IconProp} />
        </Minus>
          {passengers}
        <Plus onClick={handlePlusClick}>
          <FontAwesomeIcon icon={faPlus as IconProp} />
        </Plus>
      </ControlsWrapper>
    </Row>
  )
}

export default PassengersDropdownRow;
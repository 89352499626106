import React from 'react';
import { useQuery } from '@apollo/client';
// import { FlymbleLoading } from 'components/FlymbleLoading';

export const QueryBlog = ({ children, query, id }: any) => {
  const { data, loading, error } = useQuery(query, {
    variables: { id: parseInt(id) },
  });

  if (loading) return <div>Loading...</div>;
  // if (loading) return <FlymbleLoading />;
  if (error) return <p>Error occured please try again...</p>;
  return children({ data });
};

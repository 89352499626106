import styled from 'styled-components';

const flymbleBlue = '#4860ff';
const backgroundColor = '#f8f9fe';
const buttonWhite = '#f3f3f5';
const almostBlack = '#1a1a1a';

export const CustomerServiceWrapper = styled.div`
  color: black;
  background-color: ${backgroundColor};
  text-align: center;

  @media (min-width: 1000px) {
    padding: 100px 0 15px;
  }

  @media (max-width: 1000px) {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .title {
    font-size: 24px;
    color: black;
    font-weight: 700;
    padding-bottom: 20px;
  }

  .hereForYouMobile {
    padding-bottom: 32px;
    @media (min-width: 1000px) {
      display: none;
    }
  }

  .hereForYouDesktop {
    border-bottom: 1px rgb(219, 219, 221) solid;
    padding-bottom: 32px;
    display: grid;
    grid-template-columns: 1fr repeat(2, minmax(auto, 570px)) 1fr;
    @media (max-width: 1000px) {
      display: none;
    }

    .grid {
      grid-column: 2/4;
      display: grid;
      grid-template-columns: 1fr 1fr;
      text-align: left;

      @media (min-width: 1000px) {
        padding-bottom: 20px;
        /* padding-top: 40px; */
      }

      .secondaryGridA {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;

        p {
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 14px;
        }

        .personalAssistant {
          color: #0079ff;
          font-size: 14px;
          margin-top: 6px;
          cursor: pointer;

          img,
          p {
            display: inline-block;
            vertical-align: middle;
          }

          img {
            height: 40px;
            padding-right: 10px;
            margin-bottom: 25px;
          }
        }

        .available {
          grid-column: 1/3;
          grid-row: 1/2;

          p:nth-child(2) {
            font-weight: bold;
          }
        }

        .emailUs {
          display: none;
          cursor: pointer;
          p {
            display: block;
          }

          p:nth-child(2) {
            color: #0079ff;
          }
        }
      }

      .secondaryGridB {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 5px;

        p {
          display: inline-block;
          font-size: 14px;
          margin-top: 0px;
          margin-bottom: 16px;
        }

        a {
          text-decoration: none;
          color: black;
          cursor: pointer;
        }
      }

      h2 {
        font-size: 20px;
      }
    }
  }

  .travelCtas {
    @media (min-width: 1000px) {
      display: inline-block;
      padding-top: 15px;
    }

    .mobileOnly {
      @media (min-width: 1000px) {
        display: none;
      }
    }

    .desktopOnly {
      @media (max-width: 1000px) {
        display: none;
      }
    }
    hr {
      width: 75%;
      opacity: 0.3;
      @media (min-width: 1000px) {
        display: none;
      }
    }

    .travelSmartButtonWrapper {
      padding-top: 15px;
      padding-bottom: 15px;
      @media (min-width: 1000px) {
        display: inline-block;
      }
    }

    h3 {
      display: block;
      font-size: 20px;
      margin-top: 0px;
      margin-bottom: 0px;
      padding-bottom: 20px;
      padding-top: 30px;

      @media (min-width: 1000px) {
        display: inline-block;
        font-size: 20px;
        font-weight: 500;
        margin-right: 19px;
        vertical-align: middle;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
`;

export const InfoGroup = styled.div`
  margin-top: 14px;
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.4;

  p {
    margin-bottom: 0px;
    margin-top: 0px;
    color: black;
  }

  p:first-child {
    color: black;
    font-weight: 500;
  }

  .phoneNumber {
    color: ${flymbleBlue};
    font-size: 32px;
    letter-spacing: -0.3px;
    margin-top: 15px;
    font-weight: 400;

    a {
      text-decoration: none;
      color: ${flymbleBlue};
    }
  }
`;

const Button = styled('a')`
  background: ${props => (props.white ? '#fff' : '#337ab7')};
  color: ${props => (props.white ? '#337ab7' : '#fff')};
  outline: none;
  border-radius: 3px;
  padding: 12px 30px;
  text-decoration: none;
  height: 35px;
  font-weight: 600;
  cursor: pointer;
`;

export const ButtonPrimary = styled(Button)`
  background: ${flymbleBlue};
  margin-right: 20px;
  max-width: 120px;
  font-size: 16px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.15);
  padding: 12px 36px;
  @media (max-width: 380px) {
    padding: 12px 29px;
  }
  @media (max-width: 320px) {
    padding: 12px 20px;
  }
`;

export const ButtonWhite = styled(Button)`
  background: ${buttonWhite};
  color: ${almostBlack};
  max-width: 120px;
  font-size: 16px;
  box-shadow: inset 0 -5px 0 0 rgba(0, 0, 0, 0.15);
  padding: 12px 29px;
  @media (max-width: 380px) {
    padding: 12px 22px;
  }
  @media (max-width: 320px) {
    padding: 12px 16px;
  }
`;

import React from 'react';
import styled from 'styled-components';
import {
  AlmostDoneWrapper,
  NextStepsWrapper,
  ThankYouPageWrapper,
} from '../ThankYouPage/styles';

const ErrorPage = () => {
  return (
    <ThankYouPageWrapper>
      <div className="gridThankYou">
        <AlmostDoneWrapper>
          <h4>Status: Error</h4>
          <NextStepsWrapper>
            <span>
              Unfortunately, this is one of those rare times when the booking
              has not been successfully completed.
            </span>
            <br />
            <span>
              Please contact our support. We will try to solve the issue as soon
              as possible.
            </span>
          </NextStepsWrapper>
        </AlmostDoneWrapper>
      </div>
    </ThankYouPageWrapper>
  );
};
export default ErrorPage;

export const ErrorPageWrapper = styled.div``;

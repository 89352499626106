import styled from 'styled-components';
import { darkBlue2 } from '../../styles/colors';

const FormLabel = styled.div`
  color: ${darkBlue2};
  padding-bottom: 4px;
  font-family: 'Public Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
`;

export default FormLabel;

import React from 'react';
import { useParams } from 'react-router';

import { QUERY_ARTICLE } from 'apollo/client/blog/queryArticle';
import { QueryBlog } from 'apollo/client/blog/QueryBlog';
import ReactMarkdown from 'react-markdown';
import { ComponentStyled } from './styles';
import {Helmet} from "react-helmet";


export const BlogArticle = () => {
  let { id } = useParams();

  return (
    <QueryBlog query={QUERY_ARTICLE} id={id}>
      {({ data: { article } }) => {
        return (
          <ComponentStyled>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{article.title}</title>
                <meta name="title" content={article.title} />
                <meta name="description" content={article.introduction} />
            </Helmet>
            <div className="blog-article-header">
              <a href="/guides">Guides & Travel Essentials</a>
              <h1>{article.title}</h1>
              <p>{article.introduction}</p>
              <div className="blog-article-header_author">
                <img
                  src={article.authorAvatar.url}
                  alt={article.authorAvatar.url}
                />
                <p>{article.author}</p>
              </div>
            </div>
            <ReactMarkdown source={article.content} />
          </ComponentStyled>
        );
      }}
    </QueryBlog>
  );
};

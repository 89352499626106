import React from 'react';
import { normalize } from 'helpers/normalize';
import { DEFAULT_FILTERS } from 'constants/common';
import { IFiltersContext, IFilters } from './types';

const FiltersContext = React.createContext<IFiltersContext | null>(null);

const FILTERS = "filters"

const getDefaultFilters = () => {
  const storageFilters = sessionStorage.getItem(FILTERS)

  if (storageFilters) {
    const filters = JSON.parse(storageFilters)
    if (filters.source === [null, null] as any)
    return {...filters, source: ['kiwi,duffel']}
  }

  return DEFAULT_FILTERS
}

export const FiltersProvider: React.FC = props => {
  const [filters, setFilters] = React.useState<IFilters>(getDefaultFilters);

  const applyFilters = (data: IFilters) => {
    setFilters(data)
    sessionStorage.setItem(FILTERS, JSON.stringify(data))
  }

  const value = React.useMemo(() => ({
    filters,
    applyFilters,
  }), [normalize(filters)])

  return (
    <FiltersContext.Provider value={value} {...props} />
  );
};

export const useFiltersContext = () => {
  const context = React.useContext(FiltersContext);

  if (!context) {
    throw new Error("useFiltersContext must be inside FiltersProvider");
  }

  return context;
}

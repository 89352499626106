import { gql } from '@apollo/client';

export const QUERY_ARTICLE = gql`
  query Articles($id: ID!) {
    article(id: $id) {
      id
      title
      introduction
      author
      authorAvatar {
        url
      }
      content
      image {
        url
      }
    }
  }
`;

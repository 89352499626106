import React from 'react';
import PropTypes from 'prop-types';
import { useBaggagesContext } from 'Context/BaggagesProvider/BaggagesProvider';
// import InformationCircle from './InformationCircle';
import PriceDetailsFooter from './PriceDetailsFooter';
import styled from 'styled-components';
import { darkBlue, white } from '../../../styles/colors';
import { useCurrencyContext } from 'Context/CurrencyProvider';

const Position = styled.div``;
const Price = styled.div``;

const PaymentInformationWrapper = styled.div`
  width: 100%;
  color: ${darkBlue};
  font-family: 'Public Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  margin-bottom: 40px;
  @media (min-width: 1000px) {
    width: 280px;
    margin-left: 40px;
    font-size: 14px;
    margin-bottom: 0;
  }
`;
const PriceInformationCard = styled.div`
  background-color: ${white};
  box-shadow: 4px 4px 12px rgba(101, 101, 101, 0.05);
  padding: 20px 17px;
  @media (min-width: 1000px) {
    padding: 22px 26px;
    border-radius: 5px;
  }
`;
const Title = styled.div`
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
  margin-left: 18px;
  @media (min-width: 1000px) {
    font-size: 24px;
    line-height: 28px;
    margin-left: 0;
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;
const TotalRow = styled(Row)`
  padding-bottom: 2px;
  font-weight: 600;
  @media (max-width: 999px) {
    font-size: 16px;
  }
`;

const DisplayPaymentInformation = ({
  monthlyPriceIncludingBaggage,
  totalFlymbleIncludingBaggage,
  discountValue,
  discountCode,
  noPassengers,
  noMonths,
  airfareAllPassengers,
  sumBaggagePrices,
  isPayInFull,
  isAddedTicketServiceContext,
  isAddedPremiumSupportContext,
  costAddedTicketService,
  costAddedPremiumSupport,
  purchasedBaggage,
  cabinClass,
  baggagesCount,
}) => {
  const { freeBaggages } = useBaggagesContext();
  const { currencySymbol } = useCurrencyContext();
  const conversionRatio = 1;

  console.log(isAddedTicketServiceContext);
  return (
    <PaymentInformationWrapper>
      <Title>Price details</Title>
      <PriceInformationCard>
        <Row>
          <span>{noPassengers}x Passenger</span>
          <span>{currencySymbol}{(airfareAllPassengers*conversionRatio).toFixed(2)}</span>
        </Row>

        {isAddedTicketServiceContext && (
          <Row>
            <span>{noPassengers}x AirHelp</span>
            <span>{currencySymbol}{(costAddedTicketService*conversionRatio).toFixed(2)}</span>
          </Row>
        )}

        {isAddedPremiumSupportContext && (
          <Row>
            <span>Premium support</span>
            <span>{currencySymbol}{(costAddedPremiumSupport*conversionRatio).toFixed(2)}</span>
          </Row>
        )}

        {freeBaggages &&
          Object.keys(freeBaggages).map((el) => {
            return (
              (freeBaggages[el] && (
                <Row>
                  <span>{`${freeBaggages[el]}x ${
                    el.toLowerCase().includes('personal item and cabin')
                      ? 'Personal item and cabin bag'
                      : el
                  }`}</span>
                  <span>{`Free`}</span>
                </Row>
              )) || <span></span>
            );
          })}

        {purchasedBaggage &&
          baggagesCount &&
          Object.keys(purchasedBaggage).map((el) => {
            return (
              <Row>
                <span>{`${baggagesCount[el]}x ${
                  el.toLowerCase().includes('personal item and cabin')
                    ? 'Personal item and cabin bag'
                    : el
                }`}</span>
                <span>{`${currencySymbol}${(purchasedBaggage[el]*conversionRatio).toFixed(2)}`}</span>
              </Row>
            );
          })}

        {!purchasedBaggage && !baggagesCount && (
          <>
            <Position>Baggage</Position>
            <Price>
              {sumBaggagePrices > 0
                ? `${currencySymbol}${(sumBaggagePrices*conversionRatio).toFixed(2)}`
                : 'Free'}
            </Price>
          </>
        )}

        {discountCode && (
          <Row>
            <span>Discount {discountCode.toString()}</span>
            <span>- {currencySymbol}{(discountValue*conversionRatio).toFixed(2)}</span>
          </Row>
        )}

        <Row>
          <span>Cabin Class</span>
          <span>
            {cabinClass === 'EconomyPremium' ? 'Premium Economy' : cabinClass}
          </span>
        </Row>

        <TotalPriceRow
          isPayInFull={isPayInFull}
          totalPrice={totalFlymbleIncludingBaggage}
        />

        <PriceDetailsFooter
          minInstalments={3}
          maxInstalments={24}
          instalmentsPrice={Number(monthlyPriceIncludingBaggage).toFixed(2)}
          price={Number(totalFlymbleIncludingBaggage).toFixed(2)}
        />
      </PriceInformationCard>
    </PaymentInformationWrapper>
  );
};

const TotalPriceRow = ({ totalPrice, isPayInFull }) => {
  const { currencySymbol } = useCurrencyContext();
  return (
    <TotalRow>
      <span> Total </span>
      <div className="payInFullPrice">{currencySymbol}{totalPrice.toFixed(2)}</div>
    </TotalRow>
  );
};

TotalPriceRow.propTypes = {
  isPayInFull: PropTypes.bool.isRequired,
  totalPrice: PropTypes.number.isRequired,
};

DisplayPaymentInformation.propTypes = {
  monthlyPriceIncludingBaggage: PropTypes.number.isRequired,
  totalFlymbleIncludingBaggage: PropTypes.number.isRequired,
  discountValue: PropTypes.number.isRequired,
  discountCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  noPassengers: PropTypes.number.isRequired,
  noMonths: PropTypes.number.isRequired,
  airfareAllPassengers: PropTypes.number.isRequired,
  displayInformationCircle: PropTypes.object,
  isVisibleInformationCircle: PropTypes.bool,
  sumBaggagePrices: PropTypes.number.isRequired,
  isPayInFull: PropTypes.bool.isRequired,
  isAddedTicketServiceContext: PropTypes.bool.isRequired,
  costAddedTicketService: PropTypes.number.isRequired,
};

export default DisplayPaymentInformation;

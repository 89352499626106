import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { lightOrange, blue6, darkBlue2 } from '../../styles/colors';

const WarningBannerWrapper = styled.div<{
  isOrange?: any;
}>`
  color: ${darkBlue2};
  padding: 10px 15px;
  padding-top: 13px;
  display: grid;
  align-items: center;
  font-family: 'Public Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;

  background-color: rgb(224, 246, 255);
  background-color: ${props => (props.isOrange ? lightOrange: blue6)};
  border-radius: 5px;
  font-family: 'Public Sans';
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 9px;

  @media (min-width: 1000px) {
    padding: 11px 19px;
    grid-column: 1/3;

    .mobileOnly {
      display: none;
    }
    .faIcon {
      font-size: 26px;
      text-align: center;
    }
  }
  @media(max-width: 999px) {
    .desktopOnly {
      display: none;
    }
  }
`;

const WarningBannerWrapperOrange = styled.div`
  padding: 16px 12px;

  display: grid;
  align-items: center;

  background-color: #ffdab9a3;
  border-radius: 3px;

  margin-bottom: 25px;

  span {
    font-size: 13px;
  }

  @media (min-width: 1000px) {
    grid-column: 1/3;
    font-weight: 400;
    grid-template-columns: 1fr 16fr;
    margin-bottom: 22px;
  margin-top: 25px;

    padding-left: 18px;

    span {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.65);
      grid-column: 1/3;
    }

    .faIcon {
      font-size: 26px;
      text-align: center;
    }
  }
`;

const WarningBanner = ({ text, setDebugValues }: any) => (
  <WarningBannerWrapper>
    <FontAwesomeIcon className="faIcon" icon={faInfoCircle as any} />
    <span
      onClick={() => {
        process.env.NODE_ENV === 'development' && setDebugValues();
      }}
    >
      <b>
        {text}
        &nbsp;
      </b>
    </span>
  </WarningBannerWrapper>
);

WarningBanner.defaultProps = {
  text:
    'ContactDetails..Please fill in the contact details belonging to the person applying for installments. ',
  setDebugValues: () => {},
};

WarningBanner.propTypes = {
  text: PropTypes.string,
  setDebugValues: PropTypes.func,
};

export { WarningBannerWrapper, WarningBannerWrapperOrange, WarningBanner };

export default WarningBanner;

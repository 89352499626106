import { axiosConfig } from '../../../config/axiosConfig';

export const createApplicationAWS = async (applicationObject, appId) => {
  try {
    const result = await axiosConfig.post(
      '/createApplication',
      // 'http://localhost:4000/dev/createApplication',
      { ...applicationObject, applicationId: appId }
    );

    const { applicationId } = result.data;
    return applicationId;
  } catch (e) {
    console.log(e);
  }
};

import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { darkBlue2 } from '../../../styles/colors';

const dateText = 'YYYY-MM-DD';

const PersonSectionWrapper = styled.div`
  color: ${darkBlue2};
  margin-bottom: 15px;
  font-family: Public Sans;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
`;
const Name = styled.div`
  font-size: 16px;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: 700;
`;
const Dob = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 11px;
`;

const PersonSection = ({ passengerData }) => {
  const tempDob = new Date(passengerData.dob2).toISOString().split('T')[0];
  console.log('[PersonSection.js]', passengerData.dob.utc());

  const data = [
    {key: 'Nationality', value: passengerData.nationality},
    {key: 'Gender', value: passengerData.gender},
    {key: 'Date of birth', value: tempDob || passengerData.dob.utc().format(dateText)},
    {key: 'Passport or ID number', value: passengerData.idNumber},
    {key: 'Passport or ID expiry date', value: passengerData.idExp.utc().format(dateText)},
    {key: 'No expiry', value: passengerData.idNoExp ? "Yes " : "No"},
  ]
  return (
    <PersonSectionWrapper>
      <Name>
        <div>{passengerData.title}</div>
        <div>
          {/* Temporary disabled because of the title confusion */}
          {/* {`${genderTitle(passengerData.gender)} ${passengerData.firstname} ${
            passengerData.lastname
          }`} */}
          {`${passengerData.firstname} ${passengerData.lastname}`}
        </div>
      </Name>
      {data.map(item => (
        <Dob> <b> {item.key}:</b> {item.value}</Dob>
      ))}
    </PersonSectionWrapper>
  );
};

PersonSection.propTypes = {
  passengerData: PropTypes.shape({
    title: PropTypes.string,
    data: PropTypes.object,
    gender: PropTypes.string.isRequired,
    firstname: PropTypes.string.isRequired,
    lastname: PropTypes.string.isRequired,
    dob: PropTypes.object.isRequired,
  }),
};

export default PersonSection;

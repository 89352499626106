import React from 'react';
import i18n from 'Utils/i18n.js';
import styled from 'styled-components';
import StarsBar from './assets/trustpilotStarsBar.png';
import trustpilotLogo from 'assets/images/trustpilot-vector-logo.svg';
import { darkBlueLighter, white } from '../../../../styles/colors'

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const WhiteTriangle = styled.div`
  background-color: ${white};
  width: 90px;
  height: 90px;
  position: absolute;
  top: -10px;
  transform: rotate(135deg);
  border-radius: 8px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  @media(min-width: 1000px){
    display: none;
  }
`

const StartsAndLogoWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-top: 37px;
  padding-bottom: 20px;
  @media(min-width: 1000px) {
    display: none;
  }
`
const LogoWrapper = styled.img`
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 110px;
  padding-bottom: 2px;
  image-rendering: crisp-edges;
  @media (min-width: 1000px) {
    display: none;
  }
`;
const Subtitle = styled.div`
  font-family: 'Public Sans', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  color: ${darkBlueLighter};
  @media(min-width: 1000px) {
    display: none;
  }
`
const StarsBarImage = styled.img`
  width: 125px;
  margin-right: 20px;
`
const TrustPilotUrl =
  'https://uk.trustpilot.com/review/flymble.com?utm_medium=Trustbox&utm_source=MicroCombo';


const TrustPilotSectionUK = () => {
  return (
    <Wrapper>
      {/* <Spacer height={10} bgWhite mobileOnly /> */}
      <WhiteTriangle />
      <StartsAndLogoWrapper>
        <StarsBarImage src={StarsBar} alt="trust pilot stars bar" />
        <a
          href={TrustPilotUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <LogoWrapper src={trustpilotLogo} mobileOnly />
        </a> 
      </StartsAndLogoWrapper>
        <Subtitle>
          Based on 200+ reviews
        </Subtitle>
    </Wrapper>
  );
};

const TrustPilotSectionPL = () => {
  return <React.Fragment>{null}</React.Fragment>;
};

const TrustPilotSection =
  i18n.language === 'pl' ? TrustPilotSectionPL : TrustPilotSectionUK;

export default TrustPilotSection;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { white } from '../../../../styles/colors';
import BusinessInsider from './assets/BusinessInsider.svg';
import TheTimes from './assets/TheTimes6.png';
import Metro from './assets/MT-logo.png';
import Forbes from './assets/Forbes.svg';
import EveningStandard from './assets/EveningStandard.svg';

import { Title } from '..';

export const PressBarWrapper = styled.div`
  width: 80%;
  max-width: 1122px;
  z-index: 11;
  background-color: ${white};
  height: 124px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  @media (min-width: 1000px) {
    height: 124px;
    .businessInsider {
      img {
        height: 20px;
      }
    }
    .theTimes {
      img {
        height: 33px;
      }
    }
    .metro {
      img {
        height: 15px;
      }
    }
    .forbes {
      padding-left: 25px;
      img {
        height: 17px;
      }
    }
    .eveningStandard {
      img {
        height: 47px;
      }
    }
  }
  @media(max-width: 999px) {
    width: 100%;
    justify-content: space-around;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: row;
    .theTimes {
      img {
        height: 30px;
      }
      order: 2;
    }
    .metro {
      img {
        height 14px; 
      }
      order: 1;
    }
    .forbes {
      img {
        height 14px;
      }
      order: 3;
    }
    .businessInsider {
      display: none;
    }
    .eveningStandard {
      display: none;
    }
  }
`;
const LogoWrapper = styled.div``

export const BgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (min-width: 1000px) {
    background-color: ${white};
    z-index: 11;
  }
`;
class PressBarUK extends React.Component {
  render() {
    const { title } = this.props;
    return (
      <BgWrapper>
        {title && (
          <Title mobileOnly>
            {title}
          </Title>
        )}
        <PressBarWrapper>
          <LogoWrapper className="theTimes">
              <img src={TheTimes} alt="The Times about Flymble" />
          </LogoWrapper>
           
          <LogoWrapper className="metro">
              <img src={Metro} alt="Metro about Flymble" />
          </LogoWrapper>
           
          <LogoWrapper className="forbes">
              <img src={Forbes} alt="Forbes" />
          </LogoWrapper>
          <LogoWrapper className="businessInsider">
              <img src={BusinessInsider} alt="Business Insider about Flymble" />
          </LogoWrapper>
          <LogoWrapper className="eveningStandard">
              <img src={EveningStandard} alt="EveningStandard" />
          </LogoWrapper>
        
        </PressBarWrapper>
      </BgWrapper>
    );
  }
}

PressBarUK.propTypes = {
  title: PropTypes.string,
};

export default PressBarUK;

import React from 'react';
import styled from 'styled-components';
import { AirlineRowWrapper } from './AirlineRow';
import { airlineName } from '../../../Utils/fnplCalc';
import PropTypes from 'prop-types';
import {
  lightGrey4,
  darkBlueLighter,
} from '../../../styles/colors'

const CabinBadge = styled.div`
  padding-left: 12px;
  padding-right: 12px;
  height: 22px;
  border-radius: 10px;
  background-color: ${lightGrey4};
  color: ${darkBlueLighter};
  text-align: center;
  font-family: 'Public Sans';
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: 0px;
  line-height: 22px;
`

// This one is where we want to display multiple airlines as a summary.
// This is not the detailled view.
const AirlineRowTop = ({ airlineCodes, flightLegCodes, cabin, source}) => {
  const nameAirline = airlineName(flightLegCodes[0]);
  const isQA = localStorage.getItem('isQA');

  return (
    <AirlineRowWrapper className='airlineRow'>
        <div className="airlineInfo">
          <div>
            <div className="airlineCodes">
              {airlineCodes.map((airlineCode, i) => (
                <img
                  src={`https://images.kiwi.com/airlines/64/${airlineCode}.png`}
                  alt={airlineName(airlineCode)}
                  key={`${airlineCode}_${i}`}
                />
              ))}
            </div>
            <span className="airlineName">{nameAirline}</span>
          </div>
        </div>
        {cabin && <CabinBadge>{cabin}</CabinBadge>}
        {isQA && source && <span>{source}</span>}
    </AirlineRowWrapper>
  );
};

AirlineRowTop.propTypes = {
  airlineCodes: PropTypes.array.isRequired,
  flightLegCodes: PropTypes.array.isRequired,
  cabin: PropTypes.string,
  source:  PropTypes.string,
};

export { AirlineRowTop };

import React, { Dispatch, SetStateAction } from 'react';
import { Radio } from 'antd';
import { CABIN_CLASS } from '../../../../../constants/constants';
import {
  CabinClassDropdownWrapper,
  DropdownButtonsWrapper,
  DropdownCancelButton,
  DropdownDoneButton,
} from '../styles';

interface CabinClassDropdownProps {
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
  selectedCabin: string;
  setSelectedCabin: Dispatch<SetStateAction<string>>;
  handleClose: () => void;
  handleDone: () => void;
}

const CabinClassDropdown = (props: CabinClassDropdownProps) => {
  const { selectedCabin, setSelectedCabin, handleClose, handleDone } = props;

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  };

  return (
    <CabinClassDropdownWrapper>
      <Radio.Group
        onChange={e => setSelectedCabin(e.target.value)}
        value={selectedCabin}
      >
        <Radio style={radioStyle} value={CABIN_CLASS.ECONOMY}>
          Economy
        </Radio>
        <Radio style={radioStyle} value={CABIN_CLASS.PREMIUM_ECONOMY}>
          Premium Economy
        </Radio>
        <Radio style={radioStyle} value={CABIN_CLASS.BUSINESS}>
          Business
        </Radio>
        <Radio style={radioStyle} value={CABIN_CLASS.FIRST_CLASS}>
          First Class
        </Radio>
      </Radio.Group>

      <DropdownButtonsWrapper>
        <DropdownCancelButton onClick={handleClose}>
          <span>Cancel</span>
        </DropdownCancelButton>
        <DropdownDoneButton onClick={handleDone}>
          <span>Done</span>
        </DropdownDoneButton>
      </DropdownButtonsWrapper>
    </CabinClassDropdownWrapper>
  );
};

export default CabinClassDropdown;

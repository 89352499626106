import React, { Suspense } from 'react';
import { Form } from '@ant-design/compatible';
// import '@ant-design/compatible/assets/index.css';
import { FormLabel } from '../styles/styles';
import {
  faPlaneDeparture,
  faPlaneArrival
} from '@fortawesome/free-solid-svg-icons';
import { useFormContext, Controller } from 'react-hook-form';

import LocationPicker from 'components/LocationPicker';

const FormItem = Form.Item;

export default function SearchLocationPicker() {
  const { setValue, control } = useFormContext();

  return (
    <Suspense fallback={<div />}>
      <div className="flyingFrom">
        <FormLabel>Flying from</FormLabel>
        <Suspense fallback={<div />}>
          <FormItem>
            <Controller
              name="flyingFrom"
              control={control}
              as={
                <LocationPicker
                  name="flyingFrom"
                  airportIcon={faPlaneDeparture}
                  setFieldsValue={setValue}
                  // isOnlyUK={true}
                  isOnlyUK={false}
                />
              }
            />
          </FormItem>
        </Suspense>
      </div>
      <div className="flyingTo">
        <FormLabel>Flying to</FormLabel>
        <FormItem>
          <Controller
            name="flyingTo"
            control={control}
            as={
              <LocationPicker
                airportIcon={faPlaneArrival}
                setFieldsValue={setValue}
              />
            }
          />
        </FormItem>
      </div>
    </Suspense>
  );
}

import axios from 'axios';

// This file contains the meta (facebook) conversion API implimentation.

// TODO Change this url to Production when you deploy to production.
const apiUrl = `${process.env.REACT_APP_AWS_CLOUD_FUNCTIONS_URL}/fbConversionApi`;

type TProps = {
  eventName: 'Search' | 'Lead' | 'Purchase';
  email?: string;
};

export const metaConversionApi = async ({ eventName, email }: TProps) => {
  const pageUrl = window.location.href;

  try {
    let response = await axios.post(apiUrl, {
      eventName,
      pageUrl,
      email,
      source: 'development environment',
      msg: 'This message is from the front-end',
    });

    console.log('[analytics/metaConversionApi] response', response);
  } catch (error) {
    console.error(error);
  }
};

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { darkBlue2, flymbleGreen, neonGreen } from '../../styles/colors';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface InfoSectionPrps {
  inputItems: string[];
}

const InfoSection = (props: InfoSectionPrps) => {
  const { inputItems } = props;

  const default_items = [
    'No need to create an account',
    'Providing your details takes less than 2 minutes',
    'Receive your booking confirmation within 12 hours',
  ];

  const map_items = inputItems ? inputItems : default_items;

  return (
    <InfoSectionWrapper>
      {map_items.map((item, index) => (
        <div className="lineElement" key={index}>
          <FontAwesomeIcon icon={faCheck as IconProp} className="faIcon" />
          {item}
        </div>
      ))}
    </InfoSectionWrapper>
  );
};

export default InfoSection;
export { InfoSection };

const InfoSectionWrapper = styled.div`
  order: 1;
  color: ${darkBlue2};
  background-color: ${neonGreen};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 40px;
  font-family: 'Public Sans';
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  padding: 17px;
  padding-bottom: 2px;
  .faIcon {
    margin-right: 10px;
    color: ${flymbleGreen};
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .lineElement {
    margin-bottom: 14px;
  }
  @media (min-width: 1000px) {
    order: 2;
    font-size: 14px;
    padding: 24px 30px;
    padding-bottom: 10px;
    border: ${flymbleGreen} 2px solid;
    border-radius: 5px;
  }
`;

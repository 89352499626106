import React, { Suspense } from 'react';
import Navigation from 'components/Nav';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import AppRouter from './AppRouter';
import Footer from 'components/Footer';
import CookiesPopup from './components/CookiesPopup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PRICE_VARIANTS } from './constants/constants';
import { v4 as uuidv4 } from 'uuid';
import { isMobileDevice, getFlymblePriceVariantMargin } from 'Utils';

const menuItemsUK = [
  { title: 'About us', link: '/about-us' },
  { title: 'Contact us', link: '/help' },
  { title: 'Reviews', link: '/review-page' },
  { title: 'Guides', link: '/guides' },
];

const menuItems = menuItemsUK;

Sentry.init({
  dsn: "https://8c4b3dc48a1e46339ff64feb75fd9048@o558632.ingest.sentry.io/5692345",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.7,
});


const generatePriceVariant = () => {
  const { VARIANT_G, VARIANT_H, VARIANT_I } = PRICE_VARIANTS;
  var num = Math.random();
  console.log('The generated probabibility is', num);
  if (num < 0.6) return VARIANT_G;
  else if (num < 0.8) return VARIANT_H;
  else return VARIANT_I; //probability 0.2
};

class App extends React.Component {
  state = {
    cookiePopupVisible: this.isCookiesRead(),
  };

  componentDidMount = () => {
    const saved_price_variant = localStorage.getItem('priceVariant');

    if (saved_price_variant) {
      console.log(
        `[App.js] P-variant = ${saved_price_variant} @ ${getFlymblePriceVariantMargin()}`
      );
    } else {
      const price_variant = generatePriceVariant();
      localStorage.setItem('priceVariant', price_variant);

      console.log(
        `[App.js] NEW GENERATED P-variant = ${price_variant} @ ${getFlymblePriceVariantMargin()}`
      );
    }

    if (!localStorage.getItem('firstVisit')) {
      const todayDate = new Date();
      const today_date_string = todayDate.toGMTString();
      localStorage.setItem('firstVisit', today_date_string);
    } else {
      console.log('FVST', localStorage.getItem('firstVisit'));
    }

    const isDesktop = !isMobileDevice();
    localStorage.setItem('isDesktop', isDesktop);

    if (localStorage.getItem('flymbleUserId')) {
      //
    } else {
      const flymbleUserId = uuidv4();
      localStorage.setItem('flymbleUserId', flymbleUserId);
      window.mixpanel.identify(flymbleUserId);
    }
  };

  closeHandler() {
    localStorage.setItem('cookiePopup', false);
    this.setState({ cookiePopupVisible: false });
  }

  isCookiesRead() {
    return localStorage.getItem('cookiePopup') === null;
  }

  render() {
    return (
      <div className="App">
        <Navigation menuItems={menuItems} isPhoneVisible={false} />
        <Suspense fallback={<AppSuspense />}>
          <AppRouter />
        </Suspense>
        <Footer />
        <CookiesPopup
          closeHandler={() => this.closeHandler()}
          visible={this.state.cookiePopupVisible}
        />
        <ToastContainer />
      </div>
    );
  }
}

export default App;

const AppSuspense = () => <div style={{ minHeight: '400px' }} />;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import './Utils/i18n.js';

import { AppProvider } from 'Context/AppContext';
import { BaggagesProvider } from 'Context/BaggagesProvider';
import { OrderSummaryProvider } from 'Context/OrderSummaryProvider';
import { CurrencyProvider } from 'Context/CurrencyProvider';

import 'antd/lib/style/index.css'; // for css
import 'antd/lib/grid/style/index.css'; // for css
import 'antd/lib/affix/style/index.css'; // for css
import 'antd/lib/divider/style/index.css'; // for css
import 'antd/lib/auto-complete/style/index.css'; // for css
import 'antd/lib/form/style/index.css'; // for css
import 'antd/lib/radio/style/index.css'; // for css
import 'antd/lib/layout/style/index.css'; // for css
import 'antd/lib/button/style/index.css'; // for css
import 'antd/lib/input/style/index.css'; // for css
import 'antd/lib/tag/style/index.css'; // for css
import 'antd/lib/date-picker/style/index.css'; // for css
import 'antd/lib/select/style/index.css'; // for css
import 'antd/lib/tooltip/style/index.css'; // for css
import 'antd/lib/modal/style/index.css'; // for css
import 'antd/lib/message/style/index.css'; // for css
import 'antd/lib/checkbox/style/index.css'; // for css
import 'antd/lib/slider/style/index.css'; // for css
import 'antd/lib/spin/style/index.css'; // for css
import 'antd/lib/steps/style/index.css'; // for css
import 'antd/lib/pagination/style/index.css'; // for css
// This CSS is also important for search form display:
import './Search/SearchForm/styles/index.css';
import './styles.css';

// PLEASE DO NOT put any styling into this file,
// rather copy it into component, remove what is not necessary
// and use emotion to style the rest
// Demo styles, see 'Styles' section below for some notes on use.
import './routes/HelpPage/plain.css';

import { client } from 'apollo/config';

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <AppProvider>
        <CurrencyProvider>
          <BaggagesProvider>
            <OrderSummaryProvider>
              <App />
            </OrderSummaryProvider>
          </BaggagesProvider>
        </CurrencyProvider>
      </AppProvider>
    </BrowserRouter>
  </ApolloProvider>,

  document.getElementById('root')
);

import React from 'react';
import styled from 'styled-components';
import trustpilotLogo from 'assets/images/trustpilot-vector-logo.svg';
import trustpilotStarsBar from './assets/trustpilotStarsBar.png';
import { lightGrey3, darkBlueLighter, darkBlue2, darkBlue } from '../../styles/colors';
import ReviewCard from './ReviewCard';
import { REVIEWS } from './reviews';

const TrustPilotUrl =
  'https://uk.trustpilot.com/review/flymble.com?utm_medium=Trustbox&utm_source=MicroCombo';

const FeedbackWrapper = styled.div`
  background-color: ${lightGrey3} !important;
  padding-top: 124px;
  padding-bottom: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media(max-width: 999px) {
    flex-direction: column;
    padding: 65px 0 60px 0;
  }
`;
const ContentWrapper = styled.div`
  width: 90%;
  max-width: 1122px;
  display: flex;
  justify-content: space-between;
  @media(max-width: 999px) {
    flex-direction: column;
    width: 100%;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const Text = styled.div`
  max-width: 440px;
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  @media(max-width: 999px) {
    width: 100%;
    max-width: 1000px;
    text-align: center;
  }
`
const Title = styled(Text)`
  color: ${darkBlue2};
  font-size: 30px;
  font-weight: 700;
  line-height: 31px;
  padding-bottom: 26px;
  @media(max-width: 999px) {
    padding-bottom: 12px;
  }
`
const Subtitle = styled(Text)`
  color: ${darkBlueLighter};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  p {
    padding-top: 20px;
    @media(max-width: 999px) {
      display: none;
    }
  }
`

const TrustpilotLogoAndStarsWrapper = styled.div`
  padding-top: 20px;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;

  img {
    height: auto;
    width: 127px;
  }
  img.trustpilotLogo {
    padding-right: 5px;
    padding-left: 3px;
  }
  @media(max-width: 999px) {
    align-items: center;
    img {
      height: auto;
      width: 100px;
      // image-rendering: crisp-edges;
    }
    
    padding-bottom: 20px;
    text-align: center;
  }
`

const TrustpilotLink = styled(Text)`
  
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  a {
    color: ${darkBlue};
    text-decoration: underline
  }
  @media(max-width: 999px) {
    display: none;
  }
`
const DesktopReviewsWrapper = styled.div`
  @media(max-width: 999px) {
    display: none;
  }
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`
const MobileReviewsWrapper = styled.div`
  @media(min-width: 1000px) {
    display: none;
  }
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 5px;
  padding-bottom: 25px;
  -webkit-overflow-scrolling: touch;
  display: flex;
`;
const MobileSlider = styled.div`
  width: 100%; 
  display: flex;
  -webkit-overflow-scrolling: touch;
  margin: 8px;
`;
const Feedback = () => {
  return (
    <FeedbackWrapper>
      <ContentWrapper>
      <TextWrapper>
        <Title>We love feedback</Title>
        <Subtitle>
          {"We're super proud of our 5* Trustpilot rating"}
          <p>
            We are constantly learning and evolving our product and process 
            to reflect your needs. We take every comment, question and 
            criticism as an opportunity to learn. 
          </p>
        </Subtitle>
        <TrustpilotLogoAndStarsWrapper>
          <img src={trustpilotLogo} alt='trustpilot logo' className='trustpilotLogo'/>
          <img src={trustpilotStarsBar} alt='trustpilot stars'/>
        </TrustpilotLogoAndStarsWrapper>
        <TrustpilotLink>
          <a
            href={TrustPilotUrl}
            alt="see all reviews"
            target="_blank"
            rel="noopener noreferrer"
          >
            See all reviews
          </a>
        </TrustpilotLink>
      </TextWrapper>

      <DesktopReviewsWrapper>
        {REVIEWS.map((review, index) => (
          <ReviewCard key={index} name={review.name} review={review.review} />
        ))}
      </DesktopReviewsWrapper>

      <MobileReviewsWrapper>
        {REVIEWS.map((review, index) => (
          <MobileSlider key={index}>
            <ReviewCard  name={review.name} review={review.review} />
          </MobileSlider>
        ))}
      </MobileReviewsWrapper>
      </ContentWrapper>
    </FeedbackWrapper>
  );
};

export default Feedback;

import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { FlightsProvider } from 'Context/FlightsProvider';
import { FiltersProvider } from 'Context/FiltersProvider';

import { HeroSectionMobile } from './HeroSectionMobile/index.js';
import FrontSearch from './FrontSearch';
import { MainStyled } from './styles.js';
import backgroundImageBeach from './HeroSectionMobile/beachDesktop.jpeg'

const WrapperWithBackground = styled.div`
  @media (max-width: 999px) {
  background-image: url('${backgroundImageBeach}');
  min-height: 160px;
  background-size: 115% 150%;
  background-position-y: -10px;
  width: 100%;
  }
  width: 90%;
  max-width: 1122px;
`
interface MainProps {
  setShowSoonPopup: Dispatch<SetStateAction<boolean>>;
  showSoonPopup: boolean;
}

const Main = (props: MainProps) => {
  const { setShowSoonPopup, showSoonPopup } = props;

  return (
    <MainStyled>
      <div className="desktopTitles">
        <div className="title">Fly now, pay later</div>
        <div className="subtitle">Spread the cost from 0% APR</div>
        {/* <CovidBanner handleClick={() => window.Intercom('show')} /> */}
      </div>
      <WrapperWithBackground>
        <HeroSectionMobile />
        <div style={{paddingBottom: '50px'}}>
          <FiltersProvider>
            <FlightsProvider>
              <FrontSearch showSoonPopup={showSoonPopup} setShowSoonPopup={setShowSoonPopup}/>
            </FlightsProvider>
          </FiltersProvider>
        </div>
      </WrapperWithBackground>
   
    </MainStyled>
  );
};

export default Main;

import React, { useEffect, useState } from 'react';
import PassengersPicker from './PassengersPicker/PassengersPicker';
import CabinClassPicker from './CabinClassPicker/CabinClassPicker';
import { MobileOpacity } from './styles';

const CabinAndPassengersPickers = () => {
  const [cabinDropdownVisible, setCabinDropdownVisible] = useState(false);
  const [passengersDropdownVisible, setPassengersDropdownVisible] = useState(false);

  useEffect(() => {
    cabinDropdownVisible && setPassengersDropdownVisible(false);
  }, [cabinDropdownVisible])

  useEffect(() => {
    passengersDropdownVisible && setCabinDropdownVisible(false);
  }, [passengersDropdownVisible])

  return (
    <>
      {(cabinDropdownVisible || passengersDropdownVisible) && <MobileOpacity />}
      <CabinClassPicker 
        dropdownVisible={cabinDropdownVisible}
        setDropdownVisible={setCabinDropdownVisible}
      />
      <PassengersPicker
        dropdownVisible={passengersDropdownVisible}
        setDropdownVisible={setPassengersDropdownVisible}
      />
    </>
  )
}

export default CabinAndPassengersPickers;
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import equals from 'ramda/src/equals';
import pathOr from 'ramda/src/pathOr';

import { useLazyQueryFlights } from 'apollo/client/flight/useLazyQueryFlights';

import { useAppContext } from 'Context/AppContext';
// import { useFiltersContext } from 'Context/FiltersProvider';

// import { formatToHour } from 'Utils/formatToHour';
import { getFlymblePriceVariant } from 'Utils/getFlymblePriceVariant';

import { normalize } from 'helpers/normalize';
import { getDateMidnight } from 'helpers/getDateMidnight';

import { Flight, FlightSort, FlightSource } from 'generated/types';
import { usePrevious } from 'hooks/usePrevious';

interface ISearchParams {
  from: string;
  fromdate: string;
  nadults: string;
  nchildren: string;
  ninfants: string;
  to: string;
  todate: string;
  type: "round";
  cabin: string;
}

const priceVariant = getFlymblePriceVariant()

export const useSearchBy = (source: FlightSource, sortBy: FlightSort) => {
  // const { filters } = useFiltersContext();
  const params = useParams<ISearchParams>();
  const { searchKey } = useAppContext()
  const prevSearchKey = usePrevious(searchKey)
  const { queryFlights, data, isLoading, refetch } = useLazyQueryFlights();

  const variables = useMemo(() => {
    const isSearchPage = Boolean(params.from && params.to)

    if (!isSearchPage) {
      return;
    }

    // TODO: Maybe handle this stuff at backend
    const [_, flightFrom] = params.from.split("_");
    const [__, flightTo] = params.to.split("_");

    return ({
      where: {
        flightTo,
        flightFrom,
        adults: Number(params.nadults),
        infants: Number(params.ninfants),
        childrens: Number(params.nchildren),
        returnAt: getDateMidnight(params.todate),
        departureAt: getDateMidnight(params.fromdate),
      },
      // filters: {
      //   departureTimeFrom: formatToHour(filters.departureHours.min),
      //   departureTimeTo: formatToHour(filters.departureHours.max),
      //   returnTimeFrom: formatToHour(filters.returnHours.min),
      //   returnTimeTo: formatToHour(filters.returnHours.max),
      //   excludeAirlines: filters.excludeAirlines.join(","),
      //   // maxStopovers: filters.maxStopovers,
      //   // maxFlightDuration: filters.flightDuration.outbound,
      // },
      priceVariant,
      sortBy,
      source,
      cabin: params.cabin
    })
  }, [normalize(params)])

  const prevVariables = usePrevious(variables)

  useEffect(() => {
    if (isLoading || !variables) {
      return;
    }

    queryFlights({
      variables: {
        ...variables,
        // filters: addFilters ? {
        //   departureTimeFrom: formatToHour(filters.departureHours.min),
        //   departureTimeTo: formatToHour(filters.departureHours.max),
        //   returnTimeFrom: formatToHour(filters.returnHours.min),
        //   returnTimeTo: formatToHour(filters.returnHours.max),
        //   excludeAirlines: filters.excludeAirlines.join(","),
        //   // maxStopovers: filters.maxStopovers,
        //   // maxFlightDuration: filters.flightDuration.outbound,
        // } : {},
      }
    })
  }, [normalize(variables)]);

  const isSameVariables = equals(variables, prevVariables)
  const isSameSearchKey = equals(prevSearchKey, searchKey)

  useEffect(() => {
    if (!refetch || !variables) {
      return
    }

    if (isLoading && !isSameVariables && !isSameSearchKey) {
      return
    }

    refetch(variables)
  }, [isSameVariables, refetch, isSameSearchKey, normalize(variables)])

  const result = pathOr<Flight[]>([], ["flights"], data)

  return ({
    result,
    isLoading,
  });
};
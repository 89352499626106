import { useEffect, useState, useMemo } from 'react';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import { Flight, FlightSort, FlightSource } from 'generated/types';
import { normalize } from 'helpers/normalize';
import { useSearchBy } from './useSearchBy'
import { sortByQuality, sortByPrice, sortByDuration } from 'Utils/sortUtils';

export const useFlightsHandler = () => {
  const [priceFlights, setPriceFlights] = useState<Flight[]>([])
  const [qualityFlights, setQualityFlights] = useState<Flight[]>([])
  const [durationFlights, setDurationFlights] = useState<Flight[]>([])

  const compareFlights = (flights: Flight[]) => {
    // console.log('compare flights')
    if (!flights || flights.length === 0) return [];
 
    const flightsA = [...flights];
    const flightsB = [...flights];
    const sameFlights = [];
    const uniqFlights = flightsA.filter(a => {

      const compared = flightsB.filter(b  => {
        if (a.id !== b.id) {
          
          if (a.outbound.length !== b.outbound.length || a.return.length !== b.return.length) {
            return false;
          }
          const sameOutbound = a.outbound.every((part, index) => {
            return part.departingAt === b.outbound[index].departingAt
              && part.arrivingAt === b.outbound[index].arrivingAt 
              && part.flightNumber === b.outbound[index].flightNumber
              && part.duration === b.outbound[index].duration
              && part.origin.code === b.outbound[index].origin.code
              && part.destination.code === b.outbound[index].destination.code;
            // return part.id ===  b.outbound[index].id;
          });
          const sameReturn = a.return.every((part, index) => {
            // return part.id === b.return[index].id
            return part.departingAt === b.return[index].departingAt
              && part.arrivingAt === b.return[index].arrivingAt 
              && part.flightNumber === b.return[index].flightNumber
              && part.duration === b.return[index].duration
              && part.origin.code === b.return[index].origin.code
              && part.destination.code === b.return[index].destination.code;
          });
   
          return sameOutbound && sameReturn
        }
      })
      
      if (compared.length > 0 ) {
        sameFlights.push(...compared)
        return a.price < Math.min(...compared.map(flight => flight.price))
      } else {
        return true;
      }
    })
    // console.log(uniq(sameFlights).length)
    // console.log(uniq(sameFlights).map(x => [x.outbound[0].id, x.outbound[0].departingAt, x.outbound[x.outbound.length -1].arrivingAt, x.return[0].departingAt, x.return[0].arrivingAt]))
    return uniqFlights;
    // return sameFlights;
  }

  const { result: duffelQualityResult, isLoading: isDuffelQualityLoading } = useSearchBy(FlightSource.Duffel, FlightSort.Quality);
  const { result: kiwiQualityResult, isLoading: isKiwiQualityLoading } = useSearchBy(FlightSource.Kiwi, FlightSort.Quality);
  const { result: kiwiPriceResult, isLoading: isKiwiPriceLoading } = useSearchBy(FlightSource.Kiwi, FlightSort.Price);
  const { result: kiwiDurationResult, isLoading: isKiwiDurationLoading } = useSearchBy(FlightSource.Kiwi, FlightSort.Duration);
 
 // const { result: duffelPriceResult, isLoading: isDuffelPriceLoading } = useSearchBy(FlightSource.Duffel, FlightSort.Price);
  // const { result: duffelDurationResult, isLoading: isDuffelDurationLoading } = useSearchBy(FlightSource.Duffel, FlightSort.Duration);

  useEffect(() => {
   
    if (!kiwiQualityResult && ! duffelQualityResult && !kiwiPriceResult && !kiwiDurationResult) {
      return
    }

    const allFlights = compareFlights(uniqBy(kiwiQualityResult
      .concat(duffelQualityResult)
      .concat(kiwiPriceResult)
      .concat(kiwiDurationResult), 'id'));
    
    setQualityFlights(sortByQuality(sortByPrice([...allFlights])))
    setPriceFlights(sortByPrice(sortByDuration([...allFlights])))
    setDurationFlights(sortByDuration(sortByPrice([...allFlights])))

  }, [
    normalize(kiwiQualityResult),
    normalize(duffelQualityResult),
    normalize(kiwiDurationResult),
    // normalize(duffelDurationResult),
    normalize(kiwiPriceResult),
    // normalize(duffelPriceResult)
  ])

  const isPriceLoading = isKiwiPriceLoading;
  const isQualityLoading =  isKiwiQualityLoading || isDuffelQualityLoading;
  const isDurationLoading = isKiwiDurationLoading;

  const resetResults = () => {
    setPriceFlights([])
    setQualityFlights([])
    setDurationFlights([])
  }
  const value = useMemo(() => ({
    resetResults,
    priceFlights,
    qualityFlights,
    durationFlights,
    isPriceLoading,
    isQualityLoading,
    isDurationLoading,
    isLoading: isPriceLoading || isQualityLoading,
  }), [
    normalize(resetResults),
    normalize(priceFlights),
    normalize(qualityFlights),
    normalize(durationFlights),
    normalize(isPriceLoading),
    normalize(isQualityLoading),
    normalize(isDurationLoading),

  ])
  return (value)
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { KIND_OF_TRIP } from '../../../../constants/constants';
import {
  TravelDisplayStyle,
  CircleStop,
  RedCircleStop,
  LineStopHorizontal,
  LineStopVertical,
} from './styles';

const Stops = ({ amountStops }) => {
  const { t } = useTranslation();
  // pluralization for PL language is for 1, 2-4 and 5, for GB is 1, many
  // Here we also need to service 0 in a different way
  var textStops;
  if (amountStops === 0) {
    textStops = <span className="directFlight">Direct</span>;
  } else {
    textStops = (
      <span>{t('Stops..{{count}} stop', { count: amountStops })}</span>
    );
  }
  return textStops;
};

const TravelDisplay = ({
  departureTime,
  departureAirport,
  flightDuration,
  routeStops,
  arrivalTime,
  arrivalAirport,
  typeFlight,
  departureRouteCity,
  arrivalRouteCity
}) => (
  <TravelDisplayStyle
    className={typeFlight === KIND_OF_TRIP.ONE_WAY ? KIND_OF_TRIP.ONE_WAY : ''}
  >
    <div className="travelDisplayHolder">
      <div className="departureTime">{departureTime}</div>
      <div className="airportCodeFrom">
        {departureAirport}
        <span className="onlyCheckout">, {departureRouteCity}</span>
      </div>
      

      <div className="routeStopsWrapper">
        <span className="totalFlightDuration">{flightDuration}</span>

        <div className='routeStopsWrapper__lineAndDots'>
          <CircleStop />
          { // This code renders red dots on the LineStopHorizontal
            // Number and placement of dots is based on the numebr of stops 
          [...Array(routeStops)].map((stop, index) => {
            return <RedCircleStop key={index} stops={routeStops} index={index}/>;
          })}
          <LineStopHorizontal />
          <CircleStop />
        </div>

        <span className="routeStops">
          <Stops amountStops={routeStops} />
        </span>
      </div>

      <div className="arrivalTime">{arrivalTime}</div>
      <div className="airportCodeTo">
        {arrivalAirport}
        <span className="onlyCheckout">, {arrivalRouteCity}</span>
      </div>
    </div>
  </TravelDisplayStyle>
);

TravelDisplay.propTypes = {
  departureTime: PropTypes.string.isRequired,
  departureAirport: PropTypes.string.isRequired,
  flightDuration: PropTypes.string.isRequired,
  routeStops: PropTypes.number.isRequired,
  arrivalTime: PropTypes.string.isRequired,
  arrivalAirport: PropTypes.string.isRequired,
  typeFlight: PropTypes.string.isRequired,
}

export { TravelDisplay, CircleStop, LineStopHorizontal, LineStopVertical };

import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

export const CancelationPolicyWrapper = styled.div`
margin-top: 20px;
padding: 12px;
padding-top: 20px;
padding-bottom: 20px;
margin-bottom: 20px;
background-color: white;
color: black;

border: 1px solid #e4eaf0;


@media (min-width: 1000px) {
  padding-left: 38px;
  padding-bottom: 38px;
  padding-top: 28px;
  margin-top: 40px !important;
  margin-bottom: 30px;
  }

  h2 {
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .cancelationText {
    font-weight: 400;
  }
`;
export const CancelationPolicy = () => {
  return (
    <CancelationPolicyWrapper>
      <h2>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="faIconOverview"
        />{' '}
        Cancellation / Refund Policy
      </h2>
      <span className="cancelationText">
        In our experience, this carrier only returns a small amount when a cancellation or refund is requested.
      </span>
    </CancelationPolicyWrapper>
  );
};

import { useMemo, useState } from 'react';

import flatten from 'ramda/src/flatten';
import compose from 'ramda/src/compose';

import { useAppContext } from 'Context/AppContext';
import { useBaggagesContext } from 'Context/BaggagesProvider/BaggagesProvider';
import { Baggage, FlightSource } from 'generated/types';

import { normalize } from 'helpers/normalize';
import { getTotalBaggagesSum } from 'helpers/getTotalBaggagesSum';

import { generateOrderSummaryObject } from 'Utils/index';
import { useCurrencyContext } from 'Context/CurrencyProvider';

const getFlattenBaggages = compose(flatten, (x: Record<string, Baggage[]>) =>
  Object.values(x)
);

const getBaggagesQty = compose((x: Baggage[]) => x.length, getFlattenBaggages);

export const useContextValue = ({initialOrderSummaryContext = '', initialOrderSummaryObjectContext = {}}) => {
  const appContext = useAppContext();
  const { baggages } = useBaggagesContext();
  const [orderSummaryContext, setOrderSummaryContext] = useState<string | null>(
    initialOrderSummaryContext
  );
  console.log(appContext);
  const [orderSummaryObjectContext, setOrderSummaryObjectContext] = useState(
    initialOrderSummaryObjectContext
  );
  const conversionRatio =  1;
  const {duffelPrice} = appContext;

  const handleOrderSummaryContext = () => {
    if (appContext.selectedFlightTicket.source === FlightSource.Kiwi) {
      let purchasedBaggageTypesObject = {};

      if (appContext.purchasedBaggageTypesContext) {
        appContext.purchasedBaggageTypesContext.forEach((item: any, index: any) => {
          let returnString = `Purchased_Bag_${index}_${Object.keys(item)[0]}`;
          (purchasedBaggageTypesObject as any)[returnString] = Object.values(item)[0];
        });
      }
      console.log(
        'The purchased baggage types object is....',
        purchasedBaggageTypesObject
      );
      const { orderSummaryObject, orderSummary } = generateOrderSummaryObject(
        appContext.lastSearch,
        appContext.selectedFlightTicket,
        appContext.addedBaggagePiecesContext,
        appContext.totalBaggagePriceContext,
        purchasedBaggageTypesObject,
        appContext.discountCode,
        appContext.isAddedTicketServiceContext,
        appContext.isAddedPremiumSupportContext,
        conversionRatio
      );
      console.log('Order summary object', orderSummaryObject);
      console.log('The order summary', orderSummary);

      setOrderSummaryContext(orderSummary);
      setOrderSummaryObjectContext(orderSummaryObject);

      return { orderSummary, orderSummaryObject };
    }

    let purchasedBaggageTypesObject = {};

    if (getFlattenBaggages(baggages).length) {
      getFlattenBaggages(baggages).forEach((item: Baggage, index) => {
        let returnString = `Purchased_Bag_${index}_${item.type}`;
        (purchasedBaggageTypesObject as any)[returnString] = item.price;
      });
    }

    const { orderSummaryObject, orderSummary } = generateOrderSummaryObject(
      appContext.lastSearch,
      appContext.selectedFlightTicket,
      getBaggagesQty(baggages),
      getTotalBaggagesSum(baggages),
      purchasedBaggageTypesObject,
      appContext.discountCode,
      appContext.isAddedTicketServiceContext,
      appContext.isAddedPremiumSupportContext,
      conversionRatio,
      duffelPrice
    );

    console.log('Order summary object', orderSummaryObject);
    console.log('The order summary', orderSummary);

    setOrderSummaryContext(orderSummary);
    setOrderSummaryObjectContext(orderSummaryObject);

    return { orderSummary, orderSummaryObject };
  };

  const value = useMemo(
    () => ({
      orderSummaryContext,
      orderSummaryObjectContext,
      setOrderSummaryContext: handleOrderSummaryContext,
    }),
    [
      handleOrderSummaryContext,
      normalize(orderSummaryContext),
      normalize(orderSummaryObjectContext),
    ]
  );

  return {
    value,
  };
};

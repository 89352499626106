import {
  PRICE_VARIANT_MARGINS,
} from '../constants/constants';

import { getFlymblePriceVariant } from './getFlymblePriceVariant'

export const getFlymblePriceVariantMargin = () => {
  const priceVariant = getFlymblePriceVariant()

  const priceMargin = PRICE_VARIANT_MARGINS[priceVariant] || 1.0; // 1.00 as a fall back.

  return priceMargin;
};

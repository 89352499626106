import { Flight } from 'generated/types';
import { getTotalTripDurationSeconds } from './timeUtils';


export const sortByQuality = (flights: Flight[]) => {
  return flights.sort((a,b) => 
    a.outbound.length + a.return.length < b.outbound.length + b.return.length ? -1 : 1
  )
};

export const sortByPrice = (flights: Flight[]) => {
  return flights.sort((a,b) => a.price < b.price ? -1 : 1)
};

export const sortByDuration = (flights: Flight[]) => {
  return flights.sort((a,b) => 
    getTotalTripDurationSeconds(a) < getTotalTripDurationSeconds(b) ? -1 : 1
  )
};
import { gql } from "@apollo/client";

export const SegmentScalarFieldsFragment = gql`
  fragment SegmentScalarFieldsFragment on Segment {
    id
    arrivingAt
    departingAt
    duration
  }
`;

import React from 'react';
import styled from 'styled-components';
import { darkBlue } from '../../../styles/colors'


const TotalPriceRowWrapper = styled.div`
  grid-column: 1/3;
  font-weight: 400;
  font-size: 14px;
  color: ${darkBlue};


  @media (min-width: 1000px) {
    display: none;
  }

  .priceUnit {
    padding-top: 3px;
    padding-bottom: 3px;
  }

  .noMonths {
    padding-left: 10px;
    font-size: 15px;
  }
`;

class TotalPriceRow extends React.Component {
  render() {
    const {
      totalPrice: { flymblePrice },
    } = this.props;
    return (
      <TotalPriceRowWrapper className="totalPriceRowUnique">
        <div className="priceUnit">
          Airfare{' '} <span>£{flymblePrice.toFixed(2)}
          </span>
        </div>
      </TotalPriceRowWrapper>
    );
  }
}

export { TotalPriceRow };

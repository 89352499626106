export const blue0 = '#002FD8';
export const blue1 = '#075AE6'
export const blue6 = '#E1E3EE';
export const blue10 = '#454A6B';
export const darkBlue = '#070E39';
export const darkBlue2 = '#000317';
export const darkBlueLighter = 'rgba(7, 14, 57, 0.75)';
export const darkBlueLighter2 = 'rgba(7, 14, 57, 0.5)';
export const darkBlueLighter3 = 'rgba(7, 14, 57, 0.3)';
export const blueGrey = '#414769';
export const grey3 = '#828282';
export const lightGrey = '#ECECEC';
export const lightGrey2 = '#D1D4E3';
export const lightGrey3 = '#F7F8FB';
export const lightGrey4 = '#EEF1F8';
export const lightGrey5 = '#9B9B9B';
export const almostBlack = '#1D1D1D';
export const almostBlack2 = '#1E1E1E';
export const inputBackgroundColor = '#f6f6f6';
export const red = '#FF5E2B';
export const purple = '#5552FF';
export const flymbleGreen = '#13C29b';
export const flymbleBlue = '#4860ff';
export const bgLightBlue = '#EEEFF5';
export const bgLightBlue2 = 'rgba(239, 241, 248, 0.5)';
export const lpInputBg = '#F2F2F2';
export const white = '#ffffff';
export const green = '#60bc9c';
export const paymentPlanGrey = 'rgba(63, 74, 148, 0.09)';
export const neonGreen = 'rgba(170, 255, 224, 0.3)';
export const lightOrange = '#FFEDD2';

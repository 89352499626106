import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import LogoFlymble from 'assets/images/logo_flymble.svg';
import {
  faFacebookF,
  faLinkedinIn,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';
import { faStyle, MobileMenuWrapper } from '../styles';
import { MenuItem } from '..';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface MobileMenuProps {
  menuItems: MenuItem[];
  onToggle: () => void;
  open: boolean;
}

const MobileMenu = (props: MobileMenuProps) => {
  const { menuItems, onToggle, open } = props;
  const socials = [
    {
      href: 'https://www.facebook.com/Flymble/',
      icon: faFacebookF,
    },
    {
      href: 'https://www.instagram.com/flymbletravel/?hl=en',
      icon: faInstagram,
    },
    {
      href: 'https://www.linkedin.com/company/flymble/',
      icon: faLinkedinIn,
    },
  ];

  return (
    <MobileMenuWrapper onClick={onToggle} open={open}>
      <div className="header">
        <div>
          <Link to="/">
            <img src={LogoFlymble} alt="Flymble logo" />
          </Link>
        </div>
        <div />
        <div>
          <FontAwesomeIcon icon={faTimes as IconProp} size="1x" />
        </div>
      </div>
      {menuItems.map((item) => (
        <div className="menuitem" key={item.title} onClick={onToggle}>
          <Link to={item.link}>{item.title}</Link>
        </div>
      ))}
      <div
        className="menuitem"
        key={'help-center'}
        onClick={() => window.open('https://intercom.help/flymblesupport/en/')}
      >
        <Link to={'#'}>Help Center</Link>
      </div>
      <div className="footer">
        <div className="social">
          {socials.map(({ href, icon }) => (
            <span className="social" key={icon.iconName}>
              <a href={href} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={icon as IconProp} style={{
                  fontSize: '22px'
                }} />
              </a>
            </span>
          ))}
        </div>
      </div>
    </MobileMenuWrapper>
  );
};
export default MobileMenu;

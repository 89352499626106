import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import i18n from 'Utils/i18n.js';
import {
  blue6,
  darkBlue,
} from '../../../styles/colors'

//#region
const DetailsPaymentPlanWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 20px;
  grid-row: 4;
  grid-column: 3;
  border-top: solid 1px ${blue6};
  color: ${darkBlue};
  
  @media (max-width: 1000px) {
    display: none;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    margin: 0px;
    line-height: 27px;

    span:nth-child(2) {
      float: right;
    }

    :nth-child(even) {
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
`;
//#endregion

export default class DetailsPaymentPlan extends Component {
  render() {
    const { flymblePrice } = this.props.costs;
    return (
      <DetailsPaymentPlanWrapper className="detailsPaymentPlanDisplay">
        <p>
          <span>{i18n.t('DetailsPaymentPlan..Airfare')}</span>
          <span>
            {i18n.t('DetailsPaymentPlan..£')}
            {flymblePrice.toFixed(2)}
          </span>
        </p>
      </DetailsPaymentPlanWrapper>
    );
  }
}

DetailsPaymentPlan.propTypes = {
  costs: PropTypes.shape({
    flymblePrice: PropTypes.number.isRequired,
    serviceFee: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
  }).isRequired,
};

export { DetailsPaymentPlan };

import React from 'react';
import styled from 'styled-components';
import { darkBlue2 } from '../../styles/colors';
import Icon from '../components/Icon';

interface SectionTitleProps {
  title: string;
  subtitle?: string;
  icon: string;
}

const SectionTitleWrapper = styled.div`
  color: ${darkBlue2};
  padding-top: 30px;
  padding-bottom: 25px;
  font-family: 'Public Sans';
  text-align: left;
  letter-spacing: 0px;
  font-style: normal;
  display: flex;
  align-items: center;
  @media(min-width: 1000px) {
    padding-top: 37px;
  }
`
const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  @media(min-width: 1000px) {
    font-size: 18px;
    line-height: 25px;
  }
`

export const SectionTitle = (props: SectionTitleProps) => {
  const {title, icon} = props;
  return (
    <SectionTitleWrapper>
      <Icon icon={icon} />
      <Title>{title}</Title>
    </SectionTitleWrapper>
  );
};

export default SectionTitle;
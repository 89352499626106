import React from 'react';
import { withRouter } from 'react-router-dom';
import TrustPilot from 'assets/sub-header-trustpilot-new.png';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { Button } from 'antd';
import {
  AlmostDoneWrapper,
  NextStepsWrapper,
  RateUsWrapper,
  ThankYouPageWrapper,
} from './styles';

const NextSteps = () => (
  <NextStepsWrapper>
    <h2>You will receive your tickets shortly.</h2>

    <span className="nextStepsDescribtion">
      You can expect your booking confirmation and e-tickets in your inbox
      within 24 hours. Have a good trip!
    </span>
  </NextStepsWrapper>
);

class RateUs extends React.Component {
  handleOnSubmit = event => {
    event.preventDefault();
    this.props.form.validateFields((err, feedbackValues) => {
      if (!err) {
        window.Intercom('trackEvent', 'ticket-feedback', feedbackValues);
        window.location.assign(
          'https://www.trustpilot.com/evaluate/flymble.com'
        );
      }
    });
  };

  render() {
    return (
      <RateUsWrapper>
        <Form>
          <h2>
            Please rate us and receive a £10 discount on your next booking!
          </h2>
          <span className="describtion">
            Your feedback is greatly appreciated and improves our service.
          </span>
          <img src={TrustPilot} alt="trust pilot logo" />

          <Button className="feedbackButton" htmlType="submit">
            <a href="https://www.trustpilot.com/evaluate/flymble.com">
              Give Feedback
            </a>
          </Button>
        </Form>
      </RateUsWrapper>
    );
  }
}

const AlmostDone = () => {
  return (
    <AlmostDoneWrapper>
      <h2>Thank you for choosing Flymble</h2>
      <h4>Status: payment received. Processing your booking</h4>
    </AlmostDoneWrapper>
  );
};

const RateUsForm = Form.create()(withRouter(RateUs));

export default function ThankYouPage() {
  React.useEffect(() => {
    window.window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <ThankYouPageWrapper>
      <div className="gridThankYou">
        <AlmostDone />
        <NextSteps />
        <RateUsForm />
      </div>
    </ThankYouPageWrapper>
  );
}

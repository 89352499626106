import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import SearchForm, {
  SearchFormWrapper,
} from '../../../../Search/SearchForm/SearchForm';
import { lpInputBg } from '../../../../styles/colors'
import SoonPopup from '../SoonPopup';
import TripTypeTabs from './TripTypeTabs';
export const TRIP_TYPES = {
  FLIGHTS: 'Flights',
  HOTELS: 'Hotels',
  STAYCATIONS: 'Staycations'
}

const SearchAndTabsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1122px;
  position: relative;
  top: 25px;
  height: 100%;
  background-color: transparent;
  
  @media(max-width: 999px) {
    top: 0px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 20px;
    align-items: center;
  }
`
const FrontFormWrapper = styled.div`
  background-color: white;
  color: lightsteelblue;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  width: 100%;
  @media (min-width: 999px) {
    padding-left: 18px;
    padding-right: 18px;
    height: 90%;
    border-radius: 4px;
    width: 100%;
  }

  @media(max-width: 1000px) {
    top: 0px;
    padding: 12px 18px 22px 18px;
  }
 
  & .ant-select-auto-complete.ant-select .ant-input {
    background-color: ${lpInputBg};
  }
  & .ant-input {
    background-color: ${lpInputBg};
  }
  & .ant-picker {
    background-color: ${lpInputBg};
  }

  .ant-select {
    border: none;
    border-radius: 5px;
    > .ant-select-selector {
      border: none;
      border-radius: 5px;
      background-color: ${lpInputBg};
    }
  }
`;

interface FronSearchProps {
  setShowSoonPopup: Dispatch<SetStateAction<boolean>>;
  showSoonPopup: boolean;
}

const FrontSearch = (props: FronSearchProps) => {
  const { setShowSoonPopup, showSoonPopup } = props;
  const [selectedTab, setSelectedTab] = useState(TRIP_TYPES.FLIGHTS);
  
  return (
    <>
    {showSoonPopup && <SoonPopup closePopup={() => setShowSoonPopup(false)} selectedTab={selectedTab}/>}
    <SearchAndTabsWrapper>
      <TripTypeTabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}  
        setShowPopup={setShowSoonPopup}
      />
      <FrontFormWrapper>
        <SearchFormWrapper>
          <SearchForm />
        </SearchFormWrapper>
      </FrontFormWrapper>
    </SearchAndTabsWrapper>
    </>
  );
};

export default FrontSearch;

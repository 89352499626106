import React, { useState, useEffect } from 'react';
import { 
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_SYMBOL,
  GBP,
  GBP_SYMBOL,
  USD,
  USD_SYMBOL,
  UNITED_STATES
 } from './types';
 import { getUserCountry } from './utils';

export const CurrencyContext = React.createContext<any>(null);

export const CurrencyProvider: React.FC = (props) => {
  const [currency, setCurrency] = useState(DEFAULT_CURRENCY);
  const [currencySymbol, setCurrencySymbol] = useState(DEFAULT_CURRENCY_SYMBOL);
  const [country, setCountry] = useState("US")

  const changeCurrency = (newCurrency: string) => {
    if (newCurrency === USD || newCurrency === GBP) {
      setCurrency(newCurrency);
      setCurrencySymbol(newCurrency === GBP ? GBP_SYMBOL : USD_SYMBOL);
      setCountry(newCurrency === "USD" ? "US" : "UK");
    }
  }

  const value = React.useMemo(() => ({
    currency,
    currencySymbol,
    country,
    changeCurrency
  }), [
    currency,
    country,
    currencySymbol,
    changeCurrency
  ])
  console.log(value);

  return (
    <CurrencyContext.Provider value={value} {...props} />
  );
};

export const useCurrencyContext = () => {
  const context = React.useContext(CurrencyContext);

  if (!context) {
    throw new Error("useCurrencyContext must be inside CurrencyProvider");
  }

  return context;
}

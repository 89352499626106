import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PassengersDropdownRow from './PassengersDropdownRow';
import {
  PassengersDropdownWrapper,
  DropdownButtonsWrapper,
  DropdownCancelButton,
  DropdownDoneButton
} from '../styles';

interface PassengersDropdownProps {
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
  setLabel: Dispatch<SetStateAction<string>>;
}

const PassendersDropdown = (props: PassengersDropdownProps) => {
  const {setDropdownVisible, setLabel} = props;
  const {setValue, control, getValues} = useFormContext();
  const {noAdults, noChildren, noInfants} = getValues(['noAdults', 'noChildren', 'noInfants']);
  const [adults, setAdults] = useState(noAdults || 1);
  const [children, setChildren] = useState(noChildren || 0);
  const [infants, setInfants] = useState(noInfants ||0);

  useEffect(() => {
    setAdults(noAdults);
    setChildren(noChildren);
    setInfants(noInfants);
    setLabel(prepareLabel(noAdults,noChildren,noInfants));
  }, [noAdults, noChildren, noInfants]);

  const prepareLabel = (noAdults: number, noChildren: number, noInfants: number) => {
    let label= "";
    label = noAdults > 0 ? label + (noAdults === 1 ? `${noAdults} Adult`: `${noAdults} Adults`) : label;
    label = noChildren > 0 ? label + (noChildren === 1 ? `, ${noChildren} Child`: `, ${noChildren} Children`) : label;
    label = noInfants > 0 ? label + (noInfants === 1 ? `, ${noInfants} Infant`: `, ${noInfants} Infants`) : label;
    return label
  }

  const handleDone = () => {
    setValue('noAdults', adults)
    setValue('noChildren', children)
    setValue('noInfants', infants)
    setDropdownVisible(false);
  }

  const handleClose = () => {
    setAdults(noAdults);
    setChildren(noChildren);
    setInfants(noInfants);
    setDropdownVisible(false);
  }

  return (
    <PassengersDropdownWrapper>
      <Controller
        name="noAdults"
        control={control}
        render={() => <PassengersDropdownRow 
          title="Adults" 
          passengers={adults} 
          setPassengers={setAdults}
          />}
      />
      <Controller
        name="noChildren"
        control={control}
        render={() => <PassengersDropdownRow 
          title="Children" 
          subtitle="Ages 2 to 11" 
          passengers={children} 
          setPassengers={setChildren}/>}
      />
      <Controller
        name="noInfants"
        control={control}
        render={() => <PassengersDropdownRow 
          title="Infants" 
          subtitle="Ages 0 to 2" 
          passengers={infants} 
          setPassengers={setInfants}
          adults={adults}
          />}
      />

      <DropdownButtonsWrapper>
        <DropdownCancelButton onClick={handleClose}>
          <span>Cancel</span>
        </DropdownCancelButton>
        <DropdownDoneButton onClick={handleDone}>
          <span>Done</span>
        </DropdownDoneButton>
      </DropdownButtonsWrapper>
    </PassengersDropdownWrapper>
  )
}

export default PassendersDropdown;
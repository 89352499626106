import React from 'react';
import styled from 'styled-components';
import { white } from '../../../../../styles/colors';

interface TravelDealElementProps {
  link?: string;
  img: string;
  imgMobile: string;
  city: string;
  country: string;
  price: number;
  currencySymbol: string;
}

const MosaicElement = styled.div<{
  img: any;
  imgMobile: any;
}>`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  img {
    border-radius: 5px;
    max-width: 100%;
    min-width: 100%;
    max-height: 230px;
    min-height: 100%;
    object-fit: fill;
  }
  @media(max-width: 999px) {
    margin-bottom: 10px;
    .desktop {
      display: none;
    }
  }
  @media(min-width: 1000px) {
    .mobile {
      display: none;
    }
  }
`
const Text = styled.div`
  color: ${white};
  text-shadow: 4px 4px 12px rgba(101, 101, 101, 0.25);
  font-family: Public Sans;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  left: 20px;
`
const TextWrapper = styled.div`
position: absolute;
left: 0;
bottom: 0;
width: 100%;
height: 60px;
border-radius: 5px;
z-index: 10;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%);
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 16px;
`
const Title = styled(Text)`
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  padding-bottom: 2px;
  @media(max-width: 999px){
    font-size: 20px;
  }
`
const BottomRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `
const Subtitle = styled(Text)`
  font-size: 12px;
  font-style: normal;

`
const Price = styled(Text)`
  font-size: 14px;
  font-weight:700;
  span {
    font-style: normal;
    font-weight: 300;
  }
  @media(max-width: 999px) {
    font-size: 12px;
  }

`

const TravelDealElemet = (props: TravelDealElementProps) => {
  const { link, img, imgMobile, country, city, price, currencySymbol } = props;
  const goToSearch = () => {
    if (link) window.location.href = link;
  }

  return (
    <MosaicElement img={img} imgMobile={imgMobile} onClick={goToSearch}>
      <img className="desktop" src={img} />
      <img className="mobile" src={imgMobile} />
      <TextWrapper>
        <Title>{city}</Title>
        <BottomRow>
          <Subtitle>{country}</Subtitle>
          {price && (
            <Price>
              <span>From </span>
              {currencySymbol}{price}<span>/</span>mo</Price>
          )}
          </BottomRow>
      </TextWrapper>
      
    </MosaicElement> 
  )
}

export default TravelDealElemet;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import {
  lightGrey3,
  darkBlue2,
  green,
  white,
} from '../../.././../styles/colors';

const UspArray = [
  {
    title: 'Interest-free payments',
    textContent:
      'Spread the cost of your holiday over 3-12 monthly installments. Choose to pay off the balance before or after your departure.',
  },
  {
    title: 'Pay in 2 to 12 flexible installments',
    textContent:
      'Our credit partner FlyNowPayLater performs an instant credit check with an on-screen decision in 5 seconds. There is no charge if you are not accepted.',
  },
  {
    title: 'Apply and get a decision instantly',
    textContent:
      'Flymble aims to make travel as affordable as possible. Our price match guarantee means you always get the best deal. Simply send us a flight, hotel or package deal with a better rate and we will match it.',
  },
];

export const UspDropdowns = () => {
  const [openDropDown, setOpenDropDown] = useState('');
  return (
    <UspDropdownsWrapper>
      <div className="content">
        {UspArray.map((usp, index) => {
          const { title } = usp;
          return (
            <div
              key={index}
              onClick={() =>
                setOpenDropDown(openDropDown !== title ? title : null)
              }
            >
              <div className="uspBox">
                <FontAwesomeIcon icon={faCheck} className="faIcon" />
                <div>{title}</div>
              </div>
            </div>
          );
        })}
      </div>
    </UspDropdownsWrapper>
  );
};

const UspDropdownsWrapper = styled.div`
  margin: 20px;
  margin-top: 40px;
  margin-bottom: 0px;
  color: ${darkBlue2};
  background-color: ${white};
  border-radius: 5px;

  font-family: 'Public Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
  letter-spacing: 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 1000px) {
    display: none;
  }
  .content {
    padding: 24px 24px 18px 24px;
    max-width: 600px;
    background-color: ${lightGrey3};
    border-radius: 5px;
  }
  .uspBox {
    display: flex;
    align-items: center;
    // justify-content: center;

    .faIcon {
      color: ${green};
      margin-right: 14px;
    }
  }
`;

export const DropDownContent = ({ textContent, isVisible }) => {
  return (
    isVisible && <DropDownContentWrapper>{textContent}</DropDownContentWrapper>
  );
};

const DropDownContentWrapper = styled.div`
  padding-top: 15px;
  grid-column: 1/4;
  font-size: 15px;
`;

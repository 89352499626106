import React, { Dispatch, SetStateAction, useEffect, useState, useContext} from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import CabinClassDropdown from './CabinClassDropdown';
import {CabinClassPickerWrapper, CabinClassLabelWrapper} from '../styles';
import {CABIN_CLASS} from '../../../../../constants/constants';
import AppContext from 'Context/AppContext'
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface CabinClassPickerProps {
  dropdownVisible: boolean;
  setDropdownVisible: Dispatch<SetStateAction<boolean>>;
}

interface DropDownWrapperProps {
  dropdownVisible: boolean;
}

const DropDownWrapper = styled.div`
  position: absolute;
  display: ${(props: DropDownWrapperProps) => !props.dropdownVisible? 'none' : undefined}
`

const CabinClassPicker = (props: CabinClassPickerProps) => {
  const { dropdownVisible, setDropdownVisible } = props;
  const {control, getValues, setValue} = useFormContext();
  const {cabin} = getValues(['cabin']);
  const [selectedCabin, setSelectedCabin] = useState(cabin || CABIN_CLASS.ECONOMY);
  const [label, setLabel] = useState(cabin || selectedCabin);
  const context = useContext(AppContext);

  useEffect(() => {
    cabin && setSelectedCabin(cabin);
    cabin && setLabel(cabin);
  }, [cabin])

  const handleDone = () => {
    setValue('cabin', selectedCabin);
    context.setCabinClass(selectedCabin);
    setDropdownVisible(false);
  }

  const handleClose = () => {
    setSelectedCabin(cabin);
    setDropdownVisible(false);
  }

  let cabinLabel = label;
  if (label === CABIN_CLASS.PREMIUM_ECONOMY) {
    cabinLabel = 'Premium Economy';
  } else if (label === CABIN_CLASS.FIRST_CLASS) {
    cabinLabel = 'First Class';
  }

  return (
    <CabinClassPickerWrapper>
      <CabinClassLabelWrapper onClick={() => setDropdownVisible(dropdownVisible => !dropdownVisible)}>
        <span>{cabinLabel}</span>
        <FontAwesomeIcon icon={faChevronDown as IconProp} />
      </CabinClassLabelWrapper>
     
      <DropDownWrapper dropdownVisible={dropdownVisible}>
      <Controller
        name="cabin"
        control={control}
        render={() =>
          <CabinClassDropdown 
            setDropdownVisible={setDropdownVisible}
            selectedCabin={selectedCabin}
            setSelectedCabin={setSelectedCabin}
            handleClose={handleClose}
            handleDone={handleDone}
          />
        }
        />
      </DropDownWrapper>
    </CabinClassPickerWrapper>
  )
}

export default CabinClassPicker;








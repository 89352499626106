import React, { Component } from 'react';
import i18n from 'Utils/i18n.js';

import { PressArticlesWrapper } from './styles';

import ES from './assets/ES-logo.png';
import MT from './assets/MT-logo.png';
import BI from './assets/BI-logo.png';
import YH from './assets/YH-logo.png';

export default class PressArticles extends Component {
  render() {
    return (
      <PressArticlesWrapper>
        <div className="pressArticlesGrid">
          <h2>{i18n.t(`Don't just take our word for it`)}</h2>
          <div className="articleCard article1">
            <img src={BI} alt="/" />
            <a
              href="http://www.businessinsider.com/you-can-now-book-a-flight-and-pay-for-it-later-2017-12?IR=T"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."You can now book a flight and pay for it later."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article2">
            <img src={ES} alt="/" />
            <a
              href="https://www.standard.co.uk/lifestyle/travel/flymble-you-can-now-book-a-flight-and-pay-for-it-later-a3711821.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."This platfom allows you to spread the cost of your holiday."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article3">
            <img src={MT} alt="/" />
            <a
              href="https://www.metro.news/reimagining-the-travel-agent/897574/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."Flymble is reimagining the travel agent."'
                )}
              </i>
            </p>
          </div>

          <div className="articleCard article4">
            <img src={YH} alt="/" />
            <a
              href="https://www.metro.news/reimagining-the-travel-agent/897574/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {i18n.t('PressArticles..Read article')}
            </a>
            <p>
              <i>
                {i18n.t(
                  'PressArticles.."The easiest way to fly now and pay later"'
                )}
              </i>
            </p>
          </div>
        </div>
        <div style={{paddingBottom: '50px'}} />

      </PressArticlesWrapper>
    );
  }
}
